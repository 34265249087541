import { get, remove, set } from 'es-cookie';
import { authCookies } from './types';
import { setAuthToken } from '../api/axios';
import { impersonatedUser } from '../api/api-mapping';
import { userCookies } from '../users/types';

export function saveAuthToken(token: string): void {
  setAuthToken(token);
  set(authCookies.TOKEN, token, { expires: 7 });
}

export function getAuthToken(): string | null {
  return impersonatedUser || get(authCookies.TOKEN) || null;
}

export function clearAuthToken(): void {
  setAuthToken(null);
  if (impersonatedUser) {
    window.sessionStorage.removeItem(userCookies.IMPERSONATED_USER);
  } else {
    remove(authCookies.TOKEN);
  }
}
