import { AxiosPromise } from 'axios';
import { axios } from '../../../../api/axios';
import { ApiFilters } from '../../../types';
import { Indicator } from '../types';

export function loadIndicators(
  filters: ApiFilters,
  isForGroup?: boolean
): AxiosPromise<Indicator[]> {
  return axios.post(
    `/api/Analytics/SLCR/DiaryFactsCompareIndicator${
      isForGroup ? 'Groups' : ''
    }`,
    filters
  );
}
