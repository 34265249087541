import { Editor } from '@tiptap/react';
import { useLayer } from '../../../layer-manager/hooks';
import { Button } from './button';
import { LayerPortal } from '../../../layer-manager/layer-portal';
import { LinkAlert } from '../utils/link-alert';
import { getSelectedText } from '../utils/get-selected-text';
import { useContext } from 'react';
import { OtherButtonsMenuContext } from './other-button';
import { Tippy } from '../../tippy/tippy';
import { useIntl } from 'react-intl';
import { Link } from '../icons/link';

interface LinkButtonProps {
  readonly editor: Editor;
  readonly tabIndex: number;
  readonly setKeepControlsVisible: (v: (prev: number) => number) => void;
}

export function LinkButton({
  editor,
  setKeepControlsVisible,
  tabIndex,
}: LinkButtonProps): JSX.Element {
  const linkLayer = useLayer('alert', {
    closeOnShimClick: true,
    showShim: true,
    returnFocus: false,
    onClose: () => {
      editor.chain().focus().run();
      setKeepControlsVisible(v => Math.max(0, v - 1));
    },
  });
  const { closeMenu } = useContext(OtherButtonsMenuContext);
  const intl = useIntl();
  const label = 'richtext.toolbar.link';

  function formatLink(text: string, link: string) {
    const href =
      link.startsWith('//') ||
      link.startsWith('http://') ||
      link.startsWith('https://')
        ? link
        : `https://${link}`;

    if (!link) {
      if (editor.isActive('link')) {
        editor.chain().focus().unsetLink().run();
      }
      return;
    }
    const selectedText = getSelectedText(editor);

    editor
      .chain()
      .focus()
      .setLink({ href })
      .insertContent(text || link)
      .run();

    editor.commands.toggleMark('link');
    if (!selectedText) {
      editor.chain().focus().insertContent(' ').run();
    } else {
      editor.chain().focus().run();
    }
    closeMenu();
  }

  return (
    <>
      <Tippy tooltipContent={label} noWrapper touch={false}>
        <Button
          type="button"
          tabIndex={tabIndex}
          isActive={editor.isActive('link')}
          aria-label={intl.formatMessage({ id: label })}
          onMouseDown={e => {
            e.stopPropagation();
            if (linkLayer.isOpened) {
              linkLayer.layer.close();
            } else {
              setKeepControlsVisible(v => v + 1);

              linkLayer.open();
            }
          }}
        >
          <Link />
        </Button>
      </Tippy>
      <LayerPortal
        layerHandle={linkLayer}
        getContent={layer => (
          <LinkAlert layer={layer} onSave={formatLink} editor={editor} />
        )}
      />
    </>
  );
}
