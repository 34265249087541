import { useConfig } from '../../app/hooks';
import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { Question } from '@yarmill/icons-2';
import { useIntl } from 'react-intl';
import { Tippy } from '../../components/tippy/tippy';

export function Instructions(): JSX.Element | null {
  const instructionsUrl = useConfig('okrInstructionsUrl');
  const intl = useIntl();

  if (!instructionsUrl) {
    return null;
  }

  return (
    <Tippy
      tooltipContent="header.navigation.instructions"
      noWrapper
      touch={false}
    >
      <Button
        as="a"
        href={instructionsUrl}
        target="_blank"
        $appearance={ButtonAppearance.Secondary}
        $appearanceStyle="neutral"
        $iconOnly
        rel="noopener noreferrer"
        aria-label={intl.formatMessage({
          id: 'header.navigation.instructions',
        })}
      >
        <Icon size={IconSize.s24}>
          <Question />
        </Icon>
      </Button>
    </Tippy>
  );
}
