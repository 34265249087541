import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { PlannerEvent } from '../types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';

export interface DeleteEventRequestParams {
  readonly eventId: number;
  readonly userId: UserId | null;
  readonly userGroupId: UserGroupId | null;
}

export function deleteEvent(
  { eventId, userId, userGroupId }: DeleteEventRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<PlannerEvent[]> {
  return axios.delete(`${coreApiTarget}/api/planner`, {
    params: {
      eventId,
      userId: userId || undefined,
      userGroupId: userId ? undefined : userGroupId || undefined,
      instanceCode: devMode ? instance : undefined,
    },
    cancelToken: cancelToken?.token,
  });
}
