import { KeyResult, OkrStatus } from './types';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import {
  DatepickerButton,
  KeyResultsTableTd,
  KeyResultTdBg,
} from '../components-2/okrs/detail/key-result-detail';
import { ChangeEvent, useCallback, useContext, useRef } from 'react';
import { getStatusAppearance } from '../components-2/status';
import { Cal, Level } from '@yarmill/icons-2';

import {
  KeyResultCheckbox,
  KeyResultCheckboxWrapper,
} from '../components-2/okrs/key-result-checkbox';
import { KeyResultStore } from './mobx/key-result-store';
import { Icon, IconSize } from '@yarmill/components';
import debounce from 'lodash.debounce';
import { TextInput, TextInputProps } from '../components-2/text-input';
import { KeyResultsSelectionContext } from './key-results-selection-context';
import { StatusDropdown } from './dropdowns/status';
import { ObjectiveStore } from './mobx/objective-store';
import { DatepickerDropdown } from '../components-2/datepicker-dropdown';
import { ButtonAppearance } from '../components-2/button';
import { FlexCenterContainer } from '../components-2/flex-utils';
import moment from 'moment';
import { useOkrsStore } from './okrs-store-context';

interface KeyResultDetailProps {
  readonly keyResult: KeyResultStore;
  readonly objective: ObjectiveStore;
  readonly isDragged?: boolean;
  readonly save: () => void;
}

interface FormikTextInputProps extends TextInputProps {
  readonly objective: ObjectiveStore;
}
function KeyResultTextInput({
  objective,
  ...props
}: FormikTextInputProps): JSX.Element {
  const [field, meta] = useField(props);
  const formik = useFormikContext<KeyResult>();
  const submitFormDebounced = useRef(
    debounce(() => formik.submitForm(), 500)
  ).current;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const okrsStore = useOkrsStore();

  const onChange = useCallback(
    (e: ChangeEvent) => {
      field.onChange(e);
      objective.hasPendingUpdate = true;
      submitFormDebounced();
    },
    [field, submitFormDebounced, objective]
  );

  return (
    <>
      <KeyResultTdBg
        appearance={getStatusAppearance(formik.values.status ?? undefined)}
      />
      <TextInput
        {...field}
        {...props}
        {...meta}
        autoComplete="off"
        onChange={
          okrsStore.isCurrentUserAllowedToWrite(objective)
            ? onChange
            : undefined
        }
        appearance={getStatusAppearance(formik.values.status ?? undefined)}
        as="textarea"
        rows={1}
        maxRows={2}
        ref={inputRef}
        readOnly={!okrsStore.isCurrentUserAllowedToWrite(objective)}
      />
    </>
  );
}

export const KeyResultDetail = observer(function KeyResultDetail({
  keyResult,
  objective,
  save,
}: KeyResultDetailProps): JSX.Element {
  const formik = useFormik<KeyResult>({
    initialValues: keyResult.toFormValues(),
    onSubmit: (values, formikHelpers) => {
      keyResult.setStatus(values.status);
      keyResult.setTitle(values.title);
      keyResult.setStartValue(values.startValue);
      keyResult.setCurrentValue(values.currentValue);
      keyResult.setTargetValue(values.targetValue);
      keyResult.setTargetDate(values.targetDate);
      save();
      formikHelpers.resetForm({ values: keyResult.toFormValues() });
    },
  });
  const intl = useIntl();
  const selectionStore = useContext(KeyResultsSelectionContext);
  const okrsStore = useOkrsStore();
  const submitFormDebounced = useRef(
    debounce(() => formik.submitForm(), 500)
  ).current;
  const { values } = formik;
  const isDisabled = !okrsStore.isCurrentUserAllowedToWrite(objective);

  const setStatus = useCallback(
    (value: OkrStatus) => {
      formik.setFieldValue('status', value);
      formik.submitForm();
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <>
        <KeyResultsTableTd textAlign="left">
          <KeyResultCheckboxWrapper>
            {keyResult.id ? (
              <KeyResultCheckbox
                appearance={getStatusAppearance(values.status ?? 'not-started')}
                isChecked={selectionStore.selectedKeyResults.has(keyResult.uid)}
                disabled={isDisabled}
                onClick={() =>
                  !isDisabled
                    ? selectionStore.toggleKeyResultSelection(keyResult.uid)
                    : undefined
                }
              />
            ) : (
              <Icon size={IconSize.s16}>
                <Level />
              </Icon>
            )}
          </KeyResultCheckboxWrapper>
        </KeyResultsTableTd>
        <KeyResultsTableTd>
          <FlexCenterContainer>
            <StatusDropdown
              selectedValue={values.status}
              handleSelect={setStatus}
              hideText
              noBackground
              variant="large"
            />
          </FlexCenterContainer>
        </KeyResultsTableTd>
        <KeyResultsTableTd
          appearance={getStatusAppearance(formik.values.status ?? undefined)}
        >
          <KeyResultTextInput name="title" objective={objective} />
        </KeyResultsTableTd>
        <KeyResultsTableTd
          appearance={getStatusAppearance(formik.values.status ?? undefined)}
        >
          <KeyResultTextInput
            name="startValue"
            objective={objective}
            align="right"
          />
        </KeyResultsTableTd>
        <KeyResultsTableTd
          appearance={getStatusAppearance(formik.values.status ?? undefined)}
        >
          <KeyResultTextInput
            name="currentValue"
            objective={objective}
            align="right"
          />
        </KeyResultsTableTd>
        <KeyResultsTableTd
          appearance={getStatusAppearance(formik.values.status ?? undefined)}
        >
          <KeyResultTextInput
            name="targetValue"
            objective={objective}
            align="right"
          />
        </KeyResultsTableTd>
        <KeyResultsTableTd showHover={!isDisabled} appearance="tangerine">
          <KeyResultTdBg appearance="tangerine" />
          <DatepickerDropdown
            name="targetDate"
            label={intl.formatMessage({
              id: 'okrs.form.keyResult.targetDate.label',
            })}
            value={values.targetDate ?? ''}
            onChange={e => {
              formik.handleChange(e);
              submitFormDebounced();
            }}
            setFieldValue={value => {
              formik.setFieldValue('targetDate', value);
              submitFormDebounced();
            }}
            onBlur={formik.handleBlur}
            disabled={isDisabled}
          >
            <DatepickerButton
              as="div"
              $appearance={ButtonAppearance.Secondary}
              $appearanceStyle="tangerine"
              stretch
              readOnly={isDisabled}
            >
              <Icon size={IconSize.s16}>
                <Cal />
              </Icon>
              {values.targetDate ? (
                <FormattedDate
                  value={values.targetDate}
                  day="numeric"
                  month="numeric"
                  year={
                    moment(values.targetDate).isSame(moment(), 'year')
                      ? undefined
                      : 'numeric'
                  }
                />
              ) : (
                <FormattedMessage id="okrs.form.keyResult.targetDate.addDate" />
              )}
            </DatepickerButton>
          </DatepickerDropdown>
        </KeyResultsTableTd>
        <KeyResultsTableTd>&nbsp;</KeyResultsTableTd>
      </>
    </FormikProvider>
  );
});
