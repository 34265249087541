import { Locale } from '../types';
import { useCallback } from 'react';
import { useIntlStore } from '../hooks';
import { useCurrentUserStore } from '../../users/hooks';
import { useSettingsService } from '../../settings/hooks';

export function useChangeLanguageHandler() {
  const intlStore = useIntlStore();
  const currentUser = useCurrentUserStore();
  const settingsService = useSettingsService();

  return useCallback(
    (lang: Locale): void => {
      void intlStore.loadTranslations(lang);

      currentUser.updateSetting('app.language', lang);
      void settingsService.saveSettings(
        'app.language',
        lang,
        {
          userId: currentUser.id,
        },
        false
      );
    },
    [currentUser, intlStore, settingsService]
  );
}
