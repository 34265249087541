import moment from 'moment';
import { DATE_FORMAT } from '../diary/utils';
import { Season } from './types';
import { SeasonStore } from './mobx/season-store';

export function getSeasonMonths(season: Season): string[] {
  const start = moment(season.StartDate).date(1);
  const end = moment(season.EndDate);
  const current = start;

  const months: string[] = [];
  while (current.diff(end) <= 0) {
    months.push(current.format(DATE_FORMAT));
    current.add(1, 'months');
  }

  return months;
}

export function getSeasonCycles(season: Season): string[] {
  const start = moment(season.StartDate);
  const end = moment(season.EndDate);
  const current = start;

  const months: string[] = [];
  while (current.diff(end) <= 0) {
    months.push(current.format(DATE_FORMAT));
    current.add(4, 'weeks');
  }

  return months;
}

export function getSeasonWeeks(season: Season): string[] {
  const start = moment(season.StartDate);
  const end = moment(season.EndDate);
  const current = start;

  const months: string[] = [];
  while (current.diff(end) <= 0) {
    months.push(current.format(DATE_FORMAT));
    current.add(1, 'weeks');
  }

  return months;
}

export function sortSeasonStores(a: SeasonStore, b: SeasonStore): number {
  return b.startYear - a.startYear;
}
