import { FormattedMessage, useIntl } from 'react-intl';
import { ObjectiveValidationMessage } from '../../components-2/okrs/detail/objective-validation-message';
import { observer } from 'mobx-react-lite';
import { ObjectiveStore } from '../mobx/objective-store';
import { RightPanelSectionHeading } from '../../components-2/right-panel';

interface ObjectiveValidationChecksProps {
  readonly objective: ObjectiveStore;
}
export const ObjectiveValidationChecks = observer(
  function ObjectiveValidationChecks({
    objective,
  }: ObjectiveValidationChecksProps): JSX.Element {
    const intl = useIntl();

    return (
      <>
        <RightPanelSectionHeading as="h3" appearance="button10" upperCase>
          <FormattedMessage id="okrs.detail.validation.checks" />
        </RightPanelSectionHeading>
        {objective.errors.map(error => (
          <ObjectiveValidationMessage
            key={error}
            appearance="red"
            title={intl.formatMessage({
              id: `okrs.detail.validation.${error}.title`,
            })}
            description={
              intl.messages[`okrs.detail.validation.${error}.description`]
                ? intl.formatMessage({
                    id: `okrs.detail.validation.${error}.description`,
                  })
                : undefined
            }
          />
        ))}
        {objective.tips.map(tip => (
          <ObjectiveValidationMessage
            key={tip}
            appearance="sand"
            title={intl.formatMessage({
              id: `okrs.detail.validation.${tip}.title`,
            })}
            description={
              intl.messages[`okrs.detail.validation.${tip}.description`]
                ? intl.formatMessage({
                    id: `okrs.detail.validation.${tip}.description`,
                  })
                : undefined
            }
          />
        ))}
        {objective.tips.length === 0 && objective.errors.length === 0 && (
          <ObjectiveValidationMessage
            appearance="green"
            title={intl.formatMessage({
              id: `okrs.detail.validation.noIssues.title`,
            })}
            description={
              intl.messages[`okrs.detail.validation.noIssues.description`]
                ? intl.formatMessage({
                    id: `okrs.detail.validation.noIssues.description`,
                  })
                : undefined
            }
          />
        )}
      </>
    );
  }
);
