import { FormattedDate } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';
import { GoalAttributeStore } from './mobx/goal-attribute-store';
import { useGoalLabelLinkAttributes } from './hooks';
import {
  SeasonGoalCapitalizedDay,
  SeasonGoalTransparentDate,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { Link } from '../components/link';

export interface GoalDayLabelProps {
  attribute: GoalAttributeStore;
  highlighted?: boolean;
}

export const StyledLink = styled(Link)<{ $isHighlighted?: boolean }>`
  ${({ $isHighlighted }) => $isHighlighted && 'color: #4a90e2;'}
`;

export const SeasonGoalDayLabel = observer(function SeasonGoalDayLabel(
  props: GoalDayLabelProps
): JSX.Element {
  const { attribute, highlighted } = props;
  const { to, onClick } = useGoalLabelLinkAttributes(attribute);
  const date = moment(attribute.trainingDayStore.currentDate).toDate();

  return (
    <StyledLink to={to} onClick={onClick} $isHighlighted={highlighted}>
      <SeasonGoalCapitalizedDay
        size={TextSize.s12}
        tag={TextTag.div}
        inheritColor
      >
        <FormattedDate value={date} weekday="short" />
      </SeasonGoalCapitalizedDay>
      <SeasonGoalTransparentDate
        size={TextSize.s10}
        tag={TextTag.div}
        inheritColor
      >
        <FormattedDate value={date} day="numeric" month="numeric" />
      </SeasonGoalTransparentDate>
    </StyledLink>
  );
});
