import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ProfileHeader } from './header';
import { ProfileDetail } from './profile-detail';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useCurrentUserStore, useUsersStore } from '../users/hooks';
import {
  ContentBox,
  PageMainContent,
  PageScrollContainer,
  Text,
  TextSize,
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { Page } from '../page/page';
import { Sidebar } from '../settings/sidebar';

export interface ProfileRouteProps {
  userId?: string;
}

const ProfileWrapper = styled.div`
  max-width: 860px;
  width: 100%;
  margin: 0 auto;

  & > div {
    margin-bottom: 20px;
  }
`;

function Profile(
  props: RouteComponentProps<ProfileRouteProps>
): JSX.Element | null {
  const { match } = props;
  const currentUser = useCurrentUserStore();
  const userId = match.params.userId
    ? Number(match.params.userId)
    : currentUser.id;
  const usersStore = useUsersStore();
  const user =
    usersStore.getUserById(userId) ?? usersStore.getDeactivatedUserById(userId);

  return (
    <Page>
      <Sidebar
        activeView={user?.id !== currentUser.id ? 'users' : 'personal'}
      />
      <PageMainContent>
        <PageScrollContainer>
          {!currentUser.data || !user ? (
            <ContentBox>
              <Text size={TextSize.s14}>
                <FormattedMessage id="settings.users.invalidUser" />
              </Text>
            </ContentBox>
          ) : (
            <ProfileWrapper>
              <ProfileHeader user={user} />
              <ProfileDetail user={user} />
            </ProfileWrapper>
          )}
        </PageScrollContainer>
      </PageMainContent>
    </Page>
  );
}

const WithRouter = withRouter(observer(Profile));

export { WithRouter as Profile };
