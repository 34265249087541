import { RootStore } from '../../app/mobx/root-store';
import { autorun } from 'mobx';

export class InactiveUserService {
  private readonly localStoragePersistKey = 'yarmill-idle-timer';
  private readonly rootStore: RootStore;
  private timeout = 1000 * 60 * 60 * 8;
  private timeoutTracker: ReturnType<typeof setTimeout> | null = null;
  private idleChecker: ReturnType<typeof setInterval> | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    autorun(() => {
      this.cleanUp();
      this.timeout = this.rootStore.configStore.idleTimeout * 1000 * 60 * 60; // hours to ms
      this.startUp();
    });
  }

  private startUp(): void {
    this.tracker();
    this.startIdleChecker();
  }

  private startIdleChecker(): void {
    this.updateExpiredTime();

    this.idleChecker = setInterval(() => {
      const expiredTime = this.getExpiredTime();
      const now = Date.now();
      if (expiredTime < now) {
        this.cleanUp();
        this.onExpired();
      }
    }, 1000);
  }

  private readonly updateExpiredTime = (): void => {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      this.setExpiredTime();
    }, 300);
  };

  private tracker(): void {
    window.addEventListener('mousemove', this.updateExpiredTime);
    window.addEventListener('keydown', this.updateExpiredTime);
  }

  private cleanUp(): void {
    if (this.idleChecker) {
      clearInterval(this.idleChecker);
    }
    window.localStorage.removeItem(this.localStoragePersistKey);
    window.removeEventListener('mousemove', this.updateExpiredTime);
    window.removeEventListener('keydown', this.updateExpiredTime);
  }

  private getExpiredTime(): number {
    let value = window.localStorage.getItem(this.localStoragePersistKey);

    if (value === null) {
      value = this.setExpiredTime();
    }

    return parseInt(value, 10);
  }

  private setExpiredTime(): string {
    const expiredTime = String(Date.now() + this.timeout);
    window.localStorage.setItem(this.localStoragePersistKey, expiredTime);

    return expiredTime;
  }

  private onExpired(): void {
    const redirect = () => (window.location.href = '/');
    this.rootStore.authStore.logOut().finally(redirect);
  }
}
