import { observer } from 'mobx-react-lite';
import {
  ContentWrapper,
  MainPageContentContainer,
} from '../components-2/page-layout';
import { useRouteMatch } from 'react-router-dom';
import { useOkrsStore } from './okrs-store-context';
import { ObjectiveForm } from './objective-form';
import { ObjectiveValidationPanel } from './objective-validation-panel';
import { ObjectiveDetailFooter } from './objective-detail-footer';
import { ObjectiveDetailHeader } from './objective-detail-header';
import { KeyResultsSelectionContext } from './key-results-selection-context';
import { useCallback, useEffect, useRef } from 'react';
import { KeyResultsSelectionStore } from './mobx/key-results-selection-store';
import { ObjectiveStore } from './mobx/objective-store';
import { useWindowSize } from '../utils/use-window-size';
import { ObjectiveActivityLogPanel } from './objective-activity-log-panel';
import Helmet from 'react-helmet';
import { useTheme } from '@yarmill/components';
import { Location, useHistory, useLocation } from '../routes/hooks';
import { UnregisterCallback } from 'history';
import { ObjectiveEvaluation } from './objective-evaluation';

export const ObjectiveDetail = observer(
  function ObjectiveDetail(): JSX.Element {
    const okrsStore = useOkrsStore();
    const {
      params: { id },
    } = useRouteMatch<{ readonly id?: string }>();
    const newObjective = useRef(new ObjectiveStore());
    const objective =
      (id ? okrsStore.getObjective(Number(id)) : undefined) ??
      newObjective.current;
    const selection = useRef(new KeyResultsSelectionStore());
    const history = useHistory();
    const location = useLocation();
    const visibleRightPanel = okrsStore.visibleSidePanel;

    const { width: windowWidth = 0 } = useWindowSize();
    const theme = useTheme();

    const hideRightPanel = useCallback(() => {
      okrsStore.setVisibleRightPanel(null);
    }, [okrsStore]);

    useEffect(() => {
      let unsubscribe: UnregisterCallback;
      function listener(location: Location) {
        if (!location.search.includes('panel')) {
          okrsStore.setVisibleRightPanel(null);
        }
      }
      if (visibleRightPanel) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('panel', visibleRightPanel);
        const path = `/okr/${id}?${searchParams.toString()}`;
        history.push(path);
        unsubscribe = history.listen(listener);
      } else {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('panel');
        const path = `/okr/${id}?${searchParams.toString()}`;
        history.push(path);
      }

      return () => {
        unsubscribe?.();
      };
    }, [visibleRightPanel, id, location.search, history, okrsStore]);

    useEffect(() => {
      if (id) {
        selection.current.clear();
        newObjective.current = new ObjectiveStore();
      }
    }, [id]);

    return (
      <KeyResultsSelectionContext.Provider value={selection.current}>
        {windowWidth < 768 && (
          <Helmet>
            <meta name="theme-color" content={theme.color.neutralPlate} />
          </Helmet>
        )}
        <MainPageContentContainer
          isSidebarVisible={Boolean(visibleRightPanel)}
          windowWidth={windowWidth}
        >
          <ObjectiveDetailHeader />
          <ContentWrapper>
            <ObjectiveForm objective={objective} key={objective.uid} />
            <ObjectiveEvaluation
              objective={objective}
              key={`evaluation-${objective.uid}`}
            />
          </ContentWrapper>
          <ObjectiveDetailFooter objective={objective} />
        </MainPageContentContainer>
        {objective && (
          <ObjectiveValidationPanel
            objective={objective}
            isVisible={visibleRightPanel === 'validation'}
            close={hideRightPanel}
          />
        )}
        {objective && (
          <ObjectiveActivityLogPanel
            objective={objective}
            isVisible={visibleRightPanel === 'activity-log'}
            close={hideRightPanel}
          />
        )}
      </KeyResultsSelectionContext.Provider>
    );
  }
);
