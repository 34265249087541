import { Header } from './header';
import { RemoveObjective } from './buttons/remove-objective';
import { Instructions } from './buttons/instructions';
import { ObjectiveStore } from './mobx/objective-store';
import { useContext } from 'react';
import { KeyResultsSelectionContext } from './key-results-selection-context';
import { observer } from 'mobx-react-lite';
import { RemoveKeyResults } from './buttons/remove-key-results';
import { SelectAllKeyResults } from './buttons/select-all-key-results';
import { CancelSelection } from './buttons/cancel-selection';
import { Rating } from './buttons/rating';
import { SavingStatus } from './saving-status';

interface ObjectiveDetailFooterProps {
  readonly objective?: ObjectiveStore;
}
export const ObjectiveDetailFooter = observer(function ObjectiveDetailFooter({
  objective,
}: ObjectiveDetailFooterProps): JSX.Element {
  const selectionStore = useContext(KeyResultsSelectionContext);

  return selectionStore.selectedKeyResults.size ? (
    <SelectionFooter objective={objective} />
  ) : (
    <StandardFooter objective={objective} />
  );
});

const SelectionFooter = observer(function SelectionFooter({
  objective,
}: ObjectiveDetailFooterProps): JSX.Element {
  return (
    <Header
      inverted
      position="footer"
      left={objective && <RemoveKeyResults objective={objective} />}
      center={objective && <SelectAllKeyResults objective={objective} />}
      right={<CancelSelection />}
    />
  );
});

const StandardFooter = observer(function StandardFooter({
  objective,
}: ObjectiveDetailFooterProps): JSX.Element {
  return (
    <Header
      inverted
      position="footer"
      left={<RemoveObjective objective={objective} />}
      center={
        objective ? (
          <SavingStatus objective={objective} key={objective.id} />
        ) : undefined
      }
      right={
        <>
          <Instructions />
          <Rating objective={objective} />
        </>
      }
    />
  );
});
