import { usePlannerStore } from '../planner-store-context';
import { useCurrentUserStore } from '../../users/hooks';

export function useCurrentUserIsAllowedToWrite() {
  const plannerStore = usePlannerStore();
  const currentUser = useCurrentUserStore();
  return currentUser.hasWritePermission(
    plannerStore.groupId,
    plannerStore.athleteId
  );
}
