import { FormEvent } from 'react';
import debounce from 'lodash.debounce';
import {
  action,
  computed,
  IReactionDisposer,
  observable,
  reaction,
  makeObservable,
} from 'mobx';
import moment from 'moment';

import { updateDayActivityItemValue } from '../api/update-day-activity-item-value';
import { RootStore } from '../../app/mobx/root-store';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import {
  ActivityItem,
  ActivityItemId,
  ActivityState,
  DisabledReason,
} from '../types';
import { DiaryStore } from '../../diary/mobx/diary-store';
import {
  DATE_FORMAT,
  getDateForDayIndex,
  getDateForDayIndexString,
} from '../../diary/utils';
import { updateSeasonActivityItemValue } from '../api/update-season-activity-item-value';
import { formatValueByUnit } from '../utils';
import { PermissionScope } from '../../permissions/types';

export abstract class AbstractActivityStore {
  protected readonly rootStore: RootStore;
  protected readonly diaryStore: DiaryStore;
  protected readonly index: number;
  protected readonly activityItem: ActivityItem;

  @observable
  protected _isSelected = false;

  @observable
  protected hasFocus: boolean = false;

  @observable
  protected userValue: number | string | null = null;

  @observable
  protected apiValue: number | null = null;

  @observable
  private request: RequestStore<boolean> | null = null;

  private reactions: IReactionDisposer[] = [];

  @observable
  private disabledByParent: boolean = false;

  @observable
  public error: string | null = null;

  protected flushDebouncedUpdate: (() => void) | null = null;

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    index: number,
    activityItem: ActivityItem
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.diaryStore = diaryStore;
    this.index = index;
    this.activityItem = activityItem;
    this.registerReactions();
  }

  public get activityItemId(): ActivityItemId {
    return this.activityItem.ActivityItemId;
  }

  @computed
  public get currentValue(): number {
    return Number(this.userValue);
  }

  @computed
  public get summaryValue(): number {
    let value: number = 0;

    if (this.userValue === null && this.apiValue !== null) {
      // In default state before user types anything, we want to show what comes from api
      value = this.apiValue;
    } else if (this.userValue !== null) {
      // If user types something, we want to display this
      value = this.currentValue;
    }

    return value;
  }

  @computed
  public get formattedValue(): number | string {
    let value: number | string = '';

    if (this.userValue === null && this.apiValue !== null) {
      // In default state before user types anything, we want to show what comes from api
      value = this.apiValue;
    } else if (this.userValue !== null) {
      // If user types something, we want to display this
      value = this.userValue;
    }

    if (this.isDisabled) {
      return formatValueByUnit(value, this.activityItem.Unit);
    }

    return value;
  }

  @action
  public setUserValue(newValue: number | string): void {
    this.userValue = newValue;
  }

  @action
  public setApiValue(newValue: number): void {
    this.apiValue = newValue;
  }

  @action
  public disableByParent(): void {
    this.disabledByParent = true;
  }

  @action
  public enableByParent(): void {
    this.disabledByParent = false;
  }

  @action
  public clear(): void {
    this.error = null;
    this.userValue = null;
    this.apiValue = null;
  }

  @computed
  public get status(): AsyncStatus {
    return this.request?.status || AsyncStatus.idle;
  }

  @computed
  public get disabledReason(): DisabledReason | null {
    const disabledByParent = this.disabledByParent;
    const hasPermissionToWrite = this.hasPermissionToWrite;
    const isInAllowedBackfillScope = this.isInAllowedBackfillScope;
    const isEditable = this.isEditable;
    const isSeasonEditable = this.rootStore.configStore.seasonEditable;
    const viewType = this.diaryStore.viewType;
    const diaryType = this.diaryStore.diaryType;
    const isAthlete = this.rootStore.currentUserStore.role === 'athlete';

    if (isAthlete && diaryType === 'plan' && viewType === 'season') {
      return 'no-permissions';
    } else if (
      (diaryType === 'reality' || diaryType === 'plan') &&
      viewType === 'season' &&
      !isSeasonEditable
    ) {
      return 'computed-value';
    } else if (!isEditable) {
      return 'not-editable';
    } else if (disabledByParent) {
      return 'disabled-by-parent-mask';
    } else if (!isInAllowedBackfillScope && !hasPermissionToWrite) {
      if (isAthlete && diaryType === 'plan') {
        return 'no-permissions';
      } else if (!isAthlete) {
        return 'no-permissions';
      }
      return 'out-of-backfill-scope';
    } else if (!isInAllowedBackfillScope) {
      return 'out-of-backfill-scope';
    } else if (!hasPermissionToWrite) {
      return 'no-permissions';
    }

    return null;
  }

  @computed
  public get isDisabled(): boolean {
    const disabledByParent = this.disabledByParent;
    const hasPermissionToWrite = this.hasPermissionToWrite;
    const isInAllowedBackfillScope = this.isInAllowedBackfillScope;
    const isEditable = this.isEditable;

    return (
      disabledByParent ||
      !hasPermissionToWrite ||
      !isInAllowedBackfillScope ||
      !isEditable
    );
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  public get placeholder(): string | undefined {
    return undefined;
  }

  @action
  public onFocus(): void {
    this.hasFocus = true;
    this.userValue = this.apiValue;
    this.rootStore.focusedDayService.setFocusedDay(this.index);
  }

  @action
  public onBlur(): void {
    this.hasFocus = false;

    if (this.currentValue === 0) {
      this.setUserValue('');
      this.error = null;
    }
    this.updateActivityValueDebounced.flush();
  }

  @computed
  public get hasValue(): boolean {
    return Boolean(this.apiValue || this.userValue);
  }

  public abstract onChange(e: FormEvent<HTMLInputElement>): void;

  @computed
  protected get isEditable(): boolean {
    return this.isEditableInternal;
  }

  @computed
  protected get isEditableInternal(): boolean {
    const diaryType = this.diaryStore.diaryType;
    const viewType = this.diaryStore.viewType;
    const isSeasonEditable = this.rootStore.configStore.seasonEditable;

    return (
      (this.activityItem.IsEditable && viewType === 'week') ||
      (this.activityItem.IsAnnualPlanEditable &&
        viewType === 'season' &&
        diaryType === 'plan') ||
      (viewType === 'season' && diaryType === 'reality' && isSeasonEditable)
    );
  }

  @computed
  private get permissionScope(): PermissionScope {
    const diaryType = this.diaryStore.diaryType;
    const viewType = this.diaryStore.viewType;

    if (viewType === 'goals') {
      return `${diaryType}.goals.write`;
    }

    if (viewType === 'seasonGoals') {
      return 'plan.seasonGoals.write';
    }

    return `${diaryType}.${viewType}.activities.write`;
  }

  @computed
  protected get hasPermissionToWrite(): boolean {
    const currentUser = this.rootStore.currentUserStore;
    const permissionScope = this.permissionScope;
    const isAllowedToWrite = currentUser.isAllowedTo(permissionScope);
    const athleteId = this.diaryStore.athleteId;
    const groupId = this.diaryStore.groupId;

    if (athleteId) {
      return (
        isAllowedToWrite &&
        currentUser.getPermissionToUser(athleteId) === 'write'
      );
    } else if (groupId) {
      return (
        isAllowedToWrite &&
        currentUser.getPermissionToGroup(groupId) === 'write'
      );
    } else {
      return false;
    }
  }

  @computed
  private get isInAllowedBackfillScope(): boolean {
    if (!this.diaryStore.applyBackfillScope) {
      return true;
    }

    if (this.diaryStore.viewType === 'week') {
      const currentDate = getDateForDayIndex(this.diaryStore.week, this.index);
      return currentDate.diff(this.diaryStore.lastWritableDay!) >= 0;
    } else {
      const season = this.diaryStore.currentSeason;
      const currentDate = moment(
        season?.seasonSections[this.index],
        DATE_FORMAT
      );
      const lastWritableDay = this.diaryStore
        .lastWritableDay!.clone()
        .startOf('month');

      return currentDate.diff(lastWritableDay) >= 0;
    }
  }

  private async updateActivityValue(): Promise<void> {
    const value = this.currentValue;
    const userGroupId = this.diaryStore.athleteId
      ? null
      : this.diaryStore.groupId;
    const userId = this.diaryStore.athleteId;
    const season = this.diaryStore.currentSeason;
    const week = this.diaryStore.week;
    if (this.userValue === null || value === this.apiValue) {
      return;
    }

    if (this.request?.status === AsyncStatus.rejected) {
      this.rootStore.requestsStore.removeRequest(this.request);
    }
    const transaction = this.rootStore.navbarStore.createTransaction(
      'loading',
      'diary'
    );

    if (this.diaryStore.viewType === 'week') {
      this.request = this.rootStore.requestsStore.createRequest(() =>
        updateDayActivityItemValue({
          activityItemId: this.activityItem.ActivityItemId,
          day: getDateForDayIndexString(this.diaryStore.week, this.index),
          state: this.getState(),
          userGroupId,
          userId,
          value,
        })
      );
    } else if (this.diaryStore.viewType === 'season') {
      if (!season) {
        return;
      }

      this.request = this.rootStore.requestsStore.createRequest(() =>
        updateSeasonActivityItemValue({
          activityItemId: this.activityItem.ActivityItemId,
          seasonId: season.id,
          startDate: season.seasonSections[this.index],
          state: this.getState(),
          userGroupId,
          userId,
          value,
        })
      );
    }

    const response = await this.request?.getResponse();
    if (response) {
      transaction.success();
      if (
        (userId ? userId === this.diaryStore.athleteId : true) &&
        (userGroupId ? userGroupId === this.diaryStore.groupId : true) &&
        week === this.diaryStore.week &&
        season === this.diaryStore.currentSeason
      ) {
        this.apiValue = value || null;
      }
    } else {
      transaction.error();
    }
  }

  private getState(): ActivityState {
    if (
      this.diaryStore.diaryType === 'plan' &&
      this.diaryStore.viewType === 'week'
    ) {
      return 'P';
    } else if (this.diaryStore.diaryType === 'reality') {
      return 'R';
    } else {
      return 'O';
    }
  }

  private readonly updateActivityValueDebounced = debounce(
    () => this.updateActivityValue(),
    500
  );

  private registerReactions() {
    const updateDisposer = reaction(
      () => this.currentValue,
      this.updateActivityValueDebounced
    );
    this.reactions.push(updateDisposer);
  }

  @action
  public select(): void {
    this.userValue = this.apiValue;
    this._isSelected = true;
  }

  @action
  public unselect(): void {
    this._isSelected = false;
  }

  public get isSelected(): boolean {
    return this._isSelected;
  }

  @action
  public empty(): void {
    this.userValue = '';
  }
}
