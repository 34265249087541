import {
  getRatingMetricAppearanceIcon,
  RatingMetricIcon,
  RatingMetricProps,
} from './rating-metric';
import { Color, styled } from '@yarmill/components';
import { Text } from '../../text';

interface ObjectiveValidationMessageProps {
  readonly appearance: RatingMetricProps['appearance'];
  readonly title: string;
  readonly description?: string;
}

const ObjectiveValidationMessageLayout = styled.div`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.size.x2} auto;
  column-gap: ${({ theme }) => theme.size.x1};
  row-gap: ${({ theme }) => theme.size.x05};
  align-items: center;
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.size.x2};
  }
`;

const Headline = styled(Text)<{ readonly $color: Color }>`
  color: ${({ theme, $color: color }) =>
    color ? theme.color[color] : theme.color.green};
`;

const Description = styled(Text)`
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  color: ${({ theme }) => theme.color.white};
`;

export function ObjectiveValidationMessage({
  appearance,
  title,
  description,
}: ObjectiveValidationMessageProps): JSX.Element {
  return (
    <ObjectiveValidationMessageLayout>
      <RatingMetricIcon $appearance={appearance}>
        {getRatingMetricAppearanceIcon(appearance)}
      </RatingMetricIcon>
      <Headline $color={appearance} appearance="label10strong">
        {title}
      </Headline>
      {description && (
        <Description appearance="label10">{description}</Description>
      )}
    </ObjectiveValidationMessageLayout>
  );
}
