import { observer } from 'mobx-react-lite';
import { FormFieldProps } from './form-field';
import { useErrorMessage, useInvalidFieldFocusHandler } from '../hooks';
import { useIntl } from 'react-intl';
import { getFormFieldAttributes } from '../utils';
import { PatternInput } from '@yarmill/components';
import { validateTimeOfDayValue } from '../validation';

export const TimeOfDay = observer(function Time(
  props: FormFieldProps
): JSX.Element {
  const { attributeStore, autoFocus } = props;
  const intl = useIntl();
  const elementRef =
    useInvalidFieldFocusHandler<HTMLInputElement>(attributeStore);
  const commonAttributes = getFormFieldAttributes(attributeStore, intl);
  const error = useErrorMessage(attributeStore);
  const isCondensedObject =
    attributeStore.objectDataStore.objectStore.definition.Features?.Condensed;

  return (
    <PatternInput
      {...commonAttributes}
      autoFocus={autoFocus}
      id={String(attributeStore.id)}
      onChange={attributeStore.onChange}
      onBlur={attributeStore.onBlur}
      onFocus={attributeStore.onFocus}
      error={error}
      value={attributeStore.formValue}
      pattern="HH:MM"
      validateValue={validateTimeOfDayValue}
      delimiter=":"
      ref={elementRef}
      noLabel={isCondensedObject && !commonAttributes.label}
      noError={isCondensedObject && !error}
    />
  );
});
