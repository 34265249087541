import { observer } from 'mobx-react-lite';
import { YollandaFeedback } from './types';
import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
  YollandaLikeButtonsTooltipWrapper,
} from '@yarmill/components';
import { Tippy } from '../components/tippy/tippy';
import { MouseEventHandler } from 'react';

interface FeedbackButtonProps {
  readonly value: YollandaFeedback;
  readonly isDisabled: boolean;
  readonly showTooltip: boolean;
  readonly sendFeedback: MouseEventHandler;
}

export const FeedbackButton = observer(function FeedbackButton({
  value,
  isDisabled,
  showTooltip,
  sendFeedback,
}: FeedbackButtonProps) {
  return (
    <Tippy
      tooltipContent="yollanda.feedback.allFeedbackSent"
      isEnabled={showTooltip}
      noWrapper={!showTooltip}
      Wrapper={YollandaLikeButtonsTooltipWrapper}
    >
      <Button
        type="button"
        appearance={ButtonAppearance.Link}
        small
        square
        value={value}
        disabled={isDisabled}
        onClick={sendFeedback}
      >
        <Icon size={IconSize.s16}>
          <ExternalIcon
            name={value === YollandaFeedback.like ? 'ThumbUp' : 'ThumbDown'}
          />
        </Icon>
      </Button>
    </Tippy>
  );
});
