import { RefObject, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useRootStore } from '../app/root-store-context';
import { isSafariBrowser } from '../utils/is-safari-browser';
import { useIsElementInViewport } from '../utils/use-is-element-in-viewport';
import { useEvidenceDataStore } from './hooks/use-evidence-data-store';
import { EvidenceAttributeStore } from './mobx/evidence-attribute-store';
import { EvidenceObjectStore } from './mobx/evidence-object-store';
import { EvidenceStore } from './mobx/evidence-store';
import { isFirstInvalidField } from './utils';
import { getErrorMessage } from './validation';

export function useEvidenceStore(): EvidenceStore {
  const rootStore = useRootStore();
  return rootStore.evidenceStore;
}

export function useEvidenceObjectStore(
  objectKey: string
): EvidenceObjectStore | undefined {
  const dataStore = useEvidenceDataStore();
  return dataStore?.getObjectStore(objectKey);
}

export function useErrorMessage(
  attributeStore: EvidenceAttributeStore
): string | undefined {
  const intl = useIntl();
  const submitClicked = attributeStore.objectDataStore.submitButtonClicked;
  const type = attributeStore.attributeType;
  const hasValue = attributeStore.hasValue;
  const isRequired = attributeStore.isRequired;
  const isValid = attributeStore.isValid;

  return useMemo(
    () =>
      submitClicked
        ? getErrorMessage(
            type,
            hasValue,
            isRequired,
            isValid,
            intl,
            attributeStore.definition.Format
          )
        : undefined,
    [
      intl,
      type,
      hasValue,
      isRequired,
      isValid,
      submitClicked,
      attributeStore.definition.Format,
    ]
  );
}

export function useInvalidFieldFocusHandler<T extends HTMLElement>(
  attributeStore: EvidenceAttributeStore,
  customRef?: { current: { focus(): void } }
): RefObject<T> {
  const elementRef = useRef<T>(null);
  const submitButtonClicked =
    attributeStore.objectDataStore.submitButtonClicked;

  useEffect(() => {
    if (
      submitButtonClicked &&
      !attributeStore.isValid &&
      isFirstInvalidField(attributeStore)
    ) {
      customRef?.current?.focus() || elementRef.current?.focus();
    }
  }, [attributeStore, submitButtonClicked, customRef]);

  return elementRef;
}

function getRootMargin() {
  if (typeof window === 'undefined') {
    return undefined;
  }

  return `0px 0px -${(window.innerHeight * 2) / 3}px 0px`;
}

export function useSetVisibleCategory(
  objectKey: string,
  categoryRef: RefObject<HTMLDivElement>
): void {
  const evidenceStore = useEvidenceStore();
  const dataStore = useEvidenceDataStore();
  const isInViewport = useIsElementInViewport(categoryRef, {
    rootMargin: getRootMargin(),
  });

  useEffect(() => {
    if (isInViewport && dataStore?.hasLoadedData) {
      setTimeout(() => {
        evidenceStore.setScrolledCategory(objectKey);
      }, 100);
    }
  }, [dataStore?.hasLoadedData, evidenceStore, isInViewport, objectKey]);
}

export function useCategoryScroller(
  containerRef: RefObject<HTMLDivElement>
): void {
  const evidenceStore = useEvidenceStore();

  useEffect(() => {
    if (typeof ResizeObserver === 'undefined' || !isSafariBrowser()) {
      return;
    }
    let scrolledUser = evidenceStore.userId;

    function onResize(): void {
      const sectionId = evidenceStore.scrolledCategory;
      if (!sectionId) {
        return;
      }
      const section: HTMLDivElement | null = document.querySelector(
        `#${sectionId}`
      );

      if (scrolledUser !== evidenceStore.userId) {
        scrolledUser = evidenceStore.userId;
        section?.scrollIntoView({ behavior: 'auto', block: 'start' });
      }
    }
    const observer = new ResizeObserver(onResize);
    const container = containerRef.current;

    if (container) {
      observer.observe(container);
    }

    return () => observer.disconnect();
  }, [containerRef, evidenceStore]);
}
