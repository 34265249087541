import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { FilesOverviewTag } from '../types';

export function getTags(
  cancelToken?: CancelTokenSource
): AxiosPromise<FilesOverviewTag[]> {
  return axios.get(`api/tags?category=attachment`, {
    cancelToken: cancelToken?.token,
  });
}
