import {
  FieldSet,
  FormControlVariant,
  LoginFormButtonsWrapper,
  LoginPageLayout,
  PasswordRequirementsList,
  SimplePageLayout,
  Text,
  TextAlignment,
  TextInput,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { FormEvent, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { useAuthStore } from '../auth/hooks';
import { saveAuthToken } from '../auth/utils';
import { AsyncButton } from '../components/async-button/async-button';
import { getQueryParam } from '../utils/get-query-param';
import { AsyncState, useAsyncState } from '../utils/use-async-state';

function validateMinChars(password: string): boolean {
  return password.length >= 6;
}
function validateNumber(password: string): boolean {
  return /\d/.test(password);
}
function validateCharacters(password: string): boolean {
  return /(?=.*[a-z])(?=.*[A-Z])/.test(password);
}

export function ResetPassword(): JSX.Element {
  const authStore = useAuthStore();
  const [newPassword, setNewPassword] = useState<string>('');
  const [asyncState, setAsyncState] = useAsyncState();
  const token = getQueryParam('token');
  const intl = useIntl();

  const onChange = useCallback((e: FormEvent<HTMLInputElement>): void => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      const { value } = target;
      setNewPassword(value);
    }
  }, []);

  const onSubmit = useCallback(
    async (e: FormEvent): Promise<void> => {
      e.preventDefault();
      setAsyncState(AsyncState.pending);
      try {
        await authStore.resetForgottenPassword(newPassword);
        window.location.href = '/settings/personal';
      } catch (e) {
        setAsyncState(AsyncState.rejected);
      }
    },
    [authStore, newPassword, setAsyncState]
  );

  if (token) {
    saveAuthToken(token);

    return <Redirect to="/resetPassword" />;
  }

  return (
    <SimplePageLayout>
      <LoginPageLayout>
        <Text
          tag={TextTag.h1}
          size={TextSize.s24}
          textAlign={TextAlignment.center}
        >
          <FormattedMessage id="settings.profile.resetPassword" />
        </Text>
        <form className="big-form reset-password-form" onSubmit={onSubmit}>
          <FieldSet disabled={asyncState === AsyncState.pending}>
            <TextInput
              variant={FormControlVariant.big}
              className="form-control"
              value={newPassword}
              type="password"
              name="newPassword"
              id="newPassword"
              onChange={onChange}
              autoComplete="new-password"
              label={intl.formatMessage({ id: 'settings.profile.newPassword' })}
            />
            <PasswordRequirementsList>
              {!validateMinChars(newPassword) && (
                <li>
                  <Text size={TextSize.s14} inheritColor>
                    <FormattedMessage id="settings.profile.minChars" />
                  </Text>
                </li>
              )}
              {!validateNumber(newPassword) && (
                <li>
                  <Text size={TextSize.s14} inheritColor>
                    <FormattedMessage id="settings.profile.hasNumber" />
                  </Text>
                </li>
              )}
              {!validateCharacters(newPassword) && (
                <li>
                  <Text size={TextSize.s14} inheritColor>
                    <FormattedMessage id="settings.profile.hasCharacters" />
                  </Text>
                </li>
              )}
            </PasswordRequirementsList>
            <LoginFormButtonsWrapper>
              <AsyncButton
                variant={FormControlVariant.big}
                type="submit"
                disabled={
                  !validateMinChars(newPassword) ||
                  !validateNumber(newPassword) ||
                  !validateCharacters(newPassword)
                }
                loading={asyncState === AsyncState.pending}
                success={asyncState === AsyncState.resolved}
                error={asyncState === AsyncState.rejected}
              >
                <FormattedMessage id="settings.profile.submit" />
              </AsyncButton>
            </LoginFormButtonsWrapper>
          </FieldSet>
        </form>
      </LoginPageLayout>
    </SimplePageLayout>
  );
}
