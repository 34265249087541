import { AxiosPromise } from 'axios';
import { ConflictResolution, CopyConflictResponse } from '../../copy/types';
import { axios } from '../../api/axios';

export interface CopyAttendanceRequestParams {
  UserGroupId: number;
  StartDate: string;
  EndDate: string;
  ConflictResolution: ConflictResolution;
}

export function copyAttendance(
  params: CopyAttendanceRequestParams
): AxiosPromise<boolean | CopyConflictResponse> {
  return axios.post('api/attendance/copy/UserPlanToReality', params);
}
