import { ObjectiveStore } from '../mobx/objective-store';
import { useCallback } from 'react';
import moment from 'moment/moment';
import { useCurrentUserStore } from '../../users/hooks';
import { useOkrsStore } from '../okrs-store-context';
import { when } from 'mobx';
import { useRootStore } from '../../app/root-store-context';
import { addObjectiveEvaluation } from '../api/add-objective-evaluation';

export function useAddEvaluationHandler(
  objective: ObjectiveStore
): (evaluationText: string) => Promise<void> {
  const okrsStore = useOkrsStore();
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();

  return useCallback(
    async (evaluationText: string) => {
      const evaluation = objective.evaluations.find(
        e => e.createdBy === currentUser.id
      );

      if (evaluation) {
        if (evaluation.evaluationText) {
          evaluation.evaluationText = evaluationText;
        } else {
          objective.evaluations.remove(evaluation);
        }
      } else {
        objective.evaluations.unshift({
          createdBy: currentUser.id,
          evaluationText,
          dateCreated: moment.utc().format(),
        });
      }

      let saveAttempts = 0;
      async function saveEvaluation() {
        const request = rootStore.requestsStore.createRequest(() =>
          addObjectiveEvaluation({ objectiveId: objective.id!, evaluationText })
        );
        saveAttempts++;

        const response = await request.getResponse();

        if (request.statusCode === 200 && response) {
          objective.setLastUpdateDate(moment.utc().format());
          if (Array.isArray(response)) {
            objective.setActivities(response);
          }
        } else {
          setTimeout(() => {
            saveEvaluation();
          }, 2000 * saveAttempts);
        }
      }

      if (!objective.id) {
        await okrsStore.saveObjective(objective);
        when(
          () => objective.id !== null,
          () => saveEvaluation()
        );
      } else {
        await saveEvaluation();
      }
    },
    [objective, currentUser, okrsStore, rootStore]
  );
}
