import { ReportItemStore } from './types';
import {
  ReportDescription,
  ReportTitleLayout,
  Text,
  TextAlignment,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { useIntl } from 'react-intl';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { observer } from 'mobx-react-lite';

interface ReportTitleProps {
  readonly report: ReportItemStore;
}
export const ReportTitle = observer(function ReportTitle({
  report,
}: ReportTitleProps) {
  const intl = useIntl();
  const hasTitle = report.title && intl.messages[report.title];
  const hasDescription =
    report.description && intl.messages[report.description];
  const titleAlignment = report.titleAlignment ?? TextAlignment.left;
  const dataStore = useReportingDataStore();
  const data = dataStore?.getReportData(report.code);
  const descriptiveData = data?.Metadata?.DescriptiveData ?? {};

  return (
    <ReportTitleLayout position={report.titlePosition}>
      {hasTitle && (
        <Text
          size={TextSize.s16}
          tag={TextTag.div}
          medium
          textAlign={titleAlignment}
        >
          <FormattedHTMLMessage id={report.title} values={descriptiveData} />
        </Text>
      )}
      {hasDescription && (
        <ReportDescription
          size={TextSize.s12}
          tag={TextTag.div}
          textAlign={titleAlignment}
        >
          <FormattedHTMLMessage
            id={report.description}
            values={descriptiveData}
          />
        </ReportDescription>
      )}
    </ReportTitleLayout>
  );
});
