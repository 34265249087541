import styled from 'styled-components';
import { Status } from './status';

export interface NavbarProps {
  leftItems?: JSX.Element | JSX.Element[];
  rightItems?: JSX.Element | JSX.Element[];
}

const StyledNavbar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    margin-bottom: unset;
  }

  @media (min-width: 1116px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledLeftItemsWrapper = styled.div`
  width: 100%;

  @media (min-width: 1116px) {
    width: auto;
  }
`;

const StyledLeftItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  @media (min-width: 1116px) {
    margin-bottom: 0;
    justify-content: start;
  }
`;

const StyledRightItemsWrapper = styled.div`
  width: 100%;

  @media (min-width: 1116px) {
    width: auto;
  }
`;

const StyledRightItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
`;

export function Navbar(props: NavbarProps): JSX.Element {
  const { leftItems, rightItems } = props;
  return (
    <StyledNavbar>
      <StyledLeftItemsWrapper>
        <StyledLeftItems>{leftItems}</StyledLeftItems>
      </StyledLeftItemsWrapper>
      <StyledRightItemsWrapper>
        <StyledRightItems>
          <Status />
          {rightItems}
        </StyledRightItems>
      </StyledRightItemsWrapper>
    </StyledNavbar>
  );
}
