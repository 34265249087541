import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { UserSummaryAttendance, WeekAttendanceItem } from '../types';

export interface GetWeekAttendanceItemsRequestParams {
  UserGroupId: number;
  StartDate: string;
  EndDate: string;
  ActivityItemId: number | null;
}

export function getWeekAttendanceItems(
  params: GetWeekAttendanceItemsRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<WeekAttendanceItem[] | UserSummaryAttendance[]> {
  return axios.post('api/attendance/week/group', params, {
    cancelToken: cancelToken?.token,
  });
}
