import { observer } from 'mobx-react-lite';
import { ProfileHeader } from '../../profile/header';
import { useRootStore } from '../../app/root-store-context';
import { useEvidenceStore } from '../hooks';

export const EvidenceProfileHeader = observer(function EvidenceProfileHeader() {
  const rootStore = useRootStore();
  const evidenceStore = useEvidenceStore();
  const user = rootStore.usersStore.getUserById(evidenceStore.userId);

  return user ? <ProfileHeader user={user} noEditButton /> : null;
});
