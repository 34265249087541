import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { instance } from '../api/api-mapping';
import { useConfig } from '../app/hooks';
import { useLocale } from '../intl/hooks';
import { useLocation } from '../routes/hooks';
import { useCurrentUserStore } from '../users/hooks';
import { UserId } from '../users/types';
import { getPageFromLocation, trackPageView, trackUserId } from './utils';

declare global {
  interface Window {
    dataLayer: Record<string, string>[];
  }
}

const GLOBAL_ANALYTICS_ID = 'G-CTZT2TD7MS';

function initGA(currentUserId: UserId): void {
  if (typeof window.gtag === 'undefined' && currentUserId) {
    const element = document.createElement('script');
    element.async = false;
    element.src = `https://www.googletagmanager.com/gtag/js?id=${GLOBAL_ANALYTICS_ID}`;
    const head = document.querySelector('head');
    const element2 = document.createElement('script');
    element2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GLOBAL_ANALYTICS_ID}');
      `;

    if (head) {
      head.insertAdjacentElement('afterbegin', element);
      head.insertAdjacentElement('afterbegin', element2);
    }

    trackUserId(`${instance}-${currentUserId}`);
  }
}

export const GoogleAnalytics = observer(
  function GoogleAnalytics(): JSX.Element | null {
    const currentUser = useCurrentUserStore();
    const location = useLocation();
    const page = getPageFromLocation(location);
    const lang = useLocale();
    const instanceName = useConfig('appName');
    const currentUserId = currentUser.id;

    useEffect(() => {
      if (currentUserId !== -1) {
        initGA(currentUserId);
      }
    }, [currentUserId]);

    useEffect(() => {
      if (page && currentUser.data) {
        trackPageView(GLOBAL_ANALYTICS_ID, currentUser.data);
      }
    }, [page, currentUser.data]);

    return (
      <Helmet>
        <html lang={lang} />
        {instanceName && <title>{`${instanceName} | Yarmill`}</title>}
      </Helmet>
    );
  }
);
