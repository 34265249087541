import { LABEL_FONT_SIZE, LABEL_OFFSET } from '../../reporting/const';
import { LabelPosition } from '../../../reporting/types';
import { getRotatedHeight } from '../../reporting/utils/get-rotated-height';

export function getBarLabelOffset(
  barHeight: number,
  yValue: number | null,
  position?: LabelPosition,
  labelAngle: number = 0,
  labelWidth: number = 0
): number {
  let offset;

  if (yValue === null) {
    return 0;
  }

  const labelHeight =
    getRotatedHeight(labelWidth, LABEL_FONT_SIZE, labelAngle) / 2;

  switch (position) {
    case 'inside-center': {
      offset = -1 * (barHeight / 2);
      break;
    }
    case 'inside-top':
      offset = -LABEL_OFFSET - labelHeight / 2;
      break;
    case 'outside':
    default:
      offset = Math.max(LABEL_OFFSET, labelHeight);
      break;
  }

  return yValue < 0 ? -1 * offset : offset;
}
