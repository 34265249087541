import * as React from 'react';
import styled, { css } from 'styled-components';

import { FormControlVariant } from '../helpers';
import { Icon, IconSize } from '../icon';
import { ComponentType, Fragment, useRef } from 'react';
import { useTextAreaAutosize } from '../../utils/use-textarea-autosize';
import { useMergeRefs } from '@floating-ui/react';

export interface StyledInputProps {
  readonly variant?: FormControlVariant;
  readonly error?: string;
  readonly icon?: JSX.Element;
  readonly align?: 'left' | 'right';
}

const focusStyle = css`
  box-shadow: 0 0 5px #4a90e2;
  border-color: #4a90e2;
  color: #495057;
  outline: none;
`;

export const baseInputStyle = css<StyledInputProps>`
  display: block;
  width: 100%;
  max-width: 100%;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  text-align: ${({ align }) => align ?? 'unset'};

  ${({ icon }) => icon && `padding-left: 30px;`};

  ::placeholder {
    font-weight: 200;
  }

  :focus {
    ${focusStyle}

    ${props =>
      props.error &&
      css`
        box-shadow: 0 0 5px #d0021b;
        border-color: #d0021b;
      `}
  }

  :disabled {
    background-color: #f5f5f5;
    color: #495057;
    cursor: not-allowed;
  }

  ${props =>
    props.variant === FormControlVariant.big &&
    css`
      border: 1px solid #979797;
      border-radius: 8px;
      height: 65px;
      font-size: 18px;
      padding: 15px;
      ${props.icon && `padding-left: 60px;`};
    `}

  ${props =>
    props.error &&
    css`
      border-color: #d0021b;
    `}
`;

export const StyledInput = styled.input`
  ${baseInputStyle}
`;
const StyledTextArea = styled.textarea`
  ${baseInputStyle};
  height: auto;
  min-height: 36px;
  resize: none;
  overflow-y: hidden;
`;

export const StyledLabel = styled.label<StyledInputProps>`
  display: inline-block;
  margin-bottom: 0.25rem;
  font-size: 14px;
  line-height: 1.5;
  color: #4a4a4a;
  padding-left: 1px;
  font-family: 'Ubuntu';

  ${props =>
    props.variant === FormControlVariant.big &&
    css`
      font-weight: 500;
    `}
`;

export const StyledError = styled.div`
  display: block;
  margin-top: 0.25rem;
  color: #dc3545;
  font-size: 12px;
  line-height: 1.5;
  padding-left: 1px;
`;

export const StyledHelp = styled.div`
  display: block;
  margin-bottom: 0.25rem;
  color: #9a9a9a;
  font-size: 12px;
  padding-left: 1px;
`;

export const StyledOptionalMessage = styled.span`
  color: #9a9a9a;
  font-weight: 400;
  padding-left: 4px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div<{
  readonly variant?: FormControlVariant;
}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  color: #4a4a4a;
  ${({ variant }) =>
    variant === FormControlVariant.big &&
    css`
      left: 20px;
    `}
`;

interface CustomTextInputProps {
  readonly label?: string;
  readonly id: string;
  readonly error?: string;
  readonly help?: string;
  readonly optional?: boolean;
  readonly optionalMessage?: string;
  readonly noLabel?: boolean;
  readonly noError?: boolean;
  readonly align?: 'left' | 'right';
  readonly autoSize?: boolean;
  tooltip?: ComponentType<any>;
}

export type TextInputProps = CustomTextInputProps &
  StyledInputProps &
  React.InputHTMLAttributes<HTMLInputElement>;

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref): JSX.Element => {
    const {
      label,
      optional,
      help,
      error,
      noError,
      noLabel,
      optionalMessage,
      variant,
      icon,
      tooltip,
      type = 'text',
    } = props;

    const TooltipWrapper = tooltip ?? Fragment;
    return (
      <>
        {!noLabel && (
          <StyledLabel variant={variant} htmlFor={props.id}>
            {label}
            {optional && (
              <StyledOptionalMessage>{optionalMessage}</StyledOptionalMessage>
            )}
          </StyledLabel>
        )}
        {help && <StyledHelp>{help}</StyledHelp>}
        <TooltipWrapper>
          <InputWrapper>
            <StyledInput
              {...props}
              type={type}
              ref={ref}
              aria-label={props.label}
            />
            {icon && (
              <IconWrapper variant={variant}>
                <Icon
                  size={
                    variant === FormControlVariant.big
                      ? IconSize.s20
                      : IconSize.s14
                  }
                >
                  {icon}
                </Icon>
              </IconWrapper>
            )}
          </InputWrapper>
        </TooltipWrapper>
        {!noError && <StyledError>{error}&nbsp;</StyledError>}
      </>
    );
  }
);
TextInput.displayName = 'TextInput';

export type TextAreaProps = CustomTextInputProps &
  StyledInputProps &
  React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, passedRef): JSX.Element => {
    const innerRef = useRef<HTMLTextAreaElement>(null);
    const ref = useMergeRefs([innerRef, passedRef]);
    useTextAreaAutosize(innerRef, props.autoSize);

    return (
      <>
        <StyledLabel variant={props.variant} htmlFor={props.id}>
          {props.label}
          {props.optional && (
            <StyledOptionalMessage>
              {props.optionalMessage}
            </StyledOptionalMessage>
          )}
        </StyledLabel>
        {props.help && <StyledHelp>{props.help}</StyledHelp>}
        <StyledTextArea
          {...props}
          ref={ref}
          aria-label={props.label}
          rows={props.rows || 5}
        />
        <StyledError>{props.error}&nbsp;</StyledError>
      </>
    );
  }
);
TextInput.displayName = 'TextInput';
