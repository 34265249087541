import { Placement } from '@floating-ui/react';
import { IconSize } from '@yarmill/components';
import { Coach } from '@yarmill/icons-2';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SupervisorsDropdownButtonWrapper } from '../../components-2/dropdown-provider/dropdown-button-wrapper';
import {
  DropdownOption,
  DropdownProvider,
} from '../../components-2/dropdown-provider/dropdown-provider';
import { SelectedUsers } from '../../components-2/selected-users';
import {
  CoachSymbol,
  RoleAvatarIcon,
} from '../../components-2/sidebar-navigation';
import { UserStore } from '../../users/mobx/user-store';
import { UserId } from '../../users/types';
import { sortUserStores } from '../../users/utils/sort-user-stores';
import { Avatar } from '../avatar';
import { useOkrsStore } from '../okrs-store-context';
import { Supervisors } from '../supervisors';

const createSupervisorsOptions = (
  users: UserStore[]
): DropdownOption<UserId>[] =>
  users.map(userStore => ({
    label: userStore.displayName,
    icon: (
      <SelectedUsers
        avatars={[
          <RoleAvatarIcon
            isCoach={!userStore.isAthlete}
            size={IconSize.s16}
            key={userStore.id}
          >
            <>
              <Avatar avatar={userStore.avatar} />
              {!userStore.isAthlete && (
                <CoachSymbol size={IconSize.s8}>
                  <Coach />
                </CoachSymbol>
              )}
            </>
          </RoleAvatarIcon>,
        ]}
        name={userStore.displayName}
        hideText
        size={IconSize.s16}
      />
    ),
    value: userStore.id,
  }));

interface SupervisorsDropdownProps {
  readonly selectedValue: UserId[] | null;
  readonly handleSelect: (value: UserId) => void;
  readonly placement?: Placement;
  readonly disabled?: boolean;
}

export const SupervisorsDropdown = observer(function SupervisorsDropdown({
  selectedValue,
  handleSelect,
  placement,
  disabled,
}: SupervisorsDropdownProps): JSX.Element {
  const intl = useIntl();
  const okrsStore = useOkrsStore();
  const group = okrsStore.group;
  const athlete = okrsStore.athlete;
  const coaches = athlete?.coaches ?? group?.coaches;
  const supervisors = useMemo(
    () => createSupervisorsOptions(coaches?.slice().sort(sortUserStores) ?? []),

    [coaches]
  );

  return (
    <DropdownProvider
      keyboardShortcut="A"
      options={supervisors}
      selectedValue={selectedValue ?? []}
      searchInputPlaceholder={intl.formatMessage({
        id: 'okrs.form.supervisors.label',
      })}
      searchable
      handleSelect={handleSelect as any}
      multiSelect
      placement={placement}
      disabled={disabled}
    >
      <SupervisorsDropdownButtonWrapper
        appearance="sand"
        noBackground={selectedValue?.length !== 0}
        isEmpty={(selectedValue?.length ?? 0) === 0}
        disabled={disabled}
      >
        <Supervisors
          userIds={selectedValue ?? []}
          hideText={(selectedValue?.length || 0) > 1}
        />
      </SupervisorsDropdownButtonWrapper>
    </DropdownProvider>
  );
});
