import { Sidebar } from './sidebar';
import { DashboardType } from './types';
import { GeneralAnalyticsDashboard } from './dashboards/general/dashboard';
import { Page } from '../page/page';
import { useAnalyticsRouteParamsParser } from './hooks';
import { AnalyticsRouteParamsContext } from './analytics-route-params-context';
import { observer } from 'mobx-react-lite';
import { SpsSlcrAnalyticsDashboards } from './dashboards/sps-slcr';
import { SlcrAnalyticsDashboards } from './dashboards/slcr';
import { PageMainContent, PageScrollContainer } from '@yarmill/components';
import { ComponentType, Fragment } from 'react';

function getDashboardComponent(activeDashboard: DashboardType): ComponentType {
  switch (activeDashboard) {
    case 'general.defaultDashboard':
      return GeneralAnalyticsDashboard;
    case 'slcr.perActivityDashboard':
    case 'slcr.perGroupDashboard':
      return SlcrAnalyticsDashboards;
    case 'sps-slcr.perActivityDashboard':
    case 'sps-slcr.perGroupDashboard':
      return SpsSlcrAnalyticsDashboards;
  }
}

export const Analytics = observer(function Analytics(): JSX.Element {
  const routeParams = useAnalyticsRouteParamsParser();
  const DashboardComponent = getDashboardComponent(routeParams.activeDashboard);

  return (
    <Page>
      <AnalyticsRouteParamsContext.Provider value={routeParams}>
        <Sidebar dashboard={routeParams.activeDashboard} />
        <PageMainContent>
          <PageScrollContainer>
            {DashboardComponent ? <DashboardComponent /> : <Fragment />}
          </PageScrollContainer>
        </PageMainContent>
      </AnalyticsRouteParamsContext.Provider>
    </Page>
  );
});
