import { ActivitiesList } from '../activities/activities-list';
import { SeasonViewLoadingScreen } from './season-view-loading-screen';
import { SeasonHeaderCell } from './season-header-cell';
import { useRootStore } from '../app/root-store-context';
import { AsyncStatus } from '../api/mobx/request-store';
import { observer } from 'mobx-react-lite';
import { useDiaryStore } from './diary-store-context';
import { useCurrentUserStore } from '../users/hooks';
import { CurrentUserStore } from '../users/mobx/current-user-store';
import { DiaryStore } from './mobx/diary-store';
import { PermissionScope } from '../permissions/types';

function isAllowedView(
  currentUser: CurrentUserStore,
  diaryStore: DiaryStore
): boolean {
  const diaryType = diaryStore.diaryType;
  const permissionScope: PermissionScope = `${diaryType}.season.activities`;

  return Boolean(currentUser.isAllowedTo(permissionScope));
}

function InternalSeasonView(): JSX.Element | null {
  const rootStore = useRootStore();
  const diaryStore = useDiaryStore();
  const currentUser = useCurrentUserStore();

  if (!isAllowedView(currentUser, diaryStore)) {
    return null;
  }

  if (rootStore.status !== AsyncStatus.resolved) {
    return <SeasonViewLoadingScreen />;
  }

  const season = rootStore.seasonsStore.getSeasonByDate(diaryStore.week);

  return (
    <ActivitiesList
      columnsCount={season?.seasonSections.length || 12}
      HeaderCellContent={SeasonHeaderCell}
    />
  );
}

export const SeasonView = observer(InternalSeasonView);
