import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';

export interface RegenerateTokenResponse {
  Token: string;
}

export function regenerateToken(): AxiosPromise<RegenerateTokenResponse> {
  return axios.get('api/Account/RegenerateToken');
}
