import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { WorkoutData } from '../../training-day/types';

export interface GetDataServiceOverviewParams {
  userId: number;
  startDate: string;
  endDate: string;
}

export function getDataServiceOverview(
  params: GetDataServiceOverviewParams,
  service: string,
  cancelToken?: CancelTokenSource
): AxiosPromise<WorkoutData[]> {
  return axios.get(`api/data-services/${service}`, {
    params,
    cancelToken: cancelToken?.token,
  });
}
