import { RootStore } from '../../app/mobx/root-store';
import {
  action,
  computed,
  IObservableArray,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
  when,
  makeObservable,
} from 'mobx';
import { WEEK_SEARCH_PARAM } from '../../routes/types';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { SeasonStore } from '../../seasons/mobx/season-store';
import {
  ActivityData,
  ActivityItem,
  ActivityItemId,
  MaskCode,
} from '../../activities/types';
import { SeasonEvaluationStore } from './season-evaluation-store';
import {
  getSingleSeasonData,
  GetSingleSeasonParams,
  GetSingleSeasonResponse,
} from '../api/get-single-season-data';
import { ExpandableTree } from '../../csv-table/mobx/expandable-tree';
import { SeasonEvaluationDiaryType } from '../types';
import { PermissionScope } from '../../permissions/types';
export class SingleSeasonEvaluationStore {
  private readonly rootStore: RootStore;
  private readonly seasonEvaluationStore: SeasonEvaluationStore;
  private readonly ENABLED_DIARY_TYPES_PERSIST_KEY =
    'yarmill-seasonEvaluation-enabledDiaryTypes';
  private readonly maskCode?: MaskCode;

  private _availableDiaryTypes: SeasonEvaluationDiaryType[] = [
    'annualPlan',
    'plan',
    'reality',
  ];

  @observable
  private _enabledDiaryTypes: SeasonEvaluationDiaryType[] = [
    'annualPlan',
    'plan',
    'reality',
  ];

  @observable
  public week: string = '';

  @observable
  private _currentSeason: SeasonStore | undefined = undefined;

  @observable
  private request: RequestStore<GetSingleSeasonResponse> | null = null;

  @observable
  private planData: Map<ActivityItemId, Map<string | null, ActivityData>> =
    new Map();

  @observable
  private realityData: Map<ActivityItemId, Map<string | null, ActivityData>> =
    new Map();

  @observable
  private annualPlanData: Map<
    ActivityItemId,
    Map<string | null, ActivityData>
  > = new Map();

  @observable
  private _expandable: ExpandableTree<ActivityItem> | null = null;

  private reactions: IReactionDisposer[] = [];

  constructor(
    rootStore: RootStore,
    seasonEvaluationStore: SeasonEvaluationStore,
    maskCode?: MaskCode
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.seasonEvaluationStore = seasonEvaluationStore;
    this.maskCode = maskCode;
    this.loadEnabledDiaryTypes();

    when(
      () => rootStore.status === AsyncStatus.resolved,
      () => {
        this._availableDiaryTypes = this._availableDiaryTypes.filter(
          diaryType =>
            (this.rootStore.modulesStore[diaryType] ||
              diaryType === 'annualPlan') &&
            this.rootStore.currentUserStore.isAllowedTo(
              `seasonEvaluation.singleSeasonEvaluation.${diaryType}` as PermissionScope
            )
        );
        this._enabledDiaryTypes = this._enabledDiaryTypes.filter(diaryType =>
          this._availableDiaryTypes.includes(diaryType)
        );
        this._expandable = this.createExpandableTree();

        this.registerReactions();
      }
    );
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
  }

  @computed
  public get currentSeason(): SeasonStore | undefined {
    return this._currentSeason;
  }

  public get availableDiaryTypes(): SeasonEvaluationDiaryType[] {
    return this._availableDiaryTypes;
  }

  public get expandable(): ExpandableTree<ActivityItem> | null {
    return this._expandable;
  }

  @computed
  public get enabledDiaryTypes(): SeasonEvaluationDiaryType[] {
    return this._availableDiaryTypes.filter(type =>
      this._enabledDiaryTypes.includes(type)
    );
  }

  @action
  public toggleDiaryType(type: SeasonEvaluationDiaryType): void {
    if (this._enabledDiaryTypes.includes(type)) {
      (this._enabledDiaryTypes as IObservableArray).remove(type);
    } else {
      this._enabledDiaryTypes.push(type);
    }
  }

  @computed
  public get activities(): ActivityItem[] {
    return this.maskCode
      ? this.rootStore.activityItemsStore.getVisibleActivityItems(this.maskCode)
      : [];
  }

  public getPlanValue(
    activityItemId: ActivityItemId,
    date: string | null
  ): ActivityData | undefined {
    return this.planData.get(activityItemId)?.get(date);
  }

  public getRealityValue(
    activityItemId: ActivityItemId,
    date: string | null
  ): ActivityData | undefined {
    return this.realityData.get(activityItemId)?.get(date);
  }

  public getAnnualPlanValue(
    activityItemId: ActivityItemId,
    date: string | null
  ): ActivityData | undefined {
    return this.annualPlanData.get(activityItemId)?.get(date);
  }

  public getActivityItem(
    activityItemId: ActivityItemId
  ): ActivityItem | undefined {
    return this.maskCode
      ? this.rootStore.activityItemsStore.getActivityItem(
          this.maskCode,
          activityItemId
        )
      : undefined;
  }

  public get status(): AsyncStatus {
    if (!this.request) {
      return AsyncStatus.idle;
    }
    return this.request.status;
  }

  private async loadData(): Promise<void> {
    if (this.request) {
      this.request.cancel();
      this.rootStore.requestsStore.removeRequest(this.request);
    }

    const athleteId = this.seasonEvaluationStore.athleteId;
    const groupId = this.seasonEvaluationStore.groupId;
    const season = this.currentSeason;

    if ((!groupId && !athleteId) || !season) {
      return;
    }

    const transaction = this.rootStore.navbarStore.createTransaction(
      'loadingData',
      'season-evaluation'
    );
    const params: GetSingleSeasonParams = {
      userId: athleteId || undefined,
      userGroupId: athleteId ? undefined : (groupId as number),
      seasonId: season.id,
    };

    this.request = this.rootStore.requestsStore.createRequest(cancelToken =>
      getSingleSeasonData(params, cancelToken)
    );

    const response = await this.request.getResponse();

    if (!response) {
      transaction.error();
      return;
    }

    runInAction(() => {
      Object.entries(response).forEach(([type, activityData]) => {
        activityData?.forEach((item: ActivityData) => {
          switch (type) {
            case 'AnnualPlan': {
              if (!this.annualPlanData.has(item.ActivityItemId)) {
                this.annualPlanData.set(item.ActivityItemId, observable.map());
              }
              this.annualPlanData
                .get(item.ActivityItemId)
                ?.set(item.Date, item);
              break;
            }
            case 'Plan': {
              if (!this.planData.has(item.ActivityItemId)) {
                this.planData.set(item.ActivityItemId, observable.map());
              }
              this.planData.get(item.ActivityItemId)?.set(item.Date, item);
              break;
            }
            case 'Reality': {
              if (!this.realityData.has(item.ActivityItemId)) {
                this.realityData.set(item.ActivityItemId, observable.map());
              }
              this.realityData.get(item.ActivityItemId)?.set(item.Date, item);
              break;
            }
          }
        });
      });
      transaction.finished();
    });
  }

  private registerReactions(): void {
    const observeWeek = reaction(
      () => this.rootStore.historyService.searchParams.get(WEEK_SEARCH_PARAM),
      week => {
        this.week = week || '';
        this._currentSeason = this.rootStore.seasonsStore.getSeasonByWeek(
          this.week
        );
        this.clearData();
      },
      {
        fireImmediately: true,
      }
    );

    const observeEnabledDiaryTypes = reaction(
      () => this.enabledDiaryTypes,
      () => this.persistEnabledDiaryTypes()
    );

    const dataLoader = reaction(
      () => ({
        athleteId: this.seasonEvaluationStore.athleteId,
        groupId: this.seasonEvaluationStore.groupId,
        currentSeason: this.currentSeason,
        viewType: this.seasonEvaluationStore.viewType,
      }),
      () => {
        this.clearData();
        if (this.seasonEvaluationStore.viewType === 'single-season') {
          void this.loadData();
        }
      },
      {
        fireImmediately: true,
      }
    );

    const expandable = reaction(
      () => this.activities,
      () => {
        this._expandable = this.createExpandableTree();
      }
    );

    this.reactions.push(
      observeWeek,
      dataLoader,
      observeEnabledDiaryTypes,
      expandable
    );
  }

  private clearData(): void {
    this.planData.clear();
    this.realityData.clear();
    this.annualPlanData.clear();
  }

  private persistEnabledDiaryTypes(): void {
    window.localStorage?.setItem(
      this.ENABLED_DIARY_TYPES_PERSIST_KEY,
      JSON.stringify(this._enabledDiaryTypes)
    );
  }

  private loadEnabledDiaryTypes(): void {
    const json = window.localStorage?.getItem(
      this.ENABLED_DIARY_TYPES_PERSIST_KEY
    );
    if (json) {
      try {
        const value = JSON.parse(json);
        if (
          Array.isArray(value) &&
          value.every(type =>
            SingleSeasonEvaluationStore.isValidDiaryType(type)
          )
        ) {
          this._enabledDiaryTypes = value;
        }
      } catch (e: unknown) {
        this.rootStore.logger.warn(
          'Unable to restore season evaluation enabled diary types. Invalid JSON was loaded.'
        );
      }
    }
  }

  private static isValidDiaryType(x: string): x is SeasonEvaluationDiaryType {
    return x === 'plan' || x === 'reality' || x === 'annualPlan';
  }

  private createExpandableTree(): ExpandableTree<ActivityItem> {
    return new ExpandableTree(
      this.activities,
      item => item.ActivityItemId,
      item => item.Level || 0,
      `single-season-evaluation-${this.maskCode}`
    );
  }
}
