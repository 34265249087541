import { Header } from './header';
import { Button, ButtonAppearance } from '../components-2/button';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, IconSize } from '@yarmill/components';
import { Close } from '@yarmill/icons-2';
import { FakeButton } from '../components-2/okrs/objectives-list';
import { ObjectiveStore } from './mobx/objective-store';
import { observer } from 'mobx-react-lite';
import { useWindowSize } from '../utils/use-window-size';
import {
  RightPanelContent,
  RightPanelLayout,
} from '../components-2/right-panel';
import { AddComment } from './activity-log/add-comment';
import { ActivityLog } from './activity-log/activity-log';
import { HeaderPageTitle } from '../components-2/header';
import { useEffect, useRef } from 'react';
import { Tippy } from '../components/tippy/tippy';
import {
  ActivityLogLayoutScrollContainer,
  ActivityLogTabs,
  TabsButton,
} from '../components-2/okrs/activity-log';
import { useOkrsStore } from './okrs-store-context';

interface ObjectiveActivityLogPanelProps {
  readonly objective: ObjectiveStore;
  readonly isVisible: boolean;
  readonly close: () => void;
}
export const ObjectiveActivityLogPanel = observer(
  function ObjectiveActivityLogPanel({
    objective,
    isVisible,
    close,
  }: ObjectiveActivityLogPanelProps): JSX.Element {
    const intl = useIntl();
    const { width } = useWindowSize();
    const okrsStore = useOkrsStore();
    const contentWrapperRef = useRef<HTMLDivElement>(null);
    const activeTab = okrsStore.activityLogTab;

    useEffect(() => {
      if (isVisible && objective && objective.activities.length && activeTab) {
        contentWrapperRef.current?.scrollTo({
          top: contentWrapperRef.current.scrollHeight,
        });
      }
    }, [isVisible, objective, objective.activities.length, activeTab]);

    return (
      <RightPanelLayout
        isVisible={isVisible}
        windowWidth={width ?? 0}
        disableMobileHideAnimation
      >
        <Header
          inverted
          left={
            <Tippy tooltipContent="okrs.detail.validation.close" touch={false}>
              <Button
                $appearance={ButtonAppearance.Secondary}
                $appearanceStyle="neutral"
                $iconOnly
                onClick={close}
                aria-label={intl.formatMessage({
                  id: 'okrs.detail.validation.close',
                })}
              >
                <Icon size={IconSize.s24}>
                  <Close />
                </Icon>
              </Button>
            </Tippy>
          }
          center={
            <HeaderPageTitle appearance="text15strong">
              <FormattedMessage id="okrs.detail.activityLog.headline" />
            </HeaderPageTitle>
          }
          right={<FakeButton />}
        />
        <RightPanelContent noPadding>
          <ActivityLogTabs>
            <TabsButton
              $appearance={
                activeTab === 'comments'
                  ? ButtonAppearance.Secondary
                  : ButtonAppearance.Tertiary
              }
              $appearanceStyle={activeTab === 'comments' ? 'navy' : 'neutral'}
              onClick={() => okrsStore.setActivityLogTab('comments')}
            >
              <FormattedMessage id="okrs.activityLog.tabs.comments" />
            </TabsButton>
            <TabsButton
              $appearance={
                activeTab === 'activities'
                  ? ButtonAppearance.Secondary
                  : ButtonAppearance.Tertiary
              }
              $appearanceStyle={activeTab === 'activities' ? 'navy' : 'neutral'}
              onClick={() => okrsStore.setActivityLogTab('activities')}
            >
              <FormattedMessage id="okrs.activityLog.tabs.activities" />
            </TabsButton>
          </ActivityLogTabs>
          <ActivityLogLayoutScrollContainer ref={contentWrapperRef}>
            <ActivityLog objective={objective} activeTab={activeTab} />
          </ActivityLogLayoutScrollContainer>
        </RightPanelContent>
        {activeTab === 'comments' &&
          okrsStore.isCurrentUserAllowedToWrite(objective) && (
            <Header
              position="footer"
              inverted
              center={<AddComment objective={objective} />}
            />
          )}
      </RightPanelLayout>
    );
  }
);
