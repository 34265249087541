import { RichtextReportData, VerticalAlignment } from './types';
import { css, styled, Text, TextSize, TextTag } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { RichtextReportStore } from './mobx/richtext-report-store';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { ReportContentBox } from './report-content-box';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { Fragment } from 'react';
import { mapColor } from './utils';

export interface RichtextReportProps {
  readonly report: RichtextReportStore;
  readonly data?: RichtextReportData;
}

const StyledTextWrapper = styled.div<{
  readonly verticalAlign?: VerticalAlignment;
  readonly backgroundColor?: string;
  readonly textSize: TextSize;
}>`
  ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      height: 100%;
      display: flex;
      align-items: ${verticalAlign};

      & > div {
        width 100%;
      }
    `};

  ${({ backgroundColor, textSize }) =>
    backgroundColor &&
    css`
      font-size: ${textSize}px;
      background-color: ${backgroundColor};
      border-radius: 4px;
      padding: 0.25em 0.5em;
    `};
`;

export const RichtextReport = observer(function RichtextReport(
  props: RichtextReportProps
): JSX.Element {
  const { report, data: passedData } = props;
  const dataStore = useReportingDataStore();

  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as RichtextReportData | undefined);
  const richtextData = data?.Data;

  const id = report.getTranslateStringId(richtextData);
  const values = report.formatItemByDataTypes(
    richtextData ?? report.emptyValues
  );
  const color = report.getColor(richtextData);
  const backgroundColor = report.getBackgroundColor(richtextData);
  const textSize = report.getTextSize(richtextData) ?? TextSize.s14;
  const verticalAlignment = report.verticalAlignment;
  const TextWrapper =
    verticalAlignment || backgroundColor ? StyledTextWrapper : Fragment;

  return (
    <ReportContentBox
      customContentBoxComponent={report.isHighlighted ? undefined : Fragment}
    >
      <TextWrapper
        verticalAlign={verticalAlignment}
        backgroundColor={backgroundColor}
        textSize={textSize}
      >
        <Text
          size={textSize}
          color={color ? mapColor(color) : undefined}
          tag={TextTag.div}
          textAlign={report.textAlignment}
        >
          {id && <FormattedHTMLMessage id={id} values={values} />}
        </Text>
      </TextWrapper>
    </ReportContentBox>
  );
});
