import { SeasonsStore } from './mobx/seasons-store';
import { useRootStore } from '../app/root-store-context';
import { SeasonStore } from './mobx/season-store';

export function useSeasonsStore(): SeasonsStore {
  return useRootStore().seasonsStore;
}
export function useCurrentSeasonByDay(day: string): SeasonStore | undefined {
  return useSeasonsStore().getSeasonByDate(day);
}

export function useSeasonByYear(seasonYear: number): SeasonStore | undefined {
  return useSeasonsStore().getSeasonByYear(seasonYear);
}
