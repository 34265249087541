import moment from 'moment';
import { useMemo, useCallback } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { useHistory } from '../routes/hooks';

import {
  ChartWidthProvider,
  Cgm,
  Text,
  TextSize,
  CgmSample,
  TrainingDayAttributeLayout,
  TrainingDayAttributeLabel,
  SampleChartListLayout,
} from '@yarmill/components';

import { DATE_FORMAT, ROUTE_DATE_FORMAT } from '../diary/utils';
import { CgmValue } from '../training-day/types';
import { CgmAttributeStore } from './mobx/cgm-attribute-store';
import { observer } from 'mobx-react-lite';
import { getCgmLink } from '../cgm/utils';
import { CgmChartsData } from '../cgm/types';

interface CgmSampleChartProps {
  readonly id: string;
  readonly data: number[];
  readonly cgmMax: number | null;
  readonly cgmMin: number | null;
  readonly width: number;
}

function CgmSampleChart(props: CgmSampleChartProps): JSX.Element | null {
  const { data, cgmMax, cgmMin, id, width } = props;

  const cgmData: CgmChartsData[] = useMemo(() => {
    if (cgmMin === null || cgmMax === 0) {
      return [];
    }
    const fullData: CgmChartsData[] = data.map(
      (item: number | null, idx: number) => ({
        egv: item || null,
        time: idx,
        x: idx,
        y: item || 0,
      })
    );

    return fullData;
  }, [cgmMin, cgmMax, data]);

  return cgmData.length !== 0 && cgmMax !== null && cgmMin !== null ? (
    <Cgm
      id={id}
      width={width}
      height={45}
      data={cgmData}
      domain={[cgmMin, cgmMax]}
      total={cgmData.length}
    />
  ) : null;
}

interface SingleCgmSampleProps {
  cgm: CgmValue;
}

function SingleCgmSample(props: SingleCgmSampleProps): JSX.Element {
  const history = useHistory();
  const { cgm } = props;

  const showDetail = useCallback(() => {
    history.push(
      getCgmLink(moment(cgm.MeasurementDate).format(ROUTE_DATE_FORMAT))
    );
  }, [history, cgm]);

  const startTime = useMemo(
    () => moment(cgm.LastRecording, DATE_FORMAT).toDate(),
    [cgm]
  );

  return (
    <CgmSample
      name={
        <Text size={TextSize.s14}>
          <FormattedMessage id={cgm.Label || 'cgm.sample.label'} />
        </Text>
      }
      time={
        <Text size={TextSize.s10} inheritColor monoSpace>
          <FormattedMessage id="cgm.sample.at" />
          &nbsp;
          <FormattedTime value={startTime} />
        </Text>
      }
      min={
        <Text size={TextSize.s10} inheritColor monoSpace>
          <FormattedMessage id="cgm.sample.min" values={{ egv: cgm.EgvsMin }} />
        </Text>
      }
      max={
        <Text size={TextSize.s10} inheritColor monoSpace>
          <FormattedMessage id="cgm.sample.max" values={{ egv: cgm.EgvsMax }} />
        </Text>
      }
      chart={
        <ChartWidthProvider>
          {width => (
            <CgmSampleChart
              id={String(cgm.MeasurementDate)}
              data={cgm.ChartData || []}
              cgmMax={cgm.EgvsMax}
              cgmMin={cgm.EgvsMin}
              width={width}
            />
          )}
        </ChartWidthProvider>
      }
      onClick={showDetail}
    />
  );
}

export interface CgmAttributeProps {
  readonly attribute: CgmAttributeStore;
}

function InternalCgmAttribute(props: CgmAttributeProps): JSX.Element | null {
  const { attribute } = props;
  const cgms = attribute.measurements;

  if (cgms.length === 0) {
    return null;
  }

  return (
    <TrainingDayAttributeLayout>
      <TrainingDayAttributeLabel>
        <FormattedMessage id={attribute.name} />
      </TrainingDayAttributeLabel>
      <SampleChartListLayout>
        {cgms.map(cgm => (
          <SingleCgmSample cgm={cgm} key={cgm.MeasurementDate} />
        ))}
      </SampleChartListLayout>
    </TrainingDayAttributeLayout>
  );
}

export const CgmAttribute = observer(InternalCgmAttribute);
