import { getDaysInWeek } from '../diary/utils';

import { SumItemType } from './sum-row-item';
import { AttendanceValue, isSumColumnType, SumColumnType } from './types';
import { UserStore } from '../users/mobx/user-store';
import { AttendanceStore } from './mobx/attendance-store';

export const ENABLE_TABLE_ANIMATION = false;

export function getSumRowItemValue(
  attendanceStore: AttendanceStore,
  column: string | SumColumnType
): number {
  if (isSumColumnType(column)) {
    return attendanceStore.athletes.reduce(
      (total, user) => total + getSumColumnItemValue(attendanceStore, user),
      0
    );
  }

  return attendanceStore.athletes.reduce((total: number, user: UserStore) => {
    const item = attendanceStore.weekData.get(user.id)?.get(column);

    return item && item.value === AttendanceValue.Present ? total + 1 : total;
  }, 0);
}

export function getSumColumnItemValue(
  attendanceStore: AttendanceStore,
  user: UserStore
): number {
  const days = getDaysInWeek(attendanceStore.week || '');

  return days.reduce((total: number, date: string) => {
    const item = attendanceStore.weekData.get(user.id)?.get(date);

    return item && item.value === AttendanceValue.Present ? total + 1 : total;
  }, 0);
}

export function formatNumberValue(
  value: number | null = null
): number | string {
  if (value === null) {
    return '-';
  }

  return Math.round(value);
}

export function getEnabledDaysForUser(
  attendanceStore: AttendanceStore,
  user: UserStore
): number {
  const days = getDaysInWeek(attendanceStore.week || '');

  return days.reduce((total: number, date: string) => {
    const item = attendanceStore.weekData.get(user.id)?.get(date);

    return item && item.value !== AttendanceValue.Free ? total + 1 : total;
  }, 0);
}

export function getEnabledDaysForGroup(
  attendanceStore: AttendanceStore,
  column: string | SumItemType
): number {
  return attendanceStore.athletes.reduce((total: number, user: UserStore) => {
    if (isSumColumnType(column)) {
      return total + getEnabledDaysForUser(attendanceStore, user);
    }

    const item = attendanceStore.weekData.get(user.id)?.get(column);

    return item && item.value !== AttendanceValue.Free ? total + 1 : total;
  }, 0);
}
