import { Icon, IconSize, styled } from '@yarmill/components';
import { InputHTMLAttributes, useEffect, useRef } from 'react';
import { getTextAppearanceStyles } from './text';
import { RIGHT_PANEL_TRANSITION_DURATION } from './right-panel-transition';

interface IntegratedRightPanelInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  readonly icon?: JSX.Element;
}

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: ${({ theme }) => theme.size.x1};
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.blackHover};
`;

const RightPanelInput = styled.input`
  border: 0;
  width: 100%;
  ${getTextAppearanceStyles('task13')};
  font-weight: 600;
  background-color: ${({ theme }) => theme.color.blackHover};
  padding: ${({ theme }) => theme.size.x05} ${({ theme }) => theme.size.x1}
    ${({ theme }) => theme.size.x05} ${({ theme }) => theme.size.x4};
  color: ${({ theme }) => theme.color.white};
  height: ${({ theme }) => theme.size.x4};

  :focus,
  :focus-visible {
    outline: none;

    & + ${IconWrapper} {
      color: ${({ theme }) => theme.color.neutral};
    }
  }

  ::placeholder {
    color: ${({ theme }) => theme.color.neutralDark};
  }

  :not(:focus):placeholder-shown {
    & + ${IconWrapper} {
      color: ${({ theme }) => theme.color.neutralDark};
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

export function IntegratedRightPanelInput({
  icon,
  autoFocus,
  ...inputProps
}: IntegratedRightPanelInputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(
        () => inputRef.current?.focus({ preventScroll: true }),
        RIGHT_PANEL_TRANSITION_DURATION
      );
    }
  }, [autoFocus]);

  return (
    <InputWrapper>
      <RightPanelInput {...inputProps} ref={inputRef} />
      {icon && (
        <IconWrapper>
          <Icon size={IconSize.s16}>{icon}</Icon>
        </IconWrapper>
      )}
    </InputWrapper>
  );
}
