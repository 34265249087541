import { CurveType, LineStyle } from '@yarmill/components';
import { LineChart } from '../../types';
import { LinePath as LinePathShape } from '@visx/shape';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import {
  curveLinear,
  curveMonotoneX,
  curveStep,
  curveStepAfter,
} from '@visx/curve';
import { useCallback } from 'react';
import { DataItem } from '../../../reporting/types';

interface LinePathProps {
  readonly config: LineChart;
  readonly data: DataItem[];
  readonly getX: (item: DataItem) => number;
  readonly getY: (item: DataItem) => number;
}

const AnimatedLinePath = animated(LinePathShape<DataItem>);

const StyledLinePath = styled(AnimatedLinePath)<{
  readonly $lineStyle?: LineStyle;
  readonly $lineColor: string;
  readonly $showLine: boolean;
}>`
  stroke: ${({ $showLine, $lineStyle, $lineColor }) =>
    !$showLine || $lineStyle === 'hover' || $lineStyle === 'marker'
      ? 'transparent'
      : $lineColor};

  ${({ $lineColor, $lineStyle, $showLine }) =>
    $showLine &&
    $lineStyle === 'hover' &&
    css`
      :hover {
        stroke: ${$lineColor};
      }
    `}
`;

function getCurveConfig(type?: CurveType) {
  switch (type) {
    case 'step':
      return curveStep;
    case 'stepAfter':
      return curveStepAfter;
    case 'linear':
      return curveLinear;
    default:
      return curveMonotoneX;
  }
}

export function LinePath({ config, data, getX, getY }: LinePathProps) {
  const getIsDefined = useCallback(
    (item: DataItem) =>
      config.getYValue(item) !== null && config.getXValue(item) !== null,
    [config]
  );

  return (
    <StyledLinePath
      curve={getCurveConfig(config.getCurveType?.(data[0]))}
      data={data}
      x={getX}
      y={getY}
      $lineColor={config.getColor(data[0])}
      $showLine
      $lineStyle={config.getLineStyle?.(data[0])}
      strokeWidth={config.getStrokeWidth?.(data[0]) ?? 2}
      strokeDasharray={config.getStrokeDasharray?.(data[0])}
      strokeOpacity={config.getOpacity?.(data[0]) ?? 1}
      shapeRendering="geometricPrecision"
      pointerEvents="none"
      defined={getIsDefined}
    />
  );
}
