import { action, observable, makeObservable } from 'mobx';

export class FocusedDayService {
  @observable
  private _focusedDay: number = -1;

  constructor() {
    makeObservable(this);
  }

  public get focusedDay(): number {
    return this._focusedDay;
  }

  @action
  public setFocusedDay(focusedDay: number): void {
    this._focusedDay = focusedDay;
  }
}
