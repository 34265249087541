import { css, styled } from '@yarmill/components';
import { Button } from '../../button';
import { DropdownButton } from '../../dropdown-provider/dropdown-provider';
import { FlexCenterContainer } from '../../flex-utils';
import { TextInputAppearance, getAppearanceColors } from '../../text-input';

export const KeyResultTdBg = styled.div<{
  readonly appearance?: TextInputAppearance;
}>`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  margin: ${({ theme }) => theme.size.x025};
  background-color: ${({ theme, appearance }) =>
    getAppearanceColors(appearance, theme).background};
  border-radius: ${({ theme }) => theme.size.x1};
  border: ${({ theme }) => theme.size.x025} solid
    ${({ theme, appearance }) =>
      getAppearanceColors(appearance, theme).background};
`;

export const KeyResultsTableTd = styled.td<{
  readonly textAlign?: 'left' | 'center' | 'right';
  readonly appearance?: TextInputAppearance;
  readonly showHover?: boolean;
}>`
  color: ${({ theme }) => theme.color.neutral_24};
  vertical-align: middle;
  text-align: ${({ textAlign = 'center' }) => textAlign};
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.size.x025};
  position: relative;

  &:first-child,
  &:last-child {
    background-color: unset;
  }

  ${DropdownButton} {
    width: 100%;
  }

  transition-property: border, background-color, color, opacity;
  transition-duration: 150ms;

  ${({ showHover, appearance, theme }) =>
    showHover &&
    css`
      & div,
      & button {
        transition: none;
        margin-left: 2px;
        margin-right: 2px;
      }
      :hover {
        & > div:first-child {
          background-color: ${getAppearanceColors(appearance, theme).focus};
        }
        & button,
        & button div {
          background-color: transparent;
          border-color: transparent;
        }
      }
    `};

  :focus-within {
    textarea {
      border-color: transparent;
      background-color: transparent;
    }

    ${KeyResultTdBg} {
      border-color: ${({ theme, appearance }) =>
        getAppearanceColors(appearance, theme).focus};
    }
  }
`;

export const KeyResultDragHandle = styled(FlexCenterContainer)<{
  readonly isDragged?: boolean;
}>`
  cursor: ${({ isDragged }) => (isDragged ? 'grabbing' : 'grab')};
`;

export const DatepickerButton = styled(Button)<{ readonly readOnly?: boolean }>`
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: left;
  ${({ readOnly }) =>
    readOnly &&
    css`
      cursor: default;
    `}
`;
