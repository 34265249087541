import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { CopyConflictResponse, CopyMode } from '../types';

export interface CopyPlanRequestParams {
  diaryType: 'Plan';
  type: 'Week' | 'Cycle' | 'Season' | 'SeasonGoals';
  mode: CopyMode;
  data: Record<string, unknown>;
}

const endpointsMap: { [key in CopyMode]: string } = {
  athleteToGroups: 'UserToGroups',
  athleteToOtherAthletes: 'UserToUsers',
  groupToAthletesInGroup: 'GroupToUsers',
  groupToOtherGroup: 'GroupToGroups',
  otherSeason: 'SeasonToSeason',
  otherWeek: 'WeekToWeek',
  importFromReality: 'ImportFromReality',
};

function mapCopyModeToApiEndpoint(
  mode: CopyMode,
  data: Record<string, unknown>
): string {
  const endpoint = endpointsMap[mode];
  if (
    mode === 'otherSeason' ||
    mode === 'otherWeek' ||
    mode === 'importFromReality'
  ) {
    return `${data.SourceUserId || data.UserId ? 'User' : 'Group'}${endpoint}`;
  }
  return endpoint;
}

function hackParamNames(
  mode: 'otherSeason' | 'otherWeek',
  data: Record<string, unknown>
): Record<string, unknown> {
  const { SourceUserId, SourceUserGroupId, Days, ...rest } = data;
  let params = { ...rest };
  if (SourceUserId) {
    params = { ...params, UserId: SourceUserId };
  }
  if (SourceUserGroupId) {
    params = { ...params, UserGroupId: SourceUserGroupId };
  }
  if (Days) {
    params = { ...params, SourceDays: Days };
  }

  return { ...params };
}

export function copy(
  params: CopyPlanRequestParams
): AxiosPromise<boolean | CopyConflictResponse> {
  const { type, data, mode, diaryType } = params;
  const url = `api/${diaryType}/${type}/Copy/${mapCopyModeToApiEndpoint(
    mode,
    data
  )}`;

  return axios.post(
    url,
    mode === 'otherSeason' || mode === 'otherWeek'
      ? hackParamNames(mode, data)
      : data
  );
}
