import { action, computed, observable, makeObservable } from 'mobx';

import { RootStore } from '../../app/mobx/root-store';
import {
  TrainingDayAttribute,
  TrainingDayAttributeId,
} from '../../training-day/types';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { getTrainingDayAttributes } from '../api/get-training-day-attributes';
import { InitialConfigError } from '../../error/initial-config-error';

export class TrainingDayAttributesStore {
  private readonly rootStore: RootStore;

  private request: RequestStore<TrainingDayAttribute[]> | null = null;

  @observable
  private _planAttributes = new Map<
    TrainingDayAttributeId,
    TrainingDayAttribute
  >();

  @observable
  private _realityAttributes = new Map<
    TrainingDayAttributeId,
    TrainingDayAttribute
  >();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  public async loadTrainingDayAttributes(): Promise<void> {
    this.request = this.rootStore.requestsStore.createRequest(() =>
      getTrainingDayAttributes()
    );

    const response = await this.request.getResponse();
    if (response) {
      this.setTrainingDayAttributes(response);
    } else {
      throw new InitialConfigError(
        'Unable to load training day attributes',
        this.request.error
      );
    }
  }

  @action
  public setTrainingDayAttributes(
    trainingDayAttributes: TrainingDayAttribute[]
  ): void {
    trainingDayAttributes.forEach(item => {
      if (item.Type === 'P') {
        this._planAttributes.set(item.AttributeItemId, item);
      } else {
        this._realityAttributes.set(item.AttributeItemId, item);
      }
    });
  }

  @computed
  public get status(): AsyncStatus {
    return this.request?.status || AsyncStatus.idle;
  }

  @computed
  public get planAttributes(): TrainingDayAttribute[] {
    return Array.from(this._planAttributes.values()).sort(
      (a, b) => a.SortCode - b.SortCode
    );
  }

  @computed
  public get realityAttributes(): TrainingDayAttribute[] {
    return Array.from(this._realityAttributes.values()).sort(
      (a, b) => a.SortCode - b.SortCode
    );
  }
}
