import { Redirect } from 'react-router-dom';
import { useGeneratedUrl } from '../utils/use-generated-url';
import { REDIRECT_ACTION_PARAM } from '../app/utils';
import { useRootStore } from '../app/root-store-context';
import { observer } from 'mobx-react-lite';

export const RedirectActionHandler = observer(
  function RedirectActionHandler(): JSX.Element | null {
    const rootStore = useRootStore();
    const currentUrl = useGeneratedUrl({});
    const withoutRA = useGeneratedUrl({ [REDIRECT_ACTION_PARAM]: null });

    if (rootStore.isReady && currentUrl !== withoutRA) {
      return <Redirect to={withoutRA} />;
    }

    return null;
  }
);
