import * as React from 'react';
import styled from 'styled-components';
import { Button, ButtonAppearance } from '@yarmill/components';
import { useFileLink } from '../../files-overview/hooks/use-file-link';
import { File } from '../../fileupload/types';
import { forwardRef } from 'react';

export type FileLinkProps = React.PropsWithChildren<{
  file: File;
  forceDownload?: boolean;
  iconOnly?: boolean;
  square?: boolean;
  className?: string;
}>;

const StyledLink = styled(Button)`
  overflow: hidden;
  text-align: left;
  padding: 0;
`;

export const FileLink = forwardRef<HTMLButtonElement, FileLinkProps>(
  function FileLink(props, ref): JSX.Element {
    const { file, children, forceDownload, iconOnly, square, className } =
      props;
    const onLinkClick = useFileLink(file, forceDownload);

    return (
      <>
        <StyledLink
          onClick={onLinkClick}
          type="button"
          appearance={ButtonAppearance.Link}
          iconOnly={iconOnly}
          square={square}
          className={className}
          ref={ref}
        >
          {children}
        </StyledLink>
      </>
    );
  }
);
