import { DiaryStore } from './mobx/diary-store';
import {
  memo,
  useContext,
  createContext,
  PropsWithChildren,
  useRef,
  useEffect,
} from 'react';
import { useRootStore } from '../app/root-store-context';
import { debugValue } from '../utils/debug-value';

export const DiaryStoreContext = createContext<DiaryStore | undefined>(
  undefined
);

export function useDiaryStore(): DiaryStore {
  const diaryStore = useContext(DiaryStoreContext);
  if (!diaryStore) {
    throw new Error('Diary Store not available');
  }

  return diaryStore;
}

export function InternalDiaryStoreContextProvider(
  props: PropsWithChildren<any>
): JSX.Element {
  const rootStore = useRootStore();
  const diaryStore = useRef(new DiaryStore(rootStore)).current;

  useEffect(() => {
    debugValue(diaryStore, 'diaryStore');
  }, [diaryStore]);

  return (
    <DiaryStoreContext.Provider value={diaryStore}>
      {props.children}
    </DiaryStoreContext.Provider>
  );
}

export const DiaryStoreContextProvider = memo(
  InternalDiaryStoreContextProvider
);
