import { RootStore } from '../../app/mobx/root-store';
import {
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';
import { AnnouncementStore } from './announcement-store';

export class AnnouncementsStore {
  @observable
  private readonly _announcements: IObservableArray<AnnouncementStore> =
    observable.array();
  constructor(rootStore: RootStore) {
    when(
      () => rootStore.isReady,
      () => {
        runInAction(() => {
          rootStore.modulesStore.announcement.forEach(def =>
            this._announcements.push(new AnnouncementStore(rootStore, def))
          );
        });
      }
    );

    makeObservable(this);
  }

  @computed
  public get announcements(): Readonly<AnnouncementStore[]> {
    return this._announcements.slice();
  }
}
