import { useMemo } from 'react';
import { WEEK_SEARCH_PARAM } from '../routes/types';
import moment from 'moment';
import { ROUTE_DATE_FORMAT } from '../diary/utils';
import { useLocation } from '../routes/hooks';

export function useCurrentWeek(): string {
  const { search } = useLocation();

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const week = searchParams.get(WEEK_SEARCH_PARAM) || undefined;

    return moment(week).format(ROUTE_DATE_FORMAT);
  }, [search]);
}
