import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { ObjectiveActivity } from '../types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

export interface AddObjectiveCommentRequestParams {
  readonly objectiveId: number;
  readonly comment: string;
}

export function addObjectiveComment(
  { objectiveId, comment }: AddObjectiveCommentRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<ObjectiveActivity['activityId']> {
  return axios.post(
    `${coreApiTarget}/api/okrs/comment`,
    { comment },
    {
      cancelToken: cancelToken?.token,
      params: {
        objectiveId,
        instanceCode: devMode ? instance : undefined,
      },
    }
  );
}
