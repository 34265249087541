import React from 'react';
import ReactDOM from 'react-dom';
import { Layer } from './mobx/layer';
import { LayerHandle } from './types';

export interface LayerPortalProps {
  layerHandle: LayerHandle;
  getContent: (layer: Layer) => JSX.Element;
}

/**
 * mount - should contain mount property, where the component will render its children <br>
 * children - will be rendered inside mount point
 */
export function LayerPortal(
  props: React.PropsWithChildren<LayerPortalProps>
): JSX.Element | null {
  const { layerHandle, getContent } = props;

  return layerHandle.isOpened
    ? ReactDOM.createPortal(
        getContent(layerHandle.layer),
        layerHandle.mountPoint
      )
    : null;
}
