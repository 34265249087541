import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';

export interface UpdateSettingParams {
  UserId: UserId | undefined;
  UserGroupId: UserGroupId | undefined;
  SettingsKey: string;
  Value: string | number | boolean;
  IsGlobal?: boolean;
}

export function updateSetting(
  params: UpdateSettingParams
): AxiosPromise<boolean> {
  return axios.put('/api/Settings/UserOrGroup', params);
}
