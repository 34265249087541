import { action, observable, makeObservable } from 'mobx';
import { RootStore } from '../../app/mobx/root-store';
import { FileUploadStore } from './file-upload-store';

export class FileUploadService {
  private readonly rootStore: RootStore;
  @observable
  private readonly fileUploads: FileUploadStore[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  public uploadFile(file: File): FileUploadStore {
    const store = new FileUploadStore(this.rootStore, file);
    this.fileUploads.push(store);

    return store;
  }
}
