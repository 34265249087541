import { BarStack } from '@visx/shape/lib/types';
import { DataItem } from '../../reporting/types';
import { groupBy } from '../../helpers/group-by';

export function groupStackedBarsByCategories(
  barStacks: BarStack<DataItem, string>[],
  key: 'x' | 'y'
) {
  return Array.from(
    groupBy(barStacks.map(barStack => barStack.bars).flat(), key).entries()
  ).map(([, categoryBars]) => categoryBars);
}
