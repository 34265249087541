import Tippy, { TippyProps as LibTippyProps } from '@tippyjs/react';
import { useState } from 'react';
import styled from 'styled-components';
// tslint:disable-next-line:no-implicit-dependencies
import { Instance } from 'tippy.js';
import { PopoverContainer } from '@yarmill/components';

export interface PopoverProps {
  children: LibTippyProps['children'];
  content(tippy: Instance): LibTippyProps['content'];
  autoWidth?: boolean;
  disabled?: boolean;
  trigger?: 'click' | 'mouseenter';
}

const StyledPopover = styled(Tippy)<{ $autoWidth?: boolean }>`
  ${PopoverContainer};
`;
export function Popover(props: PopoverProps): JSX.Element {
  const { children, content, autoWidth, disabled, trigger = 'click' } = props;
  const [instance, setInstance] = useState<Instance | null>(null);

  return (
    <StyledPopover
      disabled={disabled}
      $autoWidth={autoWidth}
      arrow
      content={instance ? content(instance) : ''}
      delay={0}
      duration={250}
      hideOnClick
      appendTo={document.body}
      interactive
      placement="bottom-end"
      moveTransition="none"
      trigger={trigger}
      maxWidth={300}
      onCreate={setInstance}
    >
      {children}
    </StyledPopover>
  );
}
