import {
  ContentBox,
  FixedContentBox,
  ResponsiveTableWrapper,
  Text,
} from '@yarmill/components';
import styled from 'styled-components';
import { SeasonView } from './season-view';
import { WeekView } from './week-view';
import { FormattedMessage } from 'react-intl';
import { WeekSummaryView } from './week-summary-view';
import { useAttendanceStore } from './attendance-store-context';
import { AsyncStatus } from '../api/mobx/request-store';
import { observer } from 'mobx-react-lite';

export const StyledAvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  text-align: center;
  color: #4a4a4a;
`;

export const Attendance = observer(function Attendance(): JSX.Element {
  const attendanceStore = useAttendanceStore();
  const currentPhase = attendanceStore.currentPhase;

  if (attendanceStore.status === AsyncStatus.rejected) {
    return (
      <ContentBox>
        <Text>
          <FormattedMessage id="attendance.loadingError" />
        </Text>
      </ContentBox>
    );
  }

  return (
    <FixedContentBox>
      <ResponsiveTableWrapper>
        {attendanceStore.viewType === 'week' ? (
          currentPhase?.isEditable ? (
            <WeekView />
          ) : (
            <WeekSummaryView />
          )
        ) : (
          <SeasonView />
        )}
      </ResponsiveTableWrapper>
    </FixedContentBox>
  );
});
