import { IObservableArray, observable, reaction, makeObservable } from 'mobx';
import { EvidenceObjectHistoryItem } from '../types';

export class EvidenceSubmitHistoryStore {
  private static readonly LOCAL_STORAGE_KEY = 'yarmill-evidence-submit-history';
  @observable
  private historyByObjectKey: Map<
    string,
    IObservableArray<EvidenceObjectHistoryItem>
  > = new Map();

  constructor() {
    makeObservable(this);
    this.restoreHistory();

    reaction(
      () => this.historyByObjectKey.size,
      () => this.persistToSessionStorage()
    );
  }

  public getHistoryForObject(
    objectKey: string
  ): EvidenceObjectHistoryItem[] | undefined {
    return this.historyByObjectKey.get(objectKey);
  }

  public addHistoryItem(objectKey: string, item: EvidenceObjectHistoryItem) {
    if (!this.historyByObjectKey.has(objectKey)) {
      this.historyByObjectKey.set(objectKey, observable.array());
    }

    this.historyByObjectKey.get(objectKey)?.push(item);
  }

  private persistToSessionStorage(): void {
    const string = JSON.stringify(this.historyByObjectKey);

    window.sessionStorage?.setItem(
      EvidenceSubmitHistoryStore.LOCAL_STORAGE_KEY,
      string
    );
  }

  private restoreHistory(): void {
    const string = window.sessionStorage?.getItem(
      EvidenceSubmitHistoryStore.LOCAL_STORAGE_KEY
    );

    if (string) {
      const parsed = JSON.parse(string);
      Object.entries<EvidenceObjectHistoryItem[]>(parsed).forEach(
        ([objectKey, items]) => {
          items.forEach(item => {
            this.addHistoryItem(objectKey, item);
          });
        }
      );
    }
  }
}
