import { observer } from 'mobx-react-lite';
import { ExportItem } from '../export/types';
import { useMemo } from 'react';
import { Export } from '../export/export';
import {
  useActivitiesToPdfExportHandler,
  useAttributesPdfExport,
} from './hooks';
import { useIntl } from 'react-intl';
import { useCurrentUserStore } from '../users/hooks';
import { useDiaryStore } from './diary-store-context';
import { ExportButtonWrapper } from '@yarmill/components';

export const ExportDiaryButton = observer(
  function ExportDiaryButton(): JSX.Element {
    const [exportAttributesToPdf, exportAttributesToPdfLayer] =
      useAttributesPdfExport();
    const exportActivitiesToPdf = useActivitiesToPdfExportHandler();
    const currentUser = useCurrentUserStore();
    const diaryStore = useDiaryStore();
    const diaryType = diaryStore.diaryType as 'plan' | 'reality';
    const intl = useIntl();

    const exportItems: ExportItem[] = useMemo(() => {
      const items: (ExportItem | undefined)[] = [
        currentUser.isAllowedTo(`${diaryType}.week.attributes.export`)
          ? {
              format: 'pdf',
              doExport: exportAttributesToPdf,
              label: intl.formatMessage({ id: 'export.diaryAttributes.label' }),
              description: intl.formatMessage({
                id: 'export.diaryAttributes.description',
              }),
            }
          : undefined,
        currentUser.isAllowedTo(`${diaryType}.week.activities.export`)
          ? {
              format: 'pdf',
              doExport: exportActivitiesToPdf,
              label: intl.formatMessage({ id: 'export.diaryActivities.label' }),
              description: intl.formatMessage({
                id: 'export.diaryActivities.description',
              }),
            }
          : undefined,
      ];
      return items.filter<ExportItem>((value): value is ExportItem =>
        Boolean(value)
      );
    }, [
      currentUser,
      diaryType,
      exportAttributesToPdf,
      intl,
      exportActivitiesToPdf,
    ]);

    return (
      <ExportButtonWrapper>
        {exportItems.length !== 0 && <Export exportItems={exportItems} />}
        {exportAttributesToPdfLayer}
      </ExportButtonWrapper>
    );
  }
);
