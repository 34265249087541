import { ModulesStore } from './mobx/modules-store';

export function isModuleEnabled(
  module: keyof ModulesStore,
  modules: ModulesStore
): boolean {
  const moduleItem = modules[module];

  return Array.isArray(moduleItem) ? moduleItem.length !== 0 : moduleItem;
}
