import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { Objective } from '../types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';

export interface DeleteObjectiveRequestParams {
  readonly objectiveId: number;
  readonly userId: UserId | null;
  readonly userGroupId: UserGroupId | null;
}

export function deleteObjective(
  { objectiveId, userId, userGroupId }: DeleteObjectiveRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<Objective[]> {
  return axios.delete(`${coreApiTarget}/api/okrs/objectiveId/${objectiveId}`, {
    cancelToken: cancelToken?.token,
    params: {
      instanceCode: devMode ? instance : undefined,
      userId: userId || undefined,
      userGroupId: userId ? undefined : userGroupId || undefined,
    },
  });
}
