import { css, styled } from '@yarmill/components';
import { PropsWithChildren } from 'react';
import { TextInputProps, getAppearanceColors } from '../text-input';

const DropdownButtonWrapperLayout = styled.div<{
  readonly $appearance?: TextInputProps['appearance'];
  readonly noBackground?: boolean;
  readonly disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: ${({ theme }) => theme.size.x1};

  border-radius: ${({ theme }) => theme.borderRadius.x05};
  background-color: ${({ theme, noBackground, $appearance }) =>
    noBackground
      ? 'transparent'
      : getAppearanceColors($appearance, theme).background};

  column-gap: ${({ theme }) => theme.size.x05};
  color: ${({ theme, $appearance }) =>
    getAppearanceColors($appearance, theme).text};

  ${({ disabled, theme, noBackground, $appearance }) =>
    !disabled &&
    css`
      :hover {
        background-color: ${
          noBackground
            ? theme.color.neutral_8
            : getAppearanceColors($appearance, theme).focus
        };
      }
    `}
`;

export const SupervisorsDropdownButtonWrapper = styled(DropdownButtonWrapper)<{
  readonly isEmpty: boolean;
}>`
  padding: ${({ theme, isEmpty }) => (isEmpty ? theme.size.x1 : theme.size.x05)}
    ${({ theme }) => theme.size.x1};
`;

export const StatusDropdownButtonWrapper = styled(DropdownButtonWrapper)<{
  readonly variant?: 'default' | 'large';
}>`
  padding: ${({ theme, variant = 'default' }) =>
    variant === 'default' ? theme.size.x1 : 0};
`;

type DropdownButtonWrapperProps = PropsWithChildren<{
  readonly noBackground?: boolean;
  readonly appearance?: TextInputProps['appearance'];
  readonly className?: string;
  readonly disabled?: boolean;
}>;
export function DropdownButtonWrapper({
  children,
  noBackground,
  appearance = 'neutral',
  className,
  disabled,
}: DropdownButtonWrapperProps): JSX.Element {
  return (
    <DropdownButtonWrapperLayout
      noBackground={noBackground}
      $appearance={appearance}
      disabled={disabled}
      className={[className, appearance].filter(Boolean).join(' ')}
    >
      {children}
    </DropdownButtonWrapperLayout>
  );
}
