import { HorizontalStackedBarChart } from '../../types';
import { Text } from '@visx/text';
import { ArrayElement, DataItem } from '../../../reporting/types';
import { LABEL_FONT_SIZE } from '../../../reporting/const';
import { getHorizontalBarLabelOffset } from '../../utils/get-horizontal-bar-label-offset';
import { shouldShowHorizontalBarStackLabel } from '../../utils/should-show-horizontal-bar-stack-label';
import { getSSRStringWidth } from '@yarmill/components';
import { groupStackedBarsByCategories } from '../../utils/group-stacked-bars-by-categories';

interface HorizontalStackedBarRectLabelProps {
  readonly bar: ArrayElement<
    ArrayElement<ReturnType<typeof groupStackedBarsByCategories>>
  >;
  readonly config: HorizontalStackedBarChart;
  readonly item: DataItem;
  readonly barWidth: number;
  readonly barX: number;
}
export function HorizontalStackedBarRectLabel({
  bar,
  config,
  item,
  barWidth,
  barX,
}: HorizontalStackedBarRectLabelProps) {
  const labelText = config.formatLabelValue?.(
    (bar.bar.data[bar.key] as number | undefined) || 0,
    bar.key
  ) as string;

  const labelWidth = getSSRStringWidth(labelText);
  if (
    !shouldShowHorizontalBarStackLabel(
      config.getShowLabels(bar.bar.data, bar.key),
      config.labelPosition || 'inside-top',
      barWidth,
      bar.height,
      labelWidth
    )
  ) {
    return null;
  }

  return (
    <Text
      x={
        barX +
        getHorizontalBarLabelOffset(
          barWidth,
          1,
          config.labelPosition ?? 'inside-top',
          0,
          labelWidth
        )
      }
      y={bar.y + bar.height / 2}
      width={bar.width}
      fill={config.getLabelColor(item, bar.key) || '#4a4a4a'}
      fontSize={LABEL_FONT_SIZE}
      verticalAnchor="middle"
      textAnchor="middle"
      scaleToFit="shrink-only"
    >
      {labelText}
    </Text>
  );
}
