import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { Workout } from '../types';

export function getWorkoutDetail(
  workoutId: number,
  cancelToken?: CancelTokenSource
): AxiosPromise<Workout> {
  return axios.get('api/data-services/workouts/details', {
    params: { workoutId },
    cancelToken: cancelToken?.token,
  });
}
