import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { EvidenceDataObject } from '../types';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { devMode, instance } from '../../api/api-mapping';
import { Locale } from '../../intl/types';

export interface GetEvidenceRequestParams {
  moduleKey: string;
  userId: UserId | null;
  groupId: UserGroupId | null;
  language: Locale;
}

export function getEvidenceData(
  dataUrl: string,
  params: GetEvidenceRequestParams
): AxiosPromise<EvidenceDataObject[]> {
  return axios.get(dataUrl, {
    params: {
      groupId:
        params.userId === null ? (params.groupId ?? undefined) : undefined,
      userId: params.userId !== null ? params.userId : undefined,
      moduleKey: params.moduleKey,
      language: params.language,
      instanceCode: devMode ? instance : undefined,
    },
  });
}
