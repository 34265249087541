import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { AttachmentId, AttachmentValue } from '../../training-day/types';

export interface RenameAttachmentRequestParams {
  AttachmentId: AttachmentId;
  FileName: string;
}

export function renameAttachment(
  params: RenameAttachmentRequestParams
): AxiosPromise<AttachmentValue> {
  return axios.post('api/TrainingDayAttributeAttachment/rename', params);
}
