import { memo } from 'react';
import { Page } from '../page/page';
import { Sidebar } from './sidebar';
import { Reports } from './reports';
import { PageMainContent, PageScrollContainer } from '@yarmill/components';

function ReportingPage(): JSX.Element {
  return (
    <Page>
      <Sidebar />
      <PageMainContent>
        <PageScrollContainer>
          <Reports />
        </PageScrollContainer>
      </PageMainContent>
    </Page>
  );
}

export const Reporting = memo(ReportingPage);
