import { Icon, IconSize, styled } from '@yarmill/components';
import { Enter } from '@yarmill/icons-2';
import { TextInput } from '../text-input';
import { Button, ButtonAppearance } from '../button';
import { FormEventHandler } from 'react';
import { Tippy } from '../../components/tippy/tippy';

interface CommentInputProps {
  readonly submitButtonAriaLabel: string;
  readonly onSubmit: FormEventHandler;
}

const CommentInputLayout = styled.form`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  align-items: center;

  column-gap: ${({ theme }) => theme.size.x05};
`;
export function CommentInput({
  submitButtonAriaLabel,
  onSubmit,
}: CommentInputProps): JSX.Element {
  return (
    <CommentInputLayout onSubmit={onSubmit}>
      <TextInput name="comment" iconPosition="left" appearance="neutral" />
      <Tippy
        tooltipContent={submitButtonAriaLabel}
        translateValue={false}
        touch={false}
      >
        <Button
          $appearance={ButtonAppearance.Primary}
          $appearanceStyle="navy"
          $iconOnly
          $square
          type="submit"
          aria-label={submitButtonAriaLabel}
        >
          <Icon size={IconSize.s24}>
            <Enter />
          </Icon>
        </Button>
      </Tippy>
    </CommentInputLayout>
  );
}
