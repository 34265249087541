import { useIntl } from 'react-intl';
import { usePlannerStore } from '../planner-store-context';
import {
  SelectionList,
  SelectionListItem,
} from '../../components-2/selection-list';
import { useCallback, useContext, useMemo } from 'react';
import { PlannerEvent } from '../types';
import { useFormikContext } from 'formik';
import { EventTypeStepLayout } from '../../#-components/planner/form';
import { RightPanelContent } from '../../components-2/right-panel';
import { FormStepHeader } from './form-step-header';
import { PlannerFormContext } from './planner-form-context';
import {
  KeyboardListNavigationContext,
  useKeyboardListNavigation,
} from '../../utils/use-keyboard-list-navigation';

export function EventTypeStep(): JSX.Element {
  const intl = useIntl();
  const { setCurrentStep } = useContext(PlannerFormContext);
  const plannerStore = usePlannerStore();
  const formik = useFormikContext<PlannerEvent>();
  const { values } = formik;
  const keyboardNavigationContext = useKeyboardListNavigation();

  const onClick = useCallback(
    (eventType: SelectionListItem) => {
      formik.setFieldValue('eventTypeId', eventType.value);
      setCurrentStep('details');
    },
    [setCurrentStep, formik]
  );

  const eventTypes: SelectionListItem[] = useMemo(
    () =>
      plannerStore.eventTypes.map(type => ({
        ...type,
        value: type.eventTypeId,
        title: intl.formatMessage({ id: type.name ?? 'Missing Title' }),
      })),
    [plannerStore.eventTypes, intl]
  );

  const selectedEventType =
    eventTypes.find(t => t.value === values.eventTypeId) ?? eventTypes[0];

  return (
    <>
      <FormStepHeader />
      <KeyboardListNavigationContext.Provider value={keyboardNavigationContext}>
        <RightPanelContent
          noPadding
          {...keyboardNavigationContext.getListProps()}
        >
          <EventTypeStepLayout>
            <SelectionList
              onClick={onClick}
              items={eventTypes}
              selectedItem={selectedEventType}
              inputType="radio"
            />
          </EventTypeStepLayout>
        </RightPanelContent>
      </KeyboardListNavigationContext.Provider>
    </>
  );
}
