import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';

export interface UpdateSeasonActivityItemValueRequestParams {
  startDate: string;
  activityItemId: number;
  value: number;
  userId: number | null;
  userGroupId: number | null;
  state: string;
  seasonId: number;
}

export function updateSeasonActivityItemValue(
  params: UpdateSeasonActivityItemValueRequestParams
): AxiosPromise<boolean> {
  return axios.post('api/TrainingSeasonActivity', params);
}
