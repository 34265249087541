import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { useIntl } from 'react-intl';
import { Right } from '@yarmill/icons-2';
import { RefObject, useCallback } from 'react';
import { usePlannerStore } from '../planner-store-context';
import { useIsElementInViewport } from '../../utils/use-is-element-in-viewport';
import { Tippy } from '../../components/tippy/tippy';

interface ScrollRightProps {
  readonly plannerContainerRef: RefObject<HTMLDivElement>;
  readonly rightScrollIndicatorRef: RefObject<HTMLDivElement>;
}
export function ScrollRight({
  plannerContainerRef,
  rightScrollIndicatorRef,
}: ScrollRightProps): JSX.Element {
  const intl = useIntl();
  const plannerStore = usePlannerStore();
  const seasonMonthsCount =
    plannerStore.currentSeason?.seasonMonths.length ?? 12;
  const isDisabled = useIsElementInViewport(rightScrollIndicatorRef);

  const handleScrollRight = useCallback(() => {
    const element = plannerContainerRef.current;
    if (element) {
      element.scrollBy({
        left: element.scrollWidth / seasonMonthsCount,
        behavior: 'smooth',
      });
    }
  }, [plannerContainerRef, seasonMonthsCount]);

  return (
    <Tippy tooltipContent="planner.scrollRight" noWrapper touch={false}>
      <Button
        $appearance={ButtonAppearance.Secondary}
        $appearanceStyle="neutral"
        $iconOnly
        onClick={handleScrollRight}
        aria-label={intl.formatMessage({
          id: 'planner.scrollRight',
        })}
        type="button"
        disabled={isDisabled}
      >
        <Icon size={IconSize.s24}>
          <Right />
        </Icon>
      </Button>
    </Tippy>
  );
}
