import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { AttachmentValue } from '../../training-day/types';

export interface AssignAttachmentRequestParams {
  FileId: string;
  AttributeId: number;
  UserId?: number;
  UserGroupId?: number;
  State: 'P' | 'R';
  Date: string;
}

export function assignAttachment(
  params: AssignAttachmentRequestParams
): AxiosPromise<AttachmentValue> {
  return axios.post('api/TrainingDayAttributeAttachment/assign', params);
}
