import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { CsvTable } from '../../csv-table/types';
import { UserId } from '../../users/types';
import { Locale } from '../../intl/types';

export interface GetSeasonOverSeasonDefinitionParams {
  language: Locale;
  userId: UserId;
}

export function getSeasonOverSeasonDefinition(
  params: GetSeasonOverSeasonDefinitionParams
): AxiosPromise<CsvTable[]> {
  return axios.get('/api/rtc/def', { params });
}
