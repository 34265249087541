import { EvidenceReportStore } from './mobx/evidence-report-store';
import { observer } from 'mobx-react-lite';
import { ReactElement, useMemo } from 'react';
import { EvidenceItem } from '../evidence/evidence-item';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { EvidenceReportData } from './types';
import { EvidenceDataStore } from '../evidence/mobx/evidence-data-store';
import { useRootStore } from '../app/root-store-context';
import { EvidenceDataStoreContext } from '../evidence/evidence-data-store-context';

interface EvidenceReportProps {
  readonly report: EvidenceReportStore;
  readonly data?: EvidenceReportData;
}
export const EvidenceReport = observer(function EvidenceReport(
  props: EvidenceReportProps
): ReactElement {
  const { report, data: passedData } = props;
  const definition = report.definition;
  const moduleKey = report.moduleKey;
  const dataUrl = report.dataUrl;
  const rootStore = useRootStore();
  const dataStore = useReportingDataStore();
  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as EvidenceReportData | undefined);
  const tags = data?.Metadata?.ModuleTags;

  const evidenceDataStore = useMemo(
    () =>
      new EvidenceDataStore(
        rootStore,
        definition,
        moduleKey,
        null,
        null,
        dataUrl,
        true
      ),
    [definition, moduleKey, dataUrl, rootStore]
  );

  useMemo(() => {
    evidenceDataStore.objectStores.forEach(store => store.clear());
    if (data?.Data) {
      evidenceDataStore.setData(data.Data);
    }
  }, [data?.Data, evidenceDataStore]);

  useMemo(() => {
    evidenceDataStore.objectStores.forEach(objectStore =>
      objectStore.setTags(tags)
    );
  }, [evidenceDataStore.objectStores, tags]);

  return (
    <EvidenceDataStoreContext.Provider value={evidenceDataStore}>
      {definition?.map(category => (
        <EvidenceItem definition={category} key={category.ObjectKey} />
      ))}
    </EvidenceDataStoreContext.Provider>
  );
});
