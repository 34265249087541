import * as React from 'react';
import {
  StyledTh,
  Text,
  TextSize,
  TextTag,
  WhiteSpace,
} from '@yarmill/components';
import styled, { css } from 'styled-components';
import {
  CsvTableAlignment,
  CsvTableConfiguration,
  CsvTableHeader,
} from './types';
import { Tippy } from '../components/tippy/tippy';
import { FormattedMessageWithDefaultValues } from '../intl/formatted-message-with-default-values';

export interface CsvTableHeaderCellProps {
  config: CsvTableConfiguration;
  column?: CsvTableHeader;
  children?: React.ReactNode;
  alignment?: CsvTableAlignment | null;
  hasExpandableControls: boolean;
  descriptiveData?: Record<string, string>;
}

const HeaderCell = styled(StyledTh)<{
  empty: boolean;
  hasExpandableControls: boolean;
}>`
  position: sticky;
  padding-top: 26px;
  top: 0;
  background-color: #ffffff;
  font-family: 'Ubuntu', 'Apple Color Emoji', 'Noto Color Emoji', sans-serif;

  @media (max-width: 800px) {
    top: 0;
    padding-top: 0;
  }

  ${props =>
    props.empty &&
    css`
      left: 0;
      z-index: 1;
    `}
  &:first-of-type {
    text-align: ${({ align }) => align};
  }

  ${({ hasExpandableControls }) =>
    hasExpandableControls &&
    css`
      // Hide expandable controls in sticky position
      &:first-of-type:before {
        content: '';
        background-color: #ffffff;
        width: 26px;
        height: 55px;
        position: absolute;
        left: -26px;
        top: 0;
    `}
  }

  @media print {
    page-break-inside: avoid;
    padding-top: 10px;
    position: relative;
  }
`;

export function CsvTableHeaderCell(props: CsvTableHeaderCellProps) {
  const {
    config,
    column,
    children,
    alignment,
    hasExpandableControls,
    descriptiveData,
  } = props;

  return (
    <HeaderCell
      empty={!column}
      align={alignment || 'right'}
      hasExpandableControls={hasExpandableControls}
      colSpan={column?.ColSpan ?? undefined}
    >
      {column && (
        <Tippy
          tooltipContent={column?.Tooltip}
          isEnabled={Boolean(column?.Tooltip)}
          noWrapper
        >
          <Text
            size={TextSize.s12}
            bold
            whiteSpace={WhiteSpace.preWrap}
            tag={TextTag.span}
          >
            {config.HeaderLabels &&
            column.Label &&
            (column.TranslateHeader ?? true) ? (
              <FormattedMessageWithDefaultValues
                id={column.Label}
                values={descriptiveData}
              />
            ) : (
              column.Label
            )}
          </Text>
        </Tippy>
      )}
      {children}
    </HeaderCell>
  );
}
