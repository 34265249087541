import {
  PopoverItemAppearance,
  PopoverItemStyles,
  StyledPopoverItem,
} from '@yarmill/components';
import { Link } from '../link';
import styled from 'styled-components';
import {
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';

export type PopoverItemProps = PropsWithChildren<{
  readonly appearance?: PopoverItemAppearance;
  readonly dataTest?: string;
  readonly to?: string;
  readonly onClick?:
    | MouseEventHandler<HTMLAnchorElement>
    | MouseEventHandler<HTMLButtonElement>;
}>;

const StyledLink = styled(Link)<{
  readonly appearance?: PopoverItemAppearance;
}>`
  ${PopoverItemStyles};
`;

export const PopoverItem = forwardRef<HTMLButtonElement, PopoverItemProps>(
  function PopoverItem(props, ref): JSX.Element {
    if (props.to) {
      return (
        <StyledLink
          to={props.to}
          onClick={props.onClick as MouseEventHandler<HTMLAnchorElement>}
          data-cy={props.dataTest}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          appearance={props.appearance}
        >
          {props.children}
        </StyledLink>
      );
    }

    return (
      <StyledPopoverItem
        onClick={props.onClick as MouseEventHandler<HTMLButtonElement>}
        data-cy={props.dataTest}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        appearance={props.appearance}
      >
        {props.children}
      </StyledPopoverItem>
    );
  }
);
