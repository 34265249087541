import { createBrowserHistory, History, Location } from 'history';
import { observable, ObservableMap, runInAction, makeObservable } from 'mobx';

export class HistoryService {
  public readonly history: History;

  @observable
  public pathname: string;

  @observable
  public searchParams: Map<string, string> = new Map();

  constructor() {
    makeObservable(this);
    this.history = createBrowserHistory();
    this.pathname = this.history.location.pathname;
    this.searchParams = observable.map(
      HistoryService.createSearchParamsMap(this.history.location.search)
    );
    this.history.listen(this.historyListener);
  }

  public readonly updateSearchParams = (
    params: { key: string; value: string | null }[]
  ) => {
    const searchParams = new URLSearchParams(this.history.location.search);

    params.forEach(({ key, value }) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    this.history.push({
      pathname: this.pathname,
      search: searchParams.toString(),
    });
  };

  private readonly historyListener = (location: Location) => {
    runInAction(() => {
      if (location.pathname !== this.pathname) {
        this.pathname = location.pathname;
      }
      (this.searchParams as ObservableMap).replace(
        HistoryService.createSearchParamsMap(location.search)
      );
    });
  };

  private static createSearchParamsMap(search: string): Map<string, string> {
    const searchParams = new URLSearchParams(search);
    return new Map(searchParams.entries());
  }
}
