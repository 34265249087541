import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  trackInviteUsersToGroupSelectChange,
  trackInviteUsersToGroupSubmit,
} from '../google-analytics/utils';
import { UserSelect } from './user-select';
import { useRootStore } from '../app/root-store-context';
import { useRouteMatch } from 'react-router-dom';
import { UserStore } from '../users/mobx/user-store';
import { toastAddUsersToGroup } from './utils';
import { observer } from 'mobx-react-lite';
import {
  AddUsersToGroupsFieldset,
  Button,
  ButtonAppearance,
  FormControlVariant,
  FullScreenLayerCloseButtonWrapper,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { CloseButton } from '../components/close-button/close-button';

const StyledInviteUserToGroupWrapper = styled.div`
  margin: 0 30px;

  @media (min-width: 991px) {
    margin: 0 20%;
    padding: 30px 20px 20px;
  }
`;
StyledInviteUserToGroupWrapper.displayName = 'StyledInviteUserToGroupWrapper';

const StyledHeadline = styled(Text)`
  padding: 30px 15px 15px 0;

  @media (min-width: 767px) {
    padding: 40px 15px 15px 0;
  }

  @media (min-width: 991px) {
    padding: 50px 0 25px;
  }
`;
StyledHeadline.displayName = 'StyledHeadline';

interface AddUserToGroupExtraProps {
  closeLayer(): void;
}

export const AddUserToGroup = observer(function AddUserToGroup(
  props: AddUserToGroupExtraProps
): JSX.Element {
  const { closeLayer } = props;
  const {
    params: { groupId },
  } = useRouteMatch<{ groupId: string }>();
  const [userIds, setUserIds] = React.useState<number[]>([]);
  const [disabled, setDisabled] = React.useState(false);
  const rootStore = useRootStore();
  const group = rootStore.groupsStore.getGroupById(Number(groupId));
  const excludedUsers = group?.allUsers.map(u => u.id);

  React.useEffect(() => {
    function onKeydown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        closeLayer();
      }
    }

    window.addEventListener('keydown', onKeydown);

    return () => window.removeEventListener('keydown', onKeydown);
  }, [closeLayer]);

  const onUserChange = (
    userIds: { label: string; value: number }[] | null
  ): void => {
    trackInviteUsersToGroupSelectChange();

    const newUserIds = userIds ? userIds.map(option => option.value) : [];

    setUserIds(newUserIds);
  };

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    trackInviteUsersToGroupSubmit();
    if (group && userIds.length > 0) {
      setDisabled(true);
      const users = userIds
        .map(userId => rootStore.usersStore.getUserById(userId))
        .filter(Boolean) as UserStore[];
      const success = await group.addUsers(users);
      if (success) {
        void rootStore.currentUserStore.loadPermissions();
        void group.loadHistoricalAthletes();
        toastAddUsersToGroup(users, group, 'success');
        closeLayer();
      } else {
        toastAddUsersToGroup(users, group, 'error');
        setDisabled(false);
      }
    }
  };

  return (
    <>
      <StyledInviteUserToGroupWrapper>
        {!group ? (
          <FormattedMessage id="settings.groups.invite.notExistingGroup" />
        ) : (
          <>
            <StyledHeadline tag={TextTag.h1} size={TextSize.s24}>
              <FormattedMessage id="settings.groups.invite" />
              &nbsp;
              <Text bold size={TextSize.s24}>
                {group.name}
              </Text>
            </StyledHeadline>
            <form className="big-form" onSubmit={onSubmit}>
              <AddUsersToGroupsFieldset disabled={disabled}>
                <UserSelect
                  autoFocus
                  onChange={onUserChange}
                  excludedUsers={excludedUsers}
                />
                <Button
                  type="submit"
                  variant={FormControlVariant.big}
                  appearance={ButtonAppearance.Primary}
                  onClick={onSubmit}
                  disabled={userIds.length === 0}
                  data-cy="submit-add-users-to-group"
                >
                  <FormattedMessage id="settings.groups.inviteUser.submit" />
                </Button>
              </AddUsersToGroupsFieldset>
            </form>
          </>
        )}
      </StyledInviteUserToGroupWrapper>
      <FullScreenLayerCloseButtonWrapper>
        <CloseButton onClick={closeLayer} />
      </FullScreenLayerCloseButtonWrapper>
    </>
  );
});
