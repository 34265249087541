import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
} from '@yarmill/components';
import { useIntl } from 'react-intl';
import { trackCloseButtonClick } from '../../google-analytics/utils';
import { MouseEvent } from 'react';

export interface CloseButtonProps {
  autoFocus?: boolean;
  onClick?(e: MouseEvent): void;
  hideText?: boolean;
  'data-cy'?: string;
}

export function CloseButton({
  autoFocus,
  onClick,
  hideText,
  'data-cy': dataCy,
}: CloseButtonProps): JSX.Element {
  const intl = useIntl();
  const text = intl.formatMessage({ id: 'settings.modal.closeButton' });
  function handleClick(e: MouseEvent) {
    trackCloseButtonClick();
    onClick?.(e);
  }

  return (
    <Button
      autoFocus={autoFocus}
      appearance={ButtonAppearance.Link}
      onClick={handleClick}
      aria-label={text}
      vertical
      data-cy={dataCy}
    >
      <Icon size={IconSize.s24}>
        <ExternalIcon name="X" />
      </Icon>
      {!hideText && (
        <>
          <br />
          {text}
        </>
      )}
    </Button>
  );
}
