import { styled } from '@yarmill/components';

export const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const CategoryDropdownIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.sand};
`;
