import { RootStore } from '../../app/mobx/root-store';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { File as YtdFile } from '../types';
import { uploadFile } from '../api/upload-file';
import { AxiosProgressEvent, CancelTokenSource } from 'axios';
import { action, computed, observable, makeObservable } from 'mobx';

export class FileUploadStore {
  private readonly file: File;

  @observable
  private _uploadedFile: YtdFile | null = null;

  @observable
  private readonly request: RequestStore<YtdFile>;
  @observable
  private uploadProgress: number = 0;

  private _toastId: number | string | null = null;

  @observable
  private _isCanceled: boolean = false;

  constructor(rootStore: RootStore, file: File) {
    makeObservable(this);
    this.file = file;
    const fileUploadUrl = rootStore.configStore.fileUploadUrl;

    const formData = new FormData();
    formData.append('file', file);

    this.request = rootStore.requestsStore.createRequest(
      (cancelToken: CancelTokenSource) =>
        uploadFile(fileUploadUrl, formData, cancelToken, this.setUploadProgress)
    );
  }

  public get progress(): number {
    return this.uploadProgress;
  }

  @computed
  public get name(): string {
    return this.uploadedFile?.FileName || this.file.name;
  }

  @computed
  public get size(): number {
    return this.file.size;
  }

  @computed
  public get status(): AsyncStatus {
    return this.request.status;
  }

  public get uploadedFile(): YtdFile | null {
    return this._uploadedFile;
  }

  public cancelUpload(): void {
    this.request.cancel();
    this._isCanceled = true;
  }

  public get isCanceled(): boolean {
    return this._isCanceled;
  }

  public get toastId(): number | string | null {
    return this._toastId;
  }

  public setToastId(toastId: number | string | null): void {
    this._toastId = toastId;
  }

  @action
  private readonly setUploadProgress = (e: AxiosProgressEvent) => {
    this.uploadProgress = e && e.total ? e.loaded / e.total : 0;
  };

  @action
  public async uploadFile(): Promise<void> {
    const response = await this.request.getResponse();

    if (response) {
      this._uploadedFile = response;
    }
  }
}
