import { RootStore } from '../app/mobx/root-store';
import { when } from 'mobx';
import {
  showGoogleCalendarErrorToastMessage,
  showGoogleCalendarSuccessToastMessage,
  showGoogleCalendarToastMessage,
} from './google-calendar-toast-message';
import { AsyncStatus } from '../api/mobx/request-store';
import { GoogleCalendarService } from './mobx/google-calendar-service';
import { IntlShape } from 'react-intl';
import { GroupStore } from '../groups/mobx/group-store';
import { User } from '../users/types';
import { getUserName } from '../users/utils';
import { toast } from '../components/toast-message';

export function handleConnectGoogleCalendarServiceSuccessRedirectAction(
  rootStore: RootStore
) {
  when(
    () => rootStore.currentUserStore.data !== null,
    async () => {
      const user = rootStore.currentUserStore.data;
      if (rootStore.currentUserStore.role === 'athlete' && user) {
        await subscribeUserToCalendar(rootStore.googleCalendarService, user);
      } else {
        toast('toast.success.connectExternalService', 'success');
      }
    }
  );
}

export async function subscribeUserToCalendar(
  googleCalendarService: GoogleCalendarService,
  user: User
): Promise<void> {
  const request = googleCalendarService.subscribeToUserCalendar(user);
  const toastId = showGoogleCalendarToastMessage(
    (intl: IntlShape) =>
      intl.formatMessage(
        { id: 'toast.progress.googleCalendarSubscribe.user' },
        {
          user: getUserName(user),
        }
      ),
    () => request.cancel()
  );

  await request.getResponse();

  if (request.status === AsyncStatus.resolved) {
    showGoogleCalendarSuccessToastMessage(toastId, (intl: IntlShape) =>
      intl.formatMessage(
        { id: 'toast.success.googleCalendarSubscribe.user' },
        {
          user: getUserName(user),
        }
      )
    );
  } else {
    showGoogleCalendarErrorToastMessage(toastId, (intl: IntlShape) =>
      intl.formatMessage(
        { id: 'toast.error.googleCalendarSubscribe.user' },
        {
          user: getUserName(user),
        }
      )
    );
  }
}

export async function subscribeGroupCalendar(
  googleCalendarService: GoogleCalendarService,
  group: GroupStore
): Promise<void> {
  const request = googleCalendarService.subscribeToGroupCalendar(group);
  const toastId = showGoogleCalendarToastMessage(
    (intl: IntlShape) =>
      intl.formatMessage(
        { id: 'toast.progress.googleCalendarSubscribe.group' },
        {
          group: group.name,
        }
      ),
    () => request.cancel()
  );

  await request.getResponse();

  if (request.status === AsyncStatus.resolved) {
    showGoogleCalendarSuccessToastMessage(toastId, (intl: IntlShape) =>
      intl.formatMessage(
        { id: 'toast.success.googleCalendarSubscribe.group' },
        {
          group: group.name,
        }
      )
    );
  } else {
    showGoogleCalendarErrorToastMessage(toastId, (intl: IntlShape) =>
      intl.formatMessage(
        { id: 'toast.error.googleCalendarSubscribe.group' },
        {
          group: group.name,
        }
      )
    );
  }
}
