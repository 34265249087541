import { UserStore } from '../mobx/user-store';
import { EvidenceTableFilters } from '../../evidence/table/types';
import { getNormalizedString } from '../../utils/get-normalized-string';
import { NumberFilter } from '@yarmill/components';

export function filterUserByName(
  user: UserStore,
  filter: string,
  searchByEmail = true
): boolean {
  if (
    !getNormalizedString(user.displayName.toLocaleLowerCase('cs')).includes(
      getNormalizedString((filter as string).toLocaleLowerCase('cs'))
    ) &&
    (!searchByEmail ||
      !getNormalizedString(user.internalUser.Email.toLowerCase()).includes(
        getNormalizedString((filter as string).toLocaleLowerCase())
      ))
  ) {
    return false;
  }
  return true;
}
export function userMatchesTableFilter(
  user: UserStore,
  filtersStore: EvidenceTableFilters
): boolean {
  const nameFilter = filtersStore.get('userName');
  const roleFilter = filtersStore.get('role');
  const statusFilter = filtersStore.get('status');
  const groupsFilter = filtersStore.get('groups');

  if (nameFilter) {
    if (!filterUserByName(user, nameFilter as string)) {
      return false;
    }
  }

  if (roleFilter) {
    if (Array.isArray(roleFilter)) {
      if (!roleFilter.includes(user.internalUser.Role)) {
        return false;
      }
    } else if (roleFilter !== user.internalUser.Role) {
      return false;
    }
  }

  if (statusFilter) {
    if (Array.isArray(statusFilter)) {
      if (!statusFilter.includes(String(user.internalUser.AccountStateId))) {
        return false;
      }
    } else if (statusFilter !== String(user.internalUser.AccountStateId)) {
      return false;
    }
  }

  if (groupsFilter) {
    const numberFilter = groupsFilter as unknown as NumberFilter;
    switch (numberFilter.type) {
      case 'match': {
        if (Number(numberFilter.values[0]) !== user.groups.length) {
          return false;
        }
        break;
      }
      case 'lessorequal': {
        if (!(Number(numberFilter.values[0]) >= user.groups.length)) {
          return false;
        }
        break;
      }
      case 'greaterorequal': {
        if (!(Number(numberFilter.values[0]) <= user.groups.length)) {
          return false;
        }
        break;
      }
      case 'range': {
        if (
          !(
            Number(numberFilter.from) <= user.groups.length &&
            Number(numberFilter.to) >= user.groups.length
          )
        ) {
          return false;
        }
        break;
      }
    }
  }

  return true;
}
