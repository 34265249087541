import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { Permission } from '../../permissions/types';

export function getUserGroupUserPermission(
  userGroupId: number
): AxiosPromise<Permission[]> {
  return axios.get('api/UserGroupUserPermission', {
    params: {
      userGroupId,
    },
  });
}
