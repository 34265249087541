import { RightPanelLayout } from '../components-2/right-panel';
import { useWindowSize } from '../utils/use-window-size';
import { PlannerEventStore } from './mobx/planner-event-store';
import { PlannerEventForm } from './form/planner-event-form';
import { useEffect } from 'react';
import { ThemeProvider } from '@yarmill/components';
import { usePlannerStore } from './planner-store-context';

interface PlannerEventPanelProps {
  readonly event: PlannerEventStore | null;
  readonly calendarEvent: PlannerEventStore | null;
  readonly close: () => void;
}
export function PlannerEventPanel({
  event,
  calendarEvent,
  close,
}: PlannerEventPanelProps) {
  const { width: windowWidth } = useWindowSize();
  const isVisible = Boolean(event);
  const plannerStore = usePlannerStore();

  useEffect(() => {
    if (!isVisible) {
      plannerStore.formik = null;
    }
  }, [isVisible, plannerStore]);

  return (
    <ThemeProvider theme="main" dark>
      <RightPanelLayout
        windowWidth={windowWidth ?? 0}
        isVisible={isVisible}
        dark
      >
        {event && calendarEvent && (
          <PlannerEventForm
            key={event.uid}
            event={event}
            calendarEvent={calendarEvent}
            close={close}
          />
        )}
      </RightPanelLayout>
    </ThemeProvider>
  );
}
