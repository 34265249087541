import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
} from 'mobx';
import { uniqId } from '../../app/utils';
import { GroupStore } from '../../groups/mobx/group-store';
import { UserStore } from '../../users/mobx/user-store';
import { EventLocation, EventLocationType, PlannerEvent } from '../types';
import moment from 'moment';

export class PlannerEventStore {
  @observable
  private _id: number | null = null;
  @observable
  private _uid: string = uniqId();
  @observable
  private _title: string | null = null;
  @observable
  private _startDate: string | null = null;
  @observable
  private _endDate: string | null = null;
  @observable
  private _eventTypeId: string | null = null;
  @observable
  private _category: string | null = null;
  @observable
  private _notes: string | null = null;
  @observable
  private _attributes: number[] | null = null;
  @observable
  private _location: EventLocation | null = null;
  @observable
  private _locationType: EventLocationType | null = null;
  @observable
  private _groups: IObservableArray<GroupStore> =
    observable.array<GroupStore>();
  @observable
  private _users: IObservableArray<UserStore> = observable.array<UserStore>();
  @observable
  private _lastUpdateDate: string | null = null;
  @observable
  private _isEditable: boolean = true;
  @observable
  private _isLocked: boolean = true;
  @observable
  private _isRemovable: boolean = true;
  @observable
  private _isAttendeesEditable: boolean = true;
  @observable
  private _otherSubscribersCount: number = 0;

  @observable
  public titlePlaceholder: string = '';
  @observable
  public hasPendingUpdate: boolean = false;
  @observable
  public saveFailed: boolean = false;

  @observable
  public layout: {
    conflictingEvents: number;
    offset: number;
  } = {
    conflictingEvents: 0,
    offset: 0,
  };

  constructor() {
    makeObservable(this);
  }

  public get id() {
    return this._id;
  }

  public get uid() {
    return this._uid;
  }

  public get title() {
    return this._title;
  }

  public get startDate() {
    return this._startDate;
  }

  @computed
  public get start(): moment.Moment {
    return moment(this.startDate);
  }

  @computed
  public get end(): moment.Moment {
    return moment(this.endDate);
  }

  public get endDate() {
    return this._endDate;
  }

  public get eventTypeId() {
    return this._eventTypeId;
  }

  public get category() {
    return this._category;
  }

  public get notes() {
    return this._notes;
  }

  public get location() {
    return this._location;
  }

  public get locationType() {
    return this._locationType;
  }

  public get groups() {
    return this._groups;
  }

  public get attributes() {
    return this._attributes;
  }

  public get users() {
    return this._users;
  }

  public get lastUpdateDate() {
    return this._lastUpdateDate;
  }

  public get isEditable() {
    return this._isEditable;
  }
  public get isRemovable() {
    return this._isRemovable;
  }

  public get isLocked() {
    return this._isLocked;
  }

  public get isAttendeesEditable() {
    return this._isAttendeesEditable;
  }

  public get otherSubscribersCount() {
    return this._otherSubscribersCount;
  }

  @action
  public setId(value: number | null) {
    this._id = value;
    return this;
  }

  @action
  public setUid(value: string): this {
    this._uid = value;
    return this;
  }

  @action
  public setTitle(value: string | null): this {
    this._title = value;
    return this;
  }

  @action
  public setStartDate(value: string | null): this {
    this._startDate = value;
    return this;
  }

  @action
  public setEndDate(value: string | null): this {
    this._endDate = value;
    return this;
  }

  @action
  public setEventTypeId(value: string | null): this {
    this._eventTypeId = value;
    return this;
  }

  @action
  public setCategory(value: string | null): this {
    this._category = value;
    return this;
  }

  @action
  public setNotes(value: string | null): this {
    this._notes = value;
    return this;
  }

  @action
  public setLocation(value: EventLocation | null): this {
    this._location = value;
    return this;
  }

  @action
  public setLocationType(value: EventLocationType | null): this {
    this._locationType = value;
    return this;
  }

  @action
  public setAttributes(value: number[] | null): this {
    this._attributes = value;
    return this;
  }
  @action
  public addGroups(group: GroupStore | GroupStore[]): this {
    this._groups.push(...(Array.isArray(group) ? group : [group]));
    return this;
  }

  @action
  public addUsers(user: UserStore | UserStore[]): this {
    this._users.push(...(Array.isArray(user) ? user : [user]));
    return this;
  }

  @action
  public replaceUsers(user: UserStore | UserStore[]): this {
    this._users.replace(Array.isArray(user) ? user : [user]);
    return this;
  }

  @action
  public setLastUpdateDate(value: string | null): this {
    this._lastUpdateDate = value;
    return this;
  }

  @action
  public setIsEditable(value: boolean): this {
    this._isEditable = value;
    return this;
  }

  @action
  public setIsRemovable(value: boolean): this {
    this._isRemovable = value;
    return this;
  }

  @action
  public setIsLocked(value: boolean): this {
    this._isLocked = value;
    return this;
  }

  @action
  public setIsAttendeesEditable(value: boolean): this {
    this._isAttendeesEditable = value;
    return this;
  }

  @action
  public setOtherSubscribersCount(value: number) {
    this._otherSubscribersCount = value;
    return this;
  }

  public toJS(): PlannerEvent {
    return {
      notes: this.notes,
      endDate: this.endDate,
      eventTypeId: this.eventTypeId,
      id: this.id,
      startDate: this.startDate,
      title: this.title,
      uid: this.uid,
      users: this._users.map(u => u.id),
      lastUpdateDate: this.lastUpdateDate,
      location: this.location,
      locationType: this.locationType,
      attributes: this._attributes,
      isEditable: this.isEditable,
      isRemovable: this.isRemovable,
      isLocked: this.isLocked,
      isAttendeesEditable: this.isAttendeesEditable,
      otherSubscribersCount: this.otherSubscribersCount,
    };
  }
}
