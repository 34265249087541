import * as React from 'react';
import { File, FileType } from '../../fileupload/types';
import { useCallback } from 'react';
import { useImageService } from '../../image-preview/hooks';
import { useFileDownloadService } from '../../fileupload/hooks';
import { useVideoService } from '../../video-player/hooks';

export function useFileLink(file: File, forceDownload?: boolean) {
  const imageService = useImageService();
  const filedDownloadService = useFileDownloadService();
  const videoService = useVideoService();

  return useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      if (
        file.FileType === FileType.video &&
        file.StreamUrl &&
        !forceDownload
      ) {
        const blobUrl = await filedDownloadService.downloadFile(
          file.StreamUrl,
          file.FileName,
          { download: false }
        );
        if (blobUrl) {
          videoService.openVideoPlayer(blobUrl);
        }
      } else if (file.FileType === FileType.picture && !forceDownload) {
        const imagePromise = filedDownloadService.downloadFile(
          file.FileUrl,
          file.FileName,
          { download: false, skipToastMessage: true }
        );
        imageService.showPreview(imagePromise as Promise<string>);
      } else {
        void filedDownloadService.downloadFile(file.FileUrl, file.FileName);
      }
    },
    [file, imageService, videoService, filedDownloadService, forceDownload]
  );
}
