import { RefObject } from 'react';
import { AbstractTrainingDayAttributeStore } from './abstract-training-day-attribute-store';
import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from '../../app/mobx/root-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { TrainingDayStore } from '../../training-day/mobx/training-day-store';
import { TrainingDayAttribute } from '../../training-day/types';
import { DiaryDataUniqueId, DiaryEventData } from '../../diary/types';

export class RichtextAttributeStore extends AbstractTrainingDayAttributeStore {
  protected userValue: string | null = null;

  @observable
  private _editor: any;

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    trainingDayStore: TrainingDayStore,
    attribute: TrainingDayAttribute,
    dataId: DiaryDataUniqueId
  ) {
    super(rootStore, diaryStore, trainingDayStore, attribute, dataId);
    makeObservable(this);
  }

  @computed
  public get isVideoEnabled(): boolean {
    return (
      this.rootStore.modulesStore.video && this.dataId.diaryType === 'plan'
    );
  }

  public get formattedValue(): string {
    return this.userValue ?? '';
  }

  @computed
  protected get currentValue(): string {
    return this.userValue ?? '';
  }

  @action
  public readonly onChange = (editor: RefObject<any> | string) => {
    if (typeof editor === 'string') {
      this.userValue = editor;
    } else if (editor.current && this.hasFocus) {
      this.userValue = editor.current.getCurrentValue();
    }
  };

  public setEditor(editor: any): void {
    this._editor = editor;
  }

  public get editor(): any {
    return this._editor;
  }

  @computed
  public get events(): Readonly<DiaryEventData[]> {
    const planAttribute = this.rootStore.configStore.goalsAttributes?.plan.day;
    const realityAttribute =
      this.rootStore.configStore.goalsAttributes?.reality.day;

    if (this.id === planAttribute || this.id === realityAttribute) {
      return this.trainingDayStore.events;
    }

    return [];
  }
}
