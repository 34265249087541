import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { ServerErrorResponse } from '../api/server-error-response';
import { useFormik } from 'formik';
import {
  AlertLayerCloseButtonWrapper,
  AlertLayerContent,
  Button,
  ButtonAppearance,
  ErrorBox,
  Text,
  TextInput,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { CloseButton } from '../components/close-button/close-button';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { UserStore } from './mobx/user-store';
import { checkIfEmailExists } from './api/check-if-email-exists';
import { UserAccountState } from './types';
import { toast } from '../components/toast-message';
import { isValidEmail } from '../utils/is-valid-email';

interface UpdateEmailDialogProps {
  readonly close: () => void;
  readonly user: UserStore;
}
export function UpdateEmailDialog({
  close,
  user,
}: UpdateEmailDialogProps): JSX.Element {
  const intl = useIntl();
  const [apiError, setApiError] = useState<ServerErrorResponse | null>(null);
  const formik = useFormik({
    initialValues: {
      email: user.internalUser.Email,
    },

    validateOnBlur: true,
    validateOnChange: true,
    validate: async ({ email }) => {
      if (email === user.internalUser.Email) {
        return undefined;
      }

      if (!isValidEmail(email)) {
        return {
          email: intl.formatMessage({
            id: 'settings.users.incorrectEmail',
          }),
        };
      } else {
        const { data } = await checkIfEmailExists(email);
        if (data) {
          return {
            email: intl.formatMessage({
              id: 'settings.users.emailExists',
            }),
          };
        }
      }
    },
    onSubmit: async (values, formikHelpers) => {
      setApiError(null);
      const errors = await formikHelpers.validateForm(values);

      if (Object.keys(errors).length === 0) {
        if (values.email === user.internalUser.Email) {
          close();
          formik.resetForm();
          return;
        }
        const result = await user.updateEmail(values.email);
        if (result === true) {
          toast('toast.success.updateEmail', 'success', {
            user: user.displayName,
          });
          if (user.internalUser.AccountStateId === UserAccountState.PENDING) {
            const success = await user.resendInvitation();
            if (success) {
              toast('toast.success.resendInvitation', 'success', {
                user: user.displayName,
              });
            } else {
              toast('toast.error.resendInvitation', 'error', {
                user: user.displayName,
              });
            }
          }
          close();
          formik.resetForm();
        } else {
          setApiError(result);
        }
      }
    },
  });

  return (
    <AlertLayerContent.AlertLayerContainer>
      <AlertLayerContent.StickyWrapper>
        <AlertLayerCloseButtonWrapper>
          <CloseButton onClick={close} hideText />
        </AlertLayerCloseButtonWrapper>
      </AlertLayerContent.StickyWrapper>
      <AlertLayerContent.ContentWrapper onSubmit={formik.handleSubmit}>
        <AlertLayerContent.HeadlineWrapper>
          <Text tag={TextTag.h2} size={TextSize.s16} medium>
            <FormattedMessage
              id="settings.users.updateEmail.headline"
              values={{ user: user.displayName }}
            />
          </Text>
        </AlertLayerContent.HeadlineWrapper>
        {apiError && (
          <ErrorBox>
            <FormattedHTMLMessage id={apiError.id} values={apiError.values} />
          </ErrorBox>
        )}
        <div>
          <TextInput
            id="email"
            name="email"
            type="email"
            noLabel
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.errors.email}
          />
        </div>
        <AlertLayerContent.ButtonsWrapper>
          <Button
            type="button"
            appearance={ButtonAppearance.Link}
            noShadow
            data-cy="cancel"
            onClick={close}
          >
            <FormattedMessage id="settings.users.updateEmail.cancel" />
          </Button>
          <Button
            type="submit"
            appearance={ButtonAppearance.Primary}
            noShadow
            disabled={Object.keys(formik.errors).length !== 0}
            data-cy="save"
          >
            {user.internalUser.AccountStateId !== UserAccountState.PENDING && (
              <FormattedMessage id="settings.users.updateEmail.submit" />
            )}
            {user.internalUser.AccountStateId === UserAccountState.PENDING && (
              <FormattedMessage id="settings.users.updateEmail.submitAndSendInvitation" />
            )}
          </Button>
        </AlertLayerContent.ButtonsWrapper>
      </AlertLayerContent.ContentWrapper>
    </AlertLayerContent.AlertLayerContainer>
  );
}
