import {
  Button,
  ButtonAppearance,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  trackExternalServiceConnectClick,
  trackExternalServiceDisconnectClick,
} from '../../google-analytics/utils';

import { Tippy } from '../../components/tippy/tippy';
import { useLogger } from '../../utils/logger/logger-context';
import { createInstanceUrl } from '../utils/create-instance-url';

const StyledService = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr auto;
  column-gap: 16px;
  align-items: flex-start;
  margin: 48px 0;
`;

const Logo = styled.div`
  width: 64px;
  display: flex;
  align-items: center;
`;
const Img = styled.img<{ readonly hideBorder?: boolean }>`
  width: 100%;
  height: auto;
  border: 1px solid
    ${({ hideBorder }) => (hideBorder ? 'transparent' : 'rgba(205, 205, 205)')};
  border-radius: 8px;
`;

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 4px;
  @media (min-width: 768px) {
    padding-right: 64px;
  }

  & > :first-child {
    line-height: 1;
  }
`;

const StyledButtonWrapper = styled.div``;

export interface ServiceProps {
  readonly name: string | JSX.Element;
  readonly ident: string;
  readonly description: string | JSX.Element;
  readonly connected: boolean;
  readonly userId: number;
  readonly token: string;
  readonly editable: boolean;
  readonly hideBorder?: boolean;
  readonly connectUrl: string | null;
  readonly disconnectUrl: string | null;
}

function getLogoUrl(service: string): string {
  return `https://core.ms.yarmill.com/assets/external-services/${service}.png?v2`;
}

export function Service(props: ServiceProps): JSX.Element {
  const {
    ident,
    name,
    userId,
    token,
    description,
    connected,
    editable,
    hideBorder,
    connectUrl,
    disconnectUrl,
  } = props;
  const [clicked, setClicked] = React.useState<boolean>(false);
  const logger = useLogger();

  const onConnectClick = React.useCallback(
    (e: React.MouseEvent): void => {
      if (clicked) {
        e.preventDefault();
        return;
      }
      trackExternalServiceConnectClick(ident);
      setClicked(true);
    },
    [ident, clicked]
  );

  const onDisconnectClick = React.useCallback(
    (e: React.MouseEvent): void => {
      if (clicked) {
        e.preventDefault();
        return;
      }
      trackExternalServiceDisconnectClick(ident);
      setClicked(true);
    },
    [ident, clicked]
  );

  return (
    <StyledService>
      <Logo>
        <Img src={getLogoUrl(ident)} alt={ident} hideBorder={hideBorder} />
      </Logo>
      <StyledTextWrapper>
        <Text size={TextSize.s20} tag={TextTag.div} bold>
          {name}
        </Text>
        <Text size={TextSize.s16} tag={TextTag.div}>
          {description}
        </Text>
      </StyledTextWrapper>
      <StyledButtonWrapper>
        {connected ? (
          <Tippy
            isEnabled={!editable}
            tooltipContent={`settings.profile.services.${ident}.nonEditable`}
          >
            <Button
              appearance={ButtonAppearance.Secondary}
              noShadow
              disabled={!editable}
              href={
                editable && disconnectUrl
                  ? `${createInstanceUrl(disconnectUrl, userId, token, logger)}`
                  : undefined
              }
              onClick={editable ? onDisconnectClick : undefined}
            >
              <FormattedMessage id="settings.profile.services.disconnect" />
            </Button>
          </Tippy>
        ) : (
          <Tippy
            isEnabled={!editable}
            tooltipContent={`settings.profile.services.${ident}.nonEditable`}
          >
            <Button
              appearance={ButtonAppearance.Primary}
              inverted
              noShadow
              disabled={!editable}
              href={
                editable && connectUrl
                  ? `${createInstanceUrl(connectUrl, userId, token, logger)}`
                  : undefined
              }
              onClick={editable ? onConnectClick : undefined}
            >
              <FormattedMessage id="settings.profile.services.connect" />
            </Button>
          </Tippy>
        )}
      </StyledButtonWrapper>
    </StyledService>
  );
}
