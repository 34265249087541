import { RestingHeartRateData } from '../../training-day/types';
import { ExternalIcon, HealthDataOverviewRow } from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { Tippy } from '../../components/tippy/tippy';

export interface RestingHeartRateProps {
  value: RestingHeartRateData;
}
export function RestingHeartRate(props: RestingHeartRateProps): JSX.Element {
  const { value } = props;
  const validatedValue = {
    AvgHR: '-',
    MaxHR: '-',
    Label: '-',
    MinHR: '-',
    RestingHR: '-',
    ...value,
  };

  return (
    <HealthDataOverviewRow
      icon={<ExternalIcon name="Heartbeat" />}
      label={
        value.Label ? <FormattedMessage id={value.Label} /> : 'Missing Label'
      }
      primaryValue={
        <FormattedMessage
          id="healthData.restingHeartRate.value"
          values={validatedValue}
        />
      }
      secondaryValues={[
        value.MaxHR && (
          <Tippy tooltipContent="healthData.restingHeartRate.high.tooltip">
            <FormattedMessage
              id="healthData.restingHeartRate.high"
              values={validatedValue}
            />
          </Tippy>
        ),
        value.RestingHR && (
          <Tippy tooltipContent="healthData.restingHeartRate.restingHr.tooltip">
            <FormattedMessage
              id="healthData.restingHeartRate.restingHr"
              values={validatedValue}
            />
          </Tippy>
        ),
        value.AvgHR && (
          <Tippy tooltipContent="healthData.restingHeartRate.avg.tooltip">
            <FormattedMessage
              id="healthData.restingHeartRate.avg"
              values={validatedValue}
            />
          </Tippy>
        ),
      ].filter(Boolean)}
    />
  );
}
