import { DashboardSelector, SidebarContainer } from '@yarmill/components';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { trackChangeDashboardClick } from '../google-analytics/utils';
import { useHistory, useLocation } from '../routes/hooks';
import { observer } from 'mobx-react-lite';
import { Filters } from './filters';
import { useReportingStore } from './hooks/use-reporting-store';

export const Sidebar = observer(function Sidebar(): JSX.Element {
  const intl = useIntl();
  const reportingStore = useReportingStore();
  const history = useHistory();
  const { search } = useLocation();

  const activeDashboard = reportingStore.reportingPageCode;
  const currentPageDashboards = reportingStore.currentPageDashboards;

  const dashboards = useMemo(
    () =>
      currentPageDashboards.map(page => ({
        id: page.code,
        label: intl.formatMessage({ id: page.title }),
        description: intl.formatMessage({ id: page.description }),
      })),
    [currentPageDashboards, intl]
  );

  const setActiveDashboard = useCallback(
    (dashboard: string) => {
      if (dashboard !== activeDashboard) {
        trackChangeDashboardClick(dashboard);
        history.push(`/reporting/${dashboard}${search}`);
      }
    },
    [activeDashboard, history, search]
  );

  return (
    <SidebarContainer>
      <DashboardSelector
        activeDashboard={activeDashboard}
        dashboards={dashboards}
        onDashboardClick={setActiveDashboard}
      />
      <Filters position="sidebar" />
    </SidebarContainer>
  );
});
