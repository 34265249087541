import moment from 'moment';

import { ROUTE_DATE_FORMAT, DATE_FORMAT } from '../diary/utils';
import { loadPersistedGroupId } from '../groups/utils';

import { ViewType } from '../diary/types';
import { ViewType as AttendanceViewType } from '../attendance/types';
import { ViewType as SeasonEvaluationViewType } from '../season-evaluation/types';
import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  WEEK_SEARCH_PARAM,
} from '../routes/types';
import { RootStore } from './mobx/root-store';
import { PermissionScope } from '../permissions/types';
import { ModulesStore } from '../modules/mobx/modules-store';

type PriorityMatrix = [
  Exclude<
    keyof ModulesStore,
    'externalServices' | 'video' | 'userEvidence' | 'announcement'
  >,
  ViewType[] | SeasonEvaluationViewType[] | AttendanceViewType[] | string[],
][];

export function getDefaultMainRoute(rootStore: RootStore): string {
  const currentUser = rootStore.currentUserStore;
  const modules = rootStore.modulesStore;
  const searchParams = new URLSearchParams();
  const isAthlete = currentUser && currentUser.isAthlete;
  const isCoachOrAdmin = currentUser && !currentUser.isAthlete;

  if (!rootStore.isReady) {
    return window.location.href;
  }

  const diaryViewTypes: ViewType[] = ['season'];
  if (rootStore.configStore.goalsViewEnabled) {
    diaryViewTypes.unshift('goals');
  }
  if (rootStore.configStore.seasonGoalsViewEnabled) {
    diaryViewTypes.unshift('seasonGoals');
  }
  if (rootStore.configStore.weekViewEnabled) {
    diaryViewTypes.unshift('week');
  }

  const priorityMatrix: PriorityMatrix = [
    ['plan', diaryViewTypes],
    ['reality', diaryViewTypes],
  ];

  if (isAthlete) {
    priorityMatrix.reverse();
    searchParams.set(ATHLETE_SEARCH_PARAM, String(currentUser.id));
  }

  const extendedMatrix: PriorityMatrix = [
    [
      'analytics',
      rootStore.legacyAnalyticsStore.dashboards.map(dashboard => dashboard.id),
    ],
    [
      'reporting',
      rootStore.reportingStore.navigationLinks.map(
        link => link.dashboard.ReportPageCode
      ),
    ],
    ['attendance', ['week', 'season']],

    [
      'evidence',
      rootStore.configStore.evidenceModuleConfigurations.map(
        module => module.moduleKey
      ),
    ],
    ['filesOverview', []],
    ['seasonEvaluation', []],
    ['okr', []],
    ['settings', []],
  ];

  priorityMatrix.push(...extendedMatrix);
  const currentDay = moment().format(ROUTE_DATE_FORMAT);

  const groups = rootStore.groupsStore.sortedGroups.filter(group =>
    currentUser.hasPermissionToGroup(group.id)
  );

  if (groups.length === 0 && isCoachOrAdmin) {
    return '/noGroup';
  }

  let groupId = loadPersistedGroupId(rootStore.groupsStore, currentUser);

  if (!groupId) {
    groupId = groups[0]?.id;
  }

  if (!groupId && isCoachOrAdmin) {
    return '/noGroup';
  }

  if (groupId) {
    searchParams.set(GROUP_SEARCH_PARAM, String(groupId));
  }

  searchParams.set(WEEK_SEARCH_PARAM, currentDay);

  const resultingModule = priorityMatrix.find(([module, viewTypes]) => {
    if (!modules[module]) return false;
    if (!currentUser.isAllowedTo(module as PermissionScope)) return false;
    if (viewTypes.length) {
      return viewTypes.some(viewType =>
        currentUser.isAllowedTo(
          `${module}.${viewType}` as unknown as PermissionScope
        )
      );
    }
    return true;
  });

  if (!resultingModule) {
    return '/notFound';
  }

  const [module, viewTypes] = resultingModule;
  const finalUrl: string[] = [module];

  if (viewTypes.length) {
    const viewType = viewTypes.find(viewType =>
      currentUser.isAllowedTo(
        `${module}.${viewType}` as unknown as PermissionScope
      )
    );
    if (viewType) {
      finalUrl.push(viewType);
    }
  }

  return `/${finalUrl.join('/')}?${searchParams.toString()}`;
}

export function uniqId(): string {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
}

const dateFormats = [
  DATE_FORMAT,
  ROUTE_DATE_FORMAT,
  'DD-MM-YYYY',
  'DD.MM.YYYY',
  'DD. MM. YYYY',
];

export function sortByDate(
  a: string | moment.Moment | undefined,
  b: string | moment.Moment | undefined
): number {
  return moment(b, dateFormats).diff(moment(a, dateFormats));
}

export function sortBySortCode<
  T extends
    | { SortCode: Readonly<number | null> }
    | { sortCode: Readonly<number | null> },
>(a: T, b: T): number {
  const left = 'sortCode' in a ? a.sortCode : a.SortCode;
  const right = 'sortCode' in b ? b.sortCode : b.SortCode;

  return (left ?? 0) - (right ?? 0);
}

export const REDIRECT_ACTION_PARAM = 'ra';
