import { action, observable, makeObservable } from 'mobx';
import { Video } from '../types';

export class VideoSelectorService {
  @observable
  private _callback: ((video: Video) => void) | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public readonly openVideoSelector = (
    callback: (video: Video) => void
  ): void => {
    this._callback = callback;
  };

  @action
  public readonly closeVideoSelector = (): void => {
    this._callback = null;
  };

  public get callback(): ((video: Video) => void) | null {
    return this._callback;
  }
}
