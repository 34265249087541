import { AbstractTrainingDayAttributeStore } from './abstract-training-day-attribute-store';
import { makeObservable, observable, override } from 'mobx';
import {
  CgmValue,
  TrainingDayAttribute,
  TrainingDayData,
} from '../../training-day/types';
import { RootStore } from '../../app/mobx/root-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { TrainingDayStore } from '../../training-day/mobx/training-day-store';
import { DiaryDataUniqueId } from '../../diary/types';

export class CgmAttributeStore extends AbstractTrainingDayAttributeStore {
  protected userValue: null = null;
  @observable
  private _measurements: CgmValue[] = [];

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    trainingDayStore: TrainingDayStore,
    attribute: TrainingDayAttribute,
    dataId: DiaryDataUniqueId
  ) {
    super(rootStore, diaryStore, trainingDayStore, attribute, dataId);
    makeObservable(this);
  }

  protected get currentValue(): string | string[] {
    return '';
  }

  @override
  public setApiValue(newValue: TrainingDayData) {
    super.setApiValue(newValue);
    this._measurements = newValue.Value as CgmValue[];
  }

  public clear(): void {
    super.clear();
    this._measurements = [];
  }

  public get measurements(): CgmValue[] {
    return this._measurements;
  }
}
