import { Line } from '@visx/shape';
import { useXYChartContext } from '../xy-chart-context';
import { useTooltipContext } from '../hooks/use-tooltip';

export function ContinuousTooltip() {
  const { chartRect } = useXYChartContext();
  const { tooltipLeft, tooltipOpen } = useTooltipContext();

  return (
    <>
      {tooltipOpen && (
        <Line
          from={{ x: tooltipLeft, y: chartRect.top }}
          to={{ x: tooltipLeft, y: chartRect.bottom }}
          stroke="#c2c2c2"
          strokeWidth={2}
          pointerEvents="none"
          strokeDasharray="2,2"
        />
      )}
    </>
  );
}
