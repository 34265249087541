import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { LineStyle } from '@yarmill/components';
import { LineChart } from '../../types';
import { DataItem } from '../../../reporting/types';
import { DEFAULT_MARKER_SIZE } from '../../../reporting/const';

interface LineMarkerProps {
  readonly config: LineChart;
  readonly item: DataItem;
  readonly x: number;
  readonly y: number;
}

const AnimatedMarkerCircle = animated.circle;

export const StyledLineMarkerCircle = styled(AnimatedMarkerCircle)<{
  $lineStyle?: LineStyle;
  $lineColor: string;
  $showLine: boolean;
}>`
  ${({ $showLine, $lineColor, $lineStyle }) =>
    $showLine &&
    $lineStyle === 'hover' &&
    css`
      :hover {
        & ~ path {
          stroke: ${$lineColor};
        }
      }
    `}
`;

export function LineMarker({ config, item, x, y }: LineMarkerProps) {
  const value = config.getYValue(item);
  const markerSize = config.getMarkerSize?.(item) ?? DEFAULT_MARKER_SIZE;

  const showLabel = config.getShowLabels(item);

  if (value === null || (markerSize === 0 && !showLabel)) {
    return null;
  }

  return (
    <StyledLineMarkerCircle
      fill={config.getColor(item)}
      r={markerSize}
      cx={x}
      cy={y}
      $lineColor={config.getColor(item)}
      $lineStyle={config.getLineStyle?.(item)}
      $showLine
      pointerEvents={config.getLineStyle?.(item) === 'hover' ? 'all' : 'none'}
      stroke={config.getMarkerStrokeColor?.(item)}
      strokeWidth={config.getMarkerStrokeWidth?.(item)}
      data-x={config.getXValue(item)}
      data-y={config.getYValue(item)}
    />
  );
}
