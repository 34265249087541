import { athleteCookies } from './types';
import { UserStore } from '../users/mobx/user-store';

export function persistSelectedAthlete(athlete: UserStore | undefined): void {
  if (athlete) {
    window.localStorage.setItem(athleteCookies.ATHLETE, String(athlete.id));
  } else {
    window.localStorage.removeItem(athleteCookies.ATHLETE);
  }
}
export function loadPersistSelectedAthlete(): number | null {
  const value =
    typeof window !== 'undefined'
      ? window.localStorage.getItem(athleteCookies.ATHLETE)
      : null;

  if (value) {
    try {
      return parseInt(value, 10);
    } catch (e: unknown) {
      return null;
    }
  }

  return null;
}
