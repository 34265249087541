import styled from 'styled-components';
import { Breakpoints } from '@yarmill/components';

const ButtonsWrapper = styled.div`
  background: #fff;

  tr:hover & {
    background: #f7f9fb;
  }
  margin: -8px 0 -8px;
`;

export const DesktopButtonsWrapper = styled(ButtonsWrapper)`
  display: none;
  @media (min-width: ${Breakpoints.tablet}px) {
    display: block;
  }
`;

export const MobileButtonsWrapper = styled(ButtonsWrapper)`
  display: block;
  @media (min-width: ${Breakpoints.tablet}px) {
    display: none;
  }
`;
