import { isEvidenceLink, isMainNavLink, isReportingLink } from './utils';
import { DropdownLinks } from './dropdown-links';
import { EvidenceLink } from './links/evidence-link';
import { AnalyticsLink } from './links/analytics-link';
import { ReportingLink } from './links/reporting-link';
import { HeaderLink } from './types';
import { FormattedMessage } from 'react-intl';
import { ExternalIcon } from '@yarmill/components';

interface NavigationLinkProps {
  readonly item: HeaderLink;
  readonly allItems?: HeaderLink[];
}
export function NavigationLink({
  item,
  allItems = [],
}: NavigationLinkProps): JSX.Element | null {
  if (isMainNavLink(item) && item.module === 'reporting') {
    return (
      <DropdownLinks
        label={<FormattedMessage id="header.navigation.analytics" />}
        icon={<ExternalIcon name="ChartLine" />}
        links={allItems.filter(isReportingLink)}
        id="reportingItems"
      />
    );
  }

  return (
    <li>
      {isMainNavLink(item) ? (
        <item.Component />
      ) : isEvidenceLink(item) ? (
        <EvidenceLink
          moduleKey={item.moduleKey}
          key={item.moduleKey}
          icon={item.icon}
        />
      ) : isReportingLink(item) ? (
        item.isLegacyAnalytics ? (
          <AnalyticsLink
            dashboardName={item.dashboard.ReportPageCode}
            linkName={item.title}
          />
        ) : (
          <ReportingLink
            reportingPage={item.dashboard.ReportPageCode}
            linkName={item.title}
          />
        )
      ) : null}
    </li>
  );
}
