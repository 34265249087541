import { RootStore } from '../../app/mobx/root-store';
import { ReportsDefinitionStore } from './reports-definition-store';
import { computed, observable, reaction, when, makeObservable } from 'mobx';
import { AsyncStatus } from '../../api/mobx/request-store';
import { ReportsDataStore } from './reports-data-store';
import {
  ReportFilter,
  ReportingNavigationLink,
  ReportingSection,
  ReportItemStore,
} from '../types';
import { getReportingPagePermissionScope } from '../utils';
import { UserAccountState } from '../../users/types';
import { ReportingPageStore } from './reporting-page-store';

export class ReportingStore {
  private readonly rootStore: RootStore;
  @observable
  private _reportingPageCode: string | null = null;
  @observable
  private _definitionStore: ReportsDefinitionStore | null = null;
  @observable
  private _dataStore: ReportsDataStore | null = null;
  private _useUrlFilters = true;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    when(
      () =>
        this.rootStore.status === AsyncStatus.resolved &&
        this.rootStore.currentUserStore.data?.AccountStateId ===
          UserAccountState.ACTIVE,
      () => this.initStoresAndReactions()
    );
  }

  public get definitionStore(): ReportsDefinitionStore | null {
    return this._definitionStore;
  }

  public get dataStore(): ReportsDataStore | null {
    return this._dataStore;
  }

  public get reportingPageCode(): string | null {
    return this._reportingPageCode;
  }

  @computed
  public get navigationLinks(): ReportingNavigationLink[] {
    const moduleConfig = this.rootStore.modulesStore.reporting || [];
    const currentUser = this.rootStore.currentUserStore;
    const currentUserRole = currentUser.role;
    const currentUserId = currentUser.id;

    const links = moduleConfig
      // Find first dashboard on page that user is allowed to see
      .map(module => ({
        title: module.Title,
        isLegacyAnalytics: module.LegacyAnalytics,
        dashboard: module.Dashboards.find(
          dashboard =>
            (dashboard.Permissions.Roles.includes(currentUserRole) ||
              dashboard.Permissions.Users.includes(currentUserId)) &&
            currentUser.isAllowedTo(
              module.LegacyAnalytics
                ? `analytics.${dashboard.ReportPageCode}`
                : getReportingPagePermissionScope(dashboard!.ReportPageCode)
            )
        ),
      }))
      // Get rid of pages without dashboards
      .filter(item => item.dashboard)
      .map(item => ({
        ...item,
        permissionScope: item.isLegacyAnalytics
          ? `analytics.${item.dashboard!.ReportPageCode}`
          : getReportingPagePermissionScope(item.dashboard!.ReportPageCode),
      }));

    return links as ReportingNavigationLink[];
  }

  @computed
  public get currentPageDashboards(): ReportingPageStore[] {
    const moduleConfig = this.rootStore.modulesStore.reporting;
    const currentUser = this.rootStore.currentUserStore;

    const currentPage = moduleConfig?.find(page =>
      page.Dashboards.find(
        dashboard => dashboard.ReportPageCode === this.reportingPageCode
      )
    );

    const pageDashboards = currentPage?.Dashboards.map(dashboard =>
      this.definitionStore?.pages.get(dashboard.ReportPageCode)
    )
      .filter(Boolean)
      .filter(dashboard =>
        currentUser.isAllowedTo(
          getReportingPagePermissionScope(dashboard!.code)
        )
      ) as ReportingPageStore[];

    return pageDashboards || [];
  }

  @computed
  public get currentPageFilters(): ReportFilter[] {
    const currentPage = this.definitionStore?.pages.get(
      this.reportingPageCode || ''
    );

    return currentPage?.filters || [];
  }

  @computed
  public get pageReports(): ReportItemStore[] {
    return (
      this.currentPage?.reportItems?.filter(
        report =>
          report.permissions.isAllowed(this.rootStore.currentUserStore) &&
          ((!report.clarificators && !this._dataStore?.clarificator) ||
            this.dataStore?.clarificator?.every(clarificator =>
              report.clarificators?.includes(clarificator)
            ))
      ) || []
    );
  }

  @computed
  public get pageSections(): ReportingSection[] | undefined {
    return this.currentPage?.sections.filter(
      section =>
        (!section.Clarificators && !this._dataStore?.clarificator) ||
        this.dataStore?.clarificator?.every(clarificator =>
          section.Clarificators?.includes(clarificator)
        )
    );
  }

  @computed
  public get currentPage(): ReportingPageStore | undefined {
    return this.definitionStore?.pages.get(this.reportingPageCode || '');
  }

  private async initStoresAndReactions(): Promise<void> {
    const definitionUrl = this.rootStore.configStore.reportingDefUrl;
    this._definitionStore = new ReportsDefinitionStore(
      this.rootStore,
      definitionUrl
    );
    await this._definitionStore.loadDefinition();

    reaction(
      () => this.rootStore.historyService.pathname,
      pathname => {
        if (!pathname.includes('reporting')) {
          this._reportingPageCode = null;
          this._useUrlFilters = true;
        }
        const match = pathname.match(/\/reporting\/(.+)$/);
        this._reportingPageCode = match?.[1] || null;
      },
      {
        fireImmediately: true,
      }
    );

    reaction(
      () => this._reportingPageCode,
      pageCode => {
        if (pageCode) {
          const dataUrl = this.rootStore.configStore.reportingDataUrl;

          this._dataStore?.dispose();
          this._dataStore = new ReportsDataStore(
            this.rootStore,
            dataUrl,
            pageCode,
            this.currentPage?.filters || []
          );
          if (this._useUrlFilters) {
            this._dataStore.loadUrlFilters();
            this._useUrlFilters = false;
          }
          this._dataStore.loadPersistedFilters();
          void this._dataStore.loadData();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }
}
