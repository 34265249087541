import { FormEvent } from 'react';
import { action, computed, makeObservable, observable, override } from 'mobx';

import { AbstractActivityStore } from './abstract-activity-store';
import { formatValueByUnit, parseValueByUnit } from '../utils';
import { Units } from '../../units/types';
import { RootStore } from '../../app/mobx/root-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { ActivityItem } from '../types';

const TIME_REGEX = new RegExp(/^[0-9]+:[0-9]+$/);
const TIME_IN_PROGRESS_REGEX = new RegExp(/[0-9]+:$/);
const VALID_CHARACTERS_REGEX = new RegExp(/^[0-9:]*$/);

export class LongTimeActivityStore extends AbstractActivityStore {
  @observable
  private stringValue: string = '';

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    index: number,
    activityItem: ActivityItem
  ) {
    super(rootStore, diaryStore, index, activityItem);
    makeObservable(this);
  }

  public setApiValue(newValue: number) {
    super.setApiValue(newValue);
    this.stringValue = formatValueByUnit(newValue, Units.min);
  }

  public clear() {
    super.clear();
    this.stringValue = '';
  }

  public get formattedValue(): string {
    return this.stringValue;
  }

  @override
  public empty() {
    super.empty();
    this.stringValue = '';
  }

  @computed
  public get placeholder(): string | undefined {
    return this.hasFocus && !this.stringValue ? 'hod:min' : '';
  }

  @action
  public readonly onChange = (e: FormEvent<HTMLInputElement>): void => {
    const { target } = e;
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    if (!VALID_CHARACTERS_REGEX.test(target.value)) {
      return;
    }

    const newValue = target.value;

    const intValue = this.parseValue(newValue);
    const isInProgress = TIME_IN_PROGRESS_REGEX.test(newValue);
    const isHhMm = TIME_REGEX.test(newValue);
    const error = !isInProgress && intValue === null;

    if (error) {
      this.error = 'activityItem.longTime.invalidValue';
    } else {
      this.error = null;
    }
    this.stringValue = newValue;

    if (!isInProgress && !error && intValue !== null) {
      // if value is in hh:mm format, result is number of minutes, else we consider it as number of hours
      const minutes = isHhMm ? intValue : intValue * 60;
      this.setUserValue(minutes);
    }
  };

  private readonly parseValue = (value: string): number | null => {
    if (value === '') {
      return 0;
    }

    if (TIME_REGEX.test(value)) {
      // value is in hh:mm format, result is number of minutes
      const typedValue = parseValueByUnit(value, Units.min);

      return typedValue;
    }

    // value is number -> we consider it as number of hours
    const numberValue = parseInt(value, 10);
    if (value === String(numberValue)) {
      return numberValue;
    }

    return null;
  };

  public onBlur() {
    super.onBlur();

    if (!TIME_REGEX.test(this.stringValue)) {
      this.stringValue = formatValueByUnit(this.currentValue, Units.min);
    }
  }
}
