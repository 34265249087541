import { createContext } from 'react';
import { CsvTableHeader } from './types';

export interface CsvTableCellContextValue {
  readonly columnConfig: CsvTableHeader | undefined;
  readonly columnPrintWidth: number;
}

export const CsvTableCellContext = createContext<
  CsvTableCellContextValue | undefined
>(undefined);
