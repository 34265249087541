import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { ConflictResolution, CopyConflictResponse } from '../types';

export interface CopyUserPlanToReality {
  UserId: number;
  SourceDay: string;
  ConflictResolution: ConflictResolution;
}

export function copyUserPlanToReality(
  params: CopyUserPlanToReality
): AxiosPromise<boolean | CopyConflictResponse> {
  return axios.post('api/Plan/Week/Copy/UserPlanToReality', params);
}
