import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { AttendanceValue } from '../types';

export interface UpdateAttendanceItemForGroupRequestParams {
  Value: AttendanceValue;
  Date: string;
  UserGroupId: number;
  ActivityItemId: number | null;
}

export function updateAttendanceItemForGroup(
  params: UpdateAttendanceItemForGroupRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<boolean> {
  return axios.post('api/attendance/save/day', params, {
    cancelToken: cancelToken?.token,
  });
}
