import { RootStore } from '../../app/mobx/root-store';
import { computed, observable, runInAction, makeObservable } from 'mobx';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import {
  Clarificator,
  DataSourcesMap,
  GetReportPageDataResponse,
  ReportData,
  ReportFilter,
  ResultSetCode,
} from '../types';
import { getReportPageData } from '../api/get-report-page-data';
import { createDataSourceItem, getDataSourceItemId } from '../utils';
import { ReportingPageFilterStore } from './reporting-page-filter-store';

export class ReportsDataStore {
  private readonly rootStore: RootStore;
  private readonly dataUrl: string;
  private readonly reportPageCode: string;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;
  @observable
  private _request: RequestStore<GetReportPageDataResponse> | null = null;
  @observable
  private _data: Map<ResultSetCode, ReportData> = new Map();
  @observable
  private _dataSources: DataSourcesMap = {};
  @observable
  private _filters: Map<ResultSetCode, ReportingPageFilterStore> = new Map();
  @observable
  private _clarificator: Clarificator[] | null = null;

  constructor(
    rootStore: RootStore,
    dataUrl: string,
    reportPageCode: string,
    defaultFilters: ReportFilter[]
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.dataUrl = dataUrl;
    this.reportPageCode = reportPageCode;

    defaultFilters?.forEach(filter => {
      const store = new ReportingPageFilterStore(rootStore, filter, () =>
        this.loadData()
      );
      this._filters.set(filter.Code, store);
      store.loadPersistedValue();
    });
  }

  public async loadData(): Promise<void> {
    let hasRequiredFilters = true;
    const filtersData = this.apiFilters;

    runInAction(() => {
      this._request?.cancel();
      this._data.clear();

      if (Object.keys(filtersData).length < this._filters.size) {
        hasRequiredFilters = false;
        return;
      }

      this._status = AsyncStatus.pending;
    });

    if (!hasRequiredFilters) {
      return;
    }

    const transaction = this.rootStore.navbarStore.createTransaction(
      'loadingData',
      'reporting'
    );
    this._request = this.rootStore.requestsStore.createRequest(cancelToken =>
      getReportPageData(
        this.dataUrl,
        this.reportPageCode,
        filtersData,
        cancelToken
      )
    );

    const response = await this._request.getResponse();
    if (response) {
      runInAction(() => {
        response.Data.forEach(reportData => {
          this._data.set(reportData.Code, reportData);
        });
        response.DataSources.forEach(dataSource => {
          const items = new Map();
          dataSource.Data.forEach(item =>
            items.set(
              getDataSourceItemId(item),
              createDataSourceItem(this.rootStore, item)
            )
          );
          this._dataSources[dataSource.Code] = items;
        });
        response.Filters?.forEach(filter => {
          const store = new ReportingPageFilterStore(
            this.rootStore,
            filter,
            () => this.loadData()
          );
          store.persistCurrentValue();
          this._filters.set(filter.Code, store);
        });
        // Remove filters that are not visible anymore
        if (response.Filters) {
          Array.from(this._filters.keys()).forEach(filterCode => {
            if (!response.Filters.find(f => f.Code === filterCode)) {
              this._filters.delete(filterCode);
            }
          });
        }
        this._clarificator = Array.isArray(response.Clarificator)
          ? response.Clarificator
          : response.Clarificator
            ? [response.Clarificator]
            : null;
        this._status = AsyncStatus.resolved;
        transaction.finished();
      });
    } else {
      if (this._request.error && !this._request.wasCanceled) {
        this._status = AsyncStatus.rejected;
        transaction.error();
      } else {
        transaction.finished();
      }
    }
  }

  @computed
  public get apiFilters(): Record<string, string> {
    return Object.fromEntries(
      Array.from(this._filters.entries())
        .map(([key, store]) => [key, store.value])
        .filter(([_, value]) => Boolean(value))
    );
  }

  public dispose(): void {
    this._request?.cancel();
  }

  public get clarificator(): Clarificator[] | null {
    return this._clarificator;
  }

  public getReportData(resultSetCode: ResultSetCode): ReportData | undefined {
    return this._data.get(resultSetCode);
  }

  public get status(): AsyncStatus {
    return this._status;
  }

  @computed
  public get filters(): ReportingPageFilterStore[] {
    return Array.from(this._filters.values());
  }

  public getDataSource<T extends keyof DataSourcesMap>(
    code: T
  ): DataSourcesMap[T] | undefined {
    return this._dataSources[code];
  }

  public loadUrlFilters(): void {
    this._filters.forEach(filter => filter.loadUrlFilter());
  }

  public loadPersistedFilters(): void {
    this._filters.forEach(filter => filter.loadPersistedValue());
  }
}
