import { useRootStore } from '../app/root-store-context';
import { AuthStore } from './mobx/auth-store';

export function useToken(): string | null {
  return useAuthStore().token;
}

export function useIsLoggedIn(): boolean {
  return useAuthStore().isLoggedIn;
}

export function useAuthStore(): AuthStore {
  return useRootStore().authStore;
}
