import { UserId } from '../users/types';
import { UserGroupId } from '../groups/types';
import { ConflictResolution, CopyConflictResponse } from './types';

export class CopyConflictErrorResponse extends Error {
  private _conflictingIds: UserId[] | UserGroupId[];
  private _resolutions: ConflictResolution[];
  constructor(msg: string, conflictResponse: CopyConflictResponse) {
    super(msg);
    this._conflictingIds = conflictResponse.ConflictingIds;
    this._resolutions = conflictResponse.Resolutions;
    Object.setPrototypeOf(this, CopyConflictErrorResponse.prototype);
  }

  public get conflictingIDs(): UserId[] | UserGroupId[] {
    return this._conflictingIds;
  }

  public get resolutions(): ConflictResolution[] {
    return this._resolutions;
  }
}
