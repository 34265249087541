import { FormattedDate, FormattedMessage } from 'react-intl';

import { Tippy } from '../../../components/tippy/tippy';
import {
  Fact,
  Indicator,
  IndicatorType,
  IndicatorValueType,
  VerticalDimension,
} from './types';
import { formatNumber } from './utils';
import { observer } from 'mobx-react-lite';
import {
  AnalyticsContentBox,
  ResponsiveTableWrapper,
  SlcrTd,
  SlcrTh,
  StyledTr,
  Table,
  Text,
  TextSize,
  WhiteSpace,
} from '@yarmill/components';
import { ReactNode } from 'react';
import { groupBy } from '../../../#-components/helpers/group-by';

export interface MainTableProps {
  facts: { [key: string]: Fact[] };
  indicators: { [key: string]: Indicator[] };
  indicatorTypes: IndicatorType[];
  seasonMonths: string[];
  verticalDimensions: VerticalDimension[];
}

function renderHeader(
  seasonMonths: string[],
  indicatorTypes: IndicatorType[]
): JSX.Element[] {
  const header = [<SlcrTh key={0} sticky={{ left: 0 }} />];

  seasonMonths.forEach(month => {
    header.push(
      <SlcrTh key={month}>
        <Text size={TextSize.s10} bold>
          <FormattedDate value={new Date(month)} month="short" />
        </Text>
      </SlcrTh>
    );
  });
  header.push(
    <SlcrTh key="sum">
      <Tippy tooltipContent="analytics.sps-slcr.label.summary">
        <Text size={TextSize.s10} bold>
          <FormattedMessage id="activityItem.columns.total" />
        </Text>
      </Tippy>
    </SlcrTh>
  );

  indicatorTypes.forEach(indicator => {
    header.push(
      <SlcrTh key={indicator} title={indicator}>
        <Tippy tooltipContent={getTooltipForIndicator(indicator)}>
          <Text size={TextSize.s10} bold whiteSpace={WhiteSpace.preWrap}>
            {indicator}
          </Text>
        </Tippy>
      </SlcrTh>
    );
  });

  return header;
}

function renderCols(
  seasonMonths: string[],
  indicatorTypes: IndicatorType[]
): React.ReactNode[] {
  return [
    <col key={0} style={{ width: 100 }} />,
    ...seasonMonths.map(month => <col key={month} />),
    <col key="sum" style={{ width: 55 }} />,
    ...indicatorTypes.map(ind => <col key={ind} style={{ width: 64 }} />),
  ];
}

function renderRows(
  verticalDimensions: VerticalDimension[],
  seasonMonths: string[],
  indicatorTypes: IndicatorType[],
  groupedFacts: { [key: string]: Fact[] },
  groupedIndicators: { [key: string]: Indicator[] }
): ReactNode[] {
  return verticalDimensions
    .map(dimension => {
      const rowFactData = groupBy(groupedFacts[dimension.Ident] || [], 'Month');
      const rowIndicatorData = groupBy(
        groupedIndicators[dimension.Ident] || [],
        'IndicatorName'
      );

      if (rowFactData.size === 0 && rowIndicatorData.size === 0) {
        return null;
      }

      const isIIIPlus = dimension.Title.includes('III. int.');

      const cells = [
        <SlcrTd
          key={0}
          title={dimension.Title}
          italic={isIIIPlus}
          isIIIPlus={isIIIPlus}
          sticky={{ left: 0 }}
        >
          <Text
            size={TextSize.s10}
            whiteSpace={WhiteSpace.noWrap}
            bold={!isIIIPlus}
          >
            {dimension.Title}
          </Text>
        </SlcrTd>,
      ];
      const italics = isIIIPlus ? true : undefined;
      seasonMonths.forEach(month => {
        const cell = getCell(rowFactData, month);

        cells.push(
          <SlcrTd key={month} italic={italics}>
            <Text
              size={TextSize.s10}
              whiteSpace={WhiteSpace.noWrap}
              bold={dimension.Title === 'CYKL. HZ'}
            >
              {cell ? formatFactValue(cell.Value) : <span>&nbsp;</span>}
            </Text>
          </SlcrTd>
        );
      });

      const sumFacts = getCell(rowFactData, String(null));
      cells.push(
        <SlcrTd
          key="sum"
          italic={italics}
          align="right"
          sum
          whiteSpace={WhiteSpace.noWrap}
        >
          <Text size={TextSize.s10} bold>
            {sumFacts ? formatFactValue(sumFacts.Value) : <span>&nbsp;</span>}
          </Text>
        </SlcrTd>
      );

      indicatorTypes.forEach(indicatorType => {
        const indicator = getCell(rowIndicatorData, indicatorType);

        cells.push(
          <SlcrTd
            key={indicatorType}
            className={
              indicator
                ? getClassNameForIndicatorValue(indicator.Value, indicatorType)
                : ''
            }
          >
            <Text
              size={TextSize.s10}
              inheritColor
              whiteSpace={WhiteSpace.noWrap}
              bold={Boolean(
                indicator
                  ? getClassNameForIndicatorValue(
                      indicator.Value,
                      indicatorType
                    ) || dimension.Title === 'CYKL. HZ'
                  : dimension.Title === 'CYKL. HZ'
              )}
            >
              {indicator ? (
                formatIndicatorValue(indicator.Value, indicator.FactValueType)
              ) : (
                <span>&nbsp;</span>
              )}
            </Text>
          </SlcrTd>
        );
      });

      return <StyledTr key={dimension.Ident}>{cells}</StyledTr>;
    })
    .filter(row => row !== null);
}

const formatFactValue = (value: number): string => formatNumber(value);

const getCell = <T extends object>(
  row: Map<string, T[]>,
  column: string
): T | null => (row && row.has(column) ? (row.get(column)?.[0] ?? null) : null);

const getClassNameForIndicatorValue = (
  value: number,
  column: IndicatorType
): string => {
  const acceptedColumns: IndicatorType[] = [
    'Dif. plán/RP',
    'Dif. plán/kateg.',
    'Dif. RP/kateg.',
  ];

  if (acceptedColumns.indexOf(column) !== -1) {
    if (value < -0.1) {
      return 'analytics-orange';
    }

    if (value > 0.1) {
      return 'analytics-blue';
    }
  }

  return '';
};

const formatIndicatorValue = (
  value: number,
  type: IndicatorValueType
): string => {
  switch (type) {
    case 'P':
      return formatNumber(Number(value * 100)) + '%';
    case 'A':
      return formatNumber(Number(value));
    case 'B':
      return formatNumber(Math.abs(Number(value)));
  }
};

const getTooltipForIndicator = (indicatorType: IndicatorType): string => {
  switch (indicatorType) {
    case 'Dif. plán/kateg.':
      return 'analytics.sps-slcr.indicator.difPlanKateg';
    case 'Dif. RP/kateg.':
      return 'analytics.sps-slcr.indicator.difRpKateg';
    case 'Průměr kategorie (plán)':
      return 'analytics.sps-slcr.indicator.prumKategPlan';
    case 'Průměr kategorie (RP)':
      return 'analytics.sps-slcr.indicator.prumKategRp';
    case 'Dif. plán/RP':
      return 'analytics.sps-slcr.indicator.difPlanRp';
  }
};

export const MainTable = observer(function MainTable(
  props: MainTableProps
): JSX.Element | null {
  const {
    facts,
    verticalDimensions,
    seasonMonths,
    indicatorTypes,
    indicators,
  } = props;

  const rows = renderRows(
    verticalDimensions,
    seasonMonths,
    indicatorTypes,
    facts,
    indicators
  );

  if (rows.length === 0) {
    return null;
  }

  return (
    <AnalyticsContentBox>
      <ResponsiveTableWrapper>
        <Table
          colgroup={renderCols(seasonMonths, indicatorTypes)}
          head={renderHeader(seasonMonths, indicatorTypes)}
        >
          {rows}
        </Table>
      </ResponsiveTableWrapper>
    </AnalyticsContentBox>
  );
});
