import { RefObject, useEffect, useState } from 'react';
import { useSize } from '../../utils/use-size';
import { usePlannerStore } from '../planner-store-context';

export function useIsZoomOutAvailable(
  plannerRef: RefObject<HTMLDivElement>,
  containerRef: RefObject<HTMLDivElement>
): boolean {
  const [isZoomOutAvailable, setIsZoomOutAvailable] = useState(false);
  const { width: containerWidth, height: containerHeight } =
    useSize(containerRef);
  const { width: plannerWidth, height: plannerHeight } = useSize(plannerRef);

  const plannerStore = usePlannerStore();

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    setTimeout(() => {
      const plannerElement = plannerRef.current;
      const containerElement = containerRef.current;
      if (containerWidth && plannerElement && containerElement) {
        if (
          plannerElement.scrollWidth > containerElement.clientWidth ||
          plannerElement.scrollHeight > containerElement.clientHeight
        )
          setIsZoomOutAvailable(true);
        else {
          setIsZoomOutAvailable(false);
        }
      }
    }, 250); // animation length
  }, [
    containerWidth,
    containerHeight,
    plannerWidth,
    plannerHeight,
    plannerStore.zoomLevel,
  ]);

  return isZoomOutAvailable;
}
