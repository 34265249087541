import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import {
  EvidenceDataObject,
  EvidenceDataObjectAttribute,
  EvidenceObjectTags,
} from '../types';
import { devMode, instance } from '../../api/api-mapping';

export function updateEvidenceObject(
  dataUrl: string,
  moduleKey: string,
  objectCategoryKey: string,
  attributes: EvidenceDataObjectAttribute[],
  arrayIndex: number,
  userId: number | null,
  groupId: number | null,
  tags: EvidenceObjectTags | undefined
): AxiosPromise<EvidenceDataObject> {
  return axios.put(
    dataUrl,
    {
      ObjectCategoryKey: objectCategoryKey,
      GroupId: userId === null ? (groupId ?? undefined) : undefined,
      UserId: userId !== null ? userId : undefined,
      ArrayIndex: arrayIndex,
      Attributes: attributes,
      Tags: tags,
    },

    {
      params: {
        groupId: userId === null ? (groupId ?? undefined) : undefined,
        userId: userId !== null ? userId : undefined,
        moduleKey,
        instanceCode: devMode ? instance : undefined,
      },
    }
  );
}
