import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelProps,
  RectangleProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { Units } from '../../../../units/types';
import { ChartTooltip } from '../../../chart-tooltip';
import { formatValueByUnitForAnalytics } from '../utils';
import { ReBarChartProps } from './types';
import styled from 'styled-components';
import { ReBarChartStyles } from '@yarmill/components';

const Line = (props: RectangleProps) => {
  const { fill, x, y, height } = props;
  const radius = 4;

  return (
    <>
      {height && y && (
        <line x1={x} x2={x} y1={y + radius} y2={y + height} stroke={fill} />
      )}
      <circle
        cx={x}
        cy={y}
        r={radius}
        stroke={fill}
        strokeWidth="2"
        fill="transparent"
      />
    </>
  );
};

const xLabel = (props: LabelProps) => (
  <text x={35} y={220} className={props.className} textAnchor="end">
    <tspan>{props.value}</tspan>
  </text>
);

const StyledBarChart = styled(BarChart)`
  ${ReBarChartStyles};
`;

export class ReBarChart extends Component<ReBarChartProps> {
  public render(): JSX.Element {
    const { data, stepSize = 1, xAxisLabel } = this.props;

    return (
      <ResponsiveContainer width="100%" height={230}>
        <StyledBarChart
          width={600}
          height={300}
          margin={{ top: 15, right: 5, left: 40, bottom: 5 }}
          data={data}
        >
          <XAxis
            dataKey="label"
            xAxisId={0}
            axisLine={false}
            tickLine={false}
            tickMargin={10}
            label={{
              value: xAxisLabel,
              position: 'left',
              className: 'scale-label',
              content: xLabel,
            }}
          />
          <XAxis dataKey="label" xAxisId={1} hide />
          <YAxis
            tickLine={false}
            axisLine={false}
            orientation="right"
            tick={{ fill: '#D4D6D9' }}
            width={30}
            minTickGap={stepSize}
            tickFormatter={val => String(Math.round(val / stepSize))}
          />
          <Tooltip
            cursor={{ fill: 'rgba(206, 206, 206, 0.2)' }}
            wrapperStyle={{
              outline: 'none',
            }}
            content={(props: TooltipProps<number, string>) => (
              <ChartTooltip
                label={this.getTooltipLabel(props)}
                items={
                  props.payload
                    ? props.payload.map(item => ({
                        color: item.color as string,
                        id: String(item.name),
                        label: (
                          <FormattedMessage
                            id={`analytics.charts.header.${item.name}`}
                          />
                        ),
                        value: formatValueByUnitForAnalytics(
                          Number(item.value),
                          Units.min
                        ),
                      }))
                    : []
                }
              />
            )}
          />
          <CartesianGrid
            stroke="#D4D6D9"
            strokeDasharray="1 10"
            strokeWidth={1}
            vertical={false}
            strokeLinecap="round"
          />
          <Bar dataKey="reality" xAxisId={1} fill="#F2C80F" barSize={20} />
          <Bar
            dataKey="plan"
            xAxisId={0}
            fill="#4a4a4a"
            barSize={1}
            shape={Line as any}
          />
        </StyledBarChart>
      </ResponsiveContainer>
    );
  }

  private readonly getTooltipLabel = (
    props: TooltipProps<number, string>
  ): string => {
    const { tooltipTitlePrefix } = this.props;
    const label =
      props.payload && props.payload[0] && props.payload[0].payload
        ? props.payload[0].payload.tooltipLabel
        : props.label;

    return tooltipTitlePrefix ? `${tooltipTitlePrefix} ${label}` : label;
  };
}
