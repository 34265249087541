import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { PopoverItem } from '../../components/popover/popover-item';
import { TrainingDayExtraAttributeProps } from '../training-day-extra-attribute';
import { useTrainingDayAttributeFileUpload } from '../hooks';
import { AttachmentAttributeStore } from '../../attributes/mobx/attachment-attribute-store';

const StyledFileInput = styled.input`
  display: none;
`;

const StyledPopoverItem = styled(PopoverItem)`
  padding: 0 !important;
`;

const StyledLabel = styled.label`
  width: 100%;
  margin: 0;
  cursor: pointer;
`;

export interface AttachmentProps extends TrainingDayExtraAttributeProps {
  attribute: AttachmentAttributeStore;
}

function InternalAttachment(props: AttachmentProps): JSX.Element {
  const { attribute, hideTooltip } = props;
  const [inputId, inputRef, onChange] = useTrainingDayAttributeFileUpload(
    attribute,
    'extra'
  );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      onChange(e);
      hideTooltip();
    },
    [onChange, hideTooltip]
  );

  return (
    <StyledPopoverItem>
      <StyledFileInput
        ref={inputRef}
        id={inputId}
        type="file"
        name="fileData"
        onChange={handleChange}
        multiple
        disabled={attribute.isDisabled}
      />
      <StyledLabel htmlFor={inputId} data-cy={attribute.name}>
        <FormattedMessage id={attribute.name} />
      </StyledLabel>
    </StyledPopoverItem>
  );
}

export const Attachment = observer(InternalAttachment);
