import {
  ExternalIcon,
  FullScreenLayerCloseButtonWrapper,
  Icon,
  IconSize,
  StyledFullScreenLayerContent,
  Text,
  TextSize,
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { UpdateProfileForm } from './update-profile-form';
import { observer } from 'mobx-react-lite';
import { useCurrentUserStore } from '../users/hooks';
import { useConfig } from '../app/hooks';
import { Layer } from '../layer-manager/mobx/layer';
import { CloseButton } from '../components/close-button/close-button';

const StyledHeadline = styled(Text)`
  display: flex;
  align-items: center;
  column-gap: 16px;
  text-align: center;
  margin: 20px auto 36px;

  @media (min-width: 556px) {
    width: 80%;
  }

  @media (min-width: 768px) {
    text-align: left;
    margin: 0 auto 36px;
    width: 50%;
  }
`;

interface ProfileDetailFormProps {
  readonly layer: Layer;
}

export const ProfileDetailForm = observer(function ProfileDetailForm({
  layer,
}: ProfileDetailFormProps): JSX.Element {
  const currentUser = useCurrentUserStore();
  const avatars = useConfig('avatars');

  return (
    <>
      <StyledFullScreenLayerContent>
        <StyledHeadline size={TextSize.s24}>
          <FormattedMessage id="settings.profile.edit.header" />
          <Icon size={IconSize.s24}>
            <ExternalIcon name="HandLoveYou" />
          </Icon>
        </StyledHeadline>

        <UpdateProfileForm user={currentUser} layer={layer} avatars={avatars} />
      </StyledFullScreenLayerContent>
      <FullScreenLayerCloseButtonWrapper>
        <CloseButton onClick={layer.close} />
      </FullScreenLayerCloseButtonWrapper>
    </>
  );
});
