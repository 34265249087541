import {
  AthletesSidebarDropdownToggle,
  AthletesSidebarHeader,
  ExternalIcon,
  GroupNameWrapper,
  GroupsToggleWrapper,
  Icon,
  IconSize,
  SelectAthleteAvatar,
  SelectedAthleteWrapper,
  SidebarContainer,
  Text,
  TextSize,
} from '@yarmill/components';
import { Fragment, memo, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { persistSelectedGroup } from '../groups/utils';
import { ALL_ATHLETES_ICON, AthletesList } from './athletes-list';
import { GroupsList } from './groups-list';
import { useCommonUrlParams } from '../routes/hooks';
import { useGroup } from '../groups/hooks';
import { SidebarLoader } from './sidebar-loader';
import { useCollapsible } from '../app/hooks';
import { useRootStore } from '../app/root-store-context';
import { AsyncStatus } from '../api/mobx/request-store';
import { observer } from 'mobx-react-lite';
import { useUsersStore } from '../users/hooks';
import { useAvatar } from '../profile/hooks';

export interface SidebarProps {
  hideAthletesInGroup?: boolean;
  disableWholeGroup?: boolean;
  disableWholeGroupTooltip?: string;
}

function InternalSidebar(props: SidebarProps): JSX.Element {
  const {
    hideAthletesInGroup = false,
    disableWholeGroup,
    disableWholeGroupTooltip,
  } = props;
  const rootStore = useRootStore();
  const { group, athlete } = useCommonUrlParams();
  const usersStore = useUsersStore();
  const status = rootStore.status;
  const hasGroups = rootStore.groupsStore.sortedGroups.length > 0;
  const selectedGroup = useGroup(group);
  const selectedAthlete = usersStore.getUserById(athlete);
  const [isGroupsDropdownOpened, showGroups, hideGroups] = useCollapsible();
  const [isAthletesDropdownOpened, showAthletes, hideAthletes] =
    useCollapsible();

  const toggleGroups = useCallback(() => {
    hideAthletes();
    if (isGroupsDropdownOpened) {
      hideGroups();
    } else {
      showGroups();
    }
  }, [isGroupsDropdownOpened, showGroups, hideGroups, hideAthletes]);

  const toggleAthletes = useCallback(() => {
    hideGroups();
    if (isAthletesDropdownOpened) {
      hideAthletes();
    } else {
      showAthletes();
    }
  }, [isAthletesDropdownOpened, showAthletes, hideAthletes, hideGroups]);

  const hideDropdowns = useCallback(() => {
    hideAthletes();
    hideGroups();
  }, [hideAthletes, hideGroups]);

  useEffect(() => {
    if (selectedGroup) {
      persistSelectedGroup(selectedGroup);
    }
  }, [selectedGroup]);

  const avatar = useAvatar(selectedAthlete?.avatar);

  return (
    <SidebarContainer data-cy="sidebar">
      {status === AsyncStatus.pending && <SidebarLoader />}
      {status === AsyncStatus.resolved && (
        <Fragment>
          <AthletesSidebarHeader
            hideAthletesInGroup={hideAthletesInGroup}
            athletesToggle={
              <AthletesSidebarDropdownToggle
                label={<FormattedMessage id="sidebar.mobile.athlete" />}
                primaryRow={
                  <SelectedAthleteWrapper>
                    {selectedAthlete?.avatar ? (
                      <SelectAthleteAvatar
                        src={avatar}
                        alt={selectedAthlete?.displayName}
                      />
                    ) : (
                      <ALL_ATHLETES_ICON />
                    )}
                    <Text size={TextSize.s14} inheritColor>
                      {selectedAthlete?.displayName ?? (
                        <FormattedMessage id="sidebar.athletes.allAthletes" />
                      )}
                    </Text>
                  </SelectedAthleteWrapper>
                }
                onClick={toggleAthletes}
              />
            }
            groupsToggle={
              hasGroups ? (
                <AthletesSidebarDropdownToggle
                  data-cy="sidebar-dropdown-button"
                  label={<FormattedMessage id="sidebar.mobile.group" />}
                  primaryRow={
                    <GroupsToggleWrapper>
                      <GroupNameWrapper>
                        {isGroupsDropdownOpened ? (
                          <FormattedMessage id="sidebar.groups.chooseGroup" />
                        ) : (
                          selectedGroup?.name
                        )}
                      </GroupNameWrapper>
                      {isGroupsDropdownOpened ? (
                        <Icon size={IconSize.s20}>
                          <ExternalIcon name="ChevronUp" />
                        </Icon>
                      ) : (
                        <Icon size={IconSize.s20}>
                          <ExternalIcon name="ChevronDown" />
                        </Icon>
                      )}
                    </GroupsToggleWrapper>
                  }
                  onClick={toggleGroups}
                  secondaryRow={
                    !isGroupsDropdownOpened &&
                    selectedGroup && (
                      <FormattedMessage
                        id="sidebar.groups.athletesCount"
                        values={{ count: selectedGroup.athletes.length }}
                      />
                    )
                  }
                />
              ) : undefined
            }
          />
          {isGroupsDropdownOpened && (
            <GroupsList selectedGroup={selectedGroup} onSelect={hideGroups} />
          )}
          {!isGroupsDropdownOpened && !hideAthletesInGroup && selectedGroup && (
            <AthletesList
              disableWholeGroup={disableWholeGroup}
              disableWholeGroupTooltip={disableWholeGroupTooltip}
              group={selectedGroup}
              isVisible={isAthletesDropdownOpened}
              toggleGroups={hideDropdowns}
            />
          )}
        </Fragment>
      )}
    </SidebarContainer>
  );
}

export const Sidebar = memo(observer(InternalSidebar));
