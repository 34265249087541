import { Units } from '../../../../units/types';
import { KPIProps } from '../kpi';
import {
  formatHZHours,
  formatValueByUnitForAnalytics,
  getKPIProgressColor,
  getKPIValue,
} from '../utils';
import { observer } from 'mobx-react-lite';
import { times } from '../../../../utils/times';

export const Hours = observer((props: KPIProps) => (
  <svg viewBox="0 0 460 152">
    <g
      id="TD-novy-design"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="BasicStatistics-Copy"
        transform="translate(-782.000000, -186.000000)"
      >
        <g id="Group-25-Copy" transform="translate(782.000000, 186.000000)">
          <g id="Group-40">
            <rect
              id="Rectangle-15-Copy"
              fill="#FFFFFF"
              x="0"
              y="0"
              width="460"
              height="152"
              rx="8"
            />
            <text
              id="zbyva-213-do-konce-s"
              opacity="0.801172"
              fontFamily="Ubuntu-Light, Ubuntu"
              fontSize="12"
              fontWeight="300"
              fill="#4A4A4A"
            >
              <tspan x="51" y="107">
                {props.intl.formatMessage({ id: 'analytics.kpi.remaining' })}
                <tspan fontWeight="bold">
                  {formatValueByUnitForAnalytics(
                    getKPIValue(props.data, 'HZ AbsDiff'),
                    Units.min
                  )}
                </tspan>
                {props.intl.formatMessage({
                  id: 'analytics.kpi.toEndOfSeason',
                })}
              </tspan>
            </text>
            <text
              id="245/458"
              fontFamily="Ubuntu"
              fontSize="32"
              fontWeight="normal"
              fill="#4A4A4A"
            >
              <tspan x="420.504" y="84" textAnchor="end">
                {formatHZHours(getKPIValue(props.data, 'HZ Reality'))}/
                <tspan fontSize="26">
                  {formatHZHours(getKPIValue(props.data, 'HZ Plan'))}
                </tspan>
              </tspan>
            </text>
            <text
              id="hod"
              opacity="0.801172"
              fontFamily="Ubuntu-Light, Ubuntu"
              fontSize="12"
              fontWeight="300"
              fill="#4A4A4A"
            >
              <tspan x="420.504" y="110" textAnchor="end">
                {props.intl.formatMessage({
                  id: 'analytics.kpi.hzHoursRemaining',
                })}
              </tspan>
            </text>
            <text
              id="hodiny-zatížení"
              fontFamily="Ubuntu-Bold, Ubuntu"
              fontSize="14"
              fontWeight="bold"
              letterSpacing="0.6"
              fill="#4A4A4A"
            >
              <tspan x="51" y="47">
                {props.intl.formatMessage({ id: 'analytics.kpi.hz' })}
              </tspan>
            </text>
            <text
              id="54%"
              fontFamily="Ubuntu-Medium, Ubuntu"
              fontSize="14"
              fontWeight="400"
              fill="#4A4A4A"
            >
              <tspan x="160" y="80">
                {getKPIValue(props.data, 'HZ RelDiff')}%
              </tspan>
            </text>
            <g
              id="Group-4"
              transform="translate(51.000000, 72.000000)"
              fillRule="nonzero"
            >
              {times(19, idx => (
                <rect
                  key={idx}
                  id="Rectangle-4-Copy-9"
                  fill={getKPIProgressColor(
                    getKPIValue(props.data, 'HZ RelDiff'),
                    idx
                  )}
                  x={idx * 5}
                  y="0"
                  width="2"
                  height="7"
                />
              ))}
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
));
