import {
  Planner as PlannerComponent,
  PlannerContainer,
} from '../#-components/planner';
import { usePlannerStore } from './planner-store-context';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { MainPageContentContainer } from '../components-2/page-layout';
import { useIntl } from 'react-intl';

import { useWindowSize } from '../utils/use-window-size';
import { PlannerEventPanel } from './planner-event-panel';
import { PlannerImportPanel } from './planner-import-panel';
import { ScrollIndicator } from '../#-components/planner/scroll-indicator';
import { PlannerContainerContext } from '../#-components/planner/planner-context';
import { useSubmitOpenedForm } from './hooks/use-submit-opened-form';
import { useCloseEventFormPanelHandler } from './hooks/use-close-event-form-panel-handler';
import { useKeyboardControls } from './hooks/use-keyboard-controls';
import { useHideImporterHandler } from './hooks/use-hide-importer-handler';
import { useCreateEventHandler } from './hooks/use-create-event-handler';
import { useOnCreateStartHandler } from './hooks/use-on-create-start-handler';
import { PlannerFooter } from './planner-footer';
import { PlannerHeader } from './planner-header';
import { CalendarFormEvent } from './calendar-form-event';
import { PlannerEvent } from './planner-event';
import { useCurrentUserIsAllowedToWrite } from './hooks/use-current-user-is-allowed-to-write';

export const Planner = observer(function Planner(): JSX.Element | null {
  const plannerStore = usePlannerStore();
  const intl = useIntl();
  const plannerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const leftScrollIndicatorRef = useRef<HTMLDivElement>(null);
  const rightScrollIndicatorRef = useRef<HTMLDivElement>(null);

  const { width: windowWidth } = useWindowSize();
  const currentSeason = plannerStore.currentSeason;
  const group = plannerStore.group;
  const athlete = plannerStore.athlete;
  const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();

  const submitOpenedForm = useSubmitOpenedForm();
  const hideImporter = useHideImporterHandler();
  const handleCreate = useCreateEventHandler();
  const onCreateStart = useOnCreateStartHandler();
  const closeEventFormPanel = useCloseEventFormPanelHandler();

  useKeyboardControls();

  useEffect(() => {
    if (!athlete && !group) {
      return;
    }

    return () => {
      submitOpenedForm();
      closeEventFormPanel();
      hideImporter();
    };
  }, [athlete, group, submitOpenedForm, closeEventFormPanel, hideImporter]);

  if (!currentSeason) {
    return null;
  }

  return (
    <>
      <MainPageContentContainer
        windowWidth={windowWidth}
        isSidebarVisible={Boolean(
          plannerStore.formEvent || plannerStore.showImporter
        )}
      >
        <PlannerHeader />
        <PlannerContainer ref={containerRef} id="planner-container">
          <PlannerContainerContext.Provider value={containerRef}>
            <ScrollIndicator ref={leftScrollIndicatorRef} />
            <PlannerComponent
              language={intl.locale}
              ref={plannerRef}
              startDate={currentSeason.start}
              endDate={currentSeason.end}
              onCreate={handleCreate}
              onCreateStart={onCreateStart}
              newEventLabel={intl.formatMessage(
                { id: 'planner.newEvent' },
                { eventType: plannerStore.lastEventType }
              )}
              lastColor={plannerStore.getEventTypeColor(
                plannerStore.calendarFormEvent?.eventTypeId ??
                  plannerStore.lastEventType ??
                  plannerStore.eventTypes[0]?.eventTypeId
              )}
              zoomLevel={plannerStore.zoomLevel}
              conflictsCountPerMonth={plannerStore.eventOverlapsPerMonths}
              disableAdd={!currentUserIsAllowedToWrite}
            >
              {plannerStore.events.map(event => {
                const { startDate, endDate, eventTypeId } = event;

                if (
                  !startDate ||
                  !endDate ||
                  plannerStore.calendarFormEvent?.uid === event.uid ||
                  (eventTypeId &&
                    !plannerStore.eventTypeFilter.includes(eventTypeId))
                ) {
                  return null;
                }

                return <PlannerEvent event={event} key={event.uid} />;
              })}
              <CalendarFormEvent />
            </PlannerComponent>
            <ScrollIndicator ref={rightScrollIndicatorRef} />
          </PlannerContainerContext.Provider>
        </PlannerContainer>
        <PlannerFooter
          containerRef={containerRef}
          plannerRef={plannerRef}
          leftScrollIndicatorRef={leftScrollIndicatorRef}
          rightScrollIndicatorRef={rightScrollIndicatorRef}
        />
      </MainPageContentContainer>
      <PlannerEventPanel
        event={plannerStore.formEvent}
        calendarEvent={plannerStore.calendarFormEvent}
        close={closeEventFormPanel}
      />
      <PlannerImportPanel
        isVisible={plannerStore.showImporter}
        close={hideImporter}
      />
    </>
  );
});
