import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';
import { ObjectiveActivity } from '../types';

export interface AddObjectiveEvaluationRequestParams {
  readonly objectiveId: number;
  readonly evaluationText: string;
}

export function addObjectiveEvaluation(
  { objectiveId, evaluationText }: AddObjectiveEvaluationRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<ObjectiveActivity[]> {
  return axios.put(
    `${coreApiTarget}/api/okrs/evaluation`,
    { evaluationText },
    {
      cancelToken: cancelToken?.token,
      params: {
        objectiveId,
        instanceCode: devMode ? instance : undefined,
      },
    }
  );
}
