import { Header } from './header';
import { BackToOkrsList } from './buttons/back-to-okrs-list';
import { HeaderPageTitle } from '../components-2/header';
import { FormattedMessage } from 'react-intl';
import { Comments } from './buttons/comments';

import { Breakpoints, BreakpointWrapper } from '@yarmill/components';
import { useOkrsStore } from './okrs-store-context';

export function ObjectiveDetailHeader() {
  const okrsStore = useOkrsStore();

  return (
    <Header
      inverted
      left={<BackToOkrsList />}
      center={
        <HeaderPageTitle appearance="text15strong" absolutelyCentered>
          <BreakpointWrapper max={Breakpoints.tablet}>
            {okrsStore.athlete?.displayName ?? okrsStore.group?.name}
          </BreakpointWrapper>
          <BreakpointWrapper min={Breakpoints.tablet}>
            <FormattedMessage id="okrs.title" />
          </BreakpointWrapper>
        </HeaderPageTitle>
      }
      right={<Comments />}
    />
  );
}
