import {
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useRef,
} from 'react';
import { useRootStore } from '../app/root-store-context';
import { FilesOverviewStore } from './mobx/files-overview-store';

export const FilesOverviewStoreContext = createContext<
  FilesOverviewStore | undefined
>(undefined);

export function useFilesOverviewStore(): FilesOverviewStore {
  const diaryStore = useContext(FilesOverviewStoreContext);
  if (!diaryStore) {
    throw new Error('FilesOverview Store not available');
  }

  return diaryStore;
}

export function InternalFilesOverviewStoreContextProvider(
  props: PropsWithChildren
): JSX.Element {
  const rootStore = useRootStore();
  const diaryStore = useRef(new FilesOverviewStore(rootStore)).current;

  return (
    <FilesOverviewStoreContext.Provider value={diaryStore}>
      {props.children}
    </FilesOverviewStoreContext.Provider>
  );
}

export const FilesOverviewStoreContextProvider = memo(
  InternalFilesOverviewStoreContextProvider
);
