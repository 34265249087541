import { RootStore } from '../../app/mobx/root-store';
import { AbstractActivityStore } from './abstract-activity-store';
import {
  ActivityData,
  ActivityItem,
  ActivityItemId,
  AggregationFunction,
  MaskCode,
  SelectionRange,
} from '../types';
import { ActivityItemSummaryStore } from './activity-item-summary-store';
import { SeasonStore } from '../../seasons/mobx/season-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { getIndexForDate } from '../../diary/utils';
import { NumericActivityStore } from './numeric-activity-store';
import { Units } from '../../units/types';
import { BitActivityStore } from './bit-activity-store';
import { TimeActivityStore } from './time-activity-store';
import { ComputedActivityStore } from './computed-activity-store';
import { action, computed, makeObservable } from 'mobx';
import { LongTimeActivityStore } from './long-time-activity-store';
import { times } from '../../utils/times';

export class ActivityListStore {
  private readonly rootStore: RootStore;
  private readonly diaryStore: DiaryStore;
  private readonly activities: Map<
    ActivityItemId,
    (AbstractActivityStore | ActivityItemSummaryStore)[]
  > = new Map();
  private readonly activitiesMask?: MaskCode;
  private readonly computedActivities: ComputedActivityStore[] = [];

  public hasSelectedItemsGlobalListener = false;

  private _selectionRangeStart: SelectionRange | null = null;

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    activitiesMask?: MaskCode,
    season?: SeasonStore
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.diaryStore = diaryStore;
    this.activitiesMask = activitiesMask;

    this.initActivities(season);
    this.initComputedActivities();
  }

  public getActivityItemSummaryStore(
    activityItemId: ActivityItemId
  ): ActivityItemSummaryStore | undefined {
    const activityStores = this.activities.get(activityItemId);

    return activityStores?.[
      activityStores.length - 1
    ] as ActivityItemSummaryStore;
  }

  public getActivityStore(
    activityItemId: ActivityItemId,
    columnIndex: number
  ): AbstractActivityStore | undefined {
    return this.activities.get(activityItemId)?.[
      columnIndex
    ] as AbstractActivityStore;
  }

  @action
  public clear(): void {
    this.activities.forEach(activity => activity.forEach(item => item.clear()));
  }

  @action
  public setWeekActivitiesData(data: ActivityData[]): void {
    data.forEach(item => {
      const activity = this.activities.get(item.ActivityItemId);
      if (activity) {
        const index = item.Date
          ? getIndexForDate(item.Date)
          : activity.length - 1;

        activity[index]?.setApiValue(item.Value);
      }
    });
  }

  @action
  public setSeasonActivitiesData(
    data: ActivityData[],
    seasonMapping: string[]
  ): void {
    data.forEach(item => {
      const activity = this.activities.get(item.ActivityItemId);
      if (activity) {
        const index = item.Date
          ? seasonMapping.indexOf(item.Date)
          : activity.length - 1;

        if (activity[index]) {
          activity[index].setApiValue(item.Value);
        }
      }
    });
  }

  public hasFilledDay(date: string): boolean {
    const index = getIndexForDate(date);

    for (const activity of this.activities.values()) {
      if (activity[index]?.hasValue) {
        return true;
      }
    }

    return false;
  }

  @computed
  public get activityItems(): ActivityItem[] {
    return this.activitiesMask
      ? this.rootStore.activityItemsStore.getActivityItems(this.activitiesMask)
      : [];
  }

  @computed
  public get visibleActivityItems(): ActivityItem[] {
    return this.activitiesMask
      ? this.rootStore.activityItemsStore.getVisibleActivityItems(
          this.activitiesMask
        )
      : [];
  }

  public disposeReactions(): void {
    this.activities.forEach(activity =>
      activity.forEach(activityStore => activityStore.disposeReactions())
    );
  }

  protected initActivities(season?: SeasonStore): void {
    this.activityItems.forEach(activityItem => {
      this.activities.set(
        activityItem.ActivityItemId,
        season
          ? this.initSeasonActivities(activityItem, season)
          : this.initWeekActivities(activityItem)
      );
    });
  }

  private initWeekActivities(
    activityItem: ActivityItem
  ): (AbstractActivityStore | ActivityItemSummaryStore)[] {
    const stores: (AbstractActivityStore | ActivityItemSummaryStore)[] = times(
      7
    ).map((_, idx) =>
      activityItem.ChildrenActivityItemIds.length !== 0
        ? this.createComputedActivityStore(
            activityItem,
            idx,
            activityItem.CellAggregationFunction
          )
        : this.createActivityStore(activityItem, idx)
    );
    stores.push(
      new ActivityItemSummaryStore(
        activityItem.TotalAggregationFunction,
        stores
      )
    );

    return stores;
  }

  private initSeasonActivities(
    activityItem: ActivityItem,
    season: SeasonStore
  ): (AbstractActivityStore | ActivityItemSummaryStore)[] {
    const items = season.seasonSections;

    const stores: (AbstractActivityStore | ActivityItemSummaryStore)[] =
      items.map((_, idx) =>
        // In season view, computed activities are allowed only for annual plan
        activityItem.ChildrenActivityItemIds.length !== 0 &&
        activityItem.AnnualPlanCellAggregationFunction
          ? this.createComputedActivityStore(
              activityItem,
              idx,
              activityItem.AnnualPlanCellAggregationFunction,
              season
            )
          : this.createActivityStore(activityItem, idx, season)
      );
    stores.push(
      new ActivityItemSummaryStore(
        activityItem.AnnualPlanTotalAggregationFunction,
        stores
      )
    );

    return stores;
  }

  private createComputedActivityStore(
    activityItem: ActivityItem,
    index: number,
    aggregationFunction: AggregationFunction | null,
    season?: SeasonStore
  ) {
    const store = new ComputedActivityStore(
      this.rootStore,
      this.diaryStore,
      index,
      activityItem,
      aggregationFunction,
      this,
      this.createActivityStore(activityItem, index, season)
    );
    this.computedActivities.push(store);

    return store;
  }

  private createActivityStore(
    activityItem: ActivityItem,
    index: number,
    season?: SeasonStore
  ): AbstractActivityStore {
    let Store;
    switch (activityItem.Unit) {
      case Units.bit: {
        Store = BitActivityStore;
        break;
      }
      case Units.longTime: {
        Store = LongTimeActivityStore;
        break;
      }
      case Units.hod: {
        Store = TimeActivityStore;
        break;
      }
      case Units.min: {
        Store = season ? TimeActivityStore : NumericActivityStore;
        break;
      }
      default:
        Store = NumericActivityStore;
    }

    return new Store(this.rootStore, this.diaryStore, index, activityItem);
  }

  private initComputedActivities(): void {
    this.computedActivities.forEach(activity =>
      activity.initChildrenActivities()
    );
  }

  @computed
  public get selectedActivityStores(): AbstractActivityStore[] {
    return Array.from(this.activities.entries()).flatMap(([, activity]) =>
      activity.filter<AbstractActivityStore>(
        (item): item is AbstractActivityStore =>
          item instanceof AbstractActivityStore && item.isSelected
      )
    );
  }

  public selectWholeActivityItemRow(activityItem: ActivityItem): void {
    const activityStores = this.activities.get(activityItem.ActivityItemId);

    const isSelected =
      activityStores?.every(store =>
        store instanceof AbstractActivityStore ? store.isSelected : true
      ) ?? false;

    activityStores?.forEach(store => {
      if (store instanceof AbstractActivityStore && !store.isDisabled) {
        isSelected ? store.unselect() : store.select();
      }
    });
  }

  public selectWholeColumn(columnIndex: number): void {
    let isSelected = true;

    this.activities.forEach(activity => {
      const store = activity[columnIndex];
      if (store instanceof AbstractActivityStore && !store.isDisabled) {
        if (!isSelected) {
          return;
        } else {
          isSelected = store.isSelected;
        }
      }
    });
    this.activities.forEach(activity => {
      const store = activity[columnIndex];
      if (store instanceof AbstractActivityStore && !store.isDisabled) {
        isSelected ? store.unselect() : store.select();
      }
    });
  }

  public selectAll(): void {
    this.activities.forEach(activity => {
      activity.forEach(store => {
        if (store instanceof AbstractActivityStore && !store.isDisabled) {
          store.select();
        }
      });
    });
  }

  @action
  public startSelectionRange(
    activityItem: ActivityItemId,
    dayIndex: number
  ): void {
    this._selectionRangeStart = { activityItem, dayIndex };
  }

  @action
  public selectRange(activityItem: ActivityItemId, dayIndex: number): void {
    const startItemIndex = this.visibleActivityItems.findIndex(
      item => item.ActivityItemId === this.selectionRangeStart?.activityItem
    );
    const endItemIndex = this.visibleActivityItems.findIndex(
      item => item.ActivityItemId === activityItem
    );

    const itemsIndexRange = [
      Math.min(startItemIndex, endItemIndex),
      Math.max(startItemIndex, endItemIndex),
    ];
    const daysRange = [
      Math.min(this.selectionRangeStart?.dayIndex ?? 0, dayIndex),
      Math.max(this.selectionRangeStart?.dayIndex ?? 0, dayIndex),
    ];

    const activityItems = this.visibleActivityItems
      .filter(
        (_, index) => index >= itemsIndexRange[0] && index <= itemsIndexRange[1]
      )
      .map(item => item.ActivityItemId);

    Array.from(this.activities.entries()).forEach(([activityItemId, stores]) =>
      stores.forEach((store, storeIndex) => {
        if (
          activityItems.includes(activityItemId) &&
          storeIndex >= daysRange[0] &&
          storeIndex <= daysRange[1]
        ) {
          if (store instanceof AbstractActivityStore) {
            store.select();
          }
        } else {
          if (store instanceof AbstractActivityStore) {
            store.unselect();
          }
        }
      })
    );
  }

  @action
  public cancelSelectionRange(): void {
    this._selectionRangeStart = null;
  }

  public get selectionRangeStart(): SelectionRange | null {
    return this._selectionRangeStart;
  }
}

export class EmptyActivityListStore extends ActivityListStore {
  protected initActivities(season?: SeasonStore): void {}
}
