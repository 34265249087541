import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { Locale } from '../../intl/types';
import { UserId } from '../../users/types';
import { SeasonId } from '../../seasons/types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

export interface ExportOkrsToPdfRequestParams {
  language: Locale;
  userId: UserId;
  seasonId: SeasonId;
  templateCode?: string;
}

export function exportOkrsToPdf(
  params: ExportOkrsToPdfRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post(`${coreApiTarget}/api/print/okr`, params, {
    responseType: 'blob',
    cancelToken: cancelToken.token,
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
