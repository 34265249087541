import { AbstractActivityStore } from './abstract-activity-store';
import { action, makeObservable, observable, override } from 'mobx';
import { formatValueByUnit, parseValueByUnit } from '../utils';
import { RootStore } from '../../app/mobx/root-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { ActivityItem } from '../types';
import { FormEvent } from 'react';

export class TimeActivityStore extends AbstractActivityStore {
  private static readonly VALID_FORMAT = /[0-9][0-9]:[0-5][0-9]/i;
  @observable
  private stringValue: string = '';

  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    index: number,
    activityItem: ActivityItem
  ) {
    super(rootStore, diaryStore, index, activityItem);
    makeObservable(this);
  }

  public setApiValue(newValue: number) {
    super.setApiValue(newValue);
    this.stringValue = formatValueByUnit(newValue, this.activityItem.Unit);
  }

  public clear() {
    super.clear();
    this.stringValue = '';
  }

  @action
  public onChange(e: FormEvent<HTMLInputElement>) {
    const { target } = e;
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    this.onTimeInputChange(target.value);
  }

  @action
  public readonly onTimeInputChange = (newValue: string): void => {
    this.stringValue = newValue;

    if (
      this.stringValue.match(TimeActivityStore.VALID_FORMAT) ||
      !this.stringValue
    ) {
      const intValue = parseValueByUnit(
        this.stringValue,
        this.activityItem.Unit
      );
      this.setUserValue(intValue);
    }
  };

  public get formattedValue(): string {
    return this.stringValue;
  }

  public onBlur(): void {
    super.onBlur();
    if (
      this.stringValue &&
      !this.stringValue.match(TimeActivityStore.VALID_FORMAT)
    ) {
      this.stringValue = formatValueByUnit(
        this.apiValue || 0,
        this.activityItem.Unit
      );
      this.setUserValue(
        parseValueByUnit(this.stringValue, this.activityItem.Unit)
      );
    }
  }

  @override
  public empty() {
    super.empty();
    this.stringValue = '';
  }
}
