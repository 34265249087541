import { SortConfig } from '../../evidence/table/types';
import { UserStore } from '../mobx/user-store';
import { sortUserStoresByStatus } from './sort-user-stores-by-status';
import { sortUserStoresByRole } from './sort-user-stores-by-role';
import { sortUserStores } from './sort-user-stores';
import { sortUserStoresByGroups } from './sort-user-stores-by-groups';

export function createUserStoresSortFunction(
  sortConfig: SortConfig | null
): (a: UserStore, b: UserStore) => number {
  return (a: UserStore, b: UserStore) => {
    if (sortConfig === null || sortConfig.sort === 'userName') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStores(a, b)
        : sortUserStores(b, a);
    } else if (sortConfig.sort === 'role') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStoresByRole(a, b)
        : sortUserStoresByRole(b, a);
    } else if (sortConfig.sort === 'status') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStoresByStatus(a, b)
        : sortUserStoresByStatus(b, a);
    } else if (sortConfig.sort === 'groups') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStoresByGroups(a, b)
        : sortUserStoresByGroups(b, a);
    }

    return 0;
  };
}
