import ContentLoader from 'react-content-loader';
import { ActivitiesTableContainer } from '../activities/activities-table-container';

export function SeasonViewLoadingScreen(): JSX.Element {
  return (
    <ActivitiesTableContainer>
      <ContentLoader
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        viewBox="0 0 1166 630"
      >
        <rect x="50" y="0" rx="8" ry="8" width="1101" height="35" />
        <rect x="0" y="48" rx="8" ry="8" width="43" height="580" />
        <rect x="50" y="48" rx="8" ry="8" width="1101" height="580" />
      </ContentLoader>
    </ActivitiesTableContainer>
  );
}
