import moment from 'moment';
import { FormattedDate } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { Text, TextSize, TextTag } from '@yarmill/components';

import { useDiaryStore } from './diary-store-context';
import { HeaderCellProps } from './week-header-cell';
import { useRootStore } from '../app/root-store-context';
import { SeasonStore } from '../seasons/mobx/season-store';
import { SeasonViewType } from '../seasons/types';

export interface SeasonHeaderCellContentProps {
  seasonViewType: SeasonViewType;
  season?: SeasonStore;
  columnIndex: number;
}
export function SeasonHeaderCellContent(
  props: SeasonHeaderCellContentProps
): JSX.Element {
  const { seasonViewType, season, columnIndex } = props;
  const date = season?.seasonSections[columnIndex];
  const dateObj = moment(date).toDate();

  return seasonViewType === 'month' ? (
    <Text
      size={TextSize.s12}
      tag={TextTag.div}
      className="activity-column-label-text"
    >
      <FormattedDate value={dateObj} month="long" />
    </Text>
  ) : (
    <Text
      size={TextSize.s12}
      tag={TextTag.div}
      className="activity-column-label-text"
    >
      {columnIndex + 1}
      <br />
      <FormattedDate value={dateObj} day="numeric" month="numeric" />
      &rarr;
    </Text>
  );
}

function InternalSeasonHeaderCell(props: HeaderCellProps): JSX.Element | null {
  const { columnIndex } = props;
  const rootStore = useRootStore();
  const diaryStore = useDiaryStore();
  const season = rootStore.seasonsStore.getSeasonByDate(diaryStore.week);
  const seasonViewType = rootStore.configStore.seasonView;

  if (!season) {
    return null;
  }

  return (
    <SeasonHeaderCellContent
      columnIndex={columnIndex}
      season={season}
      seasonViewType={seasonViewType}
    />
  );
}

export const SeasonHeaderCell = observer(InternalSeasonHeaderCell);
