import { EvidenceObjectDataStore } from './mobx/evidence-object-data-store';
import { EvidenceAttributeStore } from './mobx/evidence-attribute-store';
import { FormControlVariant, TextSize } from '@yarmill/components';
import { IntlShape } from 'react-intl';
import { EvidenceAttributeType, VisualisationKey } from './types';
import {
  FULL_TIME_PATTERN_WITH_MS,
  TIME_PATTERN,
  TIME_PATTERN_HHMMSS,
  TIME_PATTERN_SS,
  TIME_PATTERN_SSS,
  TIME_PATTERN_WITH_MS,
} from '../components/pattern-input-time';
import { EvidenceAttachmentStore } from './mobx/evidence-attachment-store';

export async function handleFormSubmit(
  dataStore: EvidenceObjectDataStore
): Promise<boolean | null> {
  dataStore.submitButtonClicked = true;
  if (dataStore.isValid) {
    if (!dataStore.objectStore.data.includes(dataStore)) {
      dataStore.objectStore.addDataObject(dataStore);
    }
    const success = await dataStore?.syncValue();
    if (success) {
      dataStore.submitButtonClicked = false;
      return true;
    }
    return false;
  }

  return null;
}

export interface CommonFormFieldAttributes {
  label: string;
  help: string;
  variant: FormControlVariant;
  optional: boolean;
  optionalMessage: string;
  placeholder: string;
  disabled: boolean;
  noLabel: boolean;
}

export function getFormFieldAttributes(
  attributeStore: EvidenceAttributeStore | EvidenceAttachmentStore,
  intl: IntlShape
): Partial<CommonFormFieldAttributes> {
  const objectDefinition = attributeStore.objectDataStore.definition;
  if (
    objectDefinition.VisualizationKey !== 'table' &&
    (objectDefinition as any).VisualisationKey !== 'table'
  ) {
    return {};
  }
  const definition = attributeStore.definition;

  return {
    label: intl.formatMessage({ id: definition.AttributeName }),
    optional: definition.IsOptional,
    optionalMessage: intl.formatMessage({ id: 'evidence.attribute.optional' }),
    help:
      definition.AttributeDescription &&
      intl.messages[definition.AttributeDescription]
        ? intl.formatMessage({ id: definition.AttributeDescription })
        : undefined,
    variant: FormControlVariant.big,
    disabled: definition.IsCalculated || !definition.IsEditable,
  };
}

export function isFirstInvalidField(
  attributeStore: EvidenceAttributeStore
): boolean {
  return attributeStore.objectDataStore.invalidAttributes[0] === attributeStore;
}

export const sumEvalExpr = `const sum=(...v)=>{const f=v.filter(x=>x !== null);return f.length?f.reduce((a,b)=>a+b, 0):null;};`;
export const avgEvalExpr = `const avg=(...v)=>{const f=v.filter(x=>x !== null);return f.length?sum(...f)/f.length:null;};`;
export const roundEvalExpr = `const round=(a,x)=>a===null?null:parseFloat(a.toFixed(x));`;
export const percentileEvalExpr = `const percentil=(a,b)=>b?((b-a)/b)*100:null;`;
export const minEvalExpr = `const min=(...v)=>{const f=v.filter(x=>x !== null);return Math.min(...f);};`;
export const maxEvalExpr = `const max=(...v)=>{const f=v.filter(x=>x !== null);return Math.max(...f);};`;
export const sgnEvalExpr = `const sgn=(v)=>v === null?null:Math.sign(v);`;

export function formatValueByDataType(
  dataType: EvidenceAttributeType,
  value: string,
  format?: string | null
): string | number | null {
  switch (dataType) {
    case 'number':
      return value !== '' ? String(Number(value)) : null;
    case 'decimal-number':
      return value !== '' ? String(Number(value.replace(',', '.'))) : null;
    case 'time':
      if (!value) {
        return '';
      }
      switch (format) {
        case TIME_PATTERN_WITH_MS:
          return `00:${value}0`;
        case FULL_TIME_PATTERN_WITH_MS:
          return `${value}0`;
        case TIME_PATTERN_HHMMSS:
          return `${value}.000`;
        case TIME_PATTERN_SS:
          return `00:00:${value}0`;
        case TIME_PATTERN_SSS:
          return `00:00:${value}`;
        case TIME_PATTERN:
        default:
          return `00:${value}.000`;
      }
    default:
      return value;
  }
}

export function timeToMS(time: string): number {
  if (!time) {
    return 0;
  }
  const [hours, minutes, secondsWithMs] = time.split(':');
  const [seconds, milliseconds] = secondsWithMs.split('.');

  return (
    Number(hours) * 3600 * 1000 +
    Number(minutes) * 60 * 1000 +
    Number(seconds) * 1000 +
    Number(milliseconds)
  );
}

export function getHeaderSize(size: VisualisationKey): TextSize {
  switch (size) {
    case 'category': {
      return TextSize.s16;
    }
    default:
      return TextSize.s14;
  }
}
