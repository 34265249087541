import { loadPersistSelectedAthlete } from '../athletes-sidebar/utils';
import { loadPersistedGroupId } from '../groups/utils';
import * as React from 'react';
import {
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '../routes/types';
import { useDefaultGroup, useGroupsStore } from '../groups/hooks';
import { useCurrentUserStore } from '../users/hooks';
import { createPermissionScopeFilter } from '../permissions/utils';
import { useConfig } from '../app/hooks';
import {
  findActiveReportingModulePage,
  isEvidenceLink,
  isMainNavLink,
  isReportingLink,
} from './utils';
import { useLocation } from '../routes/hooks';
import { useModule } from '../modules/hooks';
import { EvidenceNavigationLink, HeaderLink, LINKS } from './types';
import { ReportingNavigationLink } from '../reporting/types';
import { NavigationStore } from './mobx/navigation-store';
import { NavigationContext } from './navigation-context';
import { useWindowSize } from '../utils/use-window-size';
import { useForceUpdate } from '../layer-manager/hooks';
import { useReportingStore } from '../reporting/hooks/use-reporting-store';

export function useNavigationStore(): NavigationStore {
  return useContext(NavigationContext);
}
export function useDefaultCommonSearchParams(
  customDefaultParams?: Record<string, string | undefined>
): string {
  const defaultGroup = useDefaultGroup();
  const currentUser = useCurrentUserStore();
  const groupsStore = useGroupsStore();
  const persistedAthlete = loadPersistSelectedAthlete();
  const persistedGroup = loadPersistedGroupId(groupsStore, currentUser);

  return React.useMemo(() => {
    const params = new URLSearchParams();

    if (!params.has(GROUP_SEARCH_PARAM) && defaultGroup) {
      params.set(
        GROUP_SEARCH_PARAM,
        String(persistedGroup ? persistedGroup : defaultGroup)
      );
    }
    if (currentUser.isAthlete && !params.has(ATHLETE_SEARCH_PARAM)) {
      params.set(ATHLETE_SEARCH_PARAM, String(currentUser.id));
    }

    if (
      currentUser.isAthlete &&
      !params.has(ATHLETE_SEARCH_PARAM) &&
      persistedAthlete
    ) {
      params.set(ATHLETE_SEARCH_PARAM, String(persistedAthlete));
    }

    Object.entries(customDefaultParams || {}).forEach(([param, value]) => {
      if (value && !params.has(param)) {
        params.set(param, value);
      }
    });

    return params.toString();
  }, [
    customDefaultParams,
    defaultGroup,
    currentUser,
    persistedAthlete,
    persistedGroup,
  ]);
}

export function useMainNavButtons(): HeaderLink[] {
  const evidence = useConfig('evidenceModuleConfigurations');
  const navigationConfig = useConfig('headerNavigationConfig');
  const currentUser = useCurrentUserStore();
  const reportingStore = useReportingStore();

  const reportingNavigationLinks = reportingStore.navigationLinks;

  let items: HeaderLink[] = (LINKS as HeaderLink[])
    .concat(reportingNavigationLinks)
    .concat(
      evidence.map(def => ({
        ...def,
        permissionScope: `evidence.${def.moduleKey}`,
      })) as HeaderLink[]
    )
    .filter(createPermissionScopeFilter(currentUser));

  if (navigationConfig) {
    const itemsMap = new Map(
      items.map(item => [
        isMainNavLink(item)
          ? item.module
          : isReportingLink(item)
            ? `reporting.${item.dashboard}`
            : isEvidenceLink(item)
              ? `evidence.${item.moduleKey}`
              : null,
        item,
      ])
    );
    items = navigationConfig
      .flatMap(item =>
        item.module === 'reporting'
          ? [itemsMap.get(item.module), ...reportingNavigationLinks]
          : [itemsMap.get(item.module)]
      )
      .filter(Boolean) as HeaderLink[];
  }

  return items;
}

export function useActiveHeaderLink(links: HeaderLink[]): HeaderLink {
  const { pathname } = useLocation();
  const reportingModules = useModule('reporting');

  return useMemo(() => {
    const splitRoute = pathname.split('/');
    const activeItem = splitRoute[1];
    const moduleId = splitRoute[2];

    const activeModuleDefinition: HeaderLink =
      activeItem === 'evidence'
        ? (links.find(
            l => isEvidenceLink(l) && l.moduleKey === splitRoute[2]
          ) ??
          links.find(l => isEvidenceLink(l)) ??
          ({
            moduleKey: moduleId,
          } as EvidenceNavigationLink))
        : activeItem === 'reporting' || activeItem === 'analytics'
          ? (findActiveReportingModulePage(
              reportingModules,
              links.filter(isReportingLink),
              splitRoute[2]
            ) ??
            links.find(isReportingLink) ??
            ({
              title: splitRoute[2],
              dashboard: {
                ReportPageCode: splitRoute[2],
                Title: splitRoute[2],
              },
            } as ReportingNavigationLink))
          : activeItem === 'settings'
            ? {
                module: 'settings',
                permissionScope: null,
              }
            : (links.find(
                link => isMainNavLink(link) && link.module === activeItem
              ) ?? {
                module: 'settings',
                permissionScope: null,
              });

    return activeModuleDefinition;
  }, [links, pathname, reportingModules]);
}

const visibleElementsCache: Record<number, number> = {};
export function useMaxVisibleItems(
  total: number,
  listRef: RefObject<HTMLUListElement>,
  wrapperRef: RefObject<HTMLDivElement>
): [number, boolean] {
  const navigationConfig = useConfig('headerNavigationConfig');
  const totalAttempts = useRef(0);
  const showNavigation = useRef(false);
  const { width: windowWidth } = useWindowSize();
  let maxElements = navigationConfig
    ? navigationConfig.findIndex(i => i.isHidden)
    : total;

  if (maxElements === -1) {
    maxElements = total;
  }

  const [visibleElements, setVisibleElements] = useState(
    windowWidth
      ? (visibleElementsCache[windowWidth] ?? maxElements)
      : maxElements
  );
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (windowWidth) {
      totalAttempts.current = 0;
    }
  }, [windowWidth]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const list = listRef.current;

    if (wrapper && list && windowWidth) {
      if (windowWidth < 768) {
        return;
      }

      if (wrapper.clientWidth < list.scrollWidth) {
        totalAttempts.current++;
        const newVisibleElements = visibleElements - 1;
        visibleElementsCache[windowWidth] = newVisibleElements;
        setVisibleElements(newVisibleElements);
      } else if (
        wrapper.clientWidth > list.scrollWidth + 150 &&
        visibleElements < maxElements &&
        totalAttempts.current < maxElements
      ) {
        totalAttempts.current++;
        const newVisibleElements = Math.min(maxElements, visibleElements + 1);
        visibleElementsCache[windowWidth] = newVisibleElements;
        setVisibleElements(newVisibleElements);
      } else {
        showNavigation.current = true;
        forceUpdate();
      }
    }
  }, [
    visibleElements,
    windowWidth,
    forceUpdate,
    maxElements,
    wrapperRef,
    listRef,
  ]);

  return [visibleElements, showNavigation.current];
}
