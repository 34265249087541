import { AxiosPromise, CancelToken } from 'axios';
import { axios } from '../../api/axios';
import { Video } from '../../videoselector/types';

export interface SearchVideoRequestParams {
  searchText: string;
  skip: number;
  limit: number;
}

export function searchVideo(
  params: SearchVideoRequestParams,
  cancelToken: CancelToken
): AxiosPromise<Video[]> {
  return axios.post('api/Video', params, { cancelToken });
}
