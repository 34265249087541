import {
  action,
  computed,
  observable,
  runInAction,
  makeObservable,
  reaction,
  when,
  IObservableArray,
} from 'mobx';

import { RootStore } from '../../app/mobx/root-store';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { getCurrentUser } from '../api/get-current-user';
import { User, UserId, UserRole } from '../types';
import {
  Permission,
  PermissionScope,
  PermissionType,
} from '../../permissions/types';
import { getCurrentUserRightsToUsers } from '../api/get-current-user-rights-to-users';
import { getCurrentUserRightsToGroups } from '../api/get-current-user-rights-to-groups';
import { groupCookies, UserGroupId } from '../../groups/types';
import {
  getUserName,
  isAdmin,
  isAthlete,
  isCoach,
  persistCurrentUser,
} from '../utils';
import { acceptAgreement } from '../api/accept-agreement';
import axios from 'axios';
import { SettingsKey } from '../../settings/mobx/settings-service';
import { PersonalizedSettingsConfiguration } from '../../settings/types';
import { updateUser } from '../api/update-user';
import { registerUser } from '../api/register-user';
import { saveAuthToken } from '../../auth/utils';
import {
  changeEmailPreferences,
  UserConsents,
} from '../api/change-email-preferences';
import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '../../routes/types';
import { athleteCookies } from '../../athletes-sidebar/types';
import { InitialConfigError } from '../../error/initial-config-error';
import { UserStore } from './user-store';

export class CurrentUserStore {
  private readonly rootStore: RootStore;

  @observable
  private currentUser: User | null = null;

  @observable
  private permissionsToUsers: Map<UserId, PermissionType> = new Map();

  @observable
  private permissionsToGroups: Map<UserGroupId, PermissionType> = new Map();

  @observable
  private settings: User['Settings'] = {};

  @observable
  private requests: (RequestStore<User> | RequestStore<Permission[]>)[] = [];

  @observable
  private _coaches: IObservableArray<UserStore> = observable.array();

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    when(
      () => rootStore.isReady,
      () => this.persistUrlParameters()
    );
  }

  public async loadCurrentUser(): Promise<void> {
    await this.loadUser(true);
  }

  public async loadCurrentUserWithoutPermissions(): Promise<void> {
    await this.loadUser(false);
  }

  private async loadUser(loadPermissions: boolean): Promise<void> {
    const currentUserRequest = this.rootStore.requestsStore.createRequest(() =>
      getCurrentUser()
    );
    this.requests.push(currentUserRequest);

    const response = await currentUserRequest.getResponse();
    if (response) {
      await runInAction(async () => {
        this.settings = response.Settings;
        this.currentUser = response;
        if (response.Coaches?.length) {
          this.coaches.replace(
            response.Coaches.map(coach =>
              this.rootStore.usersStore.getUserById(coach)
            ).filter<UserStore>((u): u is UserStore => Boolean(u))
          );
        }
        persistCurrentUser(response);

        if (loadPermissions) {
          await this.loadPermissions();
        }
      });
    } else {
      throw new InitialConfigError(
        'Unable to load current user',
        currentUserRequest.error
      );
    }
  }

  @action
  public async loadPermissions(): Promise<void> {
    const userId = this.currentUser?.UserId;

    if (!userId) {
      return;
    }

    const permissionRequests = [
      this.rootStore.requestsStore.createRequest(() =>
        getCurrentUserRightsToUsers(userId)
      ),
      this.rootStore.requestsStore.createRequest(() =>
        getCurrentUserRightsToGroups(userId)
      ),
    ];
    this.requests.push(...permissionRequests);

    const [usersResponse, groupsResponse] =
      await Promise.all(permissionRequests);

    if (usersResponse) {
      const permissions = await usersResponse.getResponse();
      if (permissions) {
        runInAction(() => {
          this.permissionsToUsers.clear();
          permissions.forEach(permission =>
            this.permissionsToUsers.set(
              permission.UserId,
              permission.Permission
            )
          );
        });
      }
    }

    if (groupsResponse) {
      const permissions = await groupsResponse.getResponse();
      if (permissions) {
        runInAction(() => {
          this.permissionsToGroups.clear();
          permissions.forEach(permission =>
            this.permissionsToGroups.set(
              permission.UserGroupId!,
              permission.Permission
            )
          );
        });
      }
    }
  }

  @computed
  public get status(): AsyncStatus {
    if (this.requests.length === 0) {
      return AsyncStatus.idle;
    }

    if (
      this.requests.some(
        request =>
          request.status === AsyncStatus.rejected &&
          !this.isExpiredTermsError(request.error)
      )
    ) {
      return AsyncStatus.rejected;
    }

    if (
      this.requests.some(request => request.status === AsyncStatus.pending) ||
      !this.currentUser
    ) {
      return AsyncStatus.pending;
    }

    return AsyncStatus.resolved;
  }

  @computed
  public get role(): UserRole {
    return this.currentUser?.Role || 'athlete';
  }

  @computed
  public get acceptedLegalConsent(): boolean {
    return this.data?.AgreementValidTo !== null;
  }

  public get isAthlete(): boolean {
    return isAthlete(this.data);
  }

  public get isAdmin(): boolean {
    return isAdmin(this.data);
  }

  public get isCoach(): boolean {
    return isCoach(this.data);
  }

  @computed
  public get id(): UserId {
    return this.currentUser?.UserId || -1;
  }

  public get data(): User | null {
    return this.currentUser;
  }

  public get avatar(): string | null {
    return this.currentUser?.AvatarFileName ?? null;
  }

  public get displayName(): string {
    return this.currentUser ? getUserName(this.currentUser) : '';
  }

  public hasPermissionToUser(userId: UserId): boolean {
    if (userId === this.id) {
      return true;
    }

    return this.permissionsToUsers.has(userId);
  }

  public getPermissionToUser(userId: UserId): PermissionType {
    if (userId === this.id) {
      return 'write';
    }

    return this.permissionsToUsers.get(userId) || this.defaultPermission;
  }

  public hasPermissionToGroup(groupId: UserGroupId): boolean {
    return this.permissionsToGroups.has(groupId);
  }

  public getPermissionToGroup(groupId: UserGroupId): PermissionType {
    return this.permissionsToGroups.get(groupId) || this.defaultPermission;
  }

  public async acceptNewTermsAndConditions(
    commercialConsent: boolean
  ): Promise<boolean> {
    const request = this.rootStore.requestsStore.createRequest(() =>
      acceptAgreement({
        CommercialConsent: commercialConsent,
      })
    );

    const response = await request.getResponse();

    if (response && this.currentUser) {
      this.currentUser.AgreementValidTo = null;
    }

    return response || false;
  }

  @computed
  private get defaultPermission(): PermissionType {
    if (this.role === 'admin' || this.role === 'coach') {
      return 'write';
    }

    return 'read';
  }

  public hasWritePermission(
    groupId: number | null,
    athleteId: number | null
  ): boolean {
    const role = this.role;

    return (
      (role === 'athlete' && athleteId === this.id) ||
      ((role === 'admin' || role === 'coach') &&
        ((athleteId !== null &&
          this.getPermissionToUser(athleteId) === 'write') ||
          (groupId !== null &&
            athleteId === null &&
            this.getPermissionToGroup(groupId) === 'write')))
    );
  }

  public isAllowedTo(key: PermissionScope): boolean {
    return Boolean(this.currentUser?.Permissions[key]);
  }

  private isExpiredTermsError(error?: Error): boolean {
    if (axios.isAxiosError(error)) {
      const body = error.response?.data;
      return body === 'agreementInvalid';
    }
    return false;
  }

  public getSetting<K extends SettingsKey>(
    id: K
  ): PersonalizedSettingsConfiguration[K] {
    return this.settings[id];
  }

  @action
  public updateSetting<K extends SettingsKey>(
    key: K,
    value: PersonalizedSettingsConfiguration[K]
  ) {
    this.settings[key] = value;
  }

  public async update(user: User): Promise<boolean> {
    const request = this.rootStore.requestsStore.createRequest(() =>
      updateUser(user)
    );
    await request.getResponse();

    return runInAction(() => {
      if (!request.error) {
        this.currentUser = { ...this.currentUser, ...user };
        this.rootStore.usersStore
          .getUserById(this.id)
          ?.update(this.currentUser);

        return true;
      } else {
        return false;
      }
    });
  }

  public async register(user: User): Promise<boolean> {
    const request = this.rootStore.requestsStore.createRequest(() =>
      registerUser(user)
    );
    await request.getResponse();

    return runInAction(() => {
      if (!request.error) {
        this.currentUser = { ...this.currentUser, ...user };
        const dataUser = this.rootStore.usersStore.getUserById(this.id);
        if (dataUser) {
          dataUser.update(user);
        }

        const token = this.rootStore.authStore.token;
        if (token) {
          saveAuthToken(token);
        }
        return true;
      } else {
        return false;
      }
    });
  }

  public async changeEmailPreferences(
    consents: UserConsents
  ): Promise<boolean> {
    const request = this.rootStore.requestsStore.createRequest(() =>
      changeEmailPreferences(consents)
    );
    const response = await request.getResponse();
    return runInAction(() => {
      if (response) {
        this.currentUser = { ...this.currentUser!, ...consents };
        this.rootStore.usersStore
          .getUserById(this.id)
          ?.update(this.currentUser);
        return true;
      } else {
        return false;
      }
    });
  }

  private persistUrlParameters(): void {
    reaction(
      () =>
        this.rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM),
      id => {
        if (id) {
          window.localStorage.setItem(athleteCookies.ATHLETE, id);
        }
      }
    );
    reaction(
      () => this.rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM),
      id => {
        if (id) {
          window.localStorage.setItem(groupCookies.GROUP, id);
        }
      }
    );
  }

  public get coaches(): Readonly<typeof this._coaches> {
    return this._coaches;
  }

  public updateEmail(email: string) {
    if (this.currentUser) {
      this.currentUser.Email = email;
    }
  }

  @computed
  public get writableAthletes(): UserId[] {
    return Array.from(this.permissionsToUsers.entries())
      .filter(([, permission]) => permission === 'write')
      .map(([userId]) => this.rootStore.usersStore.getUserById(userId))
      .filter(user => Boolean(user && user.isAthlete))
      .map<UserId>(user => user!.id);
  }
}
