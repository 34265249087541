import { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import {
  ActivitiesTable,
  ActivityRowWrapper,
  ContentBox,
  ContentCell,
  HeadCategoryCell,
  HeadCell,
  HoverWrapper,
  SummaryCell,
  Text,
  TextAlignment,
  TextSize,
  TextTag,
} from '@yarmill/components';

import { SeasonHeaderCellContent } from '../diary/season-header-cell';
import { useRootStore } from '../app/root-store-context';
import { ActivitiesTableContainer } from '../activities/activities-table-container';
import { SeasonViewLoadingScreen } from '../diary/season-view-loading-screen';
import { Tippy } from '../components/tippy/tippy';
import { useSeasonEvaluationStore } from './season-evaluation-store-context';
import { SingleSeasonEvaluationValue } from './single-season-evaluation-value';
import { SingleSeasonEvaluationFirstColumnCell } from './single-season-evaluation-first-column-cell';

interface StyledContentCellProps {
  showSeparator?: boolean;
}

const StyledContentCell = styled(ContentCell)<StyledContentCellProps>`
  ${props => props.showSeparator && 'border-left-color: #9a9a9a;'}
`;

interface StyledSummaryCellProps {
  showSeparator?: boolean;
  notLast?: boolean;
}
const StyledSummaryCell = styled(SummaryCell)<StyledSummaryCellProps>`
  ${props => props.showSeparator && 'border-left-color: #9a9a9a;'}

  ${props =>
    props.notLast &&
    css`
      border-right-width: 0;
      &:hover {
        :after {
          transform: unset;
        }
      }
    `}
`;

const StyledActivityRowWrapper = styled(ActivityRowWrapper)<{
  isExpandable?: boolean;
}>`
  ${props => props.isExpandable && `cursor: pointer;`}
`;

const TableContainer = styled(ActivitiesTableContainer)`
  padding-left: 0;

  @media (min-width: 768px) {
    padding-left: 0;
  }
`;

/* window-height - topbar-height - header-height - diary-padding - content-box padding */
const TABLE_HEIGHT =
  'calc( 100vh - 80px - 94px - 50px - 52px - var(--vh-offset, 0px))';

function InternalSeasonEvaluationTable(): JSX.Element {
  const rootStore = useRootStore();
  const store = useSeasonEvaluationStore();
  const singleSeasonStore = store.singleSeasonEvaluationStore;
  const season = singleSeasonStore.currentSeason;
  const seasonSections = season?.seasonSections || [];
  const seasonViewType = rootStore.configStore.seasonView;
  const enabledDiaryTypes = singleSeasonStore.enabledDiaryTypes;
  const activities = singleSeasonStore.activities;
  const expandable = singleSeasonStore.expandable;
  const cyclesView = rootStore.configStore.seasonView === 'cycle';

  if (enabledDiaryTypes.length === 0) {
    return (
      <ContentBox>
        <Text size={TextSize.s14}>
          <FormattedMessage id="seasonEvaluation.noDataSelected" />
        </Text>
      </ContentBox>
    );
  }

  if (!season) {
    return <SeasonViewLoadingScreen />;
  }

  return (
    <TableContainer height={TABLE_HEIGHT}>
      <ActivitiesTable
        columnsCount={
          (seasonSections.length * enabledDiaryTypes.length || 0) + 2
        }
      >
        <HeadCategoryCell span={1} column={1} />
        {seasonSections.map((section, index) => (
          <HeadCategoryCell
            key={section}
            span={enabledDiaryTypes.length}
            column={index * enabledDiaryTypes.length + 2}
          >
            <SeasonHeaderCellContent
              season={season}
              columnIndex={index}
              seasonViewType={seasonViewType}
            />
          </HeadCategoryCell>
        ))}
        <HeadCategoryCell
          span={enabledDiaryTypes.length}
          column={(seasonSections.length || 0) * enabledDiaryTypes.length + 2}
        >
          <Text bold size={TextSize.s12} tag={TextTag.div}>
            <FormattedMessage id="activityItem.columns.total" />
          </Text>
        </HeadCategoryCell>

        <HeadCell hasCategory firstChild cyclesView={cyclesView} />
        {seasonSections.map(day =>
          enabledDiaryTypes.map(diaryType => (
            <HeadCell
              key={`${day}-${diaryType}`}
              hasCategory
              cyclesView={cyclesView}
            >
              <Tippy
                Wrapper="div"
                tooltipContent={`seasonEvaluation.column.${diaryType}.tooltip`}
              >
                <Text
                  size={TextSize.s12}
                  textAlign={TextAlignment.center}
                  tag={TextTag.div}
                >
                  <FormattedMessage
                    id={`seasonEvaluation.column.${diaryType}.title`}
                  />
                </Text>
              </Tippy>
            </HeadCell>
          ))
        )}
        {enabledDiaryTypes.map(diaryType => (
          <HeadCell hasCategory key={diaryType} cyclesView={cyclesView}>
            <Tippy
              Wrapper="div"
              tooltipContent={`seasonEvaluation.column.${diaryType}.tooltip`}
            >
              <Text
                size={TextSize.s12}
                textAlign={TextAlignment.center}
                bold
                tag={TextTag.div}
              >
                <FormattedMessage
                  id={`seasonEvaluation.column.${diaryType}.title`}
                />
              </Text>
            </Tippy>
          </HeadCell>
        ))}

        {activities.map(
          (activity, idx) =>
            (!expandable ||
              expandable.isNodeVisible(activity.ActivityItemId)) && (
              <Fragment key={activity.ActivityItemId}>
                <SingleSeasonEvaluationFirstColumnCell
                  activity={activity}
                  expandable={expandable}
                />
                <StyledActivityRowWrapper
                  isExpandable={expandable?.isNodeExpandable(
                    activity.ActivityItemId
                  )}
                  onClick={
                    expandable?.isNodeExpandable(activity.ActivityItemId)
                      ? () =>
                          expandable.isNodeExpanded(activity.ActivityItemId)
                            ? expandable?.collapseNode(activity.ActivityItemId)
                            : expandable?.expandNode(activity.ActivityItemId)
                      : undefined
                  }
                >
                  {seasonSections.map((day, dayIndex) =>
                    enabledDiaryTypes.map((diaryType, typeIndex) => (
                      <StyledContentCell
                        key={`${activity.ActivityItemId}-${day}-${diaryType}`}
                        showSeparator={typeIndex === 0 && dayIndex !== 0}
                      >
                        <HoverWrapper>
                          <SingleSeasonEvaluationValue
                            activityItemId={activity.ActivityItemId}
                            day={day}
                            diaryType={diaryType}
                            store={store.singleSeasonEvaluationStore}
                          />
                        </HoverWrapper>
                      </StyledContentCell>
                    ))
                  )}
                  {enabledDiaryTypes.map((diaryType, index) => (
                    <StyledSummaryCell
                      key={`${activity.ActivityItemId}-summary-${diaryType}`}
                      notLast={index !== enabledDiaryTypes.length - 1}
                      showSeparator={index === 0}
                    >
                      <HoverWrapper>
                        <SingleSeasonEvaluationValue
                          activityItemId={activity.ActivityItemId}
                          day={null}
                          diaryType={diaryType}
                          store={store.singleSeasonEvaluationStore}
                        />
                      </HoverWrapper>
                    </StyledSummaryCell>
                  ))}
                </StyledActivityRowWrapper>
              </Fragment>
            )
        )}
      </ActivitiesTable>
    </TableContainer>
  );
}

export const SingleSeasonEvaluationTable = observer(
  InternalSeasonEvaluationTable
);
