import { DataLabelDefinition, HorizontalBarGroupChart } from '../types';
import { DataItem, getSSRStringWidth } from '@yarmill/components';
import { useScale } from '../hooks/use-scale';
import { createKeysScale } from './create-keys-scale';
import { getAxisPosition } from '../../reporting/utils/get-axis-position';
import { getRotatedHeight } from '../../reporting/utils/get-rotated-height';
import { LABEL_FONT_SIZE } from '../../reporting/const';
import { getHorizontalBarLabelOffset } from './get-horizontal-bar-label-offset';
import { getHorizontalBarLabelX } from './get-horizontal-bar-label-x';

export function getHorizontalBarGroupLabel(
  item: DataItem,
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>,
  chartConfig: HorizontalBarGroupChart,
  priority: number
): (DataLabelDefinition | undefined)[] | undefined {
  if (!('bandwidth' in yScale) || 'bandwidth' in xScale) {
    return undefined;
  }

  const keysScale = createKeysScale(chartConfig.keys, yScale.bandwidth());
  const domainX = xScale.domain();
  const axisPosition = getAxisPosition(domainX as number[]);

  return chartConfig.keys.map(key => {
    const showLabel = chartConfig.getShowLabels(item, key);
    if (!showLabel) {
      return undefined;
    }

    const yValue = chartConfig.getYValue(item) as string;
    const xValue = item[key] as number;
    const position = chartConfig.labelPosition;
    const barWidth = Math.abs(xScale(xValue) - xScale(axisPosition));
    const barHeight =
      keysScale.bandwidth() * chartConfig.getBarWidth(item, key);
    const groupY = yScale(yValue)!;
    const y = groupY + (keysScale(key) || 0) + barHeight / 2;
    const x = getHorizontalBarLabelX(xScale, xValue);
    const formattedValue = chartConfig.formatLabelValue(
      item[key] as number,
      key
    );
    const angle = chartConfig.getLabelAngle(item, key);
    const labelWidth = getSSRStringWidth(formattedValue);
    const labelOffset = getHorizontalBarLabelOffset(
      barWidth,
      xValue,
      position,
      angle,
      labelWidth
    );

    const rotatedLabelHeight = getRotatedHeight(
      labelWidth,
      LABEL_FONT_SIZE,
      angle
    );

    if (rotatedLabelHeight > barHeight && position !== 'outside') {
      return undefined;
    }

    const maxWidth = position !== 'outside' ? barWidth : undefined;

    return {
      x: x + labelOffset,
      y,
      originalValue: xValue,
      text: formattedValue,
      angle,
      color: chartConfig.getLabelColor(item, key),
      priority,
      maxWidth,
      maxHeight: chartConfig.keys.length > 1 ? keysScale.step() : yScale.step(),
    };
  });
}
