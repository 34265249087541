import { EvidenceObjectStore } from '../mobx/evidence-object-store';
import { AsyncStatus } from '../../api/mobx/request-store';
import { Button, ButtonAppearance } from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { useIsElementInViewport } from '../../utils/use-is-element-in-viewport';

export interface ShowMoreRowProps {
  table: EvidenceObjectStore | null;
}

const StyledShowMoreRow = styled.div`
  position: sticky;
  text-align: center;
  left: 0;
  margin: 20px 0;
  opacity: 0;
`;
export function ShowMoreRow(props: ShowMoreRowProps): JSX.Element | null {
  const { table } = props;
  const showMoreRowRef = useRef(null);
  const isInViewPort = useIsElementInViewport(showMoreRowRef);

  useEffect(() => {
    if (isInViewPort && table?.status === AsyncStatus.resolved) {
      table?.showMoreRows();
    }
  }, [isInViewPort, table]);

  if (
    table?.status === AsyncStatus.resolved &&
    (table?.data?.length || 0) >= (table?.totalRowsCount || 0)
  ) {
    return null;
  }

  return (
    <StyledShowMoreRow ref={showMoreRowRef}>
      <Button
        onClick={table?.showMoreRows}
        appearance={ButtonAppearance.Navigation}
      >
        <FormattedMessage id="evidence.table.showMore" />
      </Button>
    </StyledShowMoreRow>
  );
}
