import { useFormik } from 'formik';
import { RichTextEditor } from '../components/richtext';
import { SupervisorsDropdown } from './dropdowns/supervisors';
import { CategoryDropdown } from './dropdowns/category';

import { useOkrsStore } from './okrs-store-context';
import { useUsersStore } from '../users/hooks';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  ObjectiveFormValues,
  OkrCategory,
  OkrPriority,
  OkrStatus,
} from './types';
import { ObjectiveStore } from './mobx/objective-store';
import { UserStore } from '../users/mobx/user-store';
import { UserId } from '../users/types';
import { observer } from 'mobx-react-lite';
import { PriorityDropdown } from './dropdowns/priority';
import { runInAction } from 'mobx';
import { useHistory } from 'react-router-dom';
import { StatusDropdown } from './dropdowns/status';
import {
  ObjectiveDescription,
  ObjectiveDescriptionRichtextWrapper,
  ObjectiveDetailAttributesLayout,
  ObjectiveDetailLayout,
  ObjectiveTitle,
} from '../components-2/okrs/detail/objective-detail';
import { KeyResultsList } from './key-results-list';
import debounce from 'lodash.debounce';
import { Goal, PlainText } from '@yarmill/icons-2';
import { RichTextIconWrapper } from '../components-2/textarea';
import { Icon, IconSize } from '@yarmill/components';
import { useIntl } from 'react-intl';
import { Editor } from '@tiptap/react';

interface ObjectiveFormProps {
  readonly objective: ObjectiveStore;
}
export const ObjectiveForm = observer(function ObjectiveForm({
  objective,
}: ObjectiveFormProps): JSX.Element {
  const okrsStore = useOkrsStore();
  const intl = useIntl();
  const usersStore = useUsersStore();
  const history = useHistory();
  const initialValues = useMemo(() => objective.toFormValues(), [objective]);
  const createObjectivePromise = useRef<Promise<void> | null>(null);
  const isDisabled = !okrsStore.isCurrentUserAllowedToWrite(objective);

  const descriptionEditorRef = useRef<Editor | null>(null);
  const formik = useFormik<ObjectiveFormValues>({
    initialValues,
    onSubmit: async values => {
      await runInAction(async () => {
        objective
          .setTitle(values.title)
          .setDescription(values.description)
          .setPriority(values.priority)
          .setStatus(values.status)
          .setCategory(values.category)
          .setSupervisors(
            values.supervisors
              .map(userId => usersStore.getUserById(userId))
              .filter((u): u is UserStore => Boolean(u))
          );

        const isNewObjective = !objective.id;
        const hasNewKeyResult = objective.keyResults.find(
          keyResult => keyResult.id === null
        );

        if (createObjectivePromise.current) {
          await createObjectivePromise.current;
          createObjectivePromise.current = null;
        }

        const saveObjectivePromise = okrsStore.saveObjective(objective);

        if (isNewObjective || hasNewKeyResult) {
          createObjectivePromise.current = saveObjectivePromise;
        }

        await saveObjectivePromise;

        if (!okrsStore.objectives.includes(objective)) {
          okrsStore.addObjective(objective);
          const { location } = history;
          if (location.pathname.includes('add')) {
            const focusedElement = document.activeElement;
            history.push({
              ...location,
              pathname: `/okr/${objective.id}`,
            });
            setTimeout(() => {
              if (focusedElement instanceof HTMLElement) {
                if (focusedElement.className.toLowerCase().includes('tiptap')) {
                  descriptionEditorRef.current?.chain().focus().run();
                } else {
                  focusedElement.focus();
                }
              }
            }, 50);
          }
        }
      });
    },
  });

  const submitFormDebounced = useRef(
    debounce(() => formik.submitForm(), 500)
  ).current;
  const submitForm = useCallback(() => {
    objective.hasPendingUpdate = true;
    submitFormDebounced();
  }, [objective, submitFormDebounced]);

  const setDescription = useCallback(
    (value: string) => {
      let newValue = value;
      if (value === '<p></p>') {
        newValue = '';
      }
      formik.setFieldValue('description', newValue);

      submitForm();
    },
    [formik, submitForm]
  );

  const setTitle = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      formik.setFieldValue('title', e.target.value);
      submitForm();
    },
    [formik, submitForm]
  );

  const setCategory = useCallback(
    (value: OkrCategory) => {
      formik.setFieldValue('category', value);
      formik.submitForm();
    },
    [formik]
  );

  const setPriority = useCallback(
    (value: OkrPriority) => {
      formik.setFieldValue('priority', value);
      formik.submitForm();
    },
    [formik]
  );

  const setStatus = useCallback(
    (value: OkrStatus) => {
      formik.setFieldValue('status', value);
      formik.submitForm();
    },
    [formik]
  );

  const toggleSupervisor = useCallback(
    (id: UserId) => {
      const prevValue = formik.values.supervisors;
      const userIdx = prevValue.indexOf(id);
      let updatedValue;

      if (userIdx !== -1) {
        prevValue.splice(userIdx, 1);
        updatedValue = [...prevValue];
      } else {
        updatedValue = [...prevValue, id];
      }

      formik.setFieldValue('supervisors', updatedValue);
      formik.submitForm();
    },
    [formik]
  );

  useEffect(
    () => () => {
      submitFormDebounced.flush();
    },
    [submitFormDebounced]
  );

  return (
    <ObjectiveDetailLayout
      onSubmit={!isDisabled ? formik.handleSubmit : e => e.preventDefault()}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          if (!formik.values.title || !formik.values.category) {
            e.preventDefault();
          }
        }
      }}
    >
      <ObjectiveTitle
        autoFocus={!objective.id}
        name="title"
        onChange={!isDisabled ? setTitle : undefined}
        textAppearance="h28"
        value={formik.values.title}
        rows={1}
        icon={<Goal />}
        iconPosition="left"
        placeholder={intl.formatMessage({
          id: 'okrs.form.title.placeholder',
        })}
        readOnly={isDisabled}
      />
      <ObjectiveDetailAttributesLayout>
        <StatusDropdown
          selectedValue={formik.values.status}
          handleSelect={setStatus}
          disabled={isDisabled}
        />
        <PriorityDropdown
          selectedValue={formik.values.priority}
          handleSelect={setPriority}
          disabled={isDisabled}
        />
        <SupervisorsDropdown
          selectedValue={formik.values.supervisors}
          handleSelect={toggleSupervisor}
          disabled={isDisabled}
        />
        <CategoryDropdown
          selectedValue={formik.values.category ?? null}
          handleSelect={setCategory}
          disabled={isDisabled}
        />
      </ObjectiveDetailAttributesLayout>
      <ObjectiveDescription>
        <ObjectiveDescriptionRichtextWrapper>
          <RichTextEditor
            getEditor={editor => (descriptionEditorRef.current = editor)}
            key={objective.uid}
            content={formik.values.description ?? ''}
            disableFormatting
            onChange={!isDisabled ? setDescription : undefined}
            placeholder={intl.formatMessage({
              id: 'okrs.form.description.placeholder',
            })}
            readOnly={isDisabled}
          />
        </ObjectiveDescriptionRichtextWrapper>
        <RichTextIconWrapper position="left">
          <Icon size={IconSize.s16}>
            <PlainText />
          </Icon>
        </RichTextIconWrapper>
      </ObjectiveDescription>
      <KeyResultsList objective={objective} save={formik.submitForm} />
    </ObjectiveDetailLayout>
  );
});
