import {
  Button,
  FormControlVariant,
  SelectBox,
  SelectValueProps,
  StyledLabel,
} from '@yarmill/components';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MultiSelect } from '../#-components/multi-select/multi-select';
import { FormState } from '../app/app-types';
import { PatternInputDate } from '../components/pattern-input-date';
import { Tippy } from '../components/tippy/tippy';
import { toast } from '../components/toast-message';
import { ROUTE_DATE_FORMAT } from '../diary/utils';
import {
  StyledButtonsWrapper,
  StyledSubmitWrapper,
} from '../evidence/table/evidence-table-form';
import {
  getSubmitButtonAppearance,
  getSubmitButtonIcon,
} from '../evidence/table/utils';
import { FileUploadStore } from '../fileupload/mobx/file-upload-store';
import { File } from '../fileupload/types';
import { UserSelect } from '../groups/user-select';
import { useCurrentUserStore } from '../users/hooks';
import {
  extractDateFormat,
  extractDelimiters,
} from '../utils/extract-date-format';
import { AttachmentsFormField } from './attachments-form-field';
import { FormFieldWrapper } from './components/add-files-layer';
import { useFilesOverviewStore } from './files-overview-store-context';
import { AddMultipleFilesFormValues } from './types';

interface AddFilesFormProps {
  readonly close: () => void;
}

export const AddFilesForm = observer(function AddFilesForm({
  close,
}: AddFilesFormProps) {
  const intl = useIntl();
  const currentUser = useCurrentUserStore();
  const datePattern = extractDateFormat(intl);
  const delimiters = extractDelimiters(datePattern);
  const filesOverviewStore = useFilesOverviewStore();
  const [state, setState] = useState<FormState>(null);
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState<boolean>(false);
  const [pendingAttachments, setPendingAttachments] = useState<
    FileUploadStore[]
  >([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const prefillAthleteId = currentUser.hasWritePermission(
    null,
    filesOverviewStore.athleteId
  );

  const formik = useFormik<AddMultipleFilesFormValues>({
    initialValues: {
      date: moment().format(datePattern),
      diaryType: 'plan',
      tags: [],
      files: [],
      users:
        filesOverviewStore.athleteId !== null && prefillAthleteId
          ? [filesOverviewStore.athleteId]
          : [],
    },
    onSubmit: async (values: AddMultipleFilesFormValues) => {
      setState('submitting');
      setSubmitButtonClicked(true);
      const date = moment(values.date, datePattern);
      const formattedDate = date.format(ROUTE_DATE_FORMAT);
      const result = await filesOverviewStore.assignMultipleFiles({
        ...values,
        date: formattedDate,
      });

      if (result) {
        toast('toast.success.filesOverview.uploadFiles', 'success', {
          file: values.files[0]?.FileName,
          count: values.files.length,
        });
        setState('success');
        void filesOverviewStore.loadFiles();
        void filesOverviewStore.loadTags();
        close();
      } else {
        toast('toast.error.filesOverview.uploadFiles', 'error', {
          file: values.files[0]?.FileName,
          count: values.files.length,
        });
        setState('error');
        setSubmitButtonClicked(false);
        setTimeout(() => setState(null), 2000);
      }
    },
    onReset: () => {
      close();
    },
  });

  const diaryTypeOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'header.navigation.plan' }),
        value: 'plan',
      },
      {
        label: intl.formatMessage({ id: 'header.navigation.reality' }),
        value: 'reality',
      },
    ],
    [intl]
  );

  const tagOptions = useMemo(
    () =>
      filesOverviewStore.tags.map(tag => ({
        label: tag.TagValue,
        value: tag.TagValue,
      })),
    [filesOverviewStore.tags]
  );

  function addPendingAttachment(store: FileUploadStore) {
    setPendingAttachments(attachments => [...attachments, store]);
  }

  function removePendingAttachment(store: FileUploadStore) {
    setPendingAttachments(attachments => {
      const idx = attachments.indexOf(store);
      if (idx !== -1) {
        const update = [...attachments];
        update.splice(idx, 1);
        return update;
      }
      return attachments;
    });
  }

  function onDateChange(value: string): void {
    formik.setFieldValue('date', value);
  }

  function onDiaryTypeChange(
    value: { label: string; value: string } | null
  ): void {
    formik.setFieldValue('diaryType', value?.value ?? 'plan');
  }

  function onUsersChange(value: SelectValueProps[] | null): void {
    if (value === null) {
      formik.setFieldValue('users', []);
    } else {
      formik.setFieldValue(
        'users',
        value.map(option => option.value)
      );
    }
  }

  function onTagsChange(value: SelectValueProps[] | null): void {
    if (value === null) {
      formik.setFieldValue('tags', []);
    } else {
      formik.setFieldValue(
        'tags',
        value.map(option => option.value)
      );
    }
  }

  useEffect(() => {
    formik.setFieldValue('files', attachments);
  }, [attachments, formik.setFieldValue]);

  function addAttachment(file: File) {
    setAttachments((files: File[]) => [...files, file]);
  }

  function removeAttachment(file: File) {
    setAttachments(files => {
      const idx = files.indexOf(file);
      if (idx !== -1) {
        const updatedFiles = [...files];
        updatedFiles.splice(idx, 1);
        return updatedFiles;
      }

      return files;
    });
  }

  function createTagMessage(tagName: string) {
    return intl.formatMessage(
      { id: 'filesOverview.addFilesForm.tag.createTagMessage' },
      { tagName }
    );
  }

  const isValid =
    moment(formik.values.date, datePattern).isValid() &&
    formik.values.files.length &&
    formik.values.diaryType &&
    formik.values.users.length;

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <PatternInputDate
        variant={FormControlVariant.big}
        id="date"
        onChange={onDateChange}
        pattern={datePattern}
        delimiter={delimiters}
        value={formik.values.date}
        label={intl.formatMessage({
          id: 'filesOverview.addFilesForm.date.label',
        })}
      />
      <SelectBox
        id="diaryType"
        variant={FormControlVariant.big}
        options={diaryTypeOptions}
        onChange={onDiaryTypeChange}
        value={diaryTypeOptions.find(
          type => type.value === formik.values.diaryType
        )}
        label={intl.formatMessage({
          id: 'filesOverview.addFilesForm.diaryType.label',
        })}
        disablePortal
      />

      <StyledLabel variant={FormControlVariant.big} htmlFor="tags">
        <FormattedMessage id="filesOverview.addFilesForm.tags.label" />
      </StyledLabel>
      <MultiSelect
        id="tags"
        options={tagOptions}
        placeholder={intl.formatMessage({
          id: 'filesOverview.addFilesForm.tags.placeholder',
        })}
        onChange={onTagsChange}
        creatable={!currentUser.isAthlete}
        createOptionMessage={createTagMessage}
      />

      {!currentUser.isAthlete && (
        <FormFieldWrapper>
          <StyledLabel variant={FormControlVariant.big} htmlFor="users">
            <FormattedMessage id="filesOverview.addFilesForm.users.label" />
          </StyledLabel>
          <UserSelect
            onChange={onUsersChange}
            includedUsers={currentUser.writableAthletes}
            defaultUsers={
              filesOverviewStore.athleteId
                ? [filesOverviewStore.athleteId]
                : undefined
            }
            placeholder="filesOverview.addFilesForm.users.placeholder"
          />
        </FormFieldWrapper>
      )}
      <FormFieldWrapper>
        <AttachmentsFormField
          attachments={formik.values.files}
          addAttachment={addAttachment}
          removeAttachment={removeAttachment}
          addPendingAttachment={addPendingAttachment}
          removePendingAttachment={removePendingAttachment}
        />
      </FormFieldWrapper>
      <StyledButtonsWrapper>
        <Button type="reset" variant={FormControlVariant.big}>
          {intl.formatMessage({ id: 'evidence.form.close' })}
        </Button>
        <StyledSubmitWrapper>
          <Tippy
            tooltipContent="evidence.form.pendingAttachments"
            Wrapper="div"
            isEnabled={pendingAttachments.length !== 0}
          >
            <Button
              type={!state ? 'submit' : 'button'}
              appearance={getSubmitButtonAppearance(state)}
              variant={FormControlVariant.big}
              wide
              disabled={
                state === 'submitting' ||
                pendingAttachments.length !== 0 ||
                !isValid ||
                submitButtonClicked
              }
            >
              {getSubmitButtonIcon(state)}
              {intl.formatMessage({
                id: 'filesOverview.addFilesForm.submit',
              })}
            </Button>
          </Tippy>
        </StyledSubmitWrapper>
      </StyledButtonsWrapper>
    </form>
  );
});
