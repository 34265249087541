import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';

export interface LoginRequestParams {
  username: string;
  password: string;
}

export interface LoginResponse {
  access_token: string;
}

export function logIn(params: LoginRequestParams): AxiosPromise<LoginResponse> {
  const data = new URLSearchParams({ grant_type: 'password', ...params });

  return axios.post('token', data.toString(), {});
}
