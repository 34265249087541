import moment from 'moment';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Link } from '../../components/link';
import styled, { css } from 'styled-components';

import {
  Breakpoints,
  StyledTd,
  StyledTr,
  Text,
  TextSize,
  WhiteSpace,
} from '@yarmill/components';

import {
  getDiaryRouteLink,
  getIndexForDate,
  ROUTE_DATE_FORMAT,
} from '../../diary/utils';
import { getAligningForAttributeType } from './utils';
import { useFocusedDayService } from '../../diary/hooks';
import { EvidenceObjectDataStore } from '../mobx/evidence-object-data-store';
import { EvidenceAttributeStore } from '../mobx/evidence-attribute-store';
import { observer } from 'mobx-react-lite';
import { toast } from '../../components/toast-message';
import { RichTextEditor } from '../../components/richtext';
import { EvidenceAttachmentStore } from '../mobx/evidence-attachment-store';
import { EvidenceTableAttachments } from './evidence-table-attachments';
import { RowEditButtons } from './row-edit-buttons';

export interface EvidenceTableItemRowProps {
  item: EvidenceObjectDataStore;
  showControls: boolean;
  firstColumnSticky?: boolean;
  edit(e: React.MouseEvent, item: EvidenceObjectDataStore): void;
}

const ExtraCellStyles = css`
  margin: -8px 0 -8px;
  font-size: 12px;
  font-family: 'Roboto Mono', 'Apple Color Emoji', 'Noto Color Emoji', serif;
`;

const ExtraControlButtonsStyles = css`
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  min-width: 36px;

  @media (min-width: ${Breakpoints.tablet}px) {
    min-width: 80px;
  }
`;

const Td = styled(StyledTd)<{ readonly isDate: boolean }>`
  ${props => props.align === 'right' && `padding-right: calc(8px + 25px)`};

  ${({ isDate }) =>
    isDate &&
    css`
      :first-of-type {
        width: 1px;
      }
    `};

  ${({ sticky }) =>
    sticky &&
    css`
      left: unset;
      @media (min-width: ${Breakpoints.tablet}px) {
        left: ${sticky.left};
      }

      padding-right: 16px;

      + td {
        padding-left: 0;
      }
    `};
`;

export const EvidenceTableItemRow = observer(function EvidenceTableItemRow(
  props: EvidenceTableItemRowProps
): JSX.Element {
  const { item, edit, showControls, firstColumnSticky } = props;
  const intl = useIntl();
  const focusedDayService = useFocusedDayService();

  function getValue(
    attribute?: EvidenceAttributeStore | EvidenceAttachmentStore
  ): React.ReactNode {
    if (!attribute) {
      return '';
    }
    if (attribute instanceof EvidenceAttachmentStore) {
      return <EvidenceTableAttachments attribute={attribute} />;
    }

    const attributeValue = attribute.value;

    if (attributeValue) {
      switch (attribute.definition.AttributeTypeKey) {
        case 'dropdown': {
          const option = attribute.definition.SourceData.find(
            o => String(o.Key) === String(attributeValue)
          );

          return option
            ? intl.formatMessage({ id: option.Value })
            : attributeValue;
        }

        case 'decimal-number': {
          return Number(attributeValue).toLocaleString(intl.locale, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
        }
        case 'rich-text': {
          return <RichTextEditor content={attributeValue} readOnly />;
        }
        case 'date': {
          const format = attributeValue.includes('/')
            ? 'YYYY/MM/DD'
            : 'YYYY-MM-DD';
          const date = moment(attributeValue, format);

          const setFocusedDay = (): void => {
            setTimeout(
              () => focusedDayService.setFocusedDay(getIndexForDate(date)),
              0
            );
          };

          return (
            <Link
              to={getDiaryRouteLink({
                diaryType: 'reality',
                viewType: 'week',
                week: date.format(ROUTE_DATE_FORMAT),
                groupId: attribute.groupId || 0,
                athleteId: attribute.userId || 0,
              })}
              onClick={setFocusedDay}
            >
              {intl.formatDate(date.format(ROUTE_DATE_FORMAT))}
            </Link>
          );
        }
        default:
          return attributeValue;
      }
    }
  }

  const editRow = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      edit(e, item);
    },
    [edit, item]
  );

  const removeRow = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      if (
        // TODO: Check translations
        window.confirm(
          intl.formatMessage({
            id: `evidence.${item.definition.ObjectKey}.remove.confirm`,
          })
        )
      ) {
        const success = await item.remove();
        if (success) {
          toast(
            `toast.success.evidence.${item.definition.ObjectKey}.removeItem`,
            'success'
          );
        } else {
          toast(
            `toast.error.evidence.${item.definition.ObjectKey}.removeItem`,
            'error'
          );
        }
      }
    },
    [item, intl]
  );

  return (
    <StyledTr>
      {item.definition.Attributes?.map((attribute, idx) => {
        const attributeStore = item.getAttribute(attribute.AttributeId);
        const value = getValue(attributeStore);
        const isAttachment = attribute.AttributeTypeKey === 'attachment';

        return (
          <Td
            key={attribute.AttributeId}
            maxWidth={
              attribute.AttributeTypeKey === 'long-text'
                ? `25vw`
                : isAttachment
                  ? `160px`
                  : undefined
            }
            isDate={
              attribute.AttributeTypeKey === 'date' ||
              attribute.AttributeTypeKey === 'time'
            }
            minWidth={
              attribute.AttributeTypeKey === 'long-text' ? `25vw` : undefined
            }
            whiteSpace={
              attribute.AttributeTypeKey === 'long-text'
                ? WhiteSpace.preWrap
                : WhiteSpace.noWrap
            }
            align={getAligningForAttributeType(attribute.AttributeTypeKey)}
            dontBreakWord
            extraStyles={ExtraCellStyles}
            sticky={idx === 0 && firstColumnSticky ? { left: 0 } : undefined}
          >
            {isAttachment
              ? value
              : value && (
                  <Text monoSpace size={TextSize.s12}>
                    {attribute.PrefixKey}
                    {value}
                    {attribute.SuffixKey}
                  </Text>
                )}
          </Td>
        );
      })}
      {showControls && (
        <StyledTd
          whiteSpace={WhiteSpace.noWrap}
          sticky={{ right: 0 }}
          extraStyles={ExtraControlButtonsStyles}
          align="right"
        >
          {item.isCurrentUserAllowedToWrite && item.isEditable && (
            <RowEditButtons editRow={editRow} removeRow={removeRow} />
          )}
        </StyledTd>
      )}
    </StyledTr>
  );
});
