import { useMemo } from 'react';
import { GroupsStore } from './mobx/groups-store';
import { useRootStore } from '../app/root-store-context';
import { GroupStore } from './mobx/group-store';
import { useCurrentUserStore } from '../users/hooks';

export function useCurrentUserAllowedGroups(): GroupStore[] {
  const groupsStore = useGroupsStore();
  const currentUser = useCurrentUserStore();

  return useMemo(
    () =>
      groupsStore.sortedGroups.filter(group =>
        currentUser.hasPermissionToGroup(group.id)
      ),
    [currentUser, groupsStore]
  );
}

export function useGroup(groupId: number | null): GroupStore | undefined {
  return useGroupsStore().getGroupById(groupId);
}

export function useDefaultGroup(): number | undefined {
  const groups = useCurrentUserAllowedGroups();

  return groups[0]?.id;
}

export function useGroupsStore(): GroupsStore {
  return useRootStore().groupsStore;
}
