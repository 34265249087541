import {
  ISO_DATE_FORMAT,
  ReportingFilters,
  ReportingPageActions,
  SelectBox,
  SelectRange,
} from '@yarmill/components';

import { IntlShape, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { WeekSelector } from '../navbar/week-selector';
import styled from 'styled-components';
import { getWeekStart } from '../diary/utils';
import { ExportReportingPageButton } from './export-reporting-page-button';
import { PatternInputDate } from '../components/pattern-input-date';
import {
  createDateFormatValidator,
  extractDateFormat,
} from '../utils/extract-date-format';
import moment from 'moment';
import { ReportingPageFilterStore } from './mobx/reporting-page-filter-store';
import { SubscribeButton } from './subscribe-button';
import { Status } from '../navbar/status';
import { AsyncStatus } from '../api/mobx/request-store';
import { useReportingStore } from './hooks/use-reporting-store';

function getDropdown(
  filter: ReportingPageFilterStore,
  intl: IntlShape,
  disabled?: boolean
): JSX.Element {
  const options =
    filter.options?.map(filterOption => ({
      label:
        filter.translateLabels &&
        filterOption.Label &&
        intl.messages[filterOption.Label]
          ? intl.formatMessage({ id: filterOption.Label })
          : filterOption.Label,
      value: filterOption.Key,
    })) ?? [];

  const value = filter.value
    ? options.find(o => o.value === filter.value)
    : undefined;

  function onChange(option: { label: string; value: string } | null): void {
    filter.setValue(option?.value || null);
  }

  return (
    <div key={filter.code}>
      <SelectBox
        key={`${filter.code}`}
        id={filter.code}
        options={options}
        label={intl.formatMessage({ id: filter.title })}
        onChange={onChange}
        value={value}
        placeholder={intl.formatMessage({
          id: 'reporting.filter.chooseValue',
        })}
        noError
        disablePortal
        disabled={disabled}
      />
    </div>
  );
}

function getWeekSelector(filter: ReportingPageFilterStore): JSX.Element {
  function onChange(week: string): void {
    filter.setValue(getWeekStart(week).format(ISO_DATE_FORMAT));
  }
  return <WeekSelector key={filter.code} onChange={onChange} />;
}

function getDatepicker(
  filter: ReportingPageFilterStore,
  selectRange: SelectRange,
  intl: IntlShape,
  disabled?: boolean
): JSX.Element {
  const datePattern = extractDateFormat(intl);
  function onChange(value: string): void {
    if (value.length === datePattern.length) {
      const date = moment(value, datePattern);
      if (date.isValid()) {
        filter.setValue(date.format(ISO_DATE_FORMAT));
      }
    } else if (!value) {
      filter.setValue(null);
    }
  }
  return (
    <div key={filter.code}>
      <PatternInputDate
        key={filter.code}
        pattern={datePattern}
        id={filter.code}
        selectRange={filter.type === 'datepicker-week' ? 'week' : 'day'}
        onChange={onChange}
        validateValue={createDateFormatValidator(datePattern)}
        defaultValue={
          filter.value ? moment(filter.value).format(datePattern) : undefined
        }
        label={intl.formatMessage({ id: filter.title })}
        disabled={disabled}
        noError
      />
    </div>
  );
}

function getFilters(
  filters: ReportingPageFilterStore[],
  intl: IntlShape,
  position: 'page' | 'sidebar',
  disabled?: boolean
): JSX.Element[] {
  return filters
    .filter(filter => filter.position === position)
    .map(filter => {
      switch (filter.type.toLowerCase()) {
        case 'week':
          return getWeekSelector(filter);
        case 'datepicker-day':
          return getDatepicker(filter, 'day', intl, disabled);
        case 'datepicker-week':
          return getDatepicker(filter, 'week', intl, disabled);
        default:
          return getDropdown(filter, intl, disabled);
      }
    });
}

export interface FiltersProps {
  position: 'page' | 'sidebar';
}

const PageFilters = styled.div<{ filtersCount: number }>`
  display: flex;
  justify-content: ${({ filtersCount }) =>
    filtersCount ? 'space-between' : 'flex-end'};
`;

export const Filters = observer(function Filters(
  props: FiltersProps
): JSX.Element | null {
  const { position } = props;
  const intl = useIntl();
  const reportingStore = useReportingStore();
  const filters = reportingStore.dataStore?.filters || [];
  const disabled = reportingStore.dataStore?.status !== AsyncStatus.resolved;
  const filterConfigs = getFilters(filters, intl, position, disabled);

  return filterConfigs.length || position === 'page' ? (
    position === 'sidebar' ? (
      <ReportingFilters
        headline={intl.formatMessage({ id: 'analytics.filters' })}
        filters={filterConfigs}
      />
    ) : (
      <PageFilters filtersCount={filterConfigs.length}>
        {filterConfigs}
        <ReportingPageActions>
          <Status />
          <SubscribeButton />
          <ExportReportingPageButton />
        </ReportingPageActions>
      </PageFilters>
    )
  ) : null;
});
