import { PlannerEventStore } from './mobx/planner-event-store';
import { TrainingEvent } from '../#-components/planner/training-event';
import { usePlannerStore } from './planner-store-context';

import { observer } from 'mobx-react-lite';
import { Placement } from '@floating-ui/react';
import { PlannerEventPreview } from './planner-event-preview';
import { useEditEventHandler } from './hooks/use-edit-event-handler';
import { useWindowSize } from '../utils/use-window-size';
import { useEffect } from 'react';

interface PlannerEventProps {
  readonly event: PlannerEventStore;
}
export const PlannerEvent = observer(function PlannerEvent({
  event,
}: PlannerEventProps): JSX.Element | null {
  const plannerStore = usePlannerStore();
  const editEventHandler = useEditEventHandler(event);
  const { width: windowWidth = 0 } = useWindowSize();
  const scrollIntoView = Boolean(
    plannerStore.scrollToEvent && plannerStore.scrollToEvent === event.id
  );

  useEffect(() => {
    if (scrollIntoView) {
      plannerStore.scrollToEvent = null;
    }
  }, [scrollIntoView, plannerStore]);

  if (!event.startDate || !event.endDate) {
    return null;
  }

  return (
    <>
      <TrainingEvent
        key={event.uid ?? event.id}
        start={event.startDate}
        end={event.endDate}
        name={event.title}
        conflictingEvents={event.layout.conflictingEvents}
        conflictOffset={event.layout.offset}
        color={plannerStore.getEventTypeColor(event.eventTypeId)}
        getPreviewContent={(placement: Placement) => (
          <PlannerEventPreview event={event} placement={placement} />
        )}
        onClick={windowWidth < 768 ? editEventHandler : undefined}
        scrollIntoView={scrollIntoView}
      />
    </>
  );
});
