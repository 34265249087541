import { userCookies } from '../users/types';

declare global {
  interface Window {
    ytdDisabledInstances?: string[];
  }
}

const isBrowser = typeof window !== 'undefined';

const defaultInstance = 'test';
const instanceKey = 'yarmill-use-instance';
const targetKey = 'yarmill-use-target';
const coreTargetKey = 'yarmill-use-core-target';
const hostname = isBrowser ? window.location.hostname : '';
const defaultInstances = ['localhost', 'netlify.com', 'netlify.app', '192.168'];
export const disabledInstances: string[] =
  isBrowser && Array.isArray(window.ytdDisabledInstances)
    ? window.ytdDisabledInstances
    : [];

let instance = defaultInstances.find(i => hostname.includes(i))
  ? defaultInstance
  : hostname.replace('.yarmill.com', '').replace('.krutibrko.com', '');
let devMode = false;

const storageInstance = isBrowser
  ? window.localStorage.getItem(instanceKey)
  : null;

const impersonatedUser = isBrowser
  ? window.sessionStorage.getItem(userCookies.IMPERSONATED_USER)
  : false;

if (storageInstance) {
  instance = storageInstance;
  devMode = true;
}

instance = instance.replace('www.', '');

let apiTarget = `https://${instance}.api.yarmill.com`;
let coreApiTarget = process.env.REACT_APP_API_ENV_URL ?? '';

if (hostname.includes('krutibrko.com')) {
  apiTarget = `https://${instance}.api.krutibrko.com`;
  coreApiTarget = coreApiTarget.replace('.yarmill.', '.krutibrko.');
}

if (devMode) {
  coreApiTarget = coreApiTarget.replace('.krutibrko.', '.yarmill.');
}

if (hostname.includes('192.168')) {
  devMode = true;
}

const target = isBrowser ? window.localStorage.getItem(targetKey) : null;
const coreTarget = isBrowser
  ? window.localStorage.getItem(coreTargetKey)
  : null;

if (target) {
  apiTarget = target;
}

if (coreTarget) {
  coreApiTarget = coreTarget;
}

if (process.env.NODE_ENV === 'test') {
  apiTarget = 'https://api.yarmill.testmock';
}

export { apiTarget, coreApiTarget, instance, devMode, impersonatedUser };
