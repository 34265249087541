import { ExternalIcon } from '@yarmill/components';
import { FileType } from '../fileupload/types';

export interface FileIconProps {
  fileType: FileType;
}

export function FileIcon(props: FileIconProps): JSX.Element {
  switch (props.fileType) {
    case FileType.video: {
      return <ExternalIcon name="BrandYoutube" />;
    }
    case FileType.picture: {
      return <ExternalIcon name="Photo" />;
    }
    default:
      return <ExternalIcon name="FileDescription" />;
  }
}
