import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize, useTheme } from '@yarmill/components';
import { Sidemenu } from '@yarmill/icons-2';
import { useIntl } from 'react-intl';
import { Tippy } from '../../components/tippy/tippy';
import { useSidebarStore } from '../../sidebar/hooks/use-sidebar-store';
import { observer } from 'mobx-react-lite';

export const SideMenuToggle = observer(function SideMenuToggle(): JSX.Element {
  const sidebarStore = useSidebarStore();
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Tippy
      tooltipContent={
        sidebarStore.isVisible
          ? 'sidebar.button.hideSidebar'
          : 'sidebar.button.showSidebar'
      }
      noWrapper
      touch={false}
    >
      <Button
        $appearance={ButtonAppearance.Secondary}
        onClick={sidebarStore.toggle}
        $appearanceStyle={theme.dark ? 'white' : 'neutral'}
        $iconOnly
        aria-label={intl.formatMessage({
          id: sidebarStore.isVisible
            ? 'sidebar.button.hideSidebar'
            : 'sidebar.button.showSidebar',
        })}
      >
        <Icon size={IconSize.s24}>
          <Sidemenu />
        </Icon>
      </Button>
    </Tippy>
  );
});
