import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';

export interface AcceptAgreementRequestParams {
  NewsletterConsent?: boolean;
  CommercialConsent?: boolean;
}

export function acceptAgreement(
  params: AcceptAgreementRequestParams
): AxiosPromise<boolean> {
  return axios.post('api/User/accept-agreement', params);
}
