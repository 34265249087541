import { useScale } from './use-scale';
import { useMemo } from 'react';
import {
  AxisValue,
  DataItem,
  XAxisConfig,
  YAxisConfig,
} from '../../reporting/types';
import { AXIS_TICK_LABEL_OFFSET } from '../../reporting/const';
import { getSSRStringWidth } from '../../reporting/utils/get-ssr-string-width';
import { ChartConfig } from '@yarmill/components';

export function useCalculateLeftPadding(
  yScale: ReturnType<typeof useScale>,
  xScale: ReturnType<typeof useScale>,
  yAxisConfig: YAxisConfig<AxisValue> | undefined,
  xAxisConfig: XAxisConfig<AxisValue> | undefined,
  markerPadding: number,
  chartConfigs: ChartConfig[],
  data: DataItem[]
) {
  return useMemo(() => {
    const yTicks = 'ticks' in yScale ? yScale.ticks() : yScale.domain();
    const yTickWidth = yAxisConfig?.showTickLabels
      ? Math.max(
          ...yTicks.map(tick => {
            const value = yAxisConfig.formatTick(tick);
            return getSSRStringWidth(value) + AXIS_TICK_LABEL_OFFSET;
          })
        )
      : 0;

    const isContinuous = 'ticks' in xScale;
    const isHorizontal = 'bandwidth' in yScale;

    let xTickWidth = 0;
    if (xAxisConfig?.showTickLabels && isContinuous) {
      const xTicks = xScale.ticks();
      const firstTick = xTicks[0];
      const value = xAxisConfig.formatTick(firstTick);
      xTickWidth = getSSRStringWidth(value) / 2;
    }

    const labelWidth =
      isContinuous && !isHorizontal
        ? Math.max(
            ...chartConfigs.map(config => {
              if (
                !('getYValue' in config) ||
                config.type === 'HorizontalBarGroup' ||
                config.type === 'HorizontalBarStack'
              ) {
                return 0;
              }
              const firstItem = data.find(Boolean);
              if (!firstItem) {
                return 0;
              }
              if (!config.getShowLabels(firstItem)) {
                return 0;
              }
              const value = config.getYValue(firstItem);
              const label =
                value !== null ? config.formatLabelValue(value as number) : '';

              return getSSRStringWidth(label) / 2;
            })
          )
        : 0;

    const marker =
      isContinuous || markerPadding > xScale.bandwidth() ? markerPadding : 0;

    return Math.max(yTickWidth, xTickWidth, marker, labelWidth);
  }, [
    markerPadding,
    xAxisConfig,
    xScale,
    yAxisConfig,
    yScale,
    chartConfigs,
    data,
  ]);
}
