import { ReportItem, ReportItemStore } from '../types';
import { ClusteredColumnChartStore } from '../mobx/clustered-column-chart-store';
import { StackedColumnChartStore } from '../mobx/stacked-column-chart-store';
import { ColumnLineChartStore } from '../mobx/column-line-chart-store';
import { ContinuousChartStore } from '../mobx/continuous-chart-store';
import { KpiStore } from '../mobx/kpi-store';
import { OverviewTableStore } from '../mobx/overview-table-store';
import { TableChartStore } from '../mobx/table-chart-store';
import { VideoReportStore } from '../mobx/video-report-store';
import { RichtextReportStore } from '../mobx/richtext-report-store';
import { HamburgerReportStore } from '../mobx/hamburger-report-store';
import { RootStore } from '../../app/mobx/root-store';
import { EvidenceReportStore } from '../mobx/evidence-report-store';

export function createReportItemStore(
  item: ReportItem | null,
  pageCode: string,
  rootStore: RootStore
): ReportItemStore | null {
  switch (item?.Kind) {
    case 'ClusteredColumnChart':
    case 'HorizontalClusteredColumnChart':
      return new ClusteredColumnChartStore(item, pageCode);
    case 'StackedColumnChart':
    case 'HorizontalStackedColumnChart':
      return new StackedColumnChartStore(item, pageCode);
    case 'ColumnLineChart':
      return new ColumnLineChartStore(item, pageCode);
    case 'TimeChart':
    case 'ContinuousChart':
      return new ContinuousChartStore(item, pageCode);
    case 'Kpi':
      return new KpiStore(item);
    case 'OverviewTable':
      return new OverviewTableStore(item);
    case 'Table':
      return new TableChartStore(item, rootStore);
    case 'Video':
      return new VideoReportStore(item);
    case 'Richtext':
      return new RichtextReportStore(item);
    case 'Hamburger':
      return new HamburgerReportStore(item, pageCode);
    case 'Evidence':
      return new EvidenceReportStore(item);
    default:
      return null;
  }
}
