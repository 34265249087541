import styled, { css } from 'styled-components';
import { FormControlVariant } from '../helpers';

export const RichtextMenuBar = styled.div<{
  readonly isVisible: boolean;
  readonly variant?: FormControlVariant;
}>`
  max-width: 100%;
  overflow: hidden;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  height: ${({ isVisible }) => (isVisible ? '34px' : '0')};
  padding: ${({ isVisible }) => (isVisible ? '2px 4px' : 'unset')};
  transform: translateZ(0);
  background: #f8f8f8;
  border-top: ${({ isVisible }) => (isVisible ? '1px solid #e1e4e6' : 'none')};
  overflow: ${({ isVisible }) => (isVisible ? 'initial' : 'hidden')};
  transition:
    height 150ms,
    visibility 150ms;
  transition-timing-function: ease;
  border-radius: 0 0 4px 4px;
  z-index: 5;
  display: flex;
  align-items: flex-end;
  column-gap: 2px;
  pointer-events: none;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      & * {
        pointer-events: none;
      }
    `}

  & > :first-child {
    border-bottom-left-radius: 4px;
  }

  ${({ variant }) =>
    variant === FormControlVariant.big &&
    css`
      border-radius: 0 0 8px 8px;
      & > :first-child {
        border-bottom-left-radius: 8px;
      }
    `};
`;

export const RichtextStickyControls = styled.div<{ isMenuVisible: boolean }>`
  position: absolute;
  top: calc(100% - ${({ isMenuVisible }) => (isMenuVisible ? '30px' : '30px')});
  right: 0;
  background-color: ${({ isMenuVisible }) =>
    isMenuVisible ? 'transparent' : '#ffffff'};
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  transition: background-color 150ms;
  pointer-events: none;
`;
