import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';

export interface ChangePasswordRequestParams {
  OldPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
}

export interface ChangePasswordResponse {
  Message: string;
}

export function changePassword(
  params: ChangePasswordRequestParams
): AxiosPromise<ChangePasswordResponse> {
  return axios.post('api/Account/ChangePassword', params);
}
