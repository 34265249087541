import { RootStore } from '../../app/mobx/root-store';
import { Dashboard, DashboardType, Filter } from '../types';
import { getFilters } from '../api/getFilters';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  ObservableMap,
  reaction,
  runInAction,
} from 'mobx';
import { AsyncStatus } from '../../api/mobx/request-store';

export abstract class BaseDashboardStore {
  protected readonly _rootStore: RootStore;
  private readonly _type: DashboardType;
  private readonly _label: string;
  private readonly _description: string;

  @observable
  protected _status: AsyncStatus = AsyncStatus.idle;
  @observable
  protected readonly _filters: Filter[] = [];
  @observable
  protected readonly _filterValues: Map<string, string> = new Map();

  constructor(rootStore: RootStore, dashboard: Dashboard) {
    this._rootStore = rootStore;
    this._type = dashboard.Ident;
    this._label = dashboard.Label;
    this._description = dashboard.Description;
    makeObservable(this);

    reaction(
      () => this.apiFilters,
      () => this.loadFilters()
    );
  }

  public get id(): DashboardType {
    return this._type;
  }

  public get label(): string {
    return this._label;
  }

  public get description(): string {
    return this._description;
  }

  public get filters(): Filter[] {
    return this._filters;
  }

  public get filterValues(): Map<string, string | number | undefined> {
    return this._filterValues;
  }

  public get status(): AsyncStatus {
    return this._status;
  }

  @computed
  public get apiFilters(): Record<string, string> {
    return Object.fromEntries(this._filterValues.entries());
  }

  @action
  public setFilters(filter: Record<string, string>): void {
    this._filterValues.clear();
    Object.entries(filter).forEach(([key, value]) =>
      (this._filterValues as ObservableMap).set(key, value)
    );
  }

  public async loadFilters(): Promise<void> {
    this._status = AsyncStatus.pending;
    const request = this._rootStore.requestsStore.createRequest(() =>
      getFilters(this.id, Object.fromEntries(this._filterValues.entries()))
    );
    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        (this._filters as IObservableArray<Filter>).replace(response);
        this._filters.forEach(filter => {
          this._filterValues.set(filter.Ident, filter.Value);
        });
      });

      await this.loadData();
    }
  }

  public abstract loadData(): Promise<void>;
}
