import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { Cancel, Check } from '@yarmill/icons-2';
import { UpdateStatus } from '../components-2/update-status';
import { Loader } from '../components-2/loader';
import { useEffect, useState } from 'react';
import { usePlannerStore } from './planner-store-context';
import { AsyncStatus } from '../api/mobx/request-store';

export const SavingStatus = observer(
  function SavingStatus(): JSX.Element | null {
    const intl = useIntl();
    const plannerStore = usePlannerStore();
    const [hasChange, setHasChange] = useState(false);
    const [showGreen, setShowGreen] = useState(false);

    useEffect(() => {
      if (
        plannerStore.groupId ||
        plannerStore.athleteId ||
        plannerStore.currentSeason
      ) {
        setHasChange(false);
      }
    }, [
      plannerStore.groupId,
      plannerStore.athleteId,
      plannerStore.currentSeason,
    ]);

    useEffect(() => {
      if (plannerStore.savingStatus !== AsyncStatus.resolved && !hasChange) {
        setHasChange(true);
      }
    }, [plannerStore.savingStatus, hasChange]);

    useEffect(() => {
      if (plannerStore.savingStatus === AsyncStatus.resolved) {
        setShowGreen(true);
        setTimeout(() => {
          setShowGreen(false);
        }, 5000);
      }
    }, [plannerStore.savingStatus]);

    if (plannerStore.savingStatus === AsyncStatus.rejected) {
      return (
        <UpdateStatus
          message={intl.formatMessage({ id: 'okrs.saveFailed' })}
          appearance="red"
          icon={<Cancel />}
        />
      );
    }

    if (plannerStore.savingStatus === AsyncStatus.pending) {
      return (
        <UpdateStatus
          message={intl.formatMessage({ id: 'okrs.savingNow' })}
          appearance="green"
          icon={<Loader appearance="green" />}
        />
      );
    }

    if (plannerStore.savingStatus === AsyncStatus.resolved && hasChange) {
      return (
        <UpdateStatus
          message={intl.formatMessage({ id: 'okrs.saved' })}
          appearance={showGreen ? 'green' : 'neutral'}
          icon={<Check />}
        />
      );
    }

    return null;
  }
);
