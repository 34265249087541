import { ServerError } from '../app/app-types';

export class ServerErrorResponse extends Error {
  private _error: ServerError;
  constructor(msg: string, error: ServerError) {
    super(msg);
    this._error = error;
    Object.setPrototypeOf(this, ServerErrorResponse.prototype);
  }

  public get id(): string {
    return this._error.Id;
  }

  public get field(): string | undefined {
    return this._error.Field;
  }

  public get values(): Record<string, string | number> | undefined {
    return this._error.Values;
  }
}

// Type guard function
export function isServerError(obj: unknown): obj is ServerError {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const serverError = obj as ServerError;

  return (
    typeof serverError.Id === 'string' &&
    (serverError.Field === undefined ||
      typeof serverError.Field === 'string') &&
    (serverError.Values === undefined || typeof serverError.Values === 'object')
  );
}
