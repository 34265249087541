import {
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ConfigStore } from '../config/config-store';
import { useRootStore } from './root-store-context';
import { getDefaultMainRoute } from './utils';

type Handler = (e?: SyntheticEvent) => void;

export function useCollapsible<T extends HTMLElement>(
  defaultVisible: boolean = false
): [boolean, Handler, Handler, RefObject<T>] {
  const [visible, setVisible] = useState(defaultVisible);
  const ref = useRef<T>(null);

  const show = useCallback((e?: SyntheticEvent): void => {
    if (e) {
      e.preventDefault();
    }
    setVisible(true);
  }, []);

  const hide = useCallback((e?: SyntheticEvent): void => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setVisible(false);
  }, []);

  const closeOnClickOutside = useCallback(
    (e: MouseEvent) => {
      if (ref.current && e.target instanceof Node) {
        if (!ref.current.contains(e.target)) {
          hide();
        }
      }
    },
    [hide]
  );

  useEffect(() => {
    if (visible) {
      window.addEventListener('click', closeOnClickOutside);
    }

    return () => {
      window.removeEventListener('click', closeOnClickOutside);
    };
  }, [visible, closeOnClickOutside]);

  return [visible, show, hide, ref];
}

export function useDefaultMainRoute(): string {
  const rootStore = useRootStore();
  return getDefaultMainRoute(rootStore);
}

type ConfigKey = keyof ConfigStore;
export function useConfig<P extends ConfigKey>(configKey: P): ConfigStore[P] {
  return useRootStore().configStore[configKey];
}
