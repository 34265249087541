import { ActivityTitle } from '../activities/activity-title';
import { ActivityItem } from '../activities/types';
import styled from 'styled-components';
import {
  ExpandButton,
  ExpandableButtonProps,
} from '../csv-table/expand-button';
import { ExpandableTree } from '../csv-table/mobx/expandable-tree';
import { ComponentType } from 'react';

export interface SingleSeasonEvaluationFirstColumnCellProps {
  activity: ActivityItem;
  expandable: ExpandableTree<ActivityItem> | null;
}

const StyledSingleSeasonEvaluationFirstColumnCell = styled.div<{
  isExpandable: boolean;
}>`
  position: sticky;
  padding-left: 26px;
  display: flex;
  align-items: center;
  left: 0;
  ${props => props.isExpandable && `cursor: pointer`};
  background-color: #ffffff;
  z-index: 2;
`;

const StyledExpandButton = styled<
  ComponentType<ExpandableButtonProps<ActivityItem>>
>(ExpandButton)`
  left: 3px;
  height: 26px;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function SingleSeasonEvaluationFirstColumnCell(
  props: SingleSeasonEvaluationFirstColumnCellProps
): JSX.Element {
  const { activity, expandable } = props;
  const isExpandable = Boolean(
    expandable && expandable.isNodeExpandable(activity.ActivityItemId)
  );

  return (
    <StyledSingleSeasonEvaluationFirstColumnCell isExpandable={isExpandable}>
      <ActivityTitle
        activityItem={activity}
        onClick={
          expandable?.isNodeExpandable(activity.ActivityItemId)
            ? () =>
                expandable.isNodeExpanded(activity.ActivityItemId)
                  ? expandable?.collapseNode(activity.ActivityItemId)
                  : expandable?.expandNode(activity.ActivityItemId)
            : undefined
        }
      />
      {isExpandable && (
        <StyledExpandButton
          id={activity.ActivityItemId}
          expandable={expandable!}
        />
      )}
    </StyledSingleSeasonEvaluationFirstColumnCell>
  );
}
