import { memo } from 'react';
import {
  SeasonEvaluationStoreContextProvider,
  useSeasonEvaluationStore,
} from './season-evaluation-store-context';
import { observer } from 'mobx-react-lite';
import { Sidebar } from '../athletes-sidebar/sidebar';
import { Page } from '../page/page';
import { useCurrentUserStore } from '../users/hooks';
import { PageContent } from './page-content';
import { PageMainContent } from '@yarmill/components';
import { useRootStore } from '../app/root-store-context';
import { Redirect } from 'react-router-dom';

function InternalSeasonEvaluationRouteRoot(): JSX.Element {
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();
  const seasonEvaluationStore = useSeasonEvaluationStore();

  if (
    rootStore.isReady &&
    !currentUser.isAthlete &&
    !seasonEvaluationStore.groupId &&
    !seasonEvaluationStore.athleteId
  ) {
    return <Redirect to="/noGroup" />;
  }

  return (
    <Page>
      {currentUser.role !== 'athlete' ? (
        <Sidebar
          disableWholeGroup={
            seasonEvaluationStore.viewType === 'season-over-season'
          }
          disableWholeGroupTooltip="sidebar.seasonEvaluation.disableWholeGroupForSos"
        />
      ) : undefined}
      <PageMainContent>
        <PageContent />
      </PageMainContent>
    </Page>
  );
}

const ObserverSeasonEvaluationRouteRoot = observer(
  InternalSeasonEvaluationRouteRoot
);

export const SeasonEvaluationRouteRoot = memo(() => (
  <SeasonEvaluationStoreContextProvider>
    <ObserverSeasonEvaluationRouteRoot />
  </SeasonEvaluationStoreContextProvider>
));
