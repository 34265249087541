import {
  StyledWeekViewRow,
  TrainingDaysListScrollWrapper,
} from '@yarmill/components';
import { TrainingWeek } from '../training-day/training-week';

import { ActivitiesList } from '../activities/activities-list';
import { WeekViewLoadingPlaceholder } from '../activities/week-view-loading-placeholder';
import { useRootStore } from '../app/root-store-context';
import { AsyncStatus } from '../api/mobx/request-store';
import { useFocusedDayScroller } from './hooks';
import { WeekHeaderCell } from './week-header-cell';
import { useCurrentUserStore } from '../users/hooks';
import { observer } from 'mobx-react-lite';
import { useDiaryStore } from './diary-store-context';

export const WeekView = observer(function WeekView(): JSX.Element {
  const rootStore = useRootStore();
  const scrollWrapperRef = useFocusedDayScroller();
  const currentUser = useCurrentUserStore();
  const diaryStore = useDiaryStore();
  const diaryType = diaryStore.diaryType as 'plan' | 'reality';

  return (
    <StyledWeekViewRow>
      <TrainingDaysListScrollWrapper ref={scrollWrapperRef}>
        {currentUser.isAllowedTo(`${diaryType}.week.attributes`) && (
          <TrainingWeek />
        )}
      </TrainingDaysListScrollWrapper>

      {currentUser.isAllowedTo(`${diaryType}.week.activities`) && (
        <>
          {rootStore.status === AsyncStatus.resolved ? (
            <ActivitiesList
              columnsCount={7}
              HeaderCellContent={WeekHeaderCell}
            />
          ) : (
            <WeekViewLoadingPlaceholder />
          )}
        </>
      )}
    </StyledWeekViewRow>
  );
});
