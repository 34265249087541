import moment from 'moment';
import * as React from 'react';
import { FormattedDate } from 'react-intl';
import styled, { css } from 'styled-components';

export interface SelectSourceDayProps {
  weekDays: string[];
  selectedDays: string[];
  onChange(selectedDays: string[]): void;
}

const StyledDate = styled.div`
  white-space: nowrap;
  position: relative;
`;

const SourceDaySelector = styled.div`
  display: flex;
  padding-bottom: 16px;
`;

const Day = styled.div`
  width: calc(100% / 7);
`;

const DayCheckbox = styled.input`
  width: 0;
  height: 0;
  border: none;
  background: transparent;
  display: none;
`;

const StyledLabel = styled.label<{
  isFirst: boolean;
  isLast: boolean;
  checked: boolean;
}>`
  margin: 0;
  position: relative;
  width: 100%;
  cursor: pointer;
  display: block;
  text-align: center;
  border: 1px solid #f2f4f8;
  border-left: none;
  padding: 5px;
  font-family: Ubuntu;
  font-size: 14px;
  line-height: 21px;
  color: #4a4a4a;

  ${({ checked }) =>
    checked &&
    css`
      color: #4a90e2;
      font-weight: 700;
      &:after {
        position: absolute;
        z-index: 0;
        top: calc(100% + 10px);
        left: 50%;
        margin-left: -4px;
        content: ' ';
        border: none;
        width: 8px;
        height: 8px;
        display: block;
        background: #4a90e2;
        border-radius: 100%;
      }
    `}

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-left: 1px solid #f2f4f8;
    `}

  ${({ isLast }) =>
    isLast &&
    css`
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    `}
`;

export function SelectSourceDay(props: SelectSourceDayProps): JSX.Element {
  const { selectedDays, weekDays, onChange } = props;

  function onSelectDay(e: React.FormEvent<HTMLInputElement>): void {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      const { name } = target;
      const newDays = [...selectedDays];
      const idx = newDays.indexOf(name);
      if (idx === -1) {
        newDays.push(name);
      } else {
        newDays.splice(idx, 1);
      }

      onChange(newDays);
    }
  }

  return (
    <SourceDaySelector>
      {weekDays.map((day, idx) => (
        <Day className="day" key={day} data-cy="source-day">
          <DayCheckbox
            checked={selectedDays.indexOf(day) !== -1}
            type="checkbox"
            className="calendar-source"
            id={`copy-to-${day}`}
            name={day}
            onChange={onSelectDay}
          />
          <StyledLabel
            htmlFor={`copy-to-${day}`}
            isFirst={idx === 0}
            isLast={idx === 6}
            checked={selectedDays.indexOf(day) !== -1}
          >
            <FormattedDate value={moment(day).toDate()} weekday="short" />
            &nbsp;
            <FormattedDate
              value={moment(day).toDate()}
              day="numeric"
              month="numeric"
            >
              {(date: string) => <StyledDate>{date}</StyledDate>}
            </FormattedDate>
          </StyledLabel>
        </Day>
      ))}
    </SourceDaySelector>
  );
}
