import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { usePlannerStore } from '../planner-store-context';
import { ZoomIn } from '@yarmill/icons-2';

import { MAX_PLANNER_ZOOM_LEVEL } from '../../#-components/planner/utils';
import { Tippy } from '../../components/tippy/tippy';
import { useIntl } from 'react-intl';
export function IncreaseZoomLevel(): JSX.Element {
  const plannerStore = usePlannerStore();
  const intl = useIntl();
  const isEnabled = plannerStore.zoomLevel < MAX_PLANNER_ZOOM_LEVEL;

  return (
    <Tippy tooltipContent="planner.zoomIn" noWrapper touch={false}>
      <Button
        $appearance={ButtonAppearance.Secondary}
        onClick={isEnabled ? () => plannerStore.increaseZoomLevel() : undefined}
        $appearanceStyle="neutral"
        $iconOnly
        disabled={!isEnabled}
        type="button"
        aria-label={intl.formatMessage({ id: 'planner.zoomIn' })}
      >
        <Icon size={IconSize.s24}>
          <ZoomIn />
        </Icon>
      </Button>
    </Tippy>
  );
}
