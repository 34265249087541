import { WeekViewLoadingPlaceholder } from '../activities/week-view-loading-placeholder';
import {
  PageMainContent,
  StyledWeekViewRow,
  TrainingDaysListScrollWrapper,
} from '@yarmill/components';
import { Page } from '../page/page';
import { Sidebar } from '../athletes-sidebar/sidebar';
import { TrainingWeekLoadingPlaceholder } from '../training-day/training-week-loading-placeholder';

export const BaseLoadingScreen = () => (
  <Page>
    <Sidebar />
    <PageMainContent>
      <StyledWeekViewRow noNavbar>
        <TrainingDaysListScrollWrapper>
          <TrainingWeekLoadingPlaceholder />
        </TrainingDaysListScrollWrapper>
        <WeekViewLoadingPlaceholder />
      </StyledWeekViewRow>
    </PageMainContent>
  </Page>
);
