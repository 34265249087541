import { HrZone, ZoneData } from '../workouts/types';
import { UserId } from '../users/types';
import { BaseWorkoutData } from '@yarmill/components';

export const CGM_URL_PARAM = 'measurementDay';
export interface Cgm {
  readonly SelectedDay: string;
  readonly StartTime: string;
  readonly EndTime: string;
  readonly LastUpdated: string;
  readonly EgvMin: number;
  readonly EgvMax: number;
  readonly EgvAvg: number;
  readonly ZonesDefinition: HrZone[];
  readonly TotalZoneTimes: ZoneData[];
  readonly CgmEvolution: number[];
  readonly TimeEvolution: number[];
}

export type CgmChartsData = BaseWorkoutData;

export interface CgmId {
  userId: UserId;
  selectedDay: string;
}
