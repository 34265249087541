import { observer } from 'mobx-react-lite';
import { GoalCycleLabel } from './goal-cycle-label';
import { GoalAttributeStore } from './mobx/goal-attribute-store';
import { romanize } from '../utils/romanize';
import { SeasonGoalDayLabel } from './season-goal-day-label';
import { SeasonGoalLabelWrapper } from '@yarmill/components';

export interface SeasonGoalLabelProps {
  attribute: GoalAttributeStore;
  cycleNumber: number;
  cycle?: boolean;
  week?: boolean;
  highlighted?: boolean;
}

function InternalSeasonGoalLabel(props: SeasonGoalLabelProps): JSX.Element {
  const { attribute, week, cycle, cycleNumber, highlighted } = props;

  return (
    <SeasonGoalLabelWrapper>
      {(cycle || week) && (
        <GoalCycleLabel
          attribute={attribute}
          cycleNumber={cycle ? romanize(cycleNumber) : cycleNumber}
          highlighted={highlighted}
        />
      )}
      {!cycle && !week && (
        <SeasonGoalDayLabel attribute={attribute} highlighted={highlighted} />
      )}
    </SeasonGoalLabelWrapper>
  );
}

export const SeasonGoalLabel = observer(InternalSeasonGoalLabel);
