import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  trackHistoricalAthletesClick,
  trackSidebarLinkClick,
} from '../google-analytics/utils';
import { useCollapsible } from '../app/hooks';
import { AsyncState, useAsyncState } from '../utils/use-async-state';
import { useIsMobile } from '../utils/use-is-mobile';
import {
  Button,
  ButtonAppearance,
  HistoricalAthletesListLayout,
  SidebarList,
} from '@yarmill/components';
import { GroupStore } from '../groups/mobx/group-store';
import { observer } from 'mobx-react-lite';
import { UserStore } from '../users/mobx/user-store';
import { AthleteLink } from './athlete-link';

export interface HistoricalAthletesListProps {
  group: GroupStore;
  selectedAthlete?: UserStore | null;
  toggleDropdown(): void;
}

export const HistoricalAthletesList = observer(function HistoricalAthletesList(
  props: HistoricalAthletesListProps
): JSX.Element {
  const { group, selectedAthlete, toggleDropdown } = props;
  const [opened, open, close] = useCollapsible();
  const [state, setState] = useAsyncState();
  const athletes = group.historicalAthletes;
  const isMobile = useIsMobile();

  const toggleHistoricalAthletes = useCallback(async (): Promise<void> => {
    trackHistoricalAthletesClick(opened ? 'hide' : 'show');

    if (state === AsyncState.idle) {
      setState(AsyncState.pending);
      const success = await group.loadHistoricalAthletes();
      if (success) {
        setState(AsyncState.resolved);
      } else {
        setState(AsyncState.rejected);
      }
    }
    if (opened) {
      close();
    } else {
      open();
    }
  }, [opened, state, setState, group, close, open]);

  const onListItemClick = useCallback(
    (athlete: string): void => {
      if (isMobile) {
        toggleDropdown();
      }
      trackSidebarLinkClick(athlete);
    },
    [isMobile, toggleDropdown]
  );

  return (
    <HistoricalAthletesListLayout>
      <Button
        appearance={ButtonAppearance.Dark}
        onClick={toggleHistoricalAthletes}
        disabled={!group || group.group.HistoricalAthletesCount === 0}
      >
        <FormattedMessage
          id="sidebar.athletes.historicalAthletes"
          values={{
            count: athletes?.length || group.group.HistoricalAthletesCount || 0,
          }}
        />
      </Button>

      <SidebarList>
        {opened &&
          state === AsyncState.resolved &&
          athletes?.map(athlete => (
            <AthleteLink
              key={athlete.id}
              onClick={onListItemClick}
              athlete={athlete}
              isActive={athlete === selectedAthlete}
            />
          ))}
      </SidebarList>
    </HistoricalAthletesListLayout>
  );
});
