import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tippy } from '../../../components/tippy/tippy';
import { MainTable } from './main-table';
import { StressCompare } from './stress-compare';
import { Trends } from './trends';
import {
  FactType,
  Indicator,
  IndicatorType,
  StressCompare as iStressCompare,
  Trend,
} from './types';
import { getIndicatorTypesByFactType } from './utils';
import { useAnalyticsRouteParams } from '../../analytics-route-params-context';
import { useLegacyAnalyticsStore } from '../../hooks';
import { useSeasonByYear } from '../../../seasons/hooks';
import { observer } from 'mobx-react-lite';
import { SlcrDashboardStore } from '../../mobx/slcr-dashboard-store';
import { AsyncStatus } from '../../../api/mobx/request-store';
import {
  AnalyticsReportsContainer,
  ReportContainer,
  ReportingSection,
  Text,
  TextAlignment,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { ReportContentBox } from '../../../reporting/report-content-box';

function getIndicatorTypesByFilters(
  filterValues: Map<string, string | number | undefined>
): IndicatorType[] {
  return getIndicatorTypesByFactType(
    filterValues.get('FactType') as FactType | undefined
  );
}

export const SlcrAnalyticsDashboards = observer(
  function SlcrAnalyticsDashboards(): JSX.Element {
    const { activeDashboard } = useAnalyticsRouteParams();
    const dashboard = useLegacyAnalyticsStore().getDashboard(activeDashboard) as
      | SlcrDashboardStore
      | undefined;

    const season = useSeasonByYear(
      Number(dashboard?.filterValues.get('SeasonYear'))
    );
    const seasonMonths = season?.seasonSections || [];
    const { nonCyclicDimensions, cyclicDimensions } =
      dashboard?.verticalDimensions ?? {};

    useEffect(() => {
      dashboard?.loadVerticalDimensions();
    }, [dashboard]);

    const emptyData =
      Object.keys(dashboard?.facts ?? {}).length === 0 &&
      Object.keys(dashboard?.indicators ?? {}).length === 0 &&
      dashboard?.trends.length === 0 &&
      dashboard?.stressCompare.length === 0;

    return (
      <AnalyticsReportsContainer>
        {emptyData || dashboard?.status !== AsyncStatus.resolved ? (
          <Text
            tag={TextTag.div}
            size={TextSize.s20}
            medium
            textAlign={TextAlignment.center}
          >
            {dashboard?.status === AsyncStatus.pending && (
              <FormattedMessage id="analytics.slcr.loading" />
            )}
            {dashboard?.status === AsyncStatus.rejected && (
              <FormattedMessage id="analytics.slcr.loadingError" />
            )}
            {emptyData && <FormattedMessage id="analytics.slcr.noData" />}
          </Text>
        ) : (
          <>
            {nonCyclicDimensions && nonCyclicDimensions.length > 0 && (
              <ReportingSection layoutConfig="table">
                <ReportContainer sectionArea="table">
                  <MainTable
                    facts={dashboard.facts}
                    indicators={
                      dashboard.indicators as Record<string, Indicator[]>
                    }
                    indicatorTypes={getIndicatorTypesByFilters(
                      dashboard.filterValues
                    )}
                    seasonMonths={seasonMonths}
                    verticalDimensions={nonCyclicDimensions}
                  />
                </ReportContainer>
              </ReportingSection>
            )}
            {cyclicDimensions && cyclicDimensions.length > 0 && (
              <ReportingSection
                layoutConfig="table"
                title={
                  <Tippy tooltipContent="analytics.slcr.label.cyclicHz">
                    <Text tag={TextTag.h2} medium>
                      <FormattedMessage id="analytics.slcr.cyclicExUnits" />
                    </Text>
                  </Tippy>
                }
              >
                <ReportContainer sectionArea="table">
                  <MainTable
                    facts={dashboard.facts}
                    indicators={
                      dashboard.indicators as Record<string, Indicator[]>
                    }
                    indicatorTypes={getIndicatorTypesByFilters(
                      dashboard.filterValues
                    )}
                    seasonMonths={seasonMonths}
                    verticalDimensions={cyclicDimensions}
                  />
                </ReportContainer>
              </ReportingSection>
            )}
            <ReportingSection
              layoutConfig="trends"
              title={
                <Tippy tooltipContent="analytics.slcr.label.summaryHzTrend">
                  <Text tag={TextTag.h2} medium>
                    <FormattedMessage id="analytics.slcr.trends" />
                  </Text>
                </Tippy>
              }
            >
              <ReportContainer sectionArea="trends">
                <ReportContentBox>
                  <Trends
                    trends={dashboard.trends as Trend[]}
                    seasonMonths={seasonMonths}
                  />
                </ReportContentBox>
              </ReportContainer>
            </ReportingSection>
            <ReportingSection
              layoutConfig="trends"
              title={
                <Tippy tooltipContent="analytics.slcr.label.intensityCyclicHz">
                  <Text tag={TextTag.h2} medium>
                    <FormattedMessage id="analytics.slcr.stressCompare" />
                  </Text>
                </Tippy>
              }
            >
              <ReportContainer sectionArea="trends">
                <ReportContentBox>
                  <StressCompare
                    stressCompare={dashboard.stressCompare as iStressCompare[]}
                    seasonMonths={seasonMonths}
                  />
                </ReportContentBox>
              </ReportContainer>
            </ReportingSection>
          </>
        )}
      </AnalyticsReportsContainer>
    );
  }
);
