import { DataLabelDefinition, LineChart, MultiLineChart } from '../types';
import { DataItem } from '@yarmill/components';
import { useScale } from '../hooks/use-scale';
import { DEFAULT_MARKER_SIZE, LABEL_OFFSET } from '../../reporting/const';

export function getLineLabel(
  item: DataItem,
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>,
  chartConfig: LineChart | MultiLineChart,
  priority: number
): DataLabelDefinition | undefined {
  if (!chartConfig.getShowLabels(item)) {
    return undefined;
  }

  const xValue = chartConfig.getXValue(item);
  const yValue = chartConfig.getYValue(item);
  const offset = 'bandwidth' in xScale ? xScale.bandwidth() / 2 : 0;

  const x = (xScale(xValue as any) ?? 0) + offset;
  const y = yScale(yValue as any) ?? 0;
  const formattedValue =
    yValue !== null ? (chartConfig.formatLabelValue?.(yValue) ?? yValue) : null;
  const color = chartConfig.getLabelColor(item);
  const markerSize = chartConfig.getMarkerSize(item) ?? DEFAULT_MARKER_SIZE;

  return {
    x,
    y: y - LABEL_OFFSET - markerSize,
    originalValue: yValue,
    text: String(formattedValue),
    angle: 0,
    color,
    priority,
  };
}
