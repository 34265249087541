import { useXYChartContext } from './xy-chart-context';
import { CategoricalTooltip } from './tooltip/categorical-tooltip';
import { ContinuousTooltip } from './tooltip/continuous-tooltip';
import { useTooltipContext } from './hooks/use-tooltip';
import { ChartTooltip } from '../chart-tooltip';
import { CSSProperties } from 'react';
import { HorizontalCategoricalTooltip } from './tooltip/horizontal-categorical-tooltip';
import { TooltipValue } from '@yarmill/components';

const tooltipStyle: CSSProperties = {
  top: 0,
  left: 0,
  position: 'absolute',
  backgroundColor: 'transparent',
  pointerEvents: 'none',
};

interface TooltipProps {
  readonly hide?: boolean;
}

function filterByOriginalValue(values: TooltipValue[]) {
  return values.filter(row => row.originalValue !== null && !row.hidden);
}

export function Tooltip({ hide }: TooltipProps) {
  const { xScale, yScale } = useXYChartContext();
  const { TooltipInPortal, tooltipTop, tooltipLeft, tooltipOpen, tooltipData } =
    useTooltipContext();

  const isXCategorical = 'bandwidth' in xScale;
  const isYCategorical = 'bandwidth' in yScale;

  return (
    <>
      {isXCategorical && <CategoricalTooltip />}
      {isYCategorical && <HorizontalCategoricalTooltip />}
      {!isXCategorical && !isYCategorical && <ContinuousTooltip />}
      {!hide &&
        tooltipOpen &&
        tooltipData &&
        tooltipData.values.length > 0 &&
        (tooltipData.showLabel ||
          filterByOriginalValue(tooltipData.values).length) && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyle}
            detectBounds
          >
            <ChartTooltip
              key={tooltipData.key}
              label={tooltipData.showLabel ? tooltipData.label : ''}
              items={filterByOriginalValue(tooltipData.values)}
              tableLayout={tooltipData.tableLayout}
              showTableColumnsLabels={tooltipData.showColumnLabels}
              tableColumnsLabels={tooltipData.tableColumnsLabels}
            />
          </TooltipInPortal>
        )}
    </>
  );
}
