import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';

export interface ResendInvitationRequestParams {
  Email: string;
}

export function resendInvitation(
  params: ResendInvitationRequestParams
): AxiosPromise<string> {
  return axios.post('api/Account/Register/ResendEmail', params);
}
