import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useCallback } from 'react';
import { Button, ButtonAppearance } from '@yarmill/components';
import styled from 'styled-components';
import { ExpandableTree } from './mobx/expandable-tree';

const StyledExpandButton = styled(Button)`
  height: 32px;
  min-height: 32px;
  width: 26px;
  padding: 4px;
  position: absolute;
  left: -22px;
  top: 50%;
  transform: translateY(-50%);
`;

export interface ExpandableButtonProps<T> {
  id: number;
  expandable: ExpandableTree<T>;
  className?: string;
}

export const ExpandButton = observer(function ExpandButton<T>(
  props: ExpandableButtonProps<T>
): JSX.Element {
  const { id, expandable, className } = props;
  const expand = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      expandable.expandNode(id);
    },
    [expandable, id]
  );

  const collapse = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      expandable.collapseNode(id);
    },
    [expandable, id]
  );

  const isExpanded = expandable.isNodeExpanded(id);

  return (
    <StyledExpandButton
      className={className}
      onClick={isExpanded ? collapse : expand}
      appearance={ButtonAppearance.Link}
    >
      {isExpanded ? '-' : '+'}
    </StyledExpandButton>
  );
});
