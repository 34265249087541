import { AsyncStatus, AsyncToastMessage } from '@yarmill/components';
import {
  toast as reactToastify,
  ToastOptions,
  TypeOptions,
} from 'react-toastify';

import { FormattedMessage } from 'react-intl';
import { ComponentProps } from 'react';

type Values = ComponentProps<typeof FormattedMessage>['values'];
const defaultOptions: ToastOptions = {
  autoClose: 5000,
  closeOnClick: true,
  icon: false,
  position: 'bottom-right',
  className: 'ytd-toast',
  closeButton: false,
  hideProgressBar: true,
};

export interface ToastContentProps {
  msg: string;
  type?: TypeOptions;
  values?: Values;
}

export function ToastContent(props: ToastContentProps): JSX.Element {
  const { msg, values, type } = props;
  let status = AsyncStatus.idle;
  switch (type) {
    case 'success':
      status = AsyncStatus.resolved;
      break;
    case 'error':
      status = AsyncStatus.rejected;
      break;
  }

  return (
    <AsyncToastMessage
      title={<FormattedMessage id={msg} values={values} />}
      status={status}
    />
  );
}

export const toast = (
  msg: string,
  type?: TypeOptions,
  values?: Values,
  options: ToastOptions = {}
) =>
  reactToastify(<ToastContent msg={msg} type={type} values={values} />, {
    ...defaultOptions,
    className: [defaultOptions.className, type].join(' '),
    ...options,
    type,
  });
