import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { PlannerEvent } from '../types';
import { renderToString } from 'react-dom/server';
import {
  GoalPlannerEvent,
  GoalsEventsWrapper,
} from '../../../goals/components/goal-planner-event';

export interface PlannerEventsOptions {
  events: Readonly<PlannerEvent[]>;
}

export function createRichtextPlannerEvents(events?: Readonly<PlannerEvent[]>) {
  const element = document.createElement('span');
  const string = renderToString(
    <GoalsEventsWrapper className="planner-events">
      {events?.map(e => (
        <GoalPlannerEvent title={e.Title} color={e.Color} key={e.EventId} />
      )) ?? []}
    </GoalsEventsWrapper>
  );
  element.innerHTML = string;
  return element.children.item(0) ?? element;
}

export function createLazyRichtextPlannerEvents(
  events?: Readonly<PlannerEvent[]>
) {
  if (!events || events.length === 0) {
    return '';
  }

  return renderToString(
    <GoalsEventsWrapper
      contentEditable={false}
      className="planner-events ProseMirror-widget"
    >
      {events?.map(e => (
        <GoalPlannerEvent title={e.Title} color={e.Color} key={e.EventId} />
      )) ?? []}
    </GoalsEventsWrapper>
  );
}

export const PlannerEvents = Extension.create<PlannerEventsOptions>({
  name: 'plannerEvents',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('plannerEvents'),
        props: {
          decorations: ({ doc }) => {
            if (!this.options.events || this.options.events.length === 0) {
              return DecorationSet.empty;
            }

            const widget = Decoration.widget(
              0,
              () => createRichtextPlannerEvents(this.options.events),
              {
                key: this.options.events.map(e => e.EventId).join('-'),
              }
            );

            return DecorationSet.create(doc, [widget]);
          },
        },
      }),
    ];
  },
});
