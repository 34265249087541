import { memo } from 'react';
import { Logo } from '../app/logo';
import { Navigation } from './navigation';
import { UserSection } from './user-section';
import { useRootStore } from '../app/root-store-context';
import { observer } from 'mobx-react-lite';

import { TopBar } from '@yarmill/components';
import { YollandaHeaderButton } from '../yollanda/header-button';
import { useModulesStore } from '../modules/hooks';
import { useCurrentUserStore } from '../users/hooks';

function InternalHeader(): JSX.Element {
  const rootStore = useRootStore();
  const isReady = rootStore.isReady;
  const modulesStore = useModulesStore();
  const currentUserStore = useCurrentUserStore();

  return (
    <TopBar
      logo={<Logo />}
      rightSection={
        isReady ? (
          <>
            {modulesStore.yollanda &&
              currentUserStore.isAllowedTo('yollanda') && (
                <YollandaHeaderButton />
              )}
            <UserSection />
          </>
        ) : undefined
      }
    >
      {isReady && <Navigation />}
    </TopBar>
  );
}

export const Header = memo(observer(InternalHeader));
