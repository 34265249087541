import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { generateUrl } from '../utils/generate-url';
import { ATHLETE_SEARCH_PARAM, GROUP_SEARCH_PARAM } from '../routes/types';
import { ListItem } from './list-item';
import { FormattedMessage } from 'react-intl';
import { GroupStore } from '../groups/mobx/group-store';
import { useTheme } from '@yarmill/components';
import { NavigationItem } from '../sidebar/navigation-item';

interface GroupLinkProps {
  isActive: boolean;
  group: GroupStore;

  onClick(): void;
}

export const GroupLink = observer(function GroupLink(
  props: GroupLinkProps
): JSX.Element {
  const { isActive, group, onClick } = props;
  const link = useCallback(
    () =>
      generateUrl({
        [GROUP_SEARCH_PARAM]: group.id,
        [ATHLETE_SEARCH_PARAM]: null,
      }),
    [group]
  );
  const theme = useTheme();

  const handleClick = useCallback(() => onClick(), [onClick]);

  return theme.name === 'main' ? (
    <NavigationItem
      text={group.name}
      to={link}
      isActive={isActive}
      onClick={onClick}
    />
  ) : (
    <ListItem
      isActive={isActive}
      link={link}
      name={group.name}
      onClick={handleClick}
      secondaryLine={
        <FormattedMessage
          id="sidebar.groups.athletesCount"
          values={{ count: group.athletes.length }}
        />
      }
    />
  );
});
