import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { Group } from '../types';

export interface CreateGroupRequestParams {
  name: string;
  userIds: number[];
}

export function createGroup(
  params: CreateGroupRequestParams
): AxiosPromise<Group> {
  return axios.post('api/UserGroup', params);
}
