import { AxiosPromise, CancelToken } from 'axios';
import { axios } from '../../api/axios';
import { devMode, instance } from '../../api/api-mapping';

export function downloadFile(
  fileUrl: string,
  cancelToken: CancelToken
): AxiosPromise<BlobPart> {
  return axios.get(fileUrl, {
    responseType: 'blob',
    cancelToken,
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
