import { memo } from 'react';
import { observer } from 'mobx-react-lite';
import { Sidebar } from '../athletes-sidebar/sidebar';
import { Page } from '../page/page';
import { useCurrentUserStore } from '../users/hooks';
import { PageContent } from './page-content';
import { Redirect } from 'react-router-dom';
import { useEvidenceStore } from './hooks';
import { InvalidUser } from './invalid-user';
import { useRootStore } from '../app/root-store-context';
import { PageMainContent, PageScrollContainer } from '@yarmill/components';

function InternalEvidenceRouteRoot(): JSX.Element {
  const currentUser = useCurrentUserStore();
  const initialConfigLoaded = useRootStore().isReady;
  const evidenceStore = useEvidenceStore();

  if (!evidenceStore.moduleKey && initialConfigLoaded) {
    // we are going to /evidence without module key
    return <Redirect to="/" />;
  }

  const moduleConfiguration = evidenceStore.moduleConfiguration;
  const athleteEnabled = moduleConfiguration?.athleteEnabled;
  const groupEnabled = moduleConfiguration?.groupEnabled;
  const hideSidebar = !athleteEnabled && !groupEnabled;

  // if (
  //   initialConfigLoaded &&
  //   !currentUser.isAllowedTo(`evidence.${evidenceStore.moduleKey}`)
  // ) {
  //   return <Redirect to="/" />;
  // }

  if (
    !athleteEnabled &&
    !groupEnabled &&
    (evidenceStore.userId || evidenceStore.groupId)
  ) {
    return <Redirect to={`/evidence/${evidenceStore.moduleKey}`} />;
  } else if (evidenceStore.userId && !athleteEnabled && initialConfigLoaded) {
    return (
      <Redirect
        to={`/evidence/${evidenceStore.moduleKey}?group=${evidenceStore.groupId}`}
      />
    );
  }

  if (
    initialConfigLoaded &&
    !currentUser.isAthlete &&
    (athleteEnabled || groupEnabled) &&
    !evidenceStore.userId &&
    !evidenceStore.groupId
  ) {
    return <Redirect to="/noGroup" />;
  }

  return (
    <Page>
      {!hideSidebar && currentUser?.role !== 'athlete' ? (
        <Sidebar
          disableWholeGroup={!groupEnabled}
          disableWholeGroupTooltip={`evidence.${evidenceStore.moduleKey}.sidebar.disableAllAthletes`}
          hideAthletesInGroup={!athleteEnabled}
        />
      ) : undefined}

      <PageMainContent>
        <PageScrollContainer>
          {!initialConfigLoaded || evidenceStore.isValidParamsCombination ? (
            <PageContent />
          ) : (
            <InvalidUser moduleKey={evidenceStore.moduleKey} />
          )}
        </PageScrollContainer>
      </PageMainContent>
    </Page>
  );
}

const ObserverEvidenceRouteRoot = observer(InternalEvidenceRouteRoot);

export const EvidenceRouteRoot = memo(() => <ObserverEvidenceRouteRoot />);
