import { useCallback } from 'react';
import { Location } from 'history';

import { NavItem } from '../nav-item';
import { useDefaultCommonSearchParams } from '../hooks';
import { appendDefaultSearchParams } from '../utils';
import { LinkCallback } from '../../routes/types';

export interface FilesOverviewLinkProps {
  className?: string;
  onClick?(): void;
}

export function useFilesOverviewLink(): LinkCallback {
  const defaultSearchParams = useDefaultCommonSearchParams();

  return useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultSearchParams
      );
      return `/filesOverview/?${searchParams}`;
    },
    [defaultSearchParams]
  );
}

export function FilesOverviewLink(props: FilesOverviewLinkProps): JSX.Element {
  const { className, onClick } = props;
  const link = useFilesOverviewLink();

  return (
    <NavItem
      to={link}
      moduleKey="filesOverview"
      icon="Files"
      className={className}
      onClick={onClick}
    />
  );
}
