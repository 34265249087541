import { PlannerEventStore } from './mobx/planner-event-store';
import { TrainingEventFloatingDetail } from '../#-components/planner/training-event-floating-detail';
import { usePlannerStore } from './planner-store-context';
import { useIntl } from 'react-intl';
import { useEditEventHandler } from './hooks/use-edit-event-handler';
import { Placement } from '@floating-ui/react';
import { useCurrentUserStore } from '../users/hooks';

interface PlannerEventPreviewProps {
  readonly event: PlannerEventStore;
  readonly placement: Placement;
}
export function PlannerEventPreview({
  event,
  placement,
}: PlannerEventPreviewProps): JSX.Element {
  const plannerStore = usePlannerStore();
  const intl = useIntl();
  const editEvent = useEditEventHandler(event);
  const currentUser = useCurrentUserStore();
  const formattedStart = intl.formatDate(event.start.toDate(), {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    year:
      event.start.isSame(event.end, 'year') &&
      !event.start.isSame(event.end, 'day')
        ? undefined
        : 'numeric',
  });

  const formattedEnd = intl.formatDate(event.end.toDate(), {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
  const eventDate = `${formattedStart}${
    !event.start.isSame(event.end, 'day') ? ` - ${formattedEnd}` : ''
  }`;

  const attributes = event.attributes?.map(attr =>
    intl.formatMessage({
      id:
        plannerStore.attributeValues.find(a => a.valueId === attr)?.value ??
        'unknown-attribute',
    })
  );

  const usersFromGroupCount = event.users.filter(u =>
    plannerStore.group?.allUsers.find(gu => gu.id === u.id)
  ).length;

  return (
    <TrainingEventFloatingDetail
      appearance={plannerStore.getEventTypeColor(event.eventTypeId) || 'navy'}
      name={event.title ?? ''}
      onEditClick={editEvent}
      date={eventDate}
      location={event.location?.displayName?.text}
      placement={placement}
      attendees={
        plannerStore.athleteId === null && !currentUser.isAthlete
          ? intl.formatMessage(
              {
                id: plannerStore.group?.athletes.every(athlete =>
                  event.users.includes(athlete)
                )
                  ? `planner.event.attendees.allAthletes`
                  : `planner.event.attendees.selectedAthletes`,
              },
              {
                count: event.users.length,
                usersFromGroupCount,
                otherUsersCount:
                  event.users.length -
                  usersFromGroupCount +
                  event.otherSubscribersCount,
                group: plannerStore?.group?.name,
              }
            )
          : undefined
      }
      attributes={attributes?.join(', ')}
    />
  );
}
