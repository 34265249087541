import { action, makeObservable, observable } from 'mobx';

export class SidebarStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  private _isVisible: boolean = true;

  public get isVisible(): Readonly<typeof this._isVisible> {
    return this._isVisible;
  }

  @action
  public readonly hide = (): void => {
    this._isVisible = false;
  };

  @action
  public readonly show = () => {
    this._isVisible = true;
  };

  @action
  public readonly toggle = () => {
    this._isVisible = !this._isVisible;
  };
}
