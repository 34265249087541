import { ReportItemStore } from '../../reporting/types';
import { useCallback, useRef } from 'react';
import { useRootStore } from '../../app/root-store-context';
import { createReportItemStore } from '../../reporting/utils/create-report-item-store';
import { useReportingStore } from '../../reporting/hooks/use-reporting-store';

export function useGetCellReportDefinition(
  code: string
): () => ReportItemStore | undefined {
  const reportingStore = useReportingStore();
  const rootStore = useRootStore();
  const cellReportStore = useRef<ReportItemStore | undefined>();

  return useCallback(() => {
    if (!cellReportStore.current) {
      const currentPageCode = reportingStore.currentPage?.code;
      const originalStore = reportingStore.currentPage?.reportItems.find(
        r => r.code === code
      );

      if (currentPageCode && originalStore) {
        const reportItemStore = createReportItemStore(
          originalStore.item,
          currentPageCode,
          rootStore
        );
        if (reportItemStore) {
          cellReportStore.current = reportItemStore;
        }
      }
    }

    return cellReportStore.current;
  }, [
    code,
    reportingStore.currentPage?.code,
    reportingStore.currentPage?.reportItems,
    rootStore,
  ]);
}
