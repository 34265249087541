import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  EvidenceAttribute,
  EvidenceAttributeId,
  EvidenceDataObject,
  EvidenceObject,
} from '../types';
import { EvidenceAttributeStore } from './evidence-attribute-store';
import { RootStore } from '../../app/mobx/root-store';
import { updateEvidenceObject } from '../api/update-evidence-object';
import { addEvidenceObject } from '../api/add-evidence-object';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { EvidenceObjectStore } from './evidence-object-store';
import { removeEvidenceObject } from '../api/remove-evidence-object';
import {
  avgEvalExpr,
  maxEvalExpr,
  minEvalExpr,
  percentileEvalExpr,
  roundEvalExpr,
  sgnEvalExpr,
  sumEvalExpr,
} from '../utils';
import { EvidenceAttachmentStore } from './evidence-attachment-store';
import { FileUploadStore } from '../../fileupload/mobx/file-upload-store';
import { toastErrorFileUpload } from '../../fileupload/file-upload';

export class EvidenceObjectDataStore {
  private readonly rootStore: RootStore;
  private readonly evidenceObjectStore: EvidenceObjectStore;
  private readonly _definition: EvidenceObject;
  private readonly userId: UserId | null;
  private readonly groupId: UserGroupId | null;
  private readonly dataUrl: string;
  public readonly isEditable: boolean = true;

  private _arrayIndex: number | null = null;

  @observable
  private request: RequestStore<EvidenceDataObject> | null = null;

  @observable
  private attributes: Map<
    EvidenceAttributeId,
    EvidenceAttributeStore | EvidenceAttachmentStore
  > = new Map();

  @observable
  private _submitButtonClicked: boolean = false;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  public pendingAttachments: IObservableArray<FileUploadStore> =
    observable.array();

  constructor(
    rootStore: RootStore,
    evidenceObjectStore: EvidenceObjectStore,
    definition: EvidenceObject,
    userId: UserId | null,
    groupId: UserGroupId | null,
    dataUrl: string,
    data?: EvidenceDataObject
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this._definition = definition;
    this.evidenceObjectStore = evidenceObjectStore;
    this.userId = userId;
    this.groupId = groupId;
    this.dataUrl = dataUrl;

    if (definition.Attributes) {
      this.createAttributes(definition.Attributes);
    }

    if (data) {
      this._arrayIndex = data.ArrayIndex;
      this.isEditable = data.IsEditable;
      data.Attributes.forEach(attributeData => {
        const attribute = this.getAttribute(attributeData.AttributeId);
        const value = attributeData.AttributeValue;
        if (
          attribute instanceof EvidenceAttachmentStore &&
          typeof value === 'object' &&
          value
        ) {
          attribute.setApiValue(value);
        } else if (
          attribute instanceof EvidenceAttributeStore &&
          typeof value !== 'object'
        ) {
          attribute.setApiValue(value);
        }
      });
    }
  }

  public getAttribute(
    attributeId: EvidenceAttributeId
  ): EvidenceAttributeStore | EvidenceAttachmentStore | undefined {
    return this.attributes.get(attributeId);
  }

  @action
  public setArrayIndex(arrayIndex: number): void {
    this._arrayIndex = arrayIndex;
  }

  @action
  private createAttributes(attributes: EvidenceAttribute[]): void {
    attributes.forEach(attribute => {
      this.attributes.set(
        attribute.AttributeId,
        attribute.AttributeTypeKey === 'attachment'
          ? new EvidenceAttachmentStore(
              this,
              this.definition.ObjectKey,
              attribute,
              this.userId,
              this.groupId
            )
          : new EvidenceAttributeStore(
              this,
              this._definition.ObjectKey,
              attribute,
              this.userId,
              this.groupId
            )
      );
    });
  }

  @computed
  public get isValid(): boolean {
    const hasInvalidAttribute = Array.from(this.attributes.values()).some(
      attr => !(attr instanceof EvidenceAttachmentStore) && !attr.isValid
    );

    return !hasInvalidAttribute;
  }

  @computed
  public get status(): AsyncStatus {
    return this._status;
  }

  public get arrayIndex(): number | null {
    return this._arrayIndex;
  }

  public get definition(): EvidenceObject {
    return this._definition;
  }

  public get submitButtonClicked(): boolean {
    return this._submitButtonClicked;
  }

  public set submitButtonClicked(clicked: boolean) {
    runInAction(() => {
      this._submitButtonClicked = clicked;
    });
  }

  public async syncValue(): Promise<boolean> {
    const attributeValues = Array.from(this.attributes.values())
      .filter(attr => !attr.definition.IsCalculated)
      .map(attr => attr.attributeValueObject);
    const arrayIndex = this._arrayIndex;
    const userId = this.userId;
    const groupId = this.groupId;
    const moduleKey = this._definition.ModuleKey;
    const objectKey = this._definition.ObjectKey;
    const dataUrl = this.dataUrl;

    if (!this.isValid || !this.isEditable) {
      return false;
    }

    this._status = AsyncStatus.pending;
    if (arrayIndex !== null) {
      this.request = this.rootStore.requestsStore.createRequest(() =>
        updateEvidenceObject(
          dataUrl,
          moduleKey,
          objectKey,
          attributeValues,
          arrayIndex,
          userId,
          groupId,
          this.objectStore.tags
        )
      );
    } else {
      this.request = this.rootStore.requestsStore.createRequest(() =>
        addEvidenceObject(
          dataUrl,
          moduleKey,
          objectKey,
          attributeValues,
          userId,
          groupId,
          this.objectStore.tags
        )
      );
    }

    const response = await this.request.getResponse();

    return runInAction(() => {
      if (response) {
        this._arrayIndex = response.ArrayIndex;

        this.attributes.forEach(attribute => {
          const value = response.Attributes.find(
            a => a.AttributeId === attribute.definition.AttributeId
          )?.AttributeValue;

          if (value !== undefined) {
            attribute.clear();
            if (
              attribute instanceof EvidenceAttachmentStore &&
              typeof value === 'object' &&
              value
            ) {
              attribute.setApiValue(value);
            } else if (
              attribute instanceof EvidenceAttributeStore &&
              typeof value !== 'object'
            ) {
              attribute.setApiValue(value);
            }
          }
        });

        this.objectStore.addSubmitHistoryItem({
          userId,
          userGroupId: groupId,
          data: attributeValues,
        });
        this._status = AsyncStatus.resolved;
        return true;
      }

      this._status = AsyncStatus.rejected;
      return false;
    });
  }

  @action
  public async remove(): Promise<boolean> {
    const def = this.definition;
    const arrayIndex = this.arrayIndex;

    if (arrayIndex !== null) {
      const request = this.rootStore.requestsStore.createRequest(() =>
        removeEvidenceObject(
          this.dataUrl,
          def.ModuleKey,
          def.ObjectKey,
          arrayIndex,
          this.userId,
          this.groupId
        )
      );

      const response = await request.getResponse();
      if (response) {
        this.evidenceObjectStore.removeItem(this);
        return true;
      }
      return false;
    }

    return false;
  }

  public get isCurrentUserAllowedToWrite(): boolean {
    const currentUser = this.rootStore.currentUserStore;
    const moduleKey = this.definition.ModuleKey;

    return (
      typeof window !== 'undefined' &&
      currentUser.isAllowedTo(`evidence.${moduleKey}.write`) &&
      ((!this.groupId && !this.userId) ||
        currentUser.hasWritePermission(this.groupId, this.userId))
    );
  }

  public get objectStore(): EvidenceObjectStore {
    return this.evidenceObjectStore;
  }

  @computed
  public get invalidAttributes(): EvidenceAttributeStore[] {
    return Array.from(this.attributes.values()).filter(
      (attribute): attribute is EvidenceAttributeStore =>
        attribute instanceof EvidenceAttributeStore && !attribute.isValid
    );
  }

  @action
  public reset(): void {
    this.attributes.forEach(attribute => attribute.reset());
    this.pendingAttachments.forEach(attachment => {
      attachment.cancelUpload();
      if (attachment.toastId) {
        toastErrorFileUpload(attachment.toastId);
      }
    });
    this.pendingAttachments.clear();
  }

  @action
  public setDefaultValues(): void {
    this.attributes.forEach(
      attribute =>
        attribute instanceof EvidenceAttributeStore &&
        attribute.setDefaultValue()
    );
  }

  @action
  public computeComputedAttributes(): void {
    this.attributes.forEach(attr => {
      if (
        attr.definition.IsCalculated &&
        attr instanceof EvidenceAttributeStore
      ) {
        attr.computeValue();
      }
    });
  }

  @computed
  public get hasPendingAttachments(): boolean {
    return this.pendingAttachments.length !== 0;
  }

  public computeValue(formula: string): string {
    const jsFormula = `${sumEvalExpr}${avgEvalExpr}${roundEvalExpr}${percentileEvalExpr}${minEvalExpr}${maxEvalExpr}${sgnEvalExpr}return(${formula})`;
    let formulaWithValues = jsFormula;
    this.attributes.forEach(attr => {
      formulaWithValues = formulaWithValues.replace(
        new RegExp('\\${' + attr.definition.AttributeKey + '}', 'g'),
        attr instanceof EvidenceAttributeStore && attr.value && attr.isValid
          ? String(Number(attr.numericValue))
          : 'null'
      );
    });
    try {
      // eslint-disable-next-line no-new-func
      const result = Function(formulaWithValues)();

      if (
        isNaN(result) ||
        result === Infinity ||
        result === -Infinity ||
        result === null
      ) {
        return '';
      }

      return result;
    } catch (e) {
      this.rootStore.logger.error('Invalid calculation', formulaWithValues, e);
      return '';
    }
  }
}
