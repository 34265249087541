import { FormEvent } from 'react';
import { NumericActivityStore } from './numeric-activity-store';
import { RootStore } from '../../app/mobx/root-store';
import { DiaryStore } from '../../diary/mobx/diary-store';
import { ActivityItem } from '../types';
import { makeObservable } from 'mobx';

export class BitActivityStore extends NumericActivityStore {
  constructor(
    rootStore: RootStore,
    diaryStore: DiaryStore,
    index: number,
    activityItem: ActivityItem
  ) {
    super(rootStore, diaryStore, index, activityItem);
    makeObservable(this);
    this.onChange = this.onChange.bind(this);
  }
  public onChange(e: FormEvent<HTMLInputElement>): void {
    if (this.diaryStore.viewType === 'week') {
      const { target } = e;
      if (!(target instanceof HTMLInputElement)) {
        return;
      }
      const newValue = target.value;

      const numberValue = Math.abs(Number(newValue));
      let formattedValue = String(numberValue);

      if (newValue === '') {
        formattedValue = '';
      } else if (Number.isNaN(numberValue)) {
        return;
      } else {
        if (numberValue !== 1 && numberValue !== 0) {
          return;
        }
      }

      this.setUserValue(formattedValue);
    } else {
      super.onChange(e);
    }
  }
}
