import { UserGroupId } from '../types';
import { RootStore } from '../../app/mobx/root-store';
import { AsyncStatus } from '../../api/mobx/request-store';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { GroupStore } from './group-store';
import { getGroups } from '../api/get-groups';
import { sortGroupStores } from '../utils';
import { UserId } from '../../users/types';
import { createGroup } from '../api/create-group';
import { AxiosError } from 'axios';
import { InitialConfigError } from '../../error/initial-config-error';

export class GroupsStore {
  private readonly rootStore: RootStore;
  @observable
  private _status: AsyncStatus = AsyncStatus.idle;
  @observable
  private readonly groups: Map<UserGroupId, GroupStore> = new Map<
    UserGroupId,
    GroupStore
  >();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  public getGroupById(id?: UserGroupId | null): GroupStore | undefined {
    if (!id) {
      return undefined;
    }
    return this.groups.get(id);
  }

  public async loadGroups(): Promise<void> {
    this._status = AsyncStatus.pending;

    const request = this.rootStore.requestsStore.createRequest(() =>
      getGroups()
    );
    const response = await request.getResponse();

    runInAction(() => {
      if (response) {
        response.forEach(group => {
          this.groups.set(
            group.UserGroupId,
            new GroupStore(this.rootStore, group)
          );
        });
        this._status = AsyncStatus.resolved;
      } else {
        this._status = AsyncStatus.rejected;
        throw new InitialConfigError('Unable to load groups', request.error);
      }
    });
  }

  @action
  public removeGroup(groupId: UserGroupId): void {
    this.groups.delete(groupId);
  }

  @computed
  public get sortedGroups(): GroupStore[] {
    return Array.from(this.groups.values()).sort(sortGroupStores);
  }

  public get status(): AsyncStatus {
    return this._status;
  }

  public async createGroup(
    name: string,
    userIds: UserId[]
  ): Promise<GroupStore> {
    const request = this.rootStore.requestsStore.createRequest(() =>
      createGroup({ name, userIds })
    );

    const response = await request.getResponse();

    return runInAction(() => {
      if (response) {
        const groupStore = new GroupStore(this.rootStore, response);
        this.groups.set(response.UserGroupId, groupStore);
        void this.rootStore.currentUserStore.loadPermissions();
        return groupStore;
      } else {
        throw (request.error as AxiosError | undefined)?.response?.data;
      }
    });
  }
}
