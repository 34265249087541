import { Units } from '../units/types';

export type ActivityItemId = number;
export type MaskCode = string;

export interface ActivityItem {
  ActivityItemId: ActivityItemId;
  ChildrenActivityItemIds: number[];
  Name: string;
  ParentActivityItemId: number | null;
  SumToParent: boolean;
  Type: string;
  SortCode: number;
  IsShown: boolean;
  ToolTip: string;
  IsEditable: boolean;
  IsAnnualPlanEditable: boolean;
  Unit: Units;
  Color: string | null;
  Level: number | null;
  CellAggregationFunction: AggregationFunction | null;
  TotalAggregationFunction: AggregationFunction | null;
  AnnualPlanCellAggregationFunction: AggregationFunction | null;
  AnnualPlanTotalAggregationFunction: AggregationFunction | null;
}

export enum AggregationFunction {
  SUM = 'sum',
  SGN = 'sgn',
  AVG = 'avg',
  PROD = 'prod',
}

export interface ActivityData {
  ActivityItemId: ActivityItemId;
  Date: string | null;
  Value: number;
}

export type ActivityState = 'P' | 'R' | 'O'; // plan | reality | annualPlan

export type ActivityItemsResponse = ActivityItemsResponseItem[];

export interface MaskedActivityItems {
  MaskCode: MaskCode;
}

export interface ActivityItemsResponseItem extends MaskedActivityItems {
  ActivityItems: ActivityItem[];
}

export interface ActivityMask {
  MaskCode: string;
  Title: string;
  SortCode: number;
}

export type DisabledReason =
  | 'no-permissions'
  | 'out-of-backfill-scope'
  | 'not-editable'
  | 'disabled-by-parent-mask'
  | 'computed-value'
  | 'loading'
  | 'loading-error';

export interface SelectionRange {
  activityItem: ActivityItemId;
  dayIndex: number;
}
