import {
  AlertLayerCloseButtonWrapper,
  AlertLayerContent,
  Button,
  ButtonAppearance,
  Text,
  TextAlignment,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useSettingsService } from '../settings/hooks';
import { toast } from '../components/toast-message';
import { validateDateValue } from '../evidence/validation';
import { FormEvent, useState } from 'react';
import { useSeasonGoalsStore } from './hooks';
import { useDiaryStore } from '../diary/diary-store-context';
import { useRootStore } from '../app/root-store-context';
import { Layer } from '../layer-manager/mobx/layer';
import { CloseButton } from '../components/close-button/close-button';
import { PatternInputDate } from '../components/pattern-input-date';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 10px;
`;

interface SeasonStartDateDialogProps {
  readonly layer: Layer;
}
export const SeasonStartDateDialog = observer(function SeasonStartDateDialog({
  layer,
}: SeasonStartDateDialogProps): JSX.Element | null {
  const rootStore = useRootStore();
  const seasonGoalsStore = useSeasonGoalsStore();
  const settingsService = useSettingsService();
  const [startDate, setStartDate] = useState(
    seasonGoalsStore?.seasonStartDate || ''
  );
  const diaryStore = useDiaryStore();
  const seasonId = diaryStore.currentSeason?.id;
  const user = rootStore.usersStore.getUserById(diaryStore.athleteId);
  const group = rootStore.groupsStore.getGroupById(diaryStore.groupId);

  if (!seasonGoalsStore || !seasonId || !group) {
    return null;
  }

  const name = user?.displayName || group.name;

  const submitForm = async (e: FormEvent) => {
    e.preventDefault();

    const success = await settingsService.saveSettings(
      `season.startDate.${seasonId}`,
      startDate,
      {
        userId: user?.id,
        groupId: user ? undefined : group.id,
      },
      true
    );
    await diaryStore.reloadDiaryData();
    layer.close();
    if (success) {
      toast('toast.success.settings.seasonStartDate', 'success');
    } else {
      toast('toast.error.settings.seasonStartDate', 'error');
    }
  };

  return (
    <AlertLayerContent.AlertLayerContainer autoWidth>
      <AlertLayerContent.StickyWrapper>
        <AlertLayerCloseButtonWrapper>
          <CloseButton onClick={layer.close} hideText />
        </AlertLayerCloseButtonWrapper>
      </AlertLayerContent.StickyWrapper>
      <AlertLayerContent.ContentWrapper onSubmit={submitForm}>
        <AlertLayerContent.HeadlineWrapper>
          <Text tag={TextTag.h2} size={TextSize.s16} medium>
            <FormattedMessage
              id="settings.seasonStartDate.dialog.header"
              values={{ name }}
            />
          </Text>
        </AlertLayerContent.HeadlineWrapper>
        <Text tag={TextTag.p}>
          <FormattedMessage
            id="settings.seasonStartDate.dialog.description"
            values={{ name }}
          />
        </Text>

        <Form>
          <PatternInputDate
            id="seasonStartDate"
            onChange={setStartDate}
            pattern="YYYY-MM-DD"
            delimiter="-"
            validateValue={validateDateValue}
            value={startDate}
          />
          <Text tag={TextTag.div} textAlign={TextAlignment.center}>
            <Button
              appearance={ButtonAppearance.Primary}
              type="submit"
              disabled={startDate.length !== 10}
              noShadow
              onClick={submitForm}
            >
              <FormattedMessage id="settings.seasonStartDate.dialog.save" />
            </Button>
          </Text>
        </Form>
      </AlertLayerContent.ContentWrapper>
    </AlertLayerContent.AlertLayerContainer>
  );
});
