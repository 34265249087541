import { IntlShape } from 'react-intl';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '@yarmill/components';

export function extractDateFormat(intl: IntlShape): string {
  const isoString = moment.utc('2022-11-25').format(ISO_DATE_FORMAT); // example date!

  const intlString = intl.formatDate(isoString, { timeZone: 'utc' }); // generate a formatted date

  return intlString
    .replace('25', 'DD')
    .replace('11', 'MM')
    .replace('2022', 'YYYY')
    .replace(/\s/g, '');
}

export function extractDelimiters(format: string): string[] {
  return format
    .replace('DD', '')
    .replace('MM', '')
    .replace('YYYY', '')
    .split('');
}

export function createDateFormatValidator(
  format: string
): (value: string) => boolean {
  const validityTable: Record<string, string> = {
    D: /[3210]/.source,
    DD: /(3[01]|[12\xa0][0-9\xa0]|0?[1-9\xa0])/.source,
    M: /[10\xa0]/.source,
    MM: /(1[012\xa0]|0?[1-9\xa0]|\xa0{2})/.source,
    Y: /[12\xa0]/.source,
    YY: /(?:19|20|1\xa0|2\xa0|\xa09|\xa00)/.source,
    YYY: /((?:19|20|1\xa0|2\xa0|\xa09|\xa00)[\d\xa0])/.source,
    YYYY: /((?:19|20|1\xa0|2\xa0|\xa09|\xa00|\xa0{2})([\d\xa0]{2}))/.source,
  };

  return (val: string) => {
    if (!val) return true;
    if (val.length > format.length) return false;
    let regexString = format.substring(0, val.length);

    Object.keys(validityTable)
      .sort((a, b) => b.length - a.length)
      .forEach(pattern => {
        regexString = regexString.replace(pattern, validityTable[pattern]);
      });

    regexString.replace(/\./, '\\.');
    const regex = new RegExp('^\\s*' + regexString + '\\s*$');

    return regex.test(val);
  };
}
