import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';

export interface ForgottenPasswordRequestParams {
  Email: string;
}

export interface ForgottenPasswordResponse {
  Message: string;
}

export function forgottenPassword(
  params: ForgottenPasswordRequestParams
): AxiosPromise<ForgottenPasswordResponse> {
  return axios.post('api/Account/ForgotPassword', params);
}
