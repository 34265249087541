import { makeObservable, observable } from 'mobx';
import { askYollanda } from '../api/ask-yollanda';
import { RootStore } from '../../app/mobx/root-store';
import { ATHLETE_SEARCH_PARAM } from '../../routes/types';
import { YollandaFeedback, YollandaInteractionId } from '../types';
import { sendFeedback } from '../api/send-feedback';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { suggestTextCompletion } from '../api/suggest-text-completion';
import { CancelTokenSource, isAxiosError } from 'axios';

export class YollandaService {
  private readonly _rootStore: RootStore;
  @observable
  private _isVisible: boolean = false;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this._rootStore = rootStore;
  }

  public show() {
    this._isVisible = true;
  }

  public hide() {
    this._isVisible = false;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public askYollanda(query: string) {
    const yollandaUrl = this._rootStore.configStore.yollandaUrl;
    const userId =
      this._rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM);
    const context = this._rootStore.historyService.pathname.includes('plan')
      ? 'plan'
      : 'reality';

    if (!yollandaUrl) {
      throw new Error('Yollanda URL not configured');
    }

    return askYollanda(yollandaUrl, {
      query,
      userId: Number(userId),
      context,
    });
  }

  public sendFeedback(
    interactionId: YollandaInteractionId,
    feedback: YollandaFeedback
  ) {
    const yollandaUrl = this._rootStore.configStore.yollandaUrl;

    if (!yollandaUrl) {
      throw new Error('Yollanda URL not configured');
    }

    return sendFeedback(yollandaUrl, {
      interactionId,
      qualityResponse: feedback,
    });
  }

  public async suggestTextCompletion(
    query: string,
    userId: UserId | null,
    userGroupId: UserGroupId | null,
    cancelToken?: CancelTokenSource
  ): Promise<string> {
    const yollandaUrl = this._rootStore.configStore.yollandaUrl;

    if (!yollandaUrl) {
      throw new Error('Yollanda URL not configured');
    }
    let response;
    try {
      response = await suggestTextCompletion(yollandaUrl, {
        query,
        userId,
        userGroupId,
        cancelToken,
      });

      return response.data.completion;
    } catch (e) {
      if (isAxiosError(e) && e.code === 'ERR_CANCELED') {
        throw e;
      }
      this._rootStore.logger.error('Yollanda suggestion failed', e);
      return '';
    }
  }
}
