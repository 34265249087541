import moment from 'moment';

import { Season, SeasonId, SeasonViewType } from '../types';
import { getSeasonCycles, getSeasonMonths, getSeasonWeeks } from '../utils';
import { computed, makeObservable } from 'mobx';
import { RootStore } from '../../app/mobx/root-store';

export class SeasonStore {
  private readonly rootStore: RootStore;
  private readonly _season: Season;
  private readonly _seasonMonths: string[];
  private readonly _seasonCycles: string[];
  private readonly _seasonWeeks: string[];

  constructor(rootStore: RootStore, season: Season) {
    makeObservable(this);
    this.rootStore = rootStore;
    this._season = season;
    this._seasonMonths = getSeasonMonths(season);
    this._seasonCycles = getSeasonCycles(season);
    this._seasonWeeks = getSeasonWeeks(season);
  }

  public get id(): SeasonId {
    return this._season.SeasonId;
  }

  public get label(): string {
    return this._season.Name;
  }

  public get name(): string {
    return this._season.Name;
  }

  public get seasonMonths(): string[] {
    return this._seasonMonths;
  }

  public get seasonCycles(): string[] {
    return this._seasonCycles;
  }

  public get seasonWeeks(): string[] {
    return this._seasonWeeks;
  }

  public get startDate(): string {
    return this._season.StartDate;
  }

  @computed
  public get start(): moment.Moment {
    return moment(this.startDate);
  }

  public get endDate(): string {
    return this._season.EndDate;
  }

  @computed
  public get end(): moment.Moment {
    return moment(this.endDate);
  }

  public get startYear(): number {
    return this._season.StartYear;
  }

  @computed
  public get seasonSections(): string[] {
    return this.seasonViewType === 'month'
      ? this.seasonMonths
      : this.seasonCycles;
  }

  @computed
  public get seasonViewType(): SeasonViewType {
    return this.rootStore.configStore.seasonView;
  }

  public isInSeason(dateString: string): boolean {
    const date = moment(dateString);
    const start = moment(this._season.StartDate);
    const end = moment(this._season.EndDate);

    return start <= date && end >= date;
  }
}
