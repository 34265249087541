import { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AsyncButton } from '../components/async-button/async-button';
import { trackChangePasswordSubmitClick } from '../google-analytics/utils';
import { AsyncStatus } from '../api/mobx/request-store';
import { useAuthStore } from '../auth/hooks';
import { observer } from 'mobx-react-lite';
import { useCurrentUserStore } from '../users/hooks';
import { Layer } from '../layer-manager/mobx/layer';
import {
  ChangePasswordForm,
  FieldSet,
  FormControlVariant,
  FullScreenLayerCloseButtonWrapper,
  PasswordRequirementsList,
  StyledFullScreenLayerContent,
  Text,
  TextAlignment,
  TextInput,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { CloseButton } from '../components/close-button/close-button';

export interface ChangePasswordProps {
  readonly layer: Layer;
}

const validateMinChars = (password: string): boolean => password.length >= 6;
const validateNumber = (password: string): boolean => /\d/.test(password);
const validateCharacters = (password: string): boolean =>
  /(?=.*[a-z])(?=.*[A-Z])/.test(password);

export const ChangePassword = observer(function ChangePassword(
  props: ChangePasswordProps
): JSX.Element {
  const { layer } = props;
  const authStore = useAuthStore();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [asyncState, setAsyncState] = useState(AsyncStatus.idle);
  const currentUser = useCurrentUserStore();
  const intl = useIntl();

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    trackChangePasswordSubmitClick();
    setAsyncState(AsyncStatus.pending);
    try {
      await authStore.changePassword(oldPassword, newPassword, newPassword);
      layer.close();
    } catch (e) {
      setAsyncState(AsyncStatus.rejected);
    }
  }

  return (
    <>
      <StyledFullScreenLayerContent>
        <Text
          tag={TextTag.h1}
          size={TextSize.s24}
          textAlign={TextAlignment.center}
        >
          <FormattedMessage id="settings.profile.changePassword" />
        </Text>
        <ChangePasswordForm onSubmit={onSubmit}>
          <FieldSet disabled={asyncState === AsyncStatus.pending}>
            <input
              type="text"
              hidden
              readOnly
              name="username"
              id="username"
              autoComplete="username"
              value={currentUser.data?.Email}
            />
            <TextInput
              id="oldPassword"
              label={intl.formatMessage({
                id: 'settings.profile.currentPassword',
              })}
              type="password"
              name="oldPassword"
              autoComplete="current-password"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              variant={FormControlVariant.big}
            />
            <TextInput
              id="newPassword"
              label={intl.formatMessage({
                id: 'settings.profile.newPassword',
              })}
              type="password"
              name="newPassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              variant={FormControlVariant.big}
            />

            <PasswordRequirementsList>
              {!validateMinChars(newPassword) && (
                <li>
                  <Text size={TextSize.s14} inheritColor>
                    <FormattedMessage id="settings.profile.minChars" />
                  </Text>
                </li>
              )}
              {!validateNumber(newPassword) && (
                <li>
                  <Text size={TextSize.s14} inheritColor>
                    <FormattedMessage id="settings.profile.hasNumber" />
                  </Text>
                </li>
              )}
              {!validateCharacters(newPassword) && (
                <li>
                  <Text size={TextSize.s14} inheritColor>
                    <FormattedMessage id="settings.profile.hasCharacters" />
                  </Text>
                </li>
              )}
            </PasswordRequirementsList>

            <Text textAlign={TextAlignment.center} tag={TextTag.div}>
              <AsyncButton
                type="submit"
                disabled={
                  newPassword.length < 6 ||
                  oldPassword.length < 6 ||
                  !validateMinChars(newPassword) ||
                  !validateNumber(newPassword) ||
                  !validateCharacters(newPassword)
                }
                loading={asyncState === AsyncStatus.pending}
                success={asyncState === AsyncStatus.resolved}
                error={asyncState === AsyncStatus.rejected}
                variant={FormControlVariant.big}
              >
                <FormattedMessage id="settings.profile.submit" />
              </AsyncButton>
            </Text>
          </FieldSet>
        </ChangePasswordForm>
      </StyledFullScreenLayerContent>
      <FullScreenLayerCloseButtonWrapper>
        <CloseButton onClick={layer.close} />
      </FullScreenLayerCloseButtonWrapper>
    </>
  );
});
