import { RootStore } from './mobx/root-store';
import { useContext, createContext } from 'react';
import * as React from 'react';

export const RootStoreContext = createContext<RootStore | undefined>(undefined);

export function useRootStore(): RootStore {
  const rootStore = useContext(RootStoreContext);
  if (!rootStore) {
    throw new Error('Root Store not available');
  }

  return rootStore;
}

export interface WithRootStoreProps {
  rootStore: RootStore;
}

export const withRootStore = <P extends {}>(
  Component: React.ComponentType<P & WithRootStoreProps>
) =>
  function WithRootStore(props: P): JSX.Element {
    const rootStore = useRootStore();
    return <Component {...props} rootStore={rootStore} />;
  };
