import { css, styled } from '@yarmill/components';
import { getTextAppearanceStyles, Text } from '../../components-2/text';

export const EvaluationsLayout = styled.div`
  padding: ${({ theme }) => theme.size.x4};
`;

export const EvaluationsContainer = styled.div`
  padding-left: ${({ theme }) => theme.size.x1};
`;

export const EvaluationLayout = styled.div<{
  readonly showBorderBottom: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.size.x4} 1fr;
  grid-template-rows: auto auto;
  column-gap: ${({ theme }) => theme.size.x05};
  row-gap: ${({ theme }) => theme.size.x1};
  align-items: flex-start;
  justify-items: flex-start;
  margin-bottom: ${({ theme }) => theme.size.x2};
  padding-bottom: ${({ theme }) => theme.size.x15};
  border-bottom: ${({ theme, showBorderBottom }) =>
    showBorderBottom ? theme.size.x025 : 0}
    solid ${({ theme }) => theme.color.neutral_8};
  max-width: calc(77 * ${({ theme }) => theme.size.x1});

  @media (min-width: 768px) {
    grid-template-rows: auto;
  }
`;

export const EvaluationTextContainer = styled.div<{
  readonly showEditor?: boolean;
  readonly editable?: boolean;
}>`
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  width: 100%;
  ${getTextAppearanceStyles('text15')};
  color: ${({ theme }) => theme.color.neutral};

  padding: ${({ theme, showEditor }) => (showEditor ? theme.size.x1 : 0)};

  @media (min-width: 768px) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  ${({ showEditor, editable }) =>
    showEditor &&
    editable &&
    css`
      :focus-within,
      :hover {
        color: ${({ theme }) => theme.color.neutralDark};
        background-color: ${({ theme }) => theme.color.neutralPlate};
      }
    `}
`;

export const EvaluationAuthor = styled(Text)`
  align-self: center;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const EvaluationHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.neutral};
  margin-bottom: ${({ theme }) => theme.size.x3};
`;

export const EvaluationAvatarWrapper = styled.div`
  padding-top: ${({ theme }) => theme.size.x05};
`;

export const TooltipButtonWrapper = styled.div`
  display: inline-block;
`;
