import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { useConfig } from '../app/hooks';
import { Service } from './components/service';

import { ContentBox, ContentBoxHead, Text } from '@yarmill/components';
import { useAuthStore } from '../auth/hooks';
import { CurrentUserStore } from '../users/mobx/current-user-store';
import { User } from '../users/types';

export interface ServicesProps {
  user: CurrentUserStore;
}

type ConnectedServiceKey = keyof User['ExternalServices'];

export const Services = observer(function Services(
  props: ServicesProps
): JSX.Element | null {
  const { user } = props;
  const availableServices = useConfig('availableExternalServices');
  const token = useAuthStore().token;

  if (availableServices.length === 0 || !token) {
    return null;
  }

  return (
    <ContentBox>
      <ContentBoxHead>
        <Text medium>
          <FormattedMessage id="settings.profile.services" />
        </Text>
      </ContentBoxHead>
      {availableServices.map(service => (
        <Service
          key={service.appCode}
          ident={service.appCode}
          name={
            <FormattedMessage
              id={`settings.profile.services.${service.appCode}.name`}
            />
          }
          description={
            <FormattedMessage
              id={`settings.profile.services.${service.appCode}.description.${user.data?.Role}`}
            />
          }
          connected={Boolean(
            user.data?.ExternalServices[service.appCode as ConnectedServiceKey]
          )}
          userId={user.id}
          token={token}
          editable={Boolean(service.connectUrl && service.disconnectUrl)}
          hideBorder={
            service.appCode === 'garmin' ||
            service.appCode === 'suunto' ||
            service.appCode === 'oura'
          }
          connectUrl={service.connectUrl}
          disconnectUrl={service.disconnectUrl}
        />
      ))}
    </ContentBox>
  );
});
