import { TextareaHTMLAttributes, useEffect, useRef } from 'react';
import { css, Icon, IconSize, styled } from '@yarmill/components';
import { getTextAppearanceStyles, TextProps } from './text';
import { useTextAreaAutosize } from '../utils/use-textarea-autosize';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  readonly textAppearance?: TextProps['appearance'];
  readonly icon?: JSX.Element;
  readonly iconPosition?: 'left' | 'right';
}

interface StyledTextAreaProps {
  readonly $textAppearance?: TextProps['appearance'];
  readonly icon?: JSX.Element;
  readonly iconPosition?: 'left' | 'right';
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  font-family: ${({ theme }) => theme.text.font.default};
  ${({ $textAppearance }) => getTextAppearanceStyles($textAppearance)};

  resize: none;
  overflow-y: hidden;
  width: 100%;
  border: 0;
  padding: ${({ theme }) => theme.size.x075} ${({ theme }) => theme.size.x1};

  ${({ icon, iconPosition, theme }) =>
    icon &&
    css`
      ${
        iconPosition === 'right'
          ? `padding-right: ${theme.size.x4};`
          : `padding-left: ${theme.size.x4};`
      };
    `}

  :focus {
    background-color: ${({ theme }) => theme.color.neutralPlate};
    outline: none;
  }

  :hover {
    background-color: ${({ theme }) => theme.color.neutralPlate};
  }

  ::placeholder {
    color: ${({ theme }) => theme.color.neutral_40};
  }
`;
const IconWrapper = styled.div<{
  readonly position: 'left' | 'right';
}>`
  position: absolute;
  ${({ position, theme }) =>
    position === 'right'
      ? `right: ${theme.size.x1};`
      : `left: ${theme.size.x1};`};
  top: ${({ theme }) => theme.size.x2};
  height: 100%;
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.color.neutral_24};
`;

export const RichTextIconWrapper = styled(IconWrapper)`
  top: ${({ theme }) => theme.size.x1};
`;

const TextAreaWrapper = styled.div`
  position: relative;

  :focus-within {
    ${IconWrapper} {
      color: ${({ theme }) => theme.color.navy};
    }
  }
`;

export function TextArea({
  textAppearance,
  icon,
  iconPosition,
  autoFocus,
  ...textareaProps
}: TextAreaProps): JSX.Element {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useTextAreaAutosize(textareaRef);

  useEffect(() => {
    if (autoFocus) {
      textareaRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <TextAreaWrapper>
      <StyledTextArea
        ref={textareaRef}
        {...textareaProps}
        $textAppearance={textAppearance}
        icon={icon}
        iconPosition={iconPosition}
        rows={1}
      />
      {icon && (
        <IconWrapper position={iconPosition ?? 'left'}>
          <Icon size={IconSize.s16}>{icon}</Icon>
        </IconWrapper>
      )}
    </TextAreaWrapper>
  );
}
