import { Icon, styled } from '@yarmill/components';
import { RightPanelSectionHeading } from '../../components-2/right-panel';

export const PlannerFormElement = styled.form`
  display: contents;
`;

export const PlannerEventFormDetailsStepLayout = styled.div`
  padding: ${({ theme }) => theme.size.x1} ${({ theme }) => theme.size.x2};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x3};
`;

export const DateStepLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x3};
  padding: ${({ theme }) => theme.size.x2};
`;

export const DateStepInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x1};
`;

export const RemoveEventIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.red};
`;

export const LocationSearchResultsHeading = styled(RightPanelSectionHeading)`
  padding: 0 ${({ theme }) => theme.size.x2};
  margin-top: ${({ theme }) => theme.size.x3};
  margin-bottom: ${({ theme }) => theme.size.x1};
`;

export const EventTypeStepLayout = styled.div`
  padding: ${({ theme }) => theme.size.x2} 0;
`;
export const LocationStepLayout = styled.div`
  padding: ${({ theme }) => theme.size.x2} 0;
`;
