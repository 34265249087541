import * as React from 'react';
import { EvidenceTableForm } from './evidence-table-form';
import {
  FullScreenLayerCloseButtonWrapper,
  StyledFullScreenLayerContent,
} from '@yarmill/components';
import styled from 'styled-components';
import { EvidenceObjectDataStore } from '../mobx/evidence-object-data-store';
import { CloseButton } from '../../components/close-button/close-button';

const StyledLayerContent = styled(StyledFullScreenLayerContent)`
  @media (min-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;

export function EvidenceTableFormLayer(props: {
  item: EvidenceObjectDataStore;
  close: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const { item, close } = props;

  const handleReset = React.useCallback(
    (e?: React.FormEvent) => {
      e?.preventDefault();
      item?.reset();
      item.pendingAttachments.forEach(attachment => {
        attachment.cancelUpload();
      });
      item.pendingAttachments.clear();
      close();
    },
    [item, close]
  );

  return (
    <>
      <StyledLayerContent>
        <EvidenceTableForm item={item} close={close} />
      </StyledLayerContent>
      <FullScreenLayerCloseButtonWrapper>
        <CloseButton onClick={handleReset} />
      </FullScreenLayerCloseButtonWrapper>
    </>
  );
}
