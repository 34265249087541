import { CurrentUserStore } from './mobx/current-user-store';
import { useRootStore } from '../app/root-store-context';
import { UsersStore } from './mobx/users-store';

export function useUsersStore(): UsersStore {
  const rootStore = useRootStore();
  return rootStore.usersStore;
}

export function useCurrentUserStore(): CurrentUserStore {
  const rootStore = useRootStore();
  return rootStore.currentUserStore;
}
