import moment from 'moment';
import { WrappedComponentProps } from 'react-intl';
import { ChartHeader } from './chart-header';
import { ReBarChart } from './rebarchart';
import { KPI, SeasonTrend as SeasonTrendType } from './types';
import { getKPIValue, prepareChartData } from './utils';
import { SeasonStore } from '../../../seasons/mobx/season-store';
import { observer } from 'mobx-react-lite';
import { ContentBox } from '@yarmill/components';

export interface SeasonsTrendProps extends WrappedComponentProps {
  data: SeasonTrendType[];
  kpi: KPI[];
  season: SeasonStore;
}

export const SeasonTrend = observer(function SeasonTrend(
  props: SeasonsTrendProps
): JSX.Element {
  const { season, data, intl, kpi } = props;
  const labels =
    season.seasonViewType === 'month'
      ? season.seasonMonths.map(month => moment(month).format('MMM'))
      : season.seasonCycles.map((cycle, idx) => String(idx + 1));
  const tooltipLabels =
    season.seasonViewType === 'month'
      ? labels.map(month => moment(month).format('MMMM'))
      : labels;
  const chartData = prepareChartData(labels, data, tooltipLabels);
  const xAxisLabel = intl.formatMessage({
    id:
      season.seasonViewType === 'month'
        ? 'analytics.seasonTrendMonth.xLabel'
        : 'analytics.seasonTrendCycle.xLabel',
  });

  return (
    <ContentBox>
      <ChartHeader
        plan={getKPIValue(kpi, 'HZ Season Plan')}
        reality={getKPIValue(kpi, 'HZ Season Reality')}
        absDiff={getKPIValue(kpi, 'HZ Season AbsDiff')}
        relDiff={getKPIValue(kpi, 'HZ Season RelDiff')}
        intl={intl}
      />
      <ReBarChart
        data={chartData}
        stepSize={60}
        xAxisLabel={xAxisLabel}
        tooltipTitlePrefix={xAxisLabel}
      />
    </ContentBox>
  );
});
