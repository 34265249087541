import { observer } from 'mobx-react-lite';
import { ObjectiveStore } from './mobx/objective-store';
import {
  EvaluationsLayout,
  EvaluationHeadline,
  EvaluationsContainer,
} from './components/evaluations';
import { ObjectiveEvaluationForm } from './objective-evaluation-form';
import { useCurrentUserStore } from '../users/hooks';
import { useOkrsStore } from './okrs-store-context';
import { FormattedMessage } from 'react-intl';
import { useMemo } from 'react';

interface ObjectiveEvaluationProps {
  readonly objective: ObjectiveStore;
}
export const ObjectiveEvaluation = observer(function ObjectiveEvaluation({
  objective,
}: ObjectiveEvaluationProps) {
  const evaluations = objective.evaluations;
  const okrsStore = useOkrsStore();
  const currentUser = useCurrentUserStore();
  const showCurrentUserEvaluation = Boolean(
    objective.supervisors.find(
      supervisor => supervisor.id === currentUser.id
    ) || okrsStore.athleteId === currentUser.id
  );
  const hasCurrentUserEvaluation = evaluations.find(
    evaluation =>
      evaluation.createdBy === currentUser.id && evaluation.evaluationText
  );

  const evaluationsWithPlaceholder = useMemo(
    () =>
      showCurrentUserEvaluation && !hasCurrentUserEvaluation
        ? [null, ...evaluations]
        : evaluations,
    [evaluations, hasCurrentUserEvaluation, showCurrentUserEvaluation]
  );

  if (
    !showCurrentUserEvaluation &&
    evaluations.filter(e => e.evaluationText).length === 0
  ) {
    return null;
  }

  return (
    <EvaluationsLayout>
      <EvaluationHeadline upperCase appearance="task13strong" as="div">
        <FormattedMessage id="okrs.evaluation.headline" />
      </EvaluationHeadline>
      <EvaluationsContainer>
        {evaluationsWithPlaceholder
          .filter(
            evaluation => evaluation === null || evaluation.evaluationText
          )
          .map(evaluation => (
            <ObjectiveEvaluationForm
              key={evaluation?.createdBy ?? currentUser.id}
              objective={objective}
              evaluation={evaluation ?? undefined}
              isCurrentUsersEvaluation={
                evaluation === null || evaluation.createdBy === currentUser.id
              }
            />
          ))}
      </EvaluationsContainer>
    </EvaluationsLayout>
  );
});
