import moment from 'moment';

const trimConfigRegex = new RegExp(`{.+?}`);

export function formatDuration(
  value: number | string | null | undefined,
  format: string | null | undefined
): string {
  if (value === null || value === undefined) {
    return '';
  }

  const number = typeof value === 'string' ? Number(value) : value;
  const trimConfig = format ? format.match(trimConfigRegex) : null;
  const formatWithoutTrimConfig = format
    ? format?.replace('{', '')?.replace('}', '')
    : null;
  const stopTrim = trimConfig
    ? trimConfig[0]?.replace('{', '')?.replace('}', '')
    : undefined;

  return moment
    .duration(number, 'seconds')
    .format(formatWithoutTrimConfig ? formatWithoutTrimConfig : 'hh:mm', {
      trim: stopTrim ? 'both' : false,
      stopTrim,
    });
}
