import {
  useTooltip as useVisxTooltip,
  useTooltipInPortal,
} from '@visx/tooltip';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  MouseEvent,
  useRef,
} from 'react';
import { TooltipInPortalProps } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { GetTooltipData, TooltipData } from '../../reporting/types';
import { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip';
import { localPoint } from '@visx/event';
import { invertScale } from '../utils/invert-scale';
import { useScale } from './use-scale';
import { TOOLTIP_TIMEOUT } from '../../reporting/const';

interface TooltipContextValue {
  readonly TooltipInPortal: FC<TooltipInPortalProps>;
  readonly tooltipOpen: boolean;
  readonly tooltipLeft?: number;
  readonly tooltipTop?: number;
  readonly tooltipData?: TooltipData;
  readonly showTooltip: UseTooltipParams<TooltipData>['showTooltip'];
  readonly hideTooltip: UseTooltipParams<TooltipData>['hideTooltip'];
  readonly getTooltipData: GetTooltipData;
}
export const TooltipContext = createContext<TooltipContextValue | undefined>(
  undefined
);

export function useTooltipContext(): TooltipContextValue {
  const value = useContext(TooltipContext);
  if (!value) {
    throw new Error('TooltipContext not defined');
  }
  return value;
}

export function useTooltip(
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>,
  getTooltipData: GetTooltipData
): [
  (element: HTMLElement | SVGElement | null) => void,
  TooltipContextValue,
  (e: MouseEvent) => void,
  (e: MouseEvent) => void,
] {
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
    detectBounds: true,
    debounce: 25,
  });
  const tooltipTimeout = useRef<number>(0);

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useVisxTooltip<TooltipData>();

  const contextValue = useMemo(
    () => ({
      TooltipInPortal,
      tooltipOpen,
      tooltipLeft,
      tooltipTop,
      tooltipData,
      hideTooltip,
      showTooltip,
      getTooltipData,
    }),
    [
      TooltipInPortal,
      hideTooltip,
      showTooltip,
      tooltipData,
      tooltipLeft,
      tooltipOpen,
      tooltipTop,
      getTooltipData,
    ]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const mousePosition = localPoint(e);
      if (mousePosition) {
        const isHorizontal = 'bandwidth' in yScale;
        const left = mousePosition?.x || 0;
        const top = mousePosition?.y || 0;
        const axisValue = isHorizontal
          ? invertScale(yScale, top)
          : invertScale(xScale, left);
        const tooltipData: TooltipData = getTooltipData(axisValue, false);

        showTooltip({
          tooltipData,
          tooltipTop: top,
          tooltipLeft: left,
        });
      }
    },
    [getTooltipData, showTooltip, xScale, yScale]
  );

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout.current = window.setTimeout(
      () => hideTooltip(),
      TOOLTIP_TIMEOUT
    );
  }, [hideTooltip]);

  return [containerRef, contextValue, handleMouseMove, handleMouseLeave];
}
