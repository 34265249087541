import { useIntl } from 'react-intl';
import { ExternalIcon, YollandaButton } from '@yarmill/components';
import { useRootStore } from '../app/root-store-context';
import { ATHLETE_SEARCH_PARAM } from '../routes/types';
import { observer } from 'mobx-react-lite';

export const YollandaHeaderButton = observer(
  function YollandaHeaderButton(): JSX.Element | null {
    const intl = useIntl();
    const rootStore = useRootStore();
    const yollandaService = rootStore.yollandaService;
    const historyService = rootStore.historyService;
    const showYollanda =
      (historyService.pathname.includes('plan') ||
        historyService.pathname.includes('reality')) &&
      historyService.searchParams.has(ATHLETE_SEARCH_PARAM);

    if (!showYollanda) {
      return null;
    }

    return (
      <YollandaButton
        aria-label={intl.formatMessage({ id: 'yollanda.button.label' })}
        onClick={() =>
          yollandaService.isVisible
            ? yollandaService.hide()
            : yollandaService.show()
        }
      >
        <ExternalIcon name="CrystalBall" />
      </YollandaButton>
    );
  }
);
