import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';

export interface SubscribeToCalendarRequestParams {
  calendarName: string;
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  start?: string;
  end?: string;
}
export function subscribeToCalendar(
  params: SubscribeToCalendarRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<void> {
  return axios.post('/api/calendar/googlesubscribe/plan', params, {
    cancelToken: cancelToken.token,
  });
}
