import * as React from 'react';
import styled from 'styled-components';
import { FixedContentBox } from '@yarmill/components';

const ScrollContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;

  @media print {
    height: auto;
  }
`;

const ActivitiesContentBox = styled(FixedContentBox)`
  margin-top: 16px;
  padding-top: 10px;
  box-shadow: none;
  padding-right: 12px;

  & > div {
    padding-right: 0;
  }

  @media (min-width: 768px) {
    padding-right: 26px;

    & > div {
      padding-right: 0;
    }
  }

  @media (min-width: 1024px) {
    margin-top: unset;
    max-height: 100%;
    overflow: hidden;
  }

  &:focus-within {
    border: 1px solid #4a90e2;
  }

  @media print {
    & {
      border-radius: 0;
    }
  }
`;

export function ActivitiesTableContainer(
  props: React.PropsWithChildren<{ height?: string; className?: string }>
): JSX.Element {
  const { children, className } = props;
  return (
    <ActivitiesContentBox className={className}>
      <ScrollContainer>{children}</ScrollContainer>
    </ActivitiesContentBox>
  );
}
