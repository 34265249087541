import { OkrStatus, OkrStatuses } from '../types';
import {
  getStatusAppearance,
  Status,
  StatusIcon,
} from '../../components-2/status';
import {
  DropdownOption,
  DropdownProvider,
} from '../../components-2/dropdown-provider/dropdown-provider';
import { IntlShape, useIntl } from 'react-intl';
import { StatusDropdownButtonWrapper } from '../../components-2/dropdown-provider/dropdown-button-wrapper';
import { useMemo } from 'react';
import { Tippy } from '../../components/tippy/tippy';

const createOkrStatusOptions = (intl: IntlShape): DropdownOption<OkrStatus>[] =>
  OkrStatuses.map(status => ({
    label: intl.formatMessage({ id: `okrs.status.${status}` }),
    icon: <StatusIcon status={status} />,
    value: status,
  }));
interface StatusDropdownProps {
  readonly selectedValue: OkrStatus | null;
  readonly handleSelect: (value: OkrStatus) => void;
  readonly hideText?: boolean;
  readonly noBackground?: boolean;
  readonly variant?: 'default' | 'large';
  readonly disabled?: boolean;
}
export function StatusDropdown({
  selectedValue,
  handleSelect,
  hideText,
  noBackground,
  variant,
  disabled,
}: StatusDropdownProps): JSX.Element {
  const intl = useIntl();
  const statuses = useMemo(() => createOkrStatusOptions(intl), [intl]);

  return (
    <Tippy tooltipContent="okrs.status" noWrapper touch={false}>
      <DropdownProvider
        keyboardShortcut="S"
        options={statuses}
        searchInputPlaceholder={intl.formatMessage({
          id: 'okrs.form.keyResult.status.label',
        })}
        selectedValue={selectedValue ?? undefined}
        handleSelect={handleSelect as any}
        disabled={disabled}
      >
        <StatusDropdownButtonWrapper
          noBackground={noBackground}
          appearance={getStatusAppearance(selectedValue ?? 'not-started')}
          variant={variant}
          disabled={disabled}
        >
          <Status
            status={selectedValue ?? undefined}
            hideText={hideText}
            variant={variant}
          />
        </StatusDropdownButtonWrapper>
      </DropdownProvider>
    </Tippy>
  );
}
