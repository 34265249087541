import { toast } from '../components/toast-message';
import { loadPersistedCurrentUser } from '../users/utils';
import { groupCookies } from './types';
import moment from 'moment';
import { GroupStore } from './mobx/group-store';
import { UserStore } from '../users/mobx/user-store';
import { GroupsStore } from './mobx/groups-store';
import { CurrentUserStore } from '../users/mobx/current-user-store';

export function toastAddUsersToGroup(
  users: UserStore[],
  group: GroupStore,
  type: 'success' | 'error'
): void {
  if (users.length === 1) {
    toast(`toast.${type}.addUserToGroup`, type, {
      user: users[0].displayName,
      group: group.name,
    });
  } else {
    toast(`toast.${type}.addUsersToGroup`, type, {
      count: String(users.length),
      group: group.name,
    });
  }
}

export function persistSelectedGroup(group: GroupStore): void {
  window.localStorage.setItem(groupCookies.GROUP, String(group.id));
}

export function loadPersistedGroupId(
  groupsStore: GroupsStore,
  currentUser: CurrentUserStore
): number | null {
  const groupId = window.localStorage.getItem(groupCookies.GROUP);
  const persistedUser = loadPersistedCurrentUser();
  const currentUserId = currentUser.id;

  if (
    !groupId ||
    !currentUserId ||
    (persistedUser && persistedUser !== currentUserId)
  ) {
    return null;
  }

  if (!groupsStore.getGroupById(Number(groupId))) {
    window.localStorage.removeItem(groupCookies.GROUP);

    return null;
  }

  if (!currentUser.hasPermissionToGroup(Number(groupId))) {
    return null;
  }

  return Number(groupId);
}

export function sortGroupStores(a: GroupStore, b: GroupStore): number {
  return a.name
    .toLocaleLowerCase()
    .localeCompare(b.name.toLocaleLowerCase(), moment.locale());
}
