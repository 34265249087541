import { ExportDropdownItem } from './export-dropdown-item';
import { ExportItem } from './types';

export interface ExportDropdownProps {
  exportItems: ExportItem[];
  closeMenu(): void;
}
export function ExportDropdown(props: ExportDropdownProps): JSX.Element {
  const { exportItems, closeMenu } = props;
  return (
    <>
      {exportItems.map(item => (
        <ExportDropdownItem
          key={`${item.format}-${item.label}`}
          label={item.label}
          description={item.description}
          format={item.format}
          doExport={item.doExport}
          closeDropdown={closeMenu}
        />
      ))}
    </>
  );
}
