import { LayerManagerService } from './mobx/layer-manager-service';
import { useRootStore } from '../app/root-store-context';
import { RefObject, useCallback, useState } from 'react';
import {
  AlertLayerOptions,
  ContextLayerOptions,
  InteractionLayerOptions,
  LayerHandle,
  LayerOptions,
  LayerRenderer,
  LayerType,
} from './types';
import * as React from 'react';
import { lock, unlock } from 'tua-body-scroll-lock';

export function useLayerManagerService(): LayerManagerService {
  return useRootStore().layerManagerService;
}

export function useForceUpdate(): () => void {
  const [, setValue] = useState(0);
  return useCallback(() => setValue(value => value + 1), []);
}

export function useLayer(type: LayerType, options?: LayerOptions): LayerHandle;
export function useLayer(
  type: 'context',
  options?: ContextLayerOptions
): LayerHandle;
export function useLayer(
  type: 'interaction',
  options?: InteractionLayerOptions
): LayerHandle;
export function useLayer(
  type: 'alert',
  options?: AlertLayerOptions
): LayerHandle;
export function useLayer(
  type: LayerType,
  options?: Partial<LayerOptions>
): LayerHandle {
  const layersService = useLayerManagerService();

  const [layerResult, setLayerResult] = useState<LayerHandle>({
    isOpened: false,
    open(openOptions?: Partial<LayerOptions>): void {
      const render: LayerRenderer = (mountPoint, layer) => {
        setLayerResult({ isOpened: true, mountPoint, layer });
      };

      const layerOptions: LayerOptions = {
        ...options,
        ...openOptions,
        onClose: () => {
          // On close revert back to the initial layer result,
          // i.e. `this` (the object literal defined as the initial state).
          setLayerResult(this);
          options?.onClose?.();
          openOptions?.onClose?.();
        },
      };

      layersService.openLayer(type, render, layerOptions);
    },
  });

  return layerResult;
}

export function useBodyScrollLock(target: RefObject<HTMLDivElement>): void {
  React.useEffect(() => {
    const ref = target.current;

    if (ref) {
      lock(ref);
    }
    return () => {
      if (ref) {
        unlock(ref);
      }
    };
  }, [target]);
}
