import { Header } from '../okrs/header';
import { SideMenuToggle } from '../okrs/buttons/side-menu-toggle';
import { HeaderButtonsDivider, HeaderPageTitle } from '../components-2/header';
import { CreateNewEvent } from './buttons/create-event';
import { ShowImporter } from './buttons/show-importer';
import { FormattedMessage } from 'react-intl';
import { AthleteSelector } from './buttons/athlete-selector';
import { SeasonSelector } from '../okrs/season-selector';
import { FilterEvents } from './buttons/filter-events';
import { useCurrentUserStore } from '../users/hooks';
import { observer } from 'mobx-react-lite';
import { usePlannerStore } from './planner-store-context';
import { useShowImporterHandler } from './hooks/use-show-importer-handler';
import { useCurrentUserIsAllowedToWrite } from './hooks/use-current-user-is-allowed-to-write';
import { useSidebarStore } from '../sidebar/hooks/use-sidebar-store';

export const PlannerHeader = observer(function PlannerHeader(): JSX.Element {
  const plannerStore = usePlannerStore();
  const currentUser = useCurrentUserStore();
  const sidebarStore = useSidebarStore();
  const isSidebarVisible = sidebarStore.isVisible;
  const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();
  const showImporter = useShowImporterHandler();

  return (
    <Header
      inverted
      left={
        <>
          <SideMenuToggle />
          <HeaderButtonsDivider />
          {currentUserIsAllowedToWrite && <CreateNewEvent />}
          {currentUserIsAllowedToWrite && plannerStore.eventSets.length > 0 && (
            <ShowImporter show={showImporter} />
          )}
        </>
      }
      center={
        isSidebarVisible ? (
          <HeaderPageTitle appearance="text15strong" absolutelyCentered>
            <FormattedMessage id="planner.title" />
          </HeaderPageTitle>
        ) : currentUser.isAthlete ? (
          <HeaderPageTitle appearance="text15strong" absolutelyCentered>
            {plannerStore.athlete?.displayName ?? plannerStore.group?.name}
          </HeaderPageTitle>
        ) : (
          <AthleteSelector />
        )
      }
      right={
        <>
          <SeasonSelector />
          <FilterEvents />
        </>
      }
    />
  );
});
