import { UserRole } from '../../users/types';
import { Tag, TagAppearance } from '@yarmill/components';
import { FormattedMessage } from 'react-intl';

interface RoleTagProps {
  readonly role: UserRole;
}

function getTagAppearance(role: UserRole): TagAppearance {
  switch (role) {
    case 'admin':
      return TagAppearance.red;
    case 'athlete':
      return TagAppearance.blue;
    case 'coach':
      return TagAppearance.grey;
  }
}
export function RoleTag({ role }: RoleTagProps): JSX.Element {
  return (
    <Tag appearance={getTagAppearance(role)}>
      <FormattedMessage id={`settings.users.role.${role}`} />
    </Tag>
  );
}
