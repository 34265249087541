import moment from 'moment';
import { GoalsAttributes } from '../diary/types';
import { getWeekStart } from '../diary/utils';
import { GroupStore } from '../groups/mobx/group-store';
import { SeasonStore } from '../seasons/mobx/season-store';
import { TrainingDayAttributeId } from '../training-day/types';
import { UserStore } from '../users/mobx/user-store';
import { NUMBER_OF_SEASON_CYCLES } from './types';

export function getGoalIndexForDay(
  week: string,
  goalsAttributes: GoalsAttributes,
  day: string,
  attribute: TrainingDayAttributeId
): number {
  const weekStart = getWeekStart(week);
  const date = moment(day);
  const diff = date.diff(weekStart, 'days');
  const weekMultiplier = Math.floor(diff / 7);
  const isoWeekDay = date.isoWeekday() - 1; // we index days from 0;

  if (attribute === goalsAttributes.day) {
    // index of day + cycle goal + week goal
    return isoWeekDay + weekMultiplier * 7 + 2 * (weekMultiplier + 1);
  } else if (attribute === goalsAttributes.cycle) {
    return 0 + weekMultiplier * 9;
  } else if (attribute === goalsAttributes.week) {
    return 1 + weekMultiplier * 9;
  } else {
    throw new Error('invalid goal attribute id');
  }
}

export function getDayIndexFromGoalIndex(goalDayIndex: number): number {
  // for week goal and cycle goal we always return date of first day in week
  const weekMultiplier = Math.floor(goalDayIndex / 9);
  const rowIndex = goalDayIndex % 9;

  if (rowIndex === 0 || rowIndex === 1) {
    return 0 + weekMultiplier * 7;
  } else {
    return rowIndex - 2 + weekMultiplier * 7;
  }
}

export function isWeekGoal(goalDayIndex: number): boolean {
  const rowIndex = goalDayIndex % 9;

  return rowIndex === 1;
}

export function isCycleGoal(goalDayIndex: number): boolean {
  const rowIndex = goalDayIndex % 9;

  return rowIndex === 0;
}

export function getSeasonStart(
  season: SeasonStore,
  group?: GroupStore,
  user?: UserStore
): moment.Moment {
  return moment(
    user
      ? user?.getSeasonStart(season.id) || season.startDate
      : group?.getSeasonStart(season.id) || season.startDate
  );
}

export function getCyclesEndDate(startDate: moment.Moment): moment.Moment {
  return startDate.clone().add(NUMBER_OF_SEASON_CYCLES * 4 * 7 - 1, 'days');
}
