import { useCallback } from 'react';
import { Location } from 'history';

import { NavItem } from '../nav-item';
import { useDefaultCommonSearchParams } from '../hooks';
import { appendDefaultSearchParams } from '../utils';
import { LinkCallback } from '../../routes/types';
import { useIntl } from 'react-intl';

export interface AnalyticsLinkProps {
  className?: string;
  dashboardName?: string;
  linkName?: string;
  onClick?(): void;
}

export function useAnalyticsLink(dashboard?: string): LinkCallback {
  const defaultSearchParams = useDefaultCommonSearchParams();

  return useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultSearchParams
      );

      return `/analytics/${
        dashboard || 'general.defaultDashboard'
      }?${searchParams}`;
    },
    [defaultSearchParams, dashboard]
  );
}

export function AnalyticsLink(props: AnalyticsLinkProps): JSX.Element {
  const { className, onClick, dashboardName, linkName } = props;
  const link = useAnalyticsLink(dashboardName);
  const intl = useIntl();

  return (
    <NavItem
      to={link}
      moduleKey={`analytics/${dashboardName}`}
      icon="ChartLine"
      className={className}
      onClick={onClick}
      linkText={linkName ? intl.formatMessage({ id: linkName }) : undefined}
    />
  );
}
