import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { ContentBox, Text, TextSize } from '@yarmill/components';

import { GoalsView } from './goals-view';
import { NoAthlete } from './no-athlete';
import { SeasonView } from './season-view';
import { WeekView } from './week-view';
import { ComponentType, useEffect } from 'react';
import { AsyncStatus } from '../api/mobx/request-store';
import { useDiaryStore } from './diary-store-context';
import { ViewType } from './types';
import { SeasonGoalsView } from './season-goals-view';
import { CurrentUserStore } from '../users/mobx/current-user-store';
import { DiaryStore } from './mobx/diary-store';
import { useCurrentUserStore } from '../users/hooks';
import { NotAllowed } from '../not-allowed';
import { PermissionScope } from '../permissions/types';
import { Redirect } from 'react-router-dom';
import { useRootStore } from '../app/root-store-context';

function getViewComponent(viewType: ViewType): ComponentType {
  switch (viewType) {
    case 'week':
      return WeekView;
    case 'season':
      return SeasonView;
    case 'goals':
      return GoalsView;
    case 'seasonGoals':
      return SeasonGoalsView;
  }
}
function isAllowedView(
  currentUser: CurrentUserStore,
  diaryStore: DiaryStore
): boolean {
  const diaryType = diaryStore.diaryType;
  const viewType = diaryStore.viewType;
  const groupId = diaryStore.groupId;
  const userId = diaryStore.athleteId;

  const permissionScope: PermissionScope = `${diaryType}.${viewType}`;
  const isAllowedToRead = Boolean(
    (groupId && currentUser.hasPermissionToGroup(groupId) && !userId) ||
      (userId && currentUser.hasPermissionToUser(userId))
  );

  return Boolean(currentUser.isAllowedTo(permissionScope)) && isAllowedToRead;
}

function InternalDiary(): JSX.Element {
  const rootStore = useRootStore();
  const diaryStore = useDiaryStore();
  const status = diaryStore.status;
  const isValidAthlete = diaryStore.isValidAthleteId;
  const currentUser = useCurrentUserStore();
  const View = isAllowedView(currentUser, diaryStore)
    ? getViewComponent(diaryStore.viewType)
    : NotAllowed;

  useEffect(() => () => diaryStore.disposeReactions(), [diaryStore]);

  if (
    !currentUser.isAthlete &&
    !diaryStore.groupId &&
    (!diaryStore.athleteId || diaryStore.athleteId === currentUser.id) &&
    rootStore.isReady
  ) {
    return <Redirect to="/noGroup" />;
  }

  return (
    <>
      {status === AsyncStatus.rejected && (
        <ContentBox>
          <Text size={TextSize.s14}>
            <FormattedMessage id="diary.loadingError" />
          </Text>
        </ContentBox>
      )}
      {status !== AsyncStatus.rejected && !isValidAthlete && <NoAthlete />}
      {status !== AsyncStatus.rejected && isValidAthlete && <View />}
    </>
  );
}

export const Diary = observer(InternalDiary);
