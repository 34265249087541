import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { Group } from '../types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

export function getGroups(): AxiosPromise<Group[]> {
  return axios.get(`${coreApiTarget}/api/user-groups`, {
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
