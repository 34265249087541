import { Header } from './header';
import { Button, ButtonAppearance } from '../components-2/button';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon, IconSize } from '@yarmill/components';
import { Close } from '@yarmill/icons-2';
import { FakeButton } from '../components-2/okrs/objectives-list';
import { ObjectiveStore } from './mobx/objective-store';
import { observer } from 'mobx-react-lite';
import { useWindowSize } from '../utils/use-window-size';
import {
  RightPanelContent,
  RightPanelLayout,
  RightPanelTitle,
} from '../components-2/right-panel';
import { ObjectiveValidation } from './validation-panel/objective-validation';
import { Tippy } from '../components/tippy/tippy';

interface ObjectiveValidationPanelProps {
  readonly objective: ObjectiveStore;
  readonly isVisible: boolean;
  readonly close: () => void;
}
export const ObjectiveValidationPanel = observer(
  function ObjectiveValidationPanel({
    objective,
    isVisible,
    close,
  }: ObjectiveValidationPanelProps): JSX.Element {
    const intl = useIntl();
    const { width } = useWindowSize();

    return (
      <RightPanelLayout
        isVisible={isVisible}
        windowWidth={width ?? 0}
        dark
        disableMobileHideAnimation
      >
        <Header
          dark
          left={
            <Tippy tooltipContent="okrs.detail.validation.close" touch={false}>
              <Button
                $appearance={ButtonAppearance.Secondary}
                $appearanceStyle="white"
                $iconOnly
                onClick={close}
                aria-label={intl.formatMessage({
                  id: 'okrs.detail.validation.close',
                })}
              >
                <Icon size={IconSize.s24}>
                  <Close />
                </Icon>
              </Button>
            </Tippy>
          }
          center={
            <RightPanelTitle appearance="text15strong">
              <FormattedMessage id="okrs.detail.validation.headline" />
            </RightPanelTitle>
          }
          right={
            <FakeButton $iconOnly>
              <Icon size={IconSize.s24} />
            </FakeButton>
          }
        />
        <RightPanelContent>
          <ObjectiveValidation objective={objective} />
        </RightPanelContent>
      </RightPanelLayout>
    );
  }
);
