import { ComponentType } from 'react';
import { Location } from 'history';
import { PermissionScope } from '../permissions/types';

export interface RouteConfiguration {
  path: string;
  public?: boolean;
  exact?: boolean;
  sidebar?: ComponentType<any>;
  component: ComponentType<any>;
  fallback?: JSX.Element | string;
  permissionScope: PermissionScope | PermissionScope[] | null;
}

export const GROUP_SEARCH_PARAM = 'group';
export const ATHLETE_SEARCH_PARAM = 'athlete';
export const WEEK_SEARCH_PARAM = 'week';

export type LinkCallback = (location: Location) => string;
