import { RootStore } from '../../app/mobx/root-store';
import { downloadFile } from '../api/download-file';
import {
  showExportErrorToastMessage,
  showExportSuccessToastMessage,
  showExportToastMessage,
} from '../../export/export-toast-message';
import { Id as ToastId } from 'react-toastify/dist/types';
import { toast } from 'react-toastify';

export interface DownloadFileOptions {
  skipToastMessage?: boolean;
  download?: boolean;
}

export class FileDownloadService {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public async downloadFile(
    fileUrl: string,
    fileName: string,
    options?: DownloadFileOptions
  ): Promise<string | void> {
    let toastId: ToastId = '';
    const { skipToastMessage = false, download = true } = options || {};

    try {
      const request = this.rootStore.requestsStore.createRequest(cancelToken =>
        downloadFile(fileUrl, cancelToken.token)
      );
      let canceled = false;

      if (!skipToastMessage) {
        toastId = showExportToastMessage(
          fileName,
          () => {
            canceled = true;
            request.cancel();
          },
          'download'
        );
      }

      const data = await request.getResponse();

      if (canceled) {
        return;
      }

      if (!data) {
        throw new Error('Invalid export response');
      }

      if (!download) {
        if (!skipToastMessage) {
          toast.dismiss(toastId);
        }
        return window.URL.createObjectURL(new Blob([data]));
      }

      FileDownloadService.downloadFile(data, fileName);
      if (!skipToastMessage) {
        showExportSuccessToastMessage(toastId, fileName, 'download');
      }
    } catch (e) {
      this.rootStore.logger.error(e);
      showExportErrorToastMessage(toastId, fileName, 'download');
    }
  }

  private static downloadFile(fileLink: BlobPart, fileName: string): void {
    const element = document.createElement('a');
    element.download = fileName;
    element.href = window.URL.createObjectURL(new Blob([fileLink]));
    element.target = '_blank';
    element.rel = 'noopener noreferrer';

    element.click();
  }
}
