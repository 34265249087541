import { Button, ButtonAppearance } from '../../components-2/button';
import { IconSize } from '@yarmill/components';
import { useIntl } from 'react-intl';
import { Trash } from '@yarmill/icons-2';
import { PlannerEventStore } from '../mobx/planner-event-store';
import { usePlannerStore } from '../planner-store-context';
import { RemoveEventIcon } from '../../#-components/planner/form';
import { Tippy } from '../../components/tippy/tippy';

interface RemoveEventProps {
  readonly event: PlannerEventStore;
  readonly close: () => void;
}
export function RemoveEvent({ close, event }: RemoveEventProps): JSX.Element {
  const intl = useIntl();
  const plannerStore = usePlannerStore();

  function onClick() {
    const message = intl.formatMessage(
      { id: 'planner.removeEvent.confirm' },
      { title: event.title }
    );
    if (window.confirm(message)) {
      plannerStore.removeEvent(event);
      close();
    }
  }
  return (
    <Tippy tooltipContent="planner.removeEvent" noWrapper touch={false}>
      <Button
        $appearance={ButtonAppearance.Secondary}
        $appearanceStyle="white"
        $iconOnly
        onClick={onClick}
        aria-label={intl.formatMessage({
          id: 'planner.removeEvent',
        })}
        type="button"
      >
        <RemoveEventIcon size={IconSize.s24}>
          <Trash />
        </RemoveEventIcon>
      </Button>
    </Tippy>
  );
}
