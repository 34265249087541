import { CGM_URL_PARAM } from './types';

export function getCgmLink(selectedDay: string | null): string {
  const url = new URL(window.location.href, window.location.origin);

  if (selectedDay) {
    url.searchParams.set(CGM_URL_PARAM, selectedDay);
  } else {
    url.searchParams.delete(CGM_URL_PARAM);
  }

  return `${url.pathname}${url.search}`;
}
