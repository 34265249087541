import { observer } from 'mobx-react-lite';
import { AxisValue, TooltipData, XyChart } from '@yarmill/components';
import { AsyncStatus } from '../api/mobx/request-store';
import { useCallback, useEffect } from 'react';
import {
  CategoricalChartReportData,
  CategoricalChartReportStore,
} from './types';
import { ReportWrapper } from './report-wrapper';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { ColumnLineChartStore } from './mobx/column-line-chart-store';
import { useTranslatedAxisConfigs } from './hooks/use-translated-axis-configs';

const REPORT_HEIGHT = 200;
const HORIZONTAL_REPORT_HEIGHT = 400;

export interface ChartReportProps {
  readonly report: CategoricalChartReportStore;
  readonly sectionRatio?: number;
  readonly data?: CategoricalChartReportData;
  readonly noReportWrapper?: boolean;
  readonly isHorizontal?: boolean;
}

export const CategoricalChartReport = observer(function CategoricalChartReport(
  props: ChartReportProps
): JSX.Element {
  const {
    report,
    sectionRatio,
    data: passedData,
    noReportWrapper,
    isHorizontal,
  } = props;
  const dataStore = useReportingDataStore();

  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as
      | CategoricalChartReportData
      | undefined);

  const status = passedData ? AsyncStatus.resolved : dataStore?.status;

  const getTooltipData = useCallback(
    (axisValue: AxisValue): TooltipData => {
      return report.getTooltipData(
        axisValue,
        data?.Data || [],
        isHorizontal ? 'y' : 'x'
      );
    },
    [data?.Data, report, isHorizontal]
  );

  useEffect(() => {
    report.xy.setConfigOverride(data?.ConfigOverride);
  }, [data?.ConfigOverride, report]);

  const chartElementsConfig = report.chartElementsConfig;
  const axisConfigs = report.xy.axisConfigs;
  const height = report.xy.height;
  const gridLinesConfig = report.xy.gridLinesConfig;
  const noDataMessage = report.xy.noDataMessage;
  const aspectRatio =
    report instanceof ColumnLineChartStore ? report.aspectRatio : undefined;
  const backgroundImage =
    report instanceof ColumnLineChartStore ? report.backgroundImage : undefined;

  const getItem = useCallback(
    (key: string, tickValue: AxisValue) =>
      data?.Data?.find(item => item?.[key] === tickValue),
    [data?.Data]
  );

  const translatedAxisConfigs = useTranslatedAxisConfigs(
    axisConfigs,
    report,
    getItem
  );

  return (
    <ReportWrapper
      hasData={!dataStore || Boolean(data && data.Data.length > 0)}
      height={height ?? REPORT_HEIGHT}
      status={status || AsyncStatus.idle}
      sectionRatio={sectionRatio}
      noDataMessage={noDataMessage}
      aspectRatio={aspectRatio}
      noReportWrapper={noReportWrapper}
    >
      {width => (
        <XyChart
          code={report.code}
          width={width}
          height={
            aspectRatio && width
              ? width / aspectRatio
              : (height ??
                (isHorizontal ? HORIZONTAL_REPORT_HEIGHT : REPORT_HEIGHT))
          }
          getTooltipData={getTooltipData}
          configs={chartElementsConfig}
          axisConfigs={translatedAxisConfigs}
          data={data?.Data || []}
          domainX={
            (data?.Metadata?.AxisXDomain as string[]) ??
            (data?.Metadata?.AxisXValues as string[]) ??
            []
          }
          domainY={
            data?.Metadata?.AxisYDomain ??
            (isHorizontal
              ? (data?.Metadata?.AxisYValues?.slice().reverse() as string[])
              : undefined) ?? [0, 0]
          }
          axisXTicks={data?.Metadata?.AxisXTicks}
          axisYTicks={data?.Metadata?.AxisYTicks ?? data?.Metadata?.AxisYValues}
          gridLinesConfig={gridLinesConfig}
          gridLinesRowsTicks={data?.Metadata?.GridLinesRowValues}
          gridLinesColumnsTicks={data?.Metadata?.GridLinesColumnValues}
          hideTooltip={report.hideTooltip}
          backgroundImage={backgroundImage}
        />
      )}
    </ReportWrapper>
  );
});
