import { getAxisPosition } from '../../reporting/utils/get-axis-position';
import { DataItem, FloatingBarChart } from '@yarmill/components';
import { useScale } from '../hooks/use-scale';
import { getBarSize } from './get-bar-size';
import { getScaleSize } from './get-scale-size';

export function getFloatingBarDimensions(
  config: FloatingBarChart,
  item: DataItem,
  xScale: ReturnType<typeof useScale>,
  yScale: ReturnType<typeof useScale>
) {
  const xValue = config.getXValue(item);
  const yValue = config.getYValue(item);
  const isXCategorical = 'bandwidth' in xScale;

  const domainY = yScale.domain();
  const axisPosition = getAxisPosition(domainY as number[]);
  const barPosition = config.getBarPosition(item);
  const barWidth = getScaleSize(xScale) * config.getBarWidth(item);
  const baseOffset = isXCategorical ? xScale.bandwidth() / 2 : 0;
  const barPositionOffset =
    barPosition === 'start'
      ? 0
      : barPosition === 'center'
        ? -1 * (barWidth / 2)
        : -1 * barWidth;
  const x = (xScale(xValue as any) || 0) + barPositionOffset + baseOffset;
  const y =
    (yValue ?? 0) < 0 ? yScale(axisPosition as any) : yScale(yValue as any);
  const height = getBarSize(yScale, yValue);

  return {
    x,
    y,
    width: barWidth,
    height,
  };
}
