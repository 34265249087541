import { action, makeObservable, observable } from 'mobx';

export class MemoryStore {
  @observable
  private readonly _persistedItems: Record<string, any> = {};

  constructor() {
    makeObservable(this);
  }

  @action
  public setItem<T>(key: string, value: T): void {
    this._persistedItems[key] = value;
  }

  public getItem<T>(key: string): T | undefined {
    return this._persistedItems[key];
  }
}
