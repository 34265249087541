import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { User, UserInvitation } from '../types';

export interface InviteNewUsersParams {
  UserRegistrationInfo: UserInvitation[];
  groupIds: number[];
}

export function inviteNewUsers(
  params: InviteNewUsersParams
): AxiosPromise<User[]> {
  return axios.post('api/Account/Register/Invite', params);
}
