import { observer } from 'mobx-react-lite';
import { useLayer } from '../layer-manager/hooks';
import { useEffect } from 'react';
import { LayerPortal } from '../layer-manager/layer-portal';
import { useImageService } from './hooks';
import { ImagePreviewLayerContent } from './image-preview-layer-content';

export const ImagePreview = observer(function ImagePreview(): JSX.Element {
  const imageService = useImageService();
  const imagePreviewLayer = useLayer('alert', {
    transparent: true,
    autoSize: true,
    showShim: true,
    closeOnShimClick: true,
  });
  const src = imageService.previewSrc;

  useEffect(() => {
    if (src && !imagePreviewLayer.isOpened) {
      imagePreviewLayer.open({
        closeOnShimClick: true,
        onClose: () => imageService.closePreview(),
      });
    }
  }, [imagePreviewLayer, imageService, src]);

  return (
    <LayerPortal
      layerHandle={imagePreviewLayer}
      getContent={layer => (
        <ImagePreviewLayerContent src={src} closeLayer={layer.close} />
      )}
    />
  );
});
