import { AttendanceValue } from './types';
import { ButtonAppearance, Icon, IconSize } from '@yarmill/components';
import {
  getBackgroundForValueType,
  getBackgroundHoverForValueType,
  getIconForValueType,
  StyledAttendanceValueButton,
} from './attendance-value-button';
import styled from 'styled-components';
import { Tippy } from '../components/tippy/tippy';
import { useAttendanceStore } from './attendance-store-context';

interface AttendanceCurrentValueProps {
  value: AttendanceValue | undefined;
  dayIndex: number;
  disabled: boolean;
}

const CurrentValueButton = styled(StyledAttendanceValueButton)`
  width: 32px;
  height: 32px;
  min-height: 32px;
`;

export function AttendanceCurrentValue(
  props: AttendanceCurrentValueProps
): JSX.Element {
  const { value = AttendanceValue.Free, dayIndex, disabled } = props;
  const attendanceStore = useAttendanceStore();

  const onClick = () => {
    attendanceStore.setEditedColumn(dayIndex);
  };

  return (
    <Tippy tooltipContent={`attendance.value.${value}`} touch={false}>
      <CurrentValueButton
        type="button"
        disabled={disabled}
        square
        onClick={disabled ? undefined : onClick}
        appearance={ButtonAppearance.Link}
        $backgroundColor={getBackgroundForValueType(value)}
        $hoverBackgroundColor={getBackgroundHoverForValueType(value)}
      >
        <Icon size={IconSize.s24}>{getIconForValueType(value)}</Icon>
      </CurrentValueButton>
    </Tippy>
  );
}
