import {
  ExternalIcon,
  Icon,
  IconSize,
  NavbarStatus,
  NavbarStatusText,
  Spinner,
  TextSize,
} from '@yarmill/components';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { observer } from 'mobx-react-lite';
import { useNavbarStore } from './hooks';

export const Status = observer(function Status(): JSX.Element | null {
  const status = useNavbarStore().status;

  switch (status) {
    case 'loading':
    case 'loadingData':
      return <Loading />;
    case 'success':
      return <Success />;
    case 'error':
      return <ErrorStatus />;
    default:
      return null;
  }
});

function useHideStatus(): void {
  const navbarStore = useNavbarStore();
  useEffect(() => {
    const timeout = setTimeout(() => navbarStore.clear(), 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [navbarStore]);
}

const Loading = observer((): JSX.Element => {
  const status = useNavbarStore().status;

  return (
    <NavbarStatus appearance="info">
      <Spinner />
      <NavbarStatusText size={TextSize.s14} inheritColor>
        <FormattedMessage id={`navbar.status.${status}`} />
      </NavbarStatusText>
    </NavbarStatus>
  );
});

const Success = observer((): JSX.Element => {
  useHideStatus();

  return (
    <NavbarStatus appearance="success">
      <Icon size={IconSize.s18}>
        <ExternalIcon name="CircleCheckFilled" />
      </Icon>
      <NavbarStatusText size={TextSize.s14} inheritColor>
        <FormattedMessage id="navbar.status.success" />
      </NavbarStatusText>
    </NavbarStatus>
  );
});

const ErrorStatus = observer((): JSX.Element => {
  useHideStatus();

  return (
    <NavbarStatus appearance="error">
      <Icon size={IconSize.s18}>
        <ExternalIcon name="CircleXFilled" />
      </Icon>
      <NavbarStatusText size={TextSize.s14} inheritColor>
        <FormattedMessage id="navbar.status.error" />
      </NavbarStatusText>
    </NavbarStatus>
  );
});
