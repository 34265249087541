import { useCallback, useMemo } from 'react';
import { toast } from '../../components/toast-message';
import { RenameFileDialog } from '../../files-overview/rename-file-dialog';
import { File } from '../../fileupload/types';
import { useLayer } from '../../layer-manager/hooks';
import { LayerPortal } from '../../layer-manager/layer-portal';

export function useEvidenceRenameFileHandler(
  file: File,
  save: () => Promise<boolean>,
  autoSave: boolean = true
): [() => void, JSX.Element] {
  const renameFileLayer = useLayer('alert', {
    showShim: true,
    closeOnShimClick: true,
  });

  const rename = useCallback(
    async (name: string): Promise<true> => {
      file.FileName = name;
      if (autoSave) {
        const result = await save();

        if (result) {
          toast('toast.success.renameFile', 'success');
        } else {
          toast('toast.error.renameFile', 'error');
        }
      }
      return true;
    },
    [file, save, autoSave]
  );

  return useMemo(
    () => [
      () => {
        if (!renameFileLayer.isOpened) renameFileLayer.open();
      },
      <LayerPortal
        key="rename-file-layer"
        layerHandle={renameFileLayer}
        getContent={layer => (
          <RenameFileDialog close={layer.close} file={file} rename={rename} />
        )}
      />,
    ],
    [renameFileLayer, file, rename]
  );
}
