import {
  FieldSet,
  FormControlVariant,
  ErrorBox,
  LoginFormButtonsWrapper,
  Text,
  TextInput,
  TextSize,
  ExternalIcon,
} from '@yarmill/components';
import { FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '../components/link';
import { AsyncButton } from '../components/async-button/async-button';
import { CapsLockDetector } from '../components/caps-lock-detector/caps-lock-detector';
import { Tippy } from '../components/tippy/tippy';
import { LoginServerError } from './types';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { AsyncStatus } from '../api/mobx/request-store';
import { useAuthStore } from './hooks';
import { observer } from 'mobx-react-lite';
import { Location, useLocation } from '../routes/hooks';

export const LoginForm = observer(function LoginForm(): JSX.Element {
  const authStore = useAuthStore();
  const { state: locationState } = useLocation<{ from: Location }>();
  const [values, setValues] = useState({ username: '', password: '' });
  const [passwordFocused, setIsPasswordFocus] = useState(false);
  const [asyncState, setAsyncState] = useState<AsyncStatus>(AsyncStatus.idle);
  const [lastSubmittedValues, setLastSubmittedValues] = useState<
    { username: string; password: string } | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<
    LoginServerError | undefined
  >();
  const showDeactivatedUserMessage = authStore.shouldShowDeactivatedUserMessage;
  const intl = useIntl();

  async function handleSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { username, password } = values;
    setAsyncState(AsyncStatus.pending);
    try {
      await authStore.logIn(username, password);
      let redirectUrl = '/';
      if (
        locationState &&
        typeof locationState === 'object' &&
        'from' in locationState
      ) {
        const { from: prevLocation } = locationState;
        redirectUrl = `${prevLocation.pathname}${prevLocation.search}`;
      }
      window.location.href = redirectUrl;
    } catch (e: any) {
      setAsyncState(AsyncStatus.rejected);
      setErrorMessage(e?.response?.data);
      setLastSubmittedValues(values);
    }
  }

  return (
    <form id="login-form" onSubmit={handleSubmit} method="post">
      <FieldSet disabled={asyncState === AsyncStatus.pending}>
        {(errorMessage || showDeactivatedUserMessage) && (
          <ErrorBox>
            <FormattedHTMLMessage
              id={
                showDeactivatedUserMessage
                  ? 'auth.error.userDisabled'
                  : errorMessage?.error_description || 'auth.error.unknownError'
              }
            />
          </ErrorBox>
        )}
        <TextInput
          variant={FormControlVariant.big}
          autoFocus
          autoComplete="username"
          tabIndex={1}
          type="email"
          id="username"
          name="username"
          onChange={e => setValues(s => ({ ...s, username: e.target.value }))}
          placeholder={intl.formatMessage({ id: 'auth.username' })}
          data-cy="username"
          label={intl.formatMessage({ id: 'auth.username' })}
          icon={<ExternalIcon name="Send" />}
        />

        <CapsLockDetector>
          {isCapsLockActive => (
            <Tippy
              tooltipContent="auth.login.capsLock"
              isVisible={passwordFocused && isCapsLockActive}
              manual
              placement="right"
              theme="red"
              touch={false}
            >
              <TextInput
                variant={FormControlVariant.big}
                autoComplete="password"
                type="password"
                id="password"
                name="current-password"
                onFocus={() => setIsPasswordFocus(true)}
                onBlur={() => setIsPasswordFocus(false)}
                onChange={e =>
                  setValues(s => ({
                    ...s,
                    password: e.target.value,
                  }))
                }
                placeholder={intl.formatMessage({
                  id: 'auth.password',
                })}
                tabIndex={2}
                label={intl.formatMessage({ id: 'auth.password' })}
                data-cy="password"
                icon={<ExternalIcon name="Lock" />}
              />
            </Tippy>
          )}
        </CapsLockDetector>

        <LoginFormButtonsWrapper>
          <Link to="/forgottenPassword" data-cy="forgot-btn">
            <Text size={TextSize.s14} medium inheritColor>
              <FormattedMessage id="auth.forgottenPassword" />
            </Text>
          </Link>

          <AsyncButton
            variant={FormControlVariant.big}
            dataTest="submit"
            type="submit"
            disabled={
              Boolean(errorMessage) &&
              lastSubmittedValues?.password === values.password &&
              lastSubmittedValues?.username === values.username
            }
            loading={asyncState === AsyncStatus.pending}
            success={asyncState === AsyncStatus.resolved}
            tabIndex={3}
          >
            <FormattedMessage id="auth.loginButton" />
          </AsyncButton>
        </LoginFormButtonsWrapper>
      </FieldSet>
    </form>
  );
});
