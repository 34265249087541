import { UserRole } from '../users/types';
import {
  ItemWithPermissionScope,
  PermissionFilter,
  PermissionType,
} from './types';
import { CurrentUserStore } from '../users/mobx/current-user-store';

export const getDefaultPermission = (role?: UserRole | null): PermissionType =>
  role === 'coach' || role === 'admin' ? 'write' : 'read';
export function createPermissionScopeFilter<
  I,
  T extends ItemWithPermissionScope<I>,
>(currentUser?: CurrentUserStore): PermissionFilter<T> {
  return (item: T) => {
    if (!currentUser) {
      return item.permissionScope === null;
    }

    if (item.permissionScope === null) {
      return true;
    }

    if (Array.isArray(item.permissionScope)) {
      return item.permissionScope.some(scope => currentUser.isAllowedTo(scope));
    }

    return currentUser.isAllowedTo(item.permissionScope);
  };
}
