import {
  StyledTr,
  Table,
  Text,
  TextSize,
  TextTag,
  WhiteSpace,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import {
  MutableRefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';
import { FormattedMessageWithDefaultValues } from '../intl/formatted-message-with-default-values';
import { useLogger } from '../utils/logger/logger-context';
import { times } from '../utils/times';
import { CsvTableCell } from './csv-table-cell';
import { CsvTableFirstColumnCell } from './csv-table-first-column-cell';
import { CsvTableHeaderCell } from './csv-table-header-cell';
import { ExpandableTree } from './mobx/expandable-tree';
import {
  CsvTableConfiguration,
  CsvTableFirstColumnItem,
  CsvTableHeader,
  CsvTableRowData,
  CsvTable as ICsvTable,
} from './types';
import { formatRowDataByBusinessFormat } from './utils/format-row-data-by-business-format';

export interface CsvTableProps {
  table: ICsvTable;
  data: CsvTableRowData[] | undefined;
  expandable?: ExpandableTree<CsvTableFirstColumnItem> | undefined;
  descriptiveData?: Record<string, string>;
}

const CsvTableTr = styled(StyledTr)<{ isExpandable?: boolean }>`
  height: calc(24px + 2 * 4px);
  ${props => props.isExpandable && 'cursor: pointer;'};

  @media print {
    height: auto;
  }
`;

function getColWidth(
  config: CsvTableConfiguration,
  colIdx: number,
  header: CsvTableHeader[]
): string | number {
  const predefinedWidth = header[colIdx]?.Width;

  if (predefinedWidth !== undefined && predefinedWidth !== null) {
    return predefinedWidth;
  }

  const baseWidth = config.HasFirstColumn ? 80 : 100;

  return `${baseWidth / config.ColumnsCount}%`;
}

function getSkippedColumns(header: CsvTableHeader[]): number[] {
  const skipped: number[] = [];
  header.forEach((column, idx) => {
    if (column.ColSpan) {
      times(column.ColSpan - 1).forEach((_, colNum) =>
        skipped.push(idx + (colNum + 1))
      );
    }
  });

  return skipped;
}

function calculateRowSpan(
  data: CsvTableRowData[] | undefined,
  columnKey: string,
  rowIdx: number,
  skippedRowsMap: MutableRefObject<Record<string, number[]>>
): number | undefined {
  if (!data) {
    return;
  }

  const value = data[rowIdx][columnKey];
  let nextRowIdx = rowIdx + 1;
  let rowSpan = 1;

  while (nextRowIdx < data.length && data[nextRowIdx][columnKey] === value) {
    rowSpan++;
    nextRowIdx++;
  }

  if (rowSpan > 1) {
    skippedRowsMap.current[columnKey] = times(rowSpan - 1).map(
      (_, idx) => idx + rowIdx + 1
    );
  }

  return rowSpan;
}

function shouldRenderCell(
  columnKey: string,
  skippedRowsMap: MutableRefObject<Record<string, number[]>>,
  rowIdx: number
): boolean {
  if (!columnKey) {
    return false;
  }

  return !skippedRowsMap.current[columnKey]?.includes(rowIdx);
}

export const CsvTable = observer<CsvTableProps, HTMLTableElement>(
  forwardRef<HTMLTableElement, CsvTableProps>(
    function CsvTable(props, ref): JSX.Element {
      const { table, data, expandable, descriptiveData } = props;
      const config = table.Configuration;
      const firstColumn = table.FirstColumn;
      const rowsCount = table.Configuration.RowsCount || data?.length || 0;
      const hasExpandableControls = Boolean(expandable);
      const skippedHeaderColumns = useRef<number[]>(
        getSkippedColumns(table.Header)
      );
      const skippedRowsMap = useRef<Record<string, number[]>>({});
      const logger = useLogger();

      const shouldFormatRowData = useMemo(
        () => table.Header?.some(def => def.TranslateValue),
        [table]
      );

      useEffect(() => {
        skippedHeaderColumns.current = getSkippedColumns(table.Header);
      }, [table.Header]);

      useEffect(() => {
        if (data) {
          skippedRowsMap.current = {};
        }
      }, [data]);

      return (
        <Table
          ref={ref}
          colgroup={
            <>
              {config.HasFirstColumn && <col />}
              {times(config.ColumnsCount).map((_, idx) =>
                skippedHeaderColumns.current.includes(idx) ? null : (
                  <col
                    key={idx}
                    span={table.Header[idx]?.ColSpan ?? undefined}
                    width={getColWidth(config, idx, table.Header) ?? undefined}
                  />
                )
              )}
            </>
          }
          head={
            config.HasHeader ? (
              <>
                {config.HasFirstColumn && (
                  <CsvTableHeaderCell
                    config={config}
                    alignment="left"
                    hasExpandableControls={hasExpandableControls}
                  >
                    <Text
                      size={TextSize.s12}
                      bold
                      whiteSpace={WhiteSpace.preWrap}
                      tag={TextTag.div}
                    >
                      {table.Configuration.Title && (
                        <FormattedMessageWithDefaultValues
                          id={table.Configuration.Title}
                          values={descriptiveData}
                        />
                      )}
                    </Text>
                  </CsvTableHeaderCell>
                )}
                {table.Header.map((column, idx) =>
                  skippedHeaderColumns.current.includes(idx) ? null : (
                    <CsvTableHeaderCell
                      key={idx}
                      config={config}
                      column={column}
                      alignment={column.HeaderAlignment}
                      hasExpandableControls={hasExpandableControls}
                      descriptiveData={descriptiveData}
                    />
                  )
                )}
              </>
            ) : undefined
          }
        >
          {times(rowsCount).map((_, rowIdx) => {
            const rowData = data?.[rowIdx];
            const formattedRowData = shouldFormatRowData
              ? formatRowDataByBusinessFormat(
                  rowData ?? {},
                  table.Header,
                  logger
                )
              : rowData;

            return (
              (!expandable || expandable.isNodeVisible(rowIdx)) && (
                <CsvTableTr
                  key={rowIdx}
                  isExpandable={expandable?.isNodeExpandable(rowIdx)}
                  onClick={
                    expandable?.isNodeExpandable(rowIdx)
                      ? () =>
                          expandable.isNodeExpanded(rowIdx)
                            ? expandable?.collapseNode(rowIdx)
                            : expandable?.expandNode(rowIdx)
                      : undefined
                  }
                >
                  {config.HasFirstColumn && (
                    <CsvTableFirstColumnCell
                      item={firstColumn[rowIdx]}
                      labelIsTranslateString={config.ColumnLabels}
                      expandable={expandable}
                      rowIndex={rowIdx}
                      alignment={
                        table.FirstColumn[rowIdx]?.ValueAlignment ||
                        table.Header[rowIdx]?.ValueAlignment
                      }
                    />
                  )}
                  {times(config.ColumnsCount).map(
                    (_, columnIdx) =>
                      shouldRenderCell(
                        table.Header[columnIdx]?.ColumnName,
                        skippedRowsMap,
                        rowIdx
                      ) && (
                        <CsvTableCell
                          key={columnIdx}
                          headerConfig={table.Header[columnIdx]}
                          name={
                            config.HasFirstColumn
                              ? 'unknown'
                              : table.Header[columnIdx]?.ColumnName
                          }
                          borderRight={
                            config.HasFirstColumn
                              ? false
                              : table.Header[columnIdx]?.BorderRight
                          }
                          borderLeft={
                            config.HasFirstColumn
                              ? false
                              : table.Header[columnIdx]?.BorderLeft
                          }
                          color={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.Color
                              : table.Header[columnIdx]?.Color
                          }
                          unit={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.DataType
                              : table.Header[columnIdx]?.DataType
                          }
                          businessFormat={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.BusinessFormat
                              : table.Header[columnIdx]?.BusinessFormat
                          }
                          format={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.Format
                              : table.Header[columnIdx]?.Format
                          }
                          formattedRowData={formattedRowData}
                          data={
                            rowData?.[table.Header[columnIdx]?.ColumnName || '']
                          }
                          rowData={rowData}
                          alignment={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.ValueAlignment
                              : table.Header[columnIdx]?.ValueAlignment
                          }
                          translateValue={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.TranslateValue
                              : table.Header[columnIdx]?.TranslateValue
                          }
                          link={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.Link
                              : table.Header[columnIdx]?.Link
                          }
                          onLinkClick={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.HandleLinkClick
                              : table.Header[columnIdx]?.HandleLinkClick
                          }
                          rowSpan={
                            table.Header[columnIdx]?.GroupSameValues
                              ? calculateRowSpan(
                                  data,
                                  table.Header[columnIdx]?.ColumnName || '',
                                  rowIdx,
                                  skippedRowsMap
                                )
                              : undefined
                          }
                          verticalAlignment={
                            table.Header[columnIdx]?.ValueVerticalAlignment
                          }
                          noWrap={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.NoWrap
                              : table.Header[columnIdx]?.NoWrap
                          }
                          getTooltipContent={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.GetTooltipContent
                              : table.Header[columnIdx]?.GetTooltipContent
                          }
                          getColor={
                            config.HasFirstColumn
                              ? table.FirstColumn[rowIdx]?.GetColor
                              : table.Header[columnIdx]?.GetColor
                          }
                        />
                      )
                  )}
                </CsvTableTr>
              )
            );
          })}
          {data && data.length === 0 && (
            <CsvTableTr>
              <CsvTableCell
                data="evidence.table.noData"
                colSpan={config.ColumnsCount}
                translateValue
                rowData={{}}
                alignment="left"
                name="no-data-column"
                formattedRowData={{}}
              />
            </CsvTableTr>
          )}
        </Table>
      );
    }
  )
);
