import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { RootStore } from '../../app/mobx/root-store';
import { Season, SeasonId } from '../types';
import { AsyncStatus } from '../../api/mobx/request-store';
import { getSeasons } from '../api/get-seasons';
import { SeasonStore } from './season-store';
import { getWeekEndString, getWeekStartString } from '../../diary/utils';
import { InitialConfigError } from '../../error/initial-config-error';

export class SeasonsStore {
  private readonly rootStore: RootStore;

  @observable
  private _seasons = new Map<SeasonId, SeasonStore>();

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  public async loadSeasons(): Promise<void> {
    this._status = AsyncStatus.pending;
    const request = this.rootStore.requestsStore.createRequest(() =>
      getSeasons()
    );

    const response = await request.getResponse();
    if (response) {
      runInAction(() => {
        this.setSeasons(response);
        this._status = AsyncStatus.resolved;
      });
    } else {
      throw new InitialConfigError('Unable to load seasons', request.error);
    }
  }

  @action
  public setSeasons(seasons: Season[]): void {
    seasons.forEach(item =>
      this._seasons.set(item.SeasonId, new SeasonStore(this.rootStore, item))
    );
  }

  @computed
  public get status(): AsyncStatus {
    return this._status;
  }

  public getSeasonById(id: SeasonId): SeasonStore | undefined {
    return this._seasons.get(id);
  }

  public getSeasonByYear(year: number): SeasonStore | undefined {
    return Array.from(this._seasons.values()).find(
      season => season.startYear === year
    );
  }

  public getSeasonByDate(dateString: string): SeasonStore | undefined {
    for (const [, season] of this._seasons) {
      if (season.isInSeason(dateString)) {
        return season;
      }
    }

    return undefined;
  }

  public getSeasonByWeek(dateString: string): SeasonStore | undefined {
    let season = this.getSeasonByDate(dateString);

    if (!season) {
      season = this.getSeasonByDate(getWeekStartString(dateString));
    }

    if (!season) {
      season = this.getSeasonByDate(getWeekEndString(dateString));
    }

    return season;
  }

  public getFutureSeasons(currentSeason: Season | SeasonStore): SeasonStore[] {
    const startYear =
      currentSeason instanceof SeasonStore
        ? currentSeason.startYear
        : currentSeason?.StartYear;

    return Array.from(this._seasons.values()).filter(season =>
      Boolean(currentSeason && season.startYear > startYear)
    );
  }

  @computed
  public get seasons(): SeasonStore[] {
    return Array.from(this._seasons.values());
  }
}
