import { useCallback } from 'react';
import { Location } from 'history';

import { NavItem } from '../nav-item';
import { useDefaultCommonSearchParams } from '../hooks';
import { appendDefaultSearchParams } from '../utils';
import { ExternalIconName } from '@yarmill/components';

export interface EvidenceLinkProps {
  moduleKey: string;
  icon?: ExternalIconName;
  className?: string;
  onClick?(): void;
}

export function EvidenceLink(props: EvidenceLinkProps): JSX.Element {
  const { onClick, moduleKey, className, icon } = props;
  const defaultSearchParams = useDefaultCommonSearchParams();

  const link = useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultSearchParams
      );
      return `/evidence/${moduleKey}?${searchParams}`;
    },
    [defaultSearchParams, moduleKey]
  );

  return (
    <NavItem
      to={link}
      moduleKey={`evidence/${moduleKey}`}
      icon={icon ?? 'Table'}
      onClick={onClick}
      className={className}
    />
  );
}
