import { FormattedMessage, useIntl } from 'react-intl';
import { usePlannerStore } from './planner-store-context';
import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SelectAllButton,
  SelectionList,
  SelectionListItem,
} from '../components-2/selection-list';
import { TagInput, TagInputValue } from '../components-2/tag-input';
import { normalizeString } from '../command-palette/utils';
import {
  AttributeIcon,
  AttributesGroupHeading,
  SelectAllButtonWrapper,
} from '../#-components/planner/attributes';
import { IconSize } from '@yarmill/components';
import { Search, Tag } from '@yarmill/icons-2';
import { RIGHT_PANEL_TRANSITION_DURATION } from '../components-2/right-panel-transition';
import {
  KeyboardListNavigationContext,
  useKeyboardListNavigation,
} from '../utils/use-keyboard-list-navigation';
import {
  FilteredRightPanelScrollContainer,
  RightPanelContentWrapper,
} from '../components-2/right-panel';
import { useElementHeight } from '../utils/use-element-height';

interface AttributesSelectorProps {
  readonly selectedAttributes: number[] | null;
  readonly showSelectAllButton?: boolean;
  readonly onChange: (attributes: (number | string)[]) => void;
  readonly onEsc?: () => void;
}
export function AttributesSelector({
  selectedAttributes,
  showSelectAllButton,
  onChange,
  onEsc,
}: AttributesSelectorProps): JSX.Element {
  const intl = useIntl();
  const plannerStore = usePlannerStore();
  const [filter, setFilter] = useState('');
  const filterRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputHeight = useElementHeight(filterRef);
  const keyboardNavigationContext = useKeyboardListNavigation(onEsc);
  const { reset } = keyboardNavigationContext;
  const filterAttributes = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target instanceof HTMLInputElement) {
        reset();
        setFilter(e.target.value);
      }
    },
    [reset]
  );

  const selectAttribute = useCallback(
    (item: SelectionListItem<number>) => {
      const idx = selectedAttributes?.indexOf(item.value) ?? -1;
      if (idx !== -1) {
        const updatedValue = [...(selectedAttributes ?? [])];
        updatedValue.splice(idx, 1);
        onChange(updatedValue);
      } else {
        onChange([...(selectedAttributes ?? []), item.value]);
      }
    },
    [selectedAttributes, onChange]
  );

  const selectAll = useCallback(() => {
    onChange(plannerStore.attributeValues.map(a => a.valueId));
  }, [plannerStore, onChange]);

  const unSelectAll = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const onRemove = useCallback(
    (selectedAttributes: TagInputValue[]) => {
      onChange(
        selectedAttributes.map(attr =>
          typeof attr === 'string' ? attr : attr.value
        )
      );
    },
    [onChange]
  );

  useEffect(() => {
    setTimeout(
      () => inputRef.current?.focus({ preventScroll: true }),
      RIGHT_PANEL_TRANSITION_DURATION
    );
  }, []);

  return (
    <KeyboardListNavigationContext.Provider value={keyboardNavigationContext}>
      <RightPanelContentWrapper {...keyboardNavigationContext.getListProps()}>
        <TagInput
          ref={filterRef}
          value={
            selectedAttributes
              ?.map(attr => ({
                label: intl.formatMessage({
                  id:
                    plannerStore.attributeValues.find(a => a.valueId === attr)
                      ?.value ?? 'unknown-attribute',
                }),
                value: attr,
              }))
              .filter(x => Boolean(x)) ?? []
          }
          name="search-attribute"
          tagAppearance="sand"
          placeholder={intl.formatMessage({ id: 'planner.searchAttributes' })}
          icon={<Search />}
          onInput={filterAttributes}
          onRemove={onRemove}
          searchValue={filter}
          inputRef={inputRef}
          isSearchInput
          {...keyboardNavigationContext.getListProps()}
        />
        <FilteredRightPanelScrollContainer inputHeight={inputHeight}>
          {showSelectAllButton && (
            <SelectAllButtonWrapper>
              <SelectAllButton
                isAllSelected={
                  plannerStore.attributeValues.length ===
                  selectedAttributes?.length
                }
                selectAll={selectAll}
                unSelectAll={unSelectAll}
                selectAllLabel={intl.formatMessage({
                  id: 'planner.eventTypeFilter.selectAll',
                })}
                unSelectAllLabel={intl.formatMessage({
                  id: 'planner.eventTypeFilter.unSelectAll',
                })}
              />
            </SelectAllButtonWrapper>
          )}
          {plannerStore.groupedAttributes.map(group => {
            const filteredAttributes = filter
              ? group.values.filter(a =>
                  normalizeString(intl.formatMessage({ id: a.value }))
                    .toLocaleLowerCase()
                    .includes(normalizeString(filter).toLocaleLowerCase())
                )
              : group.values;

            if (filteredAttributes.length === 0) {
              return null;
            }

            const items: SelectionListItem<number>[] = filteredAttributes.map(
              attr => ({
                icon: (
                  <AttributeIcon size={IconSize.s16}>
                    <Tag />
                  </AttributeIcon>
                ),
                title: intl.formatMessage({ id: attr.value }),
                value: attr.valueId,
                isSelected: selectedAttributes?.includes(attr.valueId),
              })
            );

            return (
              <Fragment key={group.attributeId}>
                <AttributesGroupHeading as="h3" appearance="button10" upperCase>
                  <FormattedMessage
                    id={group.attributeCode ?? 'missing group name'}
                  />
                </AttributesGroupHeading>
                <SelectionList
                  items={items}
                  onClick={selectAttribute}
                  inputType="checkbox"
                  inputAppearance="sand"
                />
              </Fragment>
            );
          })}
        </FilteredRightPanelScrollContainer>
      </RightPanelContentWrapper>
    </KeyboardListNavigationContext.Provider>
  );
}
