import moment from 'moment';
import { useCallback } from 'react';
import { DaySelector } from '../components/day-selector';
import { ROUTE_DATE_FORMAT } from '../diary/utils';
import { trackChangeWeekDayClick } from '../google-analytics/utils';
import { useCurrentWeek } from '../utils/use-current-week';
import { generateUrl } from '../utils/generate-url';
import { WEEK_SEARCH_PARAM } from '../routes/types';
import { useHistory } from '../routes/hooks';

export function WeekDaySelector(): JSX.Element {
  const week = useCurrentWeek();
  const history = useHistory();

  const setWeek = useCallback(
    (day: moment.Moment): void => {
      const date = day.format(ROUTE_DATE_FORMAT);
      const link = generateUrl({ [WEEK_SEARCH_PARAM]: date });
      trackChangeWeekDayClick(date);
      history.push(link);
    },
    [history]
  );

  const onPrevClick = useCallback(() => {
    const prevDay = moment(week).subtract(1, 'days');
    setWeek(prevDay);
  }, [week, setWeek]);

  const onNextClick = useCallback(() => {
    const prevDay = moment(week).add(1, 'days');
    setWeek(prevDay);
  }, [week, setWeek]);

  return (
    <DaySelector
      onPrevClick={onPrevClick}
      onNextClick={onNextClick}
      date={week}
    />
  );
}
