import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { AnnouncementStore } from './mobx/announcement-store';
import { observer } from 'mobx-react-lite';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';
import { useEffect } from 'react';
import { CloseButton } from '../components/close-button/close-button';

interface StyledImageWrapperProps {
  readonly maxWidth?: string;
}

const StyledCloseButtonWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
`;

const Img = styled.img<StyledImageWrapperProps>`
  width: 80vw;
  max-width: ${({ maxWidth }) => maxWidth || 500}px;
  height: auto;
`;

interface ImageAnnouncementProps {
  announcement: AnnouncementStore;
}

export const ImageAnnouncement = observer(function ImageAnnouncement(
  props: ImageAnnouncementProps
): JSX.Element | null {
  const { announcement } = props;
  const intl = useIntl();
  const alertLayer = useLayer('alert', {
    autoSize: true,
    showShim: true,
    closeOnShimClick: true,
    onClose: () => announcement.close(),
  });

  const imgSrc = intl.formatMessage({
    id: `announcement.${announcement.code}.image`,
  });

  useEffect(() => {
    if (!alertLayer.isOpened) {
      alertLayer.open();
    }
  }, [alertLayer]);

  return (
    <LayerPortal
      layerHandle={alertLayer}
      getContent={layer => (
        <>
          <Img
            maxWidth={intl.formatMessage({
              id: `announcement.${announcement.code}.imageMaxWidth`,
              defaultMessage: '500',
            })}
            src={imgSrc}
            alt={intl.formatMessage({
              id: `announcement.${announcement.code}.imageAltText`,
            })}
          />

          <StyledCloseButtonWrapper>
            <CloseButton onClick={layer.close} hideText />
          </StyledCloseButtonWrapper>
        </>
      )}
    />
  );
});
