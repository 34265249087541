import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  GroupsHeaderButtonsContainer,
  GroupsHeaderLayout,
  Icon,
  IconSize,
  Text,
  TextInput,
  TextSize,
} from '@yarmill/components';
import * as React from 'react';
import {
  IntlContext,
  IntlShape,
  WrappedComponentProps,
  injectIntl,
} from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ServerError } from '../app/app-types';
import { WithRootStoreProps, withRootStore } from '../app/root-store-context';
import { Tippy } from '../components/tippy/tippy';
import { toast } from '../components/toast-message';
import {
  trackRemoveGroupCancelClick,
  trackRemoveGroupClick,
  trackRemoveGroupConfirmClick,
  trackRenameGroupCancel,
  trackRenameGroupClick,
  trackRenameGroupSubmit,
} from '../google-analytics/utils';
import { subscribeGroupCalendar } from '../google-calendar/utils';
import { Status } from '../navbar/status';
import { CurrentUserStore } from '../users/mobx/current-user-store';

export interface GroupsHeaderProps {
  groupId: number;
}

export interface GroupsHeaderState {
  isEdited: boolean;
  name: string;
  disabled: boolean;
  error: boolean;
  errorMessages: ServerError[];
}

class GroupsHeader extends React.PureComponent<
  GroupsHeaderProps &
    RouteComponentProps &
    WithRootStoreProps &
    WrappedComponentProps,
  GroupsHeaderState
> {
  public constructor(
    props: GroupsHeaderProps &
      RouteComponentProps &
      WithRootStoreProps &
      WrappedComponentProps
  ) {
    super(props);
    this.state = {
      isEdited: false,
      name: '',
      disabled: false,
      error: false,
      errorMessages: [],
    };
  }

  public componentDidUpdate(prevProps: GroupsHeaderProps): void {
    if (this.props.groupId !== prevProps.groupId) {
      this.setState(s => ({ ...s, isEdited: false }));
    }
  }

  public render(): JSX.Element {
    const { groupId, rootStore } = this.props;
    const { isEdited } = this.state;
    const group = rootStore.groupsStore.getGroupById(groupId);
    const currentUser = rootStore.currentUserStore;
    const showGoogleCalendar = Boolean(
      rootStore.configStore.availableExternalServices.find(
        service => service.appCode === 'google'
      )
    );

    const nameError = this.state.error
      ? this.state.errorMessages.find(e => e.Field === 'Name')
      : undefined;

    return (
      <IntlContext.Consumer>
        {intl => (
          <GroupsHeaderLayout>
            {isEdited ? (
              <div>
                <TextInput
                  id="group-name"
                  autoFocus
                  type="text"
                  value={this.state.name}
                  required
                  noError={!this.state.error}
                  error={
                    nameError
                      ? intl.formatMessage(
                          { id: nameError.Id },
                          { group: nameError.Values?.name }
                        )
                      : undefined
                  }
                  noLabel
                  onChange={this.onNameChange}
                  onKeyDown={this.onKeyDown}
                />
              </div>
            ) : (
              <Text
                size={TextSize.s18}
                onDoubleClick={currentUser.isAdmin ? this.startEdit : undefined}
                bold
              >
                {group?.name}
              </Text>
            )}
            <GroupsHeaderButtonsContainer>
              <Status />
              {isEdited
                ? this.renderEditButtons(showGoogleCalendar, currentUser)
                : this.renderNonEditButtons(
                    intl,
                    showGoogleCalendar,
                    currentUser
                  )}
            </GroupsHeaderButtonsContainer>
          </GroupsHeaderLayout>
        )}
      </IntlContext.Consumer>
    );
  }

  private readonly renderNonEditButtons = (
    intl: IntlShape,
    showGoogleCalendar: boolean,
    currentUser: CurrentUserStore
  ): (JSX.Element | null)[] => [
    this.renderEditButton(
      this.startEdit,
      <ExternalIcon name="Pencil" />,
      'settings.groups.rename',
      1,
      currentUser
    ),
    this.renderEditButton(
      () => this.maybeRemoveGroup(intl),
      <ExternalIcon name="Trash" />,
      'settings.groups.delete',
      2,
      currentUser
    ),
    showGoogleCalendar ? this.renderCalendarButton(currentUser) : null,
  ];

  private readonly renderEditButtons = (
    showGoogleCalendar: boolean,
    currentUser: CurrentUserStore
  ): (JSX.Element | null)[] => [
    this.renderEditButton(
      this.saveEdit,
      <ExternalIcon name="Pencil" />,
      'settings.groups.renameSave',
      3,
      currentUser
    ),
    this.renderEditButton(
      this.cancelEdit,
      <ExternalIcon name="X" />,
      'settings.groups.renameCancel',
      4,
      currentUser
    ),
    showGoogleCalendar ? this.renderCalendarButton(currentUser) : null,
  ];

  private readonly renderEditButton = (
    onClick: () => void,
    icon: JSX.Element,
    message: string,
    key: number,
    currentUser: CurrentUserStore
  ): JSX.Element => (
    <Tippy
      tooltipContent={
        !currentUser.isAdmin
          ? 'settings.groups.tooltip.adminAction.text'
          : message
      }
      key={key}
      Wrapper="div"
      touch={false}
    >
      <Button
        disabled={!currentUser.isAdmin}
        appearance={ButtonAppearance.Link}
        square
        onClick={currentUser.isAdmin ? onClick : undefined}
      >
        <Icon size={IconSize.s16}>{icon}</Icon>
      </Button>
    </Tippy>
  );

  private readonly renderCalendarButton = (currentUser: CurrentUserStore) => {
    const { groupId, rootStore } = this.props;
    const user = rootStore.usersStore.getUserById(currentUser.id);
    const isInGroup = user?.groups.find(group => group.id === groupId);

    return (
      <Tippy
        tooltipContent={
          !isInGroup
            ? 'settings.groups.subscribeCalendar.notInGroup'
            : currentUser?.data?.ExternalServices?.google
              ? 'settings.groups.subscribeCalendar'
              : 'settings.groups.subscribeCalendar.disabled'
        }
        key={5}
        Wrapper="div"
        touch={false}
      >
        <Button
          disabled={!isInGroup || !currentUser?.data?.ExternalServices?.google}
          appearance={ButtonAppearance.Link}
          square
          onClick={
            isInGroup && currentUser?.data?.ExternalServices?.google
              ? this.subscribeGroupCalendar
              : undefined
          }
        >
          <Icon size={IconSize.s16}>
            <ExternalIcon name="Calendar" />
          </Icon>
        </Button>
      </Tippy>
    );
  };

  private readonly startEdit = (): void => {
    trackRenameGroupClick();
    const group = this.props.rootStore.groupsStore.getGroupById(
      this.props.groupId
    );
    this.setState(s => ({ ...s, isEdited: true, name: group?.name || '' }));
  };

  private readonly cancelEdit = (): void => {
    trackRenameGroupCancel();
    this.setState(s => ({ ...s, isEdited: false, name: '' }));
  };

  private readonly saveEdit = async (): Promise<void> => {
    const { groupId } = this.props;
    const group = this.props.rootStore.groupsStore.getGroupById(groupId);

    if (!group) {
      return;
    }

    this.setState(s => ({ ...s, disabled: true }));
    const newName: string = this.state.name.trim();
    trackRenameGroupSubmit();
    if (this.state.name === group.name || !newName) {
      this.setState(s => ({
        ...s,
        isEdited: false,
        name: '',
        disabled: false,
      }));

      return;
    }

    const newGroup = { Name: newName };
    const result = await group.update(newGroup);
    if (result === true) {
      toast('toast.success.renameGroup', 'success', { group: newName });
      this.setState(s => ({
        ...s,
        isEdited: false,
        disabled: false,
        name: '',
      }));
    } else {
      toast('toast.error.renameGroup', 'error', { group: newName });
      this.setState(s => ({
        ...s,
        disabled: false,
        error: true,
        errorMessages: result,
      }));
    }
  };

  private readonly onNameChange = (
    evt: React.FormEvent<HTMLInputElement>
  ): void => {
    const { target } = evt;
    if (!(target instanceof HTMLInputElement)) {
      return;
    }
    const { value } = target;
    this.setState(s => ({ ...s, name: value }));
  };

  private readonly onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (e.key === 'Enter') {
      // If Enter
      this.saveEdit();
    } else if (e.key === 'Escape') {
      this.cancelEdit();
    }
  };

  private readonly maybeRemoveGroup = async (
    intl: IntlShape
  ): Promise<void> => {
    const { history, rootStore, groupId } = this.props;
    trackRemoveGroupClick();
    const group = rootStore.groupsStore.getGroupById(groupId);
    if (!group) {
      return;
    }
    const message = intl.formatMessage(
      { id: 'settings.groups.deleteConfirm' },
      { group: group.name }
    );
    if (window.confirm(message)) {
      trackRemoveGroupConfirmClick();
      const success = await group.delete();
      if (success) {
        toast('toast.success.deleteGroup', 'success', {
          group: group.name,
        });
        history.push('/settings/groups');
      } else {
        toast('toast.error.deleteGroup', 'error', { group: group.name });
      }
    } else {
      trackRemoveGroupCancelClick();
    }
  };

  private readonly subscribeGroupCalendar = async (): Promise<void> => {
    const { rootStore, groupId } = this.props;
    const group = rootStore.groupsStore.getGroupById(groupId);
    if (!group) {
      return;
    }
    const googleCalendarService = rootStore.googleCalendarService;
    await subscribeGroupCalendar(googleCalendarService, group);
  };
}

const Composed = withRouter(injectIntl(withRootStore(GroupsHeader)));

export { Composed as GroupsHeader };
