import { useAvatar } from '../profile/hooks';
import { AvatarImg } from '../components-2/okrs/detail/okr-form';
import { forwardRef } from 'react';

interface AvatarProps {
  avatar?: string | null;
}
export const Avatar = forwardRef<HTMLImageElement, AvatarProps>(function Avatar(
  { avatar },
  ref
): JSX.Element {
  const src = useAvatar(avatar);
  return <AvatarImg src={src} alt="avatar" ref={ref} />;
});
