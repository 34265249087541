import { RootStore } from '../../app/mobx/root-store';
import { AttachmentId, AttachmentValue } from '../../training-day/types';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { removeAttachment } from '../api/remove-attachment';
import { AttachmentListStore } from './attachment-list-store';
import { makeObservable, observable, runInAction } from 'mobx';
import { renameAttachment } from '../api/rename-attachment';
import {
  isServerError,
  ServerErrorResponse,
} from '../../api/server-error-response';
import { AxiosError } from 'axios';

export class AttachmentStore {
  private readonly rootStore: RootStore;
  private readonly attachmentsListStore: AttachmentListStore;
  private request: RequestStore<boolean> | null = null;
  @observable
  public readonly attachment: AttachmentValue;

  constructor(
    rootStore: RootStore,
    attachmentsListStore: AttachmentListStore,
    attachment: AttachmentValue
  ) {
    this.rootStore = rootStore;
    this.attachmentsListStore = attachmentsListStore;
    this.attachment = attachment;
    makeObservable(this);
  }

  public get id(): AttachmentId {
    return this.attachment.TrainingDayAttributeId;
  }

  public async remove(): Promise<boolean> {
    if (this.request?.status === AsyncStatus.rejected) {
      this.rootStore.requestsStore.removeRequest(this.request);
    }

    this.request = this.rootStore.requestsStore.createRequest(() =>
      removeAttachment({ attachmentId: this.attachment.TrainingDayAttributeId })
    );
    const response = await this.request?.getResponse();

    if (response) {
      this.attachmentsListStore.removeAttachment(this);
      return true;
    } else {
      return false;
    }
  }

  public async rename(newName: string): Promise<true | ServerErrorResponse> {
    const request = this.rootStore.requestsStore.createRequest(() =>
      renameAttachment({
        AttachmentId: this.attachment.TrainingDayAttributeId,
        FileName: newName,
      })
    );

    await request.getResponse();

    return runInAction(() => {
      if (request.status === AsyncStatus.resolved) {
        this.attachment.FileName = newName;
        return true;
      } else {
        const errorData = (request?.error as AxiosError)?.response?.data;

        return new ServerErrorResponse(
          'Unable to rename attachment',
          isServerError(errorData)
            ? errorData
            : {
                Id: 'unknownError',
                Values: {},
                Field: '',
              }
        );
      }
    });
  }

  public isAllowedToDeleteFile(file: AttachmentValue): boolean {
    return this.attachmentsListStore.isAllowedToDeleteFile(file);
  }
}
