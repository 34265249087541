import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { HeartRateZonesSet } from '../types';
import { UserId } from '../../users/types';

export function getHeartRateZones(
  userId: UserId
): AxiosPromise<HeartRateZonesSet[]> {
  return axios.get(`${process.env.REACT_APP_API_ENV_URL}/api/application`, {
    params: {
      userId,
      appName: 'hrZonesApp',
    },
  });
}
