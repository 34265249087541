import { AxiosPromise } from 'axios';
import { axios } from '../../../../api/axios';
import { ApiFilters } from '../../../types';
import { StressCompare } from '../types';

export function loadStressCompare(
  filters: ApiFilters,
  isForGroup?: boolean
): AxiosPromise<StressCompare[]> {
  return axios.post(
    `/api/Analytics/SLCR/DiaryFactsStressCompare${isForGroup ? 'Groups' : ''}`,
    filters
  );
}
