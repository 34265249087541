import styled from 'styled-components';
import { Button } from '../button';

export const ExportButtonWrapper = styled.div`
  display: none;

  @media (min-width: 1180px) {
    display: block;
  }
`;

export const ExportButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
