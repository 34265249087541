import { IconSize } from '@yarmill/components';
import { Coach } from '@yarmill/icons-2';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { SelectedUsers } from '../components-2/selected-users';
import {
  CoachSymbol,
  RoleAvatarIcon,
} from '../components-2/sidebar-navigation';
import { Tippy } from '../components/tippy/tippy';
import { useUsersStore } from '../users/hooks';
import { UserStore } from '../users/mobx/user-store';
import { UserId } from '../users/types';
import { Avatar } from './avatar';

interface SupervisorsProps {
  readonly userIds: (UserId | UserStore)[];
  readonly hideText?: boolean;
}

export const Supervisors = observer(function Supervisors({
  userIds,
  hideText,
}: SupervisorsProps): JSX.Element {
  const intl = useIntl();
  const usersStore = useUsersStore();
  const users = userIds
    .map(id => (id instanceof UserStore ? id : usersStore.getUserById(id)))
    .filter((u): u is UserStore => Boolean(u));

  const avatars = users.map(u => (
    <Tippy
      key={u.id}
      tooltipContent={u.displayName}
      noWrapper
      translateValue={false}
    >
      <RoleAvatarIcon isCoach={!u.isAthlete} size={IconSize.s24}>
        <>
          <Avatar key={u.id} avatar={u.avatar} />
          {!u.isAthlete && (
            <CoachSymbol size={IconSize.s10}>
              <Coach />
            </CoachSymbol>
          )}
        </>
      </RoleAvatarIcon>
    </Tippy>
  ));

  return (
    <SelectedUsers
      avatars={avatars}
      name={users.length === 1 ? users[0].displayName : undefined}
      noUserText={intl.formatMessage({
        id: 'okrs.supervisors.noSupervisor',
      })}
      hideText={hideText}
    />
  );
});
