import { PatternInputProps, PatternInput } from '@yarmill/components';
import { forwardRef } from 'react';
import { validateTimeOfDayValue } from '../../evidence/validation';

export const TIME_OF_DAY_PATTERN = 'HH:MM';

export const PatternInputTimeOfDay = forwardRef<
  HTMLInputElement,
  PatternInputProps
>(function PatternInputTime(props, ref): JSX.Element {
  return (
    <PatternInput
      {...props}
      ref={ref}
      pattern={TIME_OF_DAY_PATTERN}
      delimiter=":"
      validateValue={validateTimeOfDayValue}
    />
  );
});
