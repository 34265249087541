import { useCallback } from 'react';
import { Location } from 'history';

import { NavItem } from '../nav-item';
import { useDefaultCommonSearchParams } from '../hooks';
import {
  appendDefaultSearchParams,
  findActiveReportingModulePage,
} from '../utils';
import { useIntl } from 'react-intl';
import { useModule } from '../../modules/hooks';

import { useReportingStore } from '../../reporting/hooks/use-reporting-store';

export interface ReportingLinkProps {
  reportingPage: string;
  className?: string;
  linkName: string;
  onClick?(): void;
}

export function ReportingLink(props: ReportingLinkProps): JSX.Element {
  const { onClick, reportingPage, className, linkName } = props;
  const defaultSearchParams = useDefaultCommonSearchParams();
  const intl = useIntl();
  const reportingStore = useReportingStore();
  const reportingModule = useModule('reporting');
  const reportingLinks = reportingStore.navigationLinks;

  const link = useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultSearchParams
      );
      return `/reporting/${reportingPage}?${searchParams}`;
    },
    [defaultSearchParams, reportingPage]
  );

  const isLinkActive = useCallback(
    (pathname: string) => {
      if (!pathname.includes('reporting')) {
        return false;
      }
      const [, , moduleCode] = pathname.split('/');

      const activeModulePage = findActiveReportingModulePage(
        reportingModule,
        reportingLinks,
        moduleCode
      );

      return Boolean(
        activeModulePage?.dashboard.ReportPageCode === reportingPage
      );
    },
    [reportingModule, reportingLinks, reportingPage]
  );

  return (
    <NavItem
      to={link}
      moduleKey={`reporting/${reportingPage}`}
      linkText={intl.formatMessage({ id: linkName })}
      icon="ChartLine"
      onClick={onClick}
      className={className}
      isLinkActive={isLinkActive}
    />
  );
}
