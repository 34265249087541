import * as React from 'react';
import styled from 'styled-components';
import { AsyncStatus } from '../helpers';
import { Text, TextAlignment, TextSize, WhiteSpace } from '../text';
import { ToastError } from './toast-error';
import { ToastSuccess } from './toast-success';
import { ToastProgress } from './toast-progress';
import { getProgressText } from './utils';
import { ToastCancel, ToastCancelOverlay } from './toast-cancel';

export interface AsyncToastMessageProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  progress?: number;
  status: AsyncStatus;
  'data-cy'?: string;
}

const StyledToastContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .toast-cancel {
    display: none;
  }
  :hover {
    .toast-cancel {
      display: block;
    }
    .toast-progress {
      display: none;
    }
  }
`;

const StyledFileInfo = styled.div<{ status: AsyncStatus }>`
  max-width: ${({ status }) => (status === AsyncStatus.idle ? 100 : 80)}%;
`;

const StyledTextWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

function mapStatus(status: AsyncStatus): string {
  switch (status) {
    case AsyncStatus.idle:
      return 'idle';
    case AsyncStatus.pending:
      return 'pending';
    case AsyncStatus.resolved:
      return 'resolved';
    case AsyncStatus.rejected:
      return 'rejected';
  }
}

export function AsyncToastMessage(props: AsyncToastMessageProps): JSX.Element {
  const { title, description, status, progress } = props;
  return (
    <StyledToastContentWrapper
      data-cy={props['data-cy']}
      data-status={mapStatus(status)}
    >
      <StyledFileInfo status={status}>
        <StyledTextContainer>
          <StyledTextWrapper>
            <Text
              bold
              size={TextSize.s12}
              textAlign={TextAlignment.left}
              whiteSpace={description ? WhiteSpace.noWrap : WhiteSpace.preWrap}
              inheritColor
            >
              {title} {getProgressText(progress, status)}
            </Text>
          </StyledTextWrapper>

          {description && (
            <StyledTextWrapper>
              <Text
                size={TextSize.s12}
                textAlign={TextAlignment.left}
                whiteSpace={WhiteSpace.noWrap}
                inheritColor
              >
                {description}
              </Text>
            </StyledTextWrapper>
          )}
        </StyledTextContainer>
      </StyledFileInfo>

      {status === AsyncStatus.pending && <ToastProgress progress={progress} />}
      {status === AsyncStatus.rejected && <ToastError />}
      {status === AsyncStatus.resolved && <ToastSuccess />}

      {status === AsyncStatus.pending && (
        <>
          <ToastCancelOverlay className="toast-cancel" />
          <ToastCancel />
        </>
      )}
    </StyledToastContentWrapper>
  );
}
