import { observer } from 'mobx-react-lite';
import {
  AxisValue,
  ISO_DATE_FORMAT,
  TooltipData,
  XyChart,
} from '@yarmill/components';
import { AsyncStatus } from '../api/mobx/request-store';
import { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { ContinuousChartReportData } from './types';
import { ContinuousChartStore } from './mobx/continuous-chart-store';
import { ReportWrapper } from './report-wrapper';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { useTranslatedAxisConfigs } from './hooks/use-translated-axis-configs';
import { groupBy } from '../#-components/helpers/group-by';

const REPORT_HEIGHT = 200;

export interface ChartReportProps {
  readonly report: ContinuousChartStore;
  readonly sectionRatio?: number;
  readonly data?: ContinuousChartReportData;
  readonly noReportWrapper?: boolean;
}

export const ContinuousChartReport = observer(function ContinuousChartReport(
  props: ChartReportProps
): JSX.Element {
  const { report, sectionRatio, data: passedData, noReportWrapper } = props;

  const dataStore = useReportingDataStore();

  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as
      | ContinuousChartReportData
      | undefined);
  const status = passedData ? AsyncStatus.resolved : dataStore?.status;

  const domainX =
    report.xy.axisX?.DataType?.toLowerCase() === 'date'
      ? (data?.Metadata?.AxisXDomain?.map(d => moment(d).toDate()) as [
          Date,
          Date,
        ]) || [new Date(), new Date()]
      : (data?.Metadata?.AxisXDomain as [number, number]) || [0, 0];

  const groupedData = useMemo(
    () =>
      report.isMultiLine
        ? Object.fromEntries(
            groupBy(
              data?.Data || [],
              String(report.xy.category?.Name ?? '')
            ).entries()
          )
        : {},
    [report, data?.Data]
  );

  const tolerance = (domainX[1].valueOf() - domainX[0].valueOf()) * 0.01;

  const getTooltipData = useCallback(
    (xValue: AxisValue, exactDate = true): TooltipData => {
      return report.getTooltipData(
        xValue as number | Date,
        data?.Data || [],
        groupedData,
        exactDate,
        tolerance
      );
    },
    [data?.Data, groupedData, report, tolerance]
  );

  useEffect(() => {
    report.xy.setConfigOverride(data?.ConfigOverride);
  }, [data?.ConfigOverride, report]);

  const chartElementsConfig = report.chartElementsConfig;
  const axisConfigs = report.xy.axisConfigs;
  const height = report.xy.height;
  const gridLinesConfig = report.xy.gridLinesConfig;
  const noDataMessage = report.xy.noDataMessage;
  const aspectRatio = report.aspectRatio;
  const backgroundImage = report.backgroundImage;
  const xAxisDef = report.xy.axisX;

  const getItem = useCallback(
    (key: string, tickValue: AxisValue) => {
      const isDate = xAxisDef?.DataType === 'date';
      const formattedTickValue = isDate
        ? moment(tickValue).format(ISO_DATE_FORMAT)
        : tickValue;

      return data?.Data?.find(item => item?.[key] === formattedTickValue);
    },
    [data?.Data, xAxisDef]
  );

  const translatedAxisConfigs = useTranslatedAxisConfigs(
    axisConfigs,
    report,
    getItem
  );

  return (
    <ReportWrapper
      hasData={!dataStore || Boolean(data && data.Data.length > 0)}
      height={height ?? REPORT_HEIGHT}
      status={status || AsyncStatus.idle}
      sectionRatio={sectionRatio}
      noDataMessage={noDataMessage}
      aspectRatio={aspectRatio}
      noReportWrapper={noReportWrapper}
    >
      {width => (
        <XyChart
          code={report.code}
          width={width}
          height={
            aspectRatio && width
              ? width / aspectRatio
              : (height ?? REPORT_HEIGHT)
          }
          data={data?.Data || []}
          configs={chartElementsConfig}
          domainY={data?.Metadata?.AxisYDomain || [0, 0]}
          domainX={domainX}
          getTooltipData={getTooltipData}
          axisConfigs={translatedAxisConfigs}
          gridLinesConfig={gridLinesConfig}
          axisXTicks={data?.Metadata?.AxisXTicks ?? data?.Metadata?.AxisXValues}
          axisYTicks={data?.Metadata.AxisYTicks ?? data?.Metadata?.AxisYValues}
          gridLinesRowsTicks={data?.Metadata?.GridLinesRowValues}
          gridLinesColumnsTicks={data?.Metadata?.GridLinesColumnValues}
          hideTooltip={report.hideTooltip}
          backgroundImage={backgroundImage}
        />
      )}
    </ReportWrapper>
  );
});
