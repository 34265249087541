import { observer } from 'mobx-react-lite';
import { PrimarySidebarContainer } from '../components-2/page-layout';
import { PrimarySidebarLayout } from '../components-2/primary-sidebar';

import { Navigation } from './navigation';
import { Header } from './header';
import { useWindowSize } from '../utils/use-window-size';
import { useSidebarStore } from './hooks/use-sidebar-store';

export const PrimarySidebar = observer(function PrimarySidebar(): JSX.Element {
  const sidebarStore = useSidebarStore();
  const { width } = useWindowSize();

  return (
    <PrimarySidebarContainer
      isVisible={sidebarStore.isVisible}
      windowWidth={width ?? 0}
    >
      <PrimarySidebarLayout>
        <Header />
        <Navigation />
      </PrimarySidebarLayout>
    </PrimarySidebarContainer>
  );
});
