import {
  StyledError,
  StyledHelp,
  StyledLabel,
  StyledOptionalMessage,
} from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { FormFieldProps } from './form-field';
import { useErrorMessage, useInvalidFieldFocusHandler } from '../hooks';
import { getFormFieldAttributes } from '../utils';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { RichTextEditor } from '../../components/richtext';
import { EvidenceRichTextWrapper } from '../components/evidence-richtext';

export const RichText = observer(function RichText(
  props: FormFieldProps
): JSX.Element {
  const { attributeStore, autoFocus = true } = props;
  const intl = useIntl();
  const commonAttributes = getFormFieldAttributes(attributeStore, intl);
  const error = useErrorMessage(attributeStore);
  useInvalidFieldFocusHandler(attributeStore);

  const value = attributeStore.formValue;

  const handleChange = useCallback(
    (value: string) => {
      const formattedValue = value === '<p></p>' ? '' : value;
      attributeStore.onChange(formattedValue);
    },
    [attributeStore]
  );

  const isCondensedObject =
    attributeStore.objectDataStore.objectStore.definition.Features?.Condensed;

  return (
    <>
      {!(isCondensedObject && !commonAttributes.label) && (
        <StyledLabel
          htmlFor={String(attributeStore.id)}
          variant={commonAttributes.variant}
        >
          {commonAttributes.label}
          {commonAttributes.optionalMessage && (
            <StyledOptionalMessage>
              {commonAttributes.optionalMessage}
            </StyledOptionalMessage>
          )}
        </StyledLabel>
      )}
      {commonAttributes.help && (
        <StyledHelp>{commonAttributes.help}</StyledHelp>
      )}
      <EvidenceRichTextWrapper>
        <RichTextEditor
          {...commonAttributes}
          autoFocus={autoFocus}
          id={String(attributeStore.id)}
          onChange={handleChange}
          onBlur={attributeStore.onBlur}
          onFocus={attributeStore.onFocus}
          content={value}
          showToolbarByDefault
          disableLazyEditor
          asInput
        />
      </EvidenceRichTextWrapper>
      {!(isCondensedObject && !error) && (
        <StyledError>{error}&nbsp;</StyledError>
      )}
    </>
  );
});
