import { observer } from 'mobx-react-lite';
import {
  HeaderButtonsContainer,
  HeaderButtonsLayout,
  HeaderLayout,
  HeaderNavigationContainer,
} from '../components-2/header';

interface HeaderProps {
  readonly left?: JSX.Element;
  readonly center?: JSX.Element;
  readonly right?: JSX.Element;
  readonly position?: 'header' | 'footer';
  readonly inverted?: boolean;
  readonly dark?: boolean;
}
export const Header = observer(function Header({
  left,
  center,
  right,
  position = 'header',
  inverted,
  dark,
}: HeaderProps): JSX.Element {
  return (
    <HeaderLayout as={position} inverted={inverted} dark={dark}>
      {left && (
        <HeaderButtonsContainer>
          <HeaderButtonsLayout>{left}</HeaderButtonsLayout>
        </HeaderButtonsContainer>
      )}
      {center && (
        <HeaderNavigationContainer>{center}</HeaderNavigationContainer>
      )}
      {right && (
        <HeaderButtonsContainer>
          <HeaderButtonsLayout>{right}</HeaderButtonsLayout>
        </HeaderButtonsContainer>
      )}
    </HeaderLayout>
  );
});
