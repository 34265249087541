import { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RegistrationStore } from './mobx/registration-store';
import { TermsAndConditionsStore } from './mobx/terms-and-conditions-store';
import { Registration } from './registration';
import { when } from 'mobx';
import { AsyncStatus } from '../api/mobx/request-store';
import { useRootStore } from '../app/root-store-context';

export function RegistrationProcess(props: RouteComponentProps): JSX.Element {
  const registrationStore = useRef(new RegistrationStore()).current;
  const rootStore = useRootStore();
  const termsAndConditionsStore = useRef(new TermsAndConditionsStore()).current;

  useEffect(() => {
    when(
      () => rootStore.configStore.status === AsyncStatus.resolved,
      () => {
        termsAndConditionsStore.setCustomRequirements(
          rootStore.configStore.additionalRegistrationRequirements
        );
      }
    );
  }, [rootStore, termsAndConditionsStore]);

  return (
    <Registration
      {...props}
      registration={registrationStore}
      termsAndConditions={termsAndConditionsStore}
    />
  );
}
