import { styled } from '../theme-provider';

export const ButtonGroupContainer = styled.div`
  display: flex;
  align-items: center;

  & button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
