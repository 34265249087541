import { ChangeEvent, InputHTMLAttributes, useCallback } from 'react';
import { styled } from '@yarmill/components';
import { Text } from '../../components-2/text';
import { DateInput } from '../../components-2/date-input';

interface PlannerDateInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  readonly label?: string;
  readonly name: string;
  readonly additionalButton?: JSX.Element;
  readonly value?: string;
  readonly defaultValue?: string;
  readonly onChange?: (value: string) => void;
}

const PlannerDateInputLayout = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.blackHover};
  border-radius: ${({ theme }) => theme.borderRadius.x1};
  border-radius: ${({ theme }) => theme.borderRadius.x1};
  border: 2px solid transparent;
  gap: ${({ theme }) => theme.size.x1};

  input {
    border: 0;
  }

  div:first-of-type {
    font-size: ${({ theme }) => theme.text.appearance.task13.fontSize};
    font-family: ${({ theme }) => theme.text.font.mono};
    width: calc(10ch + ${({ theme }) => theme.size.x2});
  }

  @media (pointer: fine) and (hover: hover) {
    :focus-within {
      background-color: ${({ theme }) => theme.color.black};
      border-color: ${({ theme }) => theme.color.blackHover};
    }
  }
`;

const StyledLabel = styled(Text)`
  margin-left: ${({ theme }) => theme.size.x1};
  color: ${({ theme }) => theme.color.neutral};
  flex-grow: 1;
`;
const AdditionalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: ${({ theme }) => theme.size.x1};
`;

const MobileInput = styled.input<{ readonly hasAdditionalButton: boolean }>`
  position: absolute;
  opacity: 0;
  display: block;
  top: 0;
  left: 0;
  width: ${({ theme, hasAdditionalButton }) =>
    hasAdditionalButton ? `calc(100% - ${theme.size.x5})` : '100%'};
  bottom: 0;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;

  @media (pointer: fine) and (hover: hover) {
    display: none;
  }
`;
export function PlannerDateInput({
  label,
  additionalButton,
  ...inputProps
}: PlannerDateInputProps): JSX.Element {
  const onMobileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      inputProps.onChange?.(e.target.value);
    },
    [inputProps.onChange]
  );
  return (
    <PlannerDateInputLayout>
      <StyledLabel appearance="label10strong" upperCase>
        {label}
      </StyledLabel>
      <DateInput
        {...inputProps}
        appearance="black"
        autoComplete="off"
        readOnly={inputProps.readOnly || !inputProps.onChange}
        align="right"
      />
      <MobileInput
        onChange={onMobileInputChange}
        type="date"
        value={inputProps.value}
        hasAdditionalButton={Boolean(additionalButton)}
        min={inputProps.min}
        max={inputProps.max}
      />
      {additionalButton && (
        <AdditionalButtonWrapper>{additionalButton}</AdditionalButtonWrapper>
      )}
    </PlannerDateInputLayout>
  );
}
