import { AxiosPromise, CancelTokenSource } from 'axios';

import { axios } from '../../api/axios';
import { GetAttributeDataForRangeResponse } from '../types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';

export interface GetAttributeValuesForRangeRequestParams {
  userId?: number;
  userGroupId?: number;
  startDate: string;
  endDate: string;
  attributeIds: number[];
  referenceTime?: string;
}

export function getAttributeValuesForRange(
  params: GetAttributeValuesForRangeRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<GetAttributeDataForRangeResponse> {
  return axios.get(`${coreApiTarget}/api/attribute-values/for-range`, {
    cancelToken: cancelToken?.token,
    params: {
      ...params,
      attributeIds: JSON.stringify(params.attributeIds),
      instanceCode: devMode ? instance : undefined,
    },
  });
}
