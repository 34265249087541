import { Right, Team } from '@yarmill/icons-2';
import { PropsWithChildren } from 'react';
import { Button } from '../../components-2/button';
import { RightPanelSectionHeading } from '../../components-2/right-panel';

import { Icon, IconSize } from '../icon';
import { styled } from '../theme-provider';

export const AttendeesGroupHeading = styled(RightPanelSectionHeading)<{
  readonly hasDescription?: boolean;
}>`
  margin-bottom: ${({ theme }) => theme.size.x1};
  margin-top: ${({ theme }) => theme.size.x3};
  padding: 0 ${({ theme }) => theme.size.x2};
`;

export const DetailsStepAttendeesHeading = styled(RightPanelSectionHeading)`
  margin-bottom: ${({ theme }) => theme.size.x1};
  color: ${({ theme }) => theme.color.neutral};
`;

type DetailsStepAttendeesButtonProps = PropsWithChildren<{
  readonly onClick?: () => void;
}>;

const NextStepIcon = styled(Icon)`
  background-color: ${({ theme }) => theme.color.navyDark};
  color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.x05};
  padding: ${({ theme }) => theme.size.x05};
  width: ${({ theme }) => theme.size.x3};
  height: ${({ theme }) => theme.size.x3};
`;

const AttendeesButton = styled(Button)`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.size.x2} 1fr ${({ theme }) =>
    theme.size.x3};
  justify-items: start;
  align-items: center;
  padding: ${({ theme }) => theme.size.x05} ${({ theme }) => theme.size.x05}
    ${({ theme }) => theme.size.x05} ${({ theme }) => theme.size.x1};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;
export function DetailsStepAttendeesButton({
  onClick,
  children,
}: DetailsStepAttendeesButtonProps): JSX.Element {
  return (
    <AttendeesButton
      $appearanceStyle="navy"
      type="button"
      onClick={onClick}
      stretch
    >
      <Icon size={IconSize.s16}>
        <Team />
      </Icon>
      {children}
      {onClick && (
        <NextStepIcon size={IconSize.s24}>
          <Right />
        </NextStepIcon>
      )}
    </AttendeesButton>
  );
}
