import { observer } from 'mobx-react-lite';
import { ObjectiveStore } from './mobx/objective-store';
import { useIntl } from 'react-intl';
import { Cancel, Check } from '@yarmill/icons-2';
import { UpdateStatus } from '../components-2/update-status';
import { Loader } from '../components-2/loader';
import moment from 'moment';
import { useEffect, useState } from 'react';

interface SavingStatusProps {
  readonly objective: ObjectiveStore;
}

const TIMER_START_TIME = 2;
const TIMER_END_TIME = 30;
export const SavingStatus = observer(function SavingStatus({
  objective,
}: SavingStatusProps): JSX.Element | null {
  const intl = useIntl();
  const pendingUpdate = objective.hasPendingUpdate;
  const saveFailed = objective.saveFailed;
  const [elapsedTime, setElapsedTime] = useState<number | null>(null);

  useEffect(() => {
    const lastUpdated = moment(objective.lastUpdateDate);
    const now = moment();
    const diff = now.diff(lastUpdated, 'seconds');
    let animationFrame: ReturnType<typeof requestAnimationFrame>;

    if (diff < TIMER_END_TIME) {
      const start: number = Date.now();
      setElapsedTime(0);

      const updateTimer = () => {
        const delta = Math.floor((Date.now() - start) / 1000);
        setElapsedTime(delta);
        if (delta < TIMER_END_TIME) {
          requestAnimationFrame(updateTimer);
        }
      };

      animationFrame = requestAnimationFrame(updateTimer);
    }

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [objective.lastUpdateDate]);

  if (saveFailed) {
    return (
      <UpdateStatus
        message={intl.formatMessage({ id: 'okrs.saveFailed' })}
        appearance="red"
        icon={<Cancel />}
      />
    );
  }

  if (pendingUpdate) {
    return (
      <UpdateStatus
        message={intl.formatMessage({ id: 'okrs.savingNow' })}
        appearance="green"
        icon={<Loader appearance="green" />}
      />
    );
  }

  if (elapsedTime !== null && elapsedTime <= TIMER_START_TIME) {
    return (
      <UpdateStatus
        message={intl.formatMessage({ id: 'okrs.saved' })}
        appearance="green"
        icon={<Check />}
      />
    );
  }

  if (
    elapsedTime !== null &&
    elapsedTime > TIMER_START_TIME &&
    elapsedTime < TIMER_END_TIME
  ) {
    return (
      <UpdateStatus
        message={intl.formatMessage(
          {
            id: 'okrs.savedBefore',
          },
          { elapsedTime: `${elapsedTime}s` }
        )}
        appearance="neutral"
        icon={<Check />}
      />
    );
  }

  return null;
});
