import {
  Button,
  ButtonAppearance,
  DescriptiveText,
  ResponsiveTableWrapper,
  Table,
  TextAlignment,
  TextSize,
  UsersInGroupLayout,
  UsersInGroupTh,
  WhiteSpace,
  styled,
} from '@yarmill/components';
import { observer, useLocalObservable } from 'mobx-react-lite';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRootStore } from '../app/root-store-context';
import { TableFiltersStore } from '../evidence/mobx/table-filters-store';
import { Filters } from '../evidence/table/filters';
import { createUserStoresSortFunction } from '../users/utils/create-user-stores-sort-function';
import { userMatchesTableFilter } from '../users/utils/user-matches-table-filter';
import { UserRow } from './user-row';

export interface UsersInGroupListProps {
  groupId: number;
}

const TableHeaderText = styled(DescriptiveText)`
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign }) =>
    textAlign === TextAlignment.center ? 'center' : 'flex-start'};
`;

interface HeaderProps {
  readonly filtersStore: TableFiltersStore;
}
const Header = observer(function Header({
  filtersStore,
}: HeaderProps): JSX.Element {
  const intl = useIntl();
  const sortConfig = filtersStore.sortConfig;

  return (
    <React.Fragment>
      <UsersInGroupTh />
      <UsersInGroupTh align="left">
        <TableHeaderText size={TextSize.s12} medium>
          <FormattedMessage id="settings.users.table.header.firstName" />
          <Filters
            attributeId="userName"
            onChange={filtersStore.handleFilterChange}
            filter={filtersStore.getFilter('userName')}
            sort={sortConfig?.sort === 'userName' ? sortConfig.order : null}
            filterType="text"
          />
        </TableHeaderText>
      </UsersInGroupTh>
      <UsersInGroupTh>
        <TableHeaderText size={TextSize.s12} medium>
          <FormattedMessage id="settings.users.table.header.role" />
          <Filters
            attributeId="role"
            onChange={filtersStore.handleFilterChange}
            filter={filtersStore.getFilter('role')}
            sort={sortConfig?.sort === 'role' ? sortConfig.order : null}
            filterType="options"
            options={[
              {
                label: intl.formatMessage({
                  id: 'settings.users.role.admin',
                }),
                value: 'admin',
              },
              {
                label: intl.formatMessage({
                  id: 'settings.users.role.coach',
                }),
                value: 'coach',
              },
              {
                label: intl.formatMessage({
                  id: 'settings.users.role.athlete',
                }),
                value: 'athlete',
              },
            ]}
          />
        </TableHeaderText>
      </UsersInGroupTh>
      <UsersInGroupTh>
        <DescriptiveText size={TextSize.s12} medium>
          <FormattedMessage id="settings.users.table.header.permission" />
        </DescriptiveText>
      </UsersInGroupTh>
      <UsersInGroupTh>
        <DescriptiveText
          size={TextSize.s12}
          medium
          whiteSpace={WhiteSpace.preWrap}
        >
          <FormattedMessage id="settings.users.table.header.addedToGroup" />
        </DescriptiveText>
      </UsersInGroupTh>
      <UsersInGroupTh>
        <DescriptiveText
          size={TextSize.s12}
          medium
          whiteSpace={WhiteSpace.preWrap}
        >
          <FormattedMessage id="settings.users.table.header.removedFromGroup" />
        </DescriptiveText>
      </UsersInGroupTh>
      <UsersInGroupTh />
    </React.Fragment>
  );
});

function ColGroup(): JSX.Element {
  return (
    <>
      <col width="40" />
      <col />
      <col width="125" />
      <col width="120" />
      <col width="105" />
      <col width="105" />
      <col width="40" />
    </>
  );
}

export const UsersInGroupList = observer(function UsersInGroupList(
  props: UsersInGroupListProps
): JSX.Element | null {
  const { groupId } = props;
  const rootStore = useRootStore();
  const [state, setState] = useState({
    loading: false,
    opened: false,
    loaded: false,
  });
  const { opened, loading, loaded } = state;
  const groupsStore = rootStore.groupsStore;
  const group = groupsStore.getGroupById(groupId);
  const filtersStore = useLocalObservable(() => new TableFiltersStore());

  const maybeLoadPermissions = useCallback(async (): Promise<void> => {
    const totalUsers = group?.allUsers.length || 0;

    if ((group?.loadedPermissionsCount || 0) < totalUsers) {
      setState(s => ({ ...s, loading: true }));
      const success = await group?.loadMembersPermissions();
      if (success) {
        setState(s => ({ ...s, loading: false, loaded: true }));
      }
    }
  }, [group]);

  async function toggleHistoricalAthletes(): Promise<void> {
    const loaded = (group?.historicalMembers.length || 0) !== 0;

    if (!loaded && !loading) {
      setState(s => ({ ...s, loading: true, opened: !opened }));
      await group?.loadHistoricalAthletes();
      setState(s => ({ ...s, loading: false, opened: true }));
    } else {
      setState(s => ({ ...s, loading: false, opened: !opened }));
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: jsFilters are necessary for proper update
  const filteredUsers = useMemo(
    () =>
      group?.allUsers
        .filter(user => userMatchesTableFilter(user, filtersStore.filters))
        .sort(createUserStoresSortFunction(filtersStore.sortConfig)) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filtersStore.filters,
      filtersStore.sortConfig,
      group?.allUsers,
      filtersStore.jsFilters,
    ]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: jsFilters are necessary for proper update
  const filteredHistoricalUsers = useMemo(
    () =>
      group?.historicalMembers
        .filter(user => userMatchesTableFilter(user, filtersStore.filters))
        .sort(createUserStoresSortFunction(filtersStore.sortConfig)) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filtersStore.filters,
      filtersStore.sortConfig,
      group?.historicalMembers,
      filtersStore.jsFilters,
    ]
  );

  useEffect(() => {
    if (!loading && !loaded) {
      void maybeLoadPermissions();
    }
  }, [maybeLoadPermissions, loading, loaded]);

  if (!group) {
    return null;
  }

  return (
    <UsersInGroupLayout>
      <ResponsiveTableWrapper>
        <Table
          head={<Header filtersStore={filtersStore} />}
          colgroup={<ColGroup />}
        >
          {filteredUsers.map(user => (
            <UserRow
              user={user}
              key={user.id}
              group={group}
              permission={group.getUserPermissions(user.id)}
              validityDate={group.getUserValidityDate(user.id)}
            />
          ))}
        </Table>
      </ResponsiveTableWrapper>
      <Button
        type="button"
        appearance={ButtonAppearance.Dark}
        onClick={toggleHistoricalAthletes}
        disabled={!group || group.historicalMembersCount === 0}
      >
        <FormattedMessage
          id="sidebar.athletes.historicalMembers"
          values={{
            count: group.historicalMembersCount,
          }}
        />
      </Button>
      {opened && !loading && (
        <ResponsiveTableWrapper>
          <Table colgroup={<ColGroup />}>
            {filteredHistoricalUsers.map(user => (
              <UserRow
                isHistorical
                user={user}
                key={user.id}
                group={group}
                permission={group.getUserPermissions(user.id)}
                validityDate={group.getUserValidityDate(user.id)}
              />
            ))}
          </Table>
        </ResponsiveTableWrapper>
      )}
    </UsersInGroupLayout>
  );
});
