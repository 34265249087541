import {
  observable,
  makeObservable,
  IObservableArray,
  action,
  computed,
} from 'mobx';
import { KeyResultStore } from './key-result-store';
import {
  KeyResultUid,
  Objective,
  ObjectiveActivity,
  ObjectiveEvaluation,
  ObjectiveFormValues,
  ObjectiveValidationRule,
  OkrCategory,
  OkrPriority,
  OkrStatus,
} from '../types';
import { UserStore } from '../../users/mobx/user-store';
import { isValidKeyResult } from '../utils';
import { sortBySortCode, uniqId } from '../../app/utils';

export class ObjectiveStore {
  @observable
  private _id: number | null = null;
  @observable
  private _uid: string = uniqId();
  @observable
  private _title: string | null = null;
  @observable
  private _description: string | null = null;
  @observable
  private _priority: OkrPriority | null = null;
  @observable
  private _category: OkrCategory | null = null;
  @observable
  private _supervisors: IObservableArray<UserStore> = observable.array();
  @observable
  private _status: OkrStatus | null = null;
  @observable
  private _lastUpdateDate: string | null = null;
  @observable
  private _keyResults: IObservableArray<KeyResultStore> = observable.array();
  @observable
  public hasPendingUpdate: boolean = false;
  @observable
  public saveFailed: boolean = false;

  @observable
  private _activities: IObservableArray<ObjectiveActivity> = observable.array();
  @observable
  private _evaluations: IObservableArray<ObjectiveEvaluation> =
    observable.array();

  constructor() {
    makeObservable(this);
  }

  public get id(): Readonly<typeof this._id> {
    return this._id;
  }
  public get uid(): Readonly<typeof this._uid> {
    return this._uid;
  }
  public get title(): Readonly<typeof this._title> {
    return this._title;
  }

  public get description(): Readonly<typeof this._description> {
    return this._description;
  }

  public get priority(): Readonly<typeof this._priority> {
    return this._priority;
  }

  public get category(): Readonly<typeof this._category> {
    return this._category;
  }

  public get supervisors(): Readonly<typeof this._supervisors> {
    return this._supervisors;
  }

  public get status(): Readonly<typeof this._status> {
    return this._status;
  }

  public get keyResults(): Readonly<typeof this._keyResults> {
    return this._keyResults;
  }

  @computed
  public get keyResultsUIds(): KeyResultUid[] {
    return this._keyResults.map(k => k.uid);
  }

  public get activities(): Readonly<typeof this._activities> {
    return this._activities;
  }

  public get evaluations() {
    return this._evaluations;
  }

  public getKeyResult(uid: string): KeyResultStore | undefined {
    return this._keyResults.find(kr => kr.uid === uid);
  }

  public get lastUpdateDate(): Readonly<typeof this._lastUpdateDate> {
    return this._lastUpdateDate;
  }

  @action
  public removeKeyResult(uid: string): void {
    const keyResult = this.getKeyResult(uid);
    if (keyResult) {
      this._keyResults.remove(keyResult);
    }
  }

  @action
  public setId(id: number | null): this {
    this._id = id;
    return this;
  }
  @action
  public setTitle(title: string): this {
    this._title = title;
    return this;
  }

  @action
  public setDescription(description: string): this {
    this._description = description;
    return this;
  }

  @action
  public setPriority(priority: OkrPriority): this {
    this._priority = priority;
    return this;
  }

  @action
  public setCategory(category: OkrCategory | null): this {
    this._category = category;
    return this;
  }

  @action
  public setLastUpdateDate(value: string | null): this {
    this._lastUpdateDate = value;
    return this;
  }

  @action
  public setSupervisors(supervisors: UserStore | UserStore[]): this {
    this._supervisors.clear();
    this._supervisors.push(
      ...(Array.isArray(supervisors) ? supervisors : [supervisors])
    );
    return this;
  }

  @action
  public addSupervisor(supervisor: UserStore): this {
    this._supervisors.push(supervisor);
    return this;
  }

  @action
  public removeSupervisor(supervisor: UserStore): this {
    this._supervisors.remove(supervisor);
    return this;
  }

  @action
  public setStatus(status: OkrStatus): this {
    this._status = status;
    return this;
  }

  @action
  public addKeyResults(keyResults: KeyResultStore | KeyResultStore[]): this {
    this._keyResults.push(
      ...(Array.isArray(keyResults) ? keyResults : [keyResults])
    );
    return this;
  }

  @action
  public sortKeyResults(): this {
    this._keyResults.sort(sortBySortCode);
    return this;
  }

  @action
  public setActivities(activities: ObjectiveActivity[]): this {
    this._activities.replace(activities);
    return this;
  }

  @action
  public setEvaluations(evaluations: ObjectiveEvaluation[]): this {
    this._evaluations.replace(evaluations);
    return this;
  }

  public toFormValues(): ObjectiveFormValues {
    return {
      id: this.id,
      category: this.category,
      title: this.title ?? '',
      status: this.status ?? 'not-started',
      description: this.description ?? '',
      priority: this.priority ?? 'low',
      supervisors: this.supervisors.map(s => s.id),
    };
  }

  public toJS(): Objective {
    return {
      id: this.id,
      category: this.category,
      title: this.title,
      keyResults: this.keyResults.map(keyResult => keyResult.toJS()),
      status: this.status,
      description: this.description,
      priority: this.priority,
      supervisors: this.supervisors.map(s => s.id),
      lastUpdated: this._lastUpdateDate,
    };
  }

  @computed
  public get errors(): ObjectiveValidationRule[] {
    const messages: ObjectiveValidationRule[] = [];
    if (!this.title) {
      messages.push('missingTitle');
    }

    if (!this.supervisors.length) {
      messages.push('missingSupervisor');
    }

    if (!this.category) {
      messages.push('missingCategory');
    }

    if (this.keyResults.length === 0) {
      messages.push('missingKeyResults');
    }

    return messages;
  }

  @computed
  public get tips(): ObjectiveValidationRule[] {
    const messages: ObjectiveValidationRule[] = [];

    if (this.keyResults.length > 0 && this.keyResults.length < 3) {
      messages.push('keyResultsCount');
    }

    if (!this.description) {
      messages.push('missingDescription');
    }

    return messages;
  }

  @computed
  public get hasInvalidKeyResult(): boolean {
    return this.keyResults.some(result => !isValidKeyResult(result));
  }
}
