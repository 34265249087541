import { Command } from 'react-command-palette';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';
import {
  useAllowedViewType,
  useRealityLink,
} from '../header/links/reality-link';
import { usePlanLink } from '../header/links/plan-link';
import { useAnalyticsLink } from '../header/links/analytics-link';
import { useAttendanceLink } from '../header/links/attendance-link';
import { useProfileLink } from '../header/links/settings-link';
import { useDefaultCommonSearchParams } from '../header/hooks';
import { useModule, useModulesStore } from '../modules/hooks';
import { useCurrentUserAllowedGroups, useGroupsStore } from '../groups/hooks';
import { useCurrentUserStore, useUsersStore } from '../users/hooks';
import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  WEEK_SEARCH_PARAM,
} from '../routes/types';
import { useMemo } from 'react';
import { useLocale } from '../intl/hooks';
import { normalizeString } from './utils';
import moment from 'moment';
import { ROUTE_DATE_FORMAT } from '../diary/utils';
import { useConfig } from '../app/hooks';
import { useRootStore } from '../app/root-store-context';
import { AsyncStatus } from '../api/mobx/request-store';
import { useAuthStore } from '../auth/hooks';
import { intlCookies } from '../intl/types';
import { ExternalIconName } from '@yarmill/components';
import { useChangeLanguageHandler } from '../intl/hooks/use-change-language-handler';
import { useOkrsLink } from '../header/links/okrs-link';
import { usePlannerLink } from '../header/links/planner-link';
import { useSeasonEvaluationLink } from '../header/links/season-evaluation-link';
import { useFilesOverviewLink } from '../header/links/files-overview-link';
import { PermissionScope } from '../permissions/types';
import { useYollandaService } from '../yollanda/hooks/use-yollanda-service';
import { ModulesStore } from '../modules/mobx/modules-store';
import { getReportingPagePermissionScope } from '../reporting/utils';
import { useReportingStore } from '../reporting/hooks/use-reporting-store';

function getIconForDiaryType(type: string): ExternalIconName | undefined {
  switch (type) {
    case 'plan':
      return 'ClipboardList';
    case 'reality':
      return 'Notebook';
    case 'analytics':
    case 'reporting':
      return 'ChartLine';
    case 'attendance':
      return 'UserCheck';
    case 'settings':
      return 'Adjustments';
    case 'evidence':
      return 'Briefcase';
    case 'okr':
      return 'TargetArrow';
    case 'planner':
      return 'Calendar';
    case 'filesOverview':
      return 'Files';
    case 'seasonEvaluation':
      return 'Report';
    default:
      return;
  }
}

function useHeaderLinks(): Command[] {
  const intl = useIntl();
  const history = useHistory();
  const realityLink = useRealityLink();
  const planLink = usePlanLink();
  const analyticsLink = useAnalyticsLink();
  const attendanceLink = useAttendanceLink();
  const profileLink = useProfileLink();
  const okrsLink = useOkrsLink();
  const plannerLink = usePlannerLink();
  const seasonEvaluationLink = useSeasonEvaluationLink();
  const filesOverviewLink = useFilesOverviewLink();
  const searchParams = useDefaultCommonSearchParams();
  const evidenceModules = useConfig('evidenceModuleConfigurations');
  const modules = useModulesStore();
  const rootStore = useRootStore();
  const currentUser = useCurrentUserStore();

  return useMemo(() => {
    const commands: Command[] = [];
    if (rootStore.status !== AsyncStatus.resolved) {
      return commands;
    }

    if (modules.plan && currentUser.isAllowedTo('plan')) {
      commands.push({
        icon: getIconForDiaryType('plan'),
        name: intl.formatMessage({ id: 'commandPalette.goToPlan' }),
        command: () => {
          history.push(planLink(window.location as unknown as Location));
        },
      });
    }

    if (modules.reality && currentUser.isAllowedTo('reality')) {
      commands.push({
        icon: getIconForDiaryType('reality'),
        name: intl.formatMessage({ id: 'commandPalette.goToReality' }),
        command: () => {
          history.push(realityLink(window.location as unknown as Location));
        },
      });
    }

    if (modules.analytics && currentUser.isAllowedTo('analytics')) {
      commands.push({
        icon: getIconForDiaryType('analytics'),
        name: intl.formatMessage({ id: 'commandPalette.goToAnalytics' }),
        command: () => {
          history.push(analyticsLink(window.location as unknown as Location));
        },
      });

      rootStore.legacyAnalyticsStore.dashboards.forEach(dashboard => {
        if (currentUser.isAllowedTo(`analytics.${dashboard.id}`)) {
          commands.push({
            icon: getIconForDiaryType('analytics'),
            name: intl.formatMessage(
              { id: 'commandPalette.goToLegacyAnalytics' },
              { dashboard: dashboard.id }
            ),
            command: () => {
              history.push(`/analytics/${dashboard.id}?${searchParams}`);
            },
          });
        }
      });
    }

    if (modules.attendance && currentUser.isAllowedTo('attendance')) {
      commands.push({
        icon: getIconForDiaryType('attendance'),
        name: intl.formatMessage({
          id: 'commandPalette.goToAttendance',
        }),
        command: () => {
          history.push(attendanceLink(window.location as unknown as Location));
        },
      });
    }

    evidenceModules.forEach(config => {
      if (currentUser.isAllowedTo(`evidence.${config.moduleKey}`)) {
        commands.push({
          icon: config.icon ?? getIconForDiaryType('evidence'),
          name: intl.formatMessage({
            id: `commandPalette.goToEvidence.${config.moduleKey}`,
          }),
          command: () => {
            history.push(`/evidence/${config.moduleKey}?${searchParams}`);
          },
        });
      }
    });

    if (modules.okr && currentUser.isAllowedTo('okr')) {
      commands.push({
        icon: getIconForDiaryType('okr'),
        name: intl.formatMessage({
          id: 'commandPalette.goToOkr',
        }),
        command: () => {
          history.push(okrsLink(window.location as unknown as Location));
        },
      });
    }

    if (modules.planner && currentUser.isAllowedTo('planner')) {
      commands.push({
        icon: getIconForDiaryType('planner'),
        name: intl.formatMessage({
          id: 'commandPalette.goToPlanner',
        }),
        command: () => {
          history.push(plannerLink(window.location as unknown as Location));
        },
      });
    }

    if (
      modules.seasonEvaluation &&
      currentUser.isAllowedTo('seasonEvaluation')
    ) {
      commands.push({
        icon: getIconForDiaryType('seasonEvaluation'),
        name: intl.formatMessage({
          id: 'commandPalette.goToSeasonEvaluation',
        }),
        command: () => {
          history.push(
            seasonEvaluationLink(window.location as unknown as Location)
          );
        },
      });
    }

    if (modules.filesOverview && currentUser.isAllowedTo('filesOverview')) {
      commands.push({
        icon: getIconForDiaryType('filesOverview'),
        name: intl.formatMessage({
          id: 'commandPalette.goToFilesOverview',
        }),
        command: () => {
          history.push(
            filesOverviewLink(window.location as unknown as Location)
          );
        },
      });
    }

    commands.push({
      icon: getIconForDiaryType('settings'),
      name: intl.formatMessage({ id: 'commandPalette.goToProfile' }),
      command: () => {
        history.push(profileLink(window.location as unknown as Location));
      },
    });

    return commands;
  }, [
    rootStore.status,
    rootStore.legacyAnalyticsStore.dashboards,
    modules.plan,
    modules.reality,
    modules.analytics,
    modules.attendance,
    modules.okr,
    modules.planner,
    modules.seasonEvaluation,
    modules.filesOverview,
    currentUser,
    evidenceModules,
    intl,
    history,
    planLink,
    realityLink,
    analyticsLink,
    searchParams,
    attendanceLink,
    okrsLink,
    plannerLink,
    seasonEvaluationLink,
    filesOverviewLink,
    profileLink,
  ]);
}

function useModulesLinks(): Command[] {
  const groups = useCurrentUserAllowedGroups();
  const history = useHistory();
  const currentUser = useCurrentUserStore();
  const intl = useIntl();
  const modulesStore = useRootStore().modulesStore;
  const planViewType = useAllowedViewType('plan');
  const realityViewType = useAllowedViewType('reality');

  return useMemo(() => {
    const diaryTypes: [keyof ModulesStore, string | null][] = [
      ['plan', planViewType || 'week'],
      ['reality', realityViewType || 'week'],
      ['analytics', 'general.defaultDashboard'],
      ['seasonEvaluation', null],
      ['filesOverview', null],
      ['okr', null],
      ['planner', null],
      ['attendance', 'week'],
    ];

    const commands: Command[] = [];
    if (currentUser.isAdmin || currentUser.isCoach) {
      diaryTypes
        .filter(
          ([dt]) =>
            modulesStore[dt] && currentUser.isAllowedTo(dt as PermissionScope)
        )
        .forEach(([diaryType, viewType]) =>
          groups.forEach(group =>
            group.athletes.forEach(athlete => {
              if (!athlete) {
                return;
              }

              commands.push({
                icon: getIconForDiaryType(diaryType),
                name: intl.formatMessage(
                  { id: `commandPalette.goToUser.${diaryType}` },
                  { user: athlete.displayName, group: group.name }
                ),
                command: () => {
                  const searchParams = new URLSearchParams(
                    window.location.search
                  );
                  searchParams.set(GROUP_SEARCH_PARAM, String(group.id));
                  searchParams.set(ATHLETE_SEARCH_PARAM, String(athlete.id));

                  if (
                    diaryType !== 'analytics' &&
                    !searchParams.has(WEEK_SEARCH_PARAM)
                  ) {
                    searchParams.set(
                      WEEK_SEARCH_PARAM,
                      moment().format(ROUTE_DATE_FORMAT)
                    );
                  }

                  const url = `/${diaryType}${
                    viewType ? `/${viewType}` : ''
                  }?${searchParams.toString()}`;
                  history.push(url);
                },
              });
            })
          )
        );
    }
    return commands;
  }, [
    planViewType,
    realityViewType,
    currentUser,
    modulesStore,
    groups,
    intl,
    history,
  ]);
}

function useEvidenceUserLinks(): Command[] {
  const groups = useCurrentUserAllowedGroups();
  const history = useHistory();
  const intl = useIntl();
  const evidence = useConfig('evidenceModuleConfigurations');
  const currentUser = useCurrentUserStore();

  return useMemo(() => {
    const commands: Command[] = [];
    if (currentUser.isAdmin || currentUser.isCoach) {
      evidence
        .filter(def => currentUser.isAllowedTo(`evidence.${def.moduleKey}`))
        .forEach(moduleDefinition =>
          groups.forEach(group =>
            group.athletes.forEach(athlete => {
              if (!athlete) {
                return;
              }

              commands.push({
                icon: moduleDefinition.icon ?? 'Table',
                name: intl.formatMessage(
                  {
                    id: `commandPalette.goToUser.evidence.${moduleDefinition.moduleKey}`,
                  },
                  { user: athlete.displayName, group: group.name }
                ),
                command: () => {
                  const searchParams = new URLSearchParams(
                    window.location.search
                  );
                  searchParams.set(GROUP_SEARCH_PARAM, String(group.id));
                  searchParams.set(ATHLETE_SEARCH_PARAM, String(athlete.id));

                  const url = `/evidence/${
                    moduleDefinition.moduleKey
                  }?${searchParams.toString()}`;
                  history.push(url);
                },
              });
            })
          )
        );
    }
    return commands;
  }, [evidence, groups, intl, history, currentUser]);
}

export function useUserSectionLinks(): Command[] {
  const currentUser = useCurrentUserStore();
  const intl = useIntl();
  const locale = useLocale();
  const rootStore = useRootStore();

  return useMemo(() => {
    const commands: Command[] = [];

    currentUser?.data?.Instances.forEach(instance => {
      commands.push({
        icon: 'CircleArrowRight',
        name: intl.formatMessage(
          { id: 'header.navigation.signInTo' },
          { instance: instance.Name }
        ),
        command: () => {
          const link = document.createElement('a');
          link.href = instance.FeUrl;
          link.target = '_blank';
          link.click();
        },
      });
    });

    commands.push({
      icon: 'CirclePlus',
      name: intl.formatMessage({ id: 'header.navigation.signInToOtherTeam' }),
      command: () => {
        const link = document.createElement('a');
        link.href = `https://yarmill.com/${
          locale === 'en' ? 'en/sign-in' : 'cs/prihlasit'
        }`;
        link.target = '_blank';
        link.click();
      },
    });

    commands.push({
      icon: 'Help',
      name: intl.formatMessage({ id: 'header.navigation.help' }),
      command: () => {
        const link = document.createElement('a');
        link.href = 'https://www.yarmill.com/cs-navod/';
        link.target = '_blank';
        link.click();
      },
    });

    commands.push({
      icon: 'Logout',
      name: intl.formatMessage({ id: 'header.navigation.logout' }),
      command: () => {
        rootStore.authStore
          .logOut()
          .finally(() => (window.location.href = '/'));
      },
    });

    return commands;
  }, [currentUser, intl, locale, rootStore]);
}

function useAdminLinks(): Command[] {
  const currentUser = useCurrentUserStore();
  const intl = useIntl();
  const history = useHistory();
  const groupsStore = useGroupsStore();

  return useMemo(() => {
    const commands: Command[] = [];

    if (currentUser.isAdmin || currentUser.isCoach) {
      commands.push({
        icon: 'Adjustments',
        name: intl.formatMessage({
          id: 'commandPalette.goToGroupsSettings',
        }),
        command: () => {
          const searchParams = new URLSearchParams(window.location.search);
          const groupId = searchParams.get(GROUP_SEARCH_PARAM);
          history.push(`/settings/groups/${groupId || ''}`);
        },
      });
    }

    if (currentUser.isAdmin) {
      commands.push({
        icon: 'Adjustments',
        name: intl.formatMessage({
          id: 'commandPalette.goToUsersSettings',
        }),
        command: () => {
          history.push(`/settings/users`);
        },
      });
      commands.push({
        icon: 'CirclePlus',
        name: intl.formatMessage({
          id: 'settings.groups.sidebar.addGroupButton',
        }),
        command: () => {
          history.push(`/settings/groups/createNew`);
        },
      });
      commands.push({
        icon: 'CirclePlus',
        name: intl.formatMessage({
          id: 'settings.users.inviteNewButton',
        }),
        command: () => {
          history.push(`/settings/users`, { showForm: true });
        },
      });
      groupsStore.sortedGroups.forEach(group => {
        commands.push({
          icon: 'CirclePlus',
          name: intl.formatMessage(
            {
              id: 'commandPalette.addUsersToGroup',
            },
            { group: group.name }
          ),
          command: () => {
            history.push(`/settings/groups/${group.id}/inviteUsers`);
          },
        });
      });
    }

    return commands;
  }, [currentUser, intl, history, groupsStore.sortedGroups]);
}

function useProfileLinks(): Command[] {
  const users = useUsersStore().activeUsers;
  const history = useHistory();
  const currentUser = useCurrentUserStore();
  const intl = useIntl();

  return useMemo(() => {
    const commands: Command[] = [];
    if (currentUser.isAdmin) {
      users.forEach(user => {
        commands.push({
          icon: 'UserCircle',
          name: intl.formatMessage(
            { id: `commandPalette.goToUserProfile` },
            { user: user.displayName }
          ),
          command: () => {
            const url = `/settings/users/${user.id}`;
            history.push(url);
          },
        });
      });
    }
    return commands;
  }, [users, intl, history, currentUser]);
}

function useDebugLinks(): Command[] {
  const intl = useIntl();
  const authStore = useAuthStore();
  return useMemo(
    () =>
      localStorage.getItem('yarmill-use-instance')
        ? [
            {
              icon: 'Logout',
              name: intl.formatMessage({
                id: `commandPalette.debug.logoutFromInstance`,
              }),
              command: async () => {
                localStorage.removeItem('yarmill-use-instance');
                window.localStorage.removeItem(intlCookies.LOCALE);
                window.localStorage.removeItem(intlCookies.MESSAGES);
                await authStore.logOut();
                window.location.href = '/';
              },
            },
          ]
        : [],
    [intl, authStore]
  );
}

function useLanguages(): Command[] {
  const intl = useIntl();
  const handleChangeLanguage = useChangeLanguageHandler();
  const availableLanguages = useConfig('availableLanguages');

  return useMemo(
    () =>
      availableLanguages
        .filter(lang => lang !== intl.locale)
        .map(lang => ({
          icon: 'Language',
          name: intl.formatMessage(
            {
              id: `commandPalette.changeLanguage`,
            },
            {
              language: lang,
            }
          ),
          command: async () => {
            handleChangeLanguage(lang);
          },
        })),
    [availableLanguages, handleChangeLanguage, intl]
  );
}

function useYollandaLinks(): Command[] {
  const planViewType = useAllowedViewType('plan');
  const realityViewType = useAllowedViewType('reality');
  const groups = useCurrentUserAllowedGroups();
  const currentUser = useCurrentUserStore();
  const intl = useIntl();
  const yollandaService = useYollandaService();
  const history = useHistory();

  return useMemo(
    () =>
      [
        ['plan', planViewType],
        ['reality', realityViewType],
      ]
        .filter(
          ([diaryType]) =>
            currentUser.isAllowedTo(diaryType as PermissionScope) &&
            currentUser.isAllowedTo('yollanda')
        )
        .flatMap(([diaryType, viewType]) =>
          (currentUser.isAthlete ? [groups[0]] : groups).flatMap(group =>
            group.athletes.map(athlete => ({
              icon: getIconForDiaryType('yollanda'),
              name: intl.formatMessage(
                {
                  id: currentUser.isAthlete
                    ? 'commandPalette.yollanda'
                    : 'commandPalette.goToUser.yollanda',
                },
                {
                  user: athlete.displayName,
                  group: group.name,
                  type: diaryType,
                }
              ),
              command: () => {
                const searchParams = new URLSearchParams(
                  window.location.search
                );
                searchParams.set(GROUP_SEARCH_PARAM, String(group.id));
                searchParams.set(ATHLETE_SEARCH_PARAM, String(athlete.id));

                history.push(
                  `/${diaryType}/${viewType}?${searchParams.toString()}`
                );
                yollandaService.show();
              },
            }))
          )
        ),
    [
      currentUser,
      groups,
      history,
      intl,
      planViewType,
      realityViewType,
      yollandaService,
    ]
  );
}

function useReportingLinks(): Command[] {
  const moduleConfig = useModule('reporting');
  const currentUser = useCurrentUserStore();
  const intl = useIntl();
  const history = useHistory();
  const searchParams = useDefaultCommonSearchParams();
  const reportingStore = useReportingStore();
  const reportingPages = reportingStore.definitionStore?.pages;
  const reportingPagesCount = reportingPages?.size;

  return useMemo(
    () =>
      reportingPagesCount
        ? moduleConfig
            .filter(p => !p.LegacyAnalytics)
            .flatMap(
              page =>
                page.Dashboards.filter(
                  dashboard =>
                    (dashboard.Permissions.Roles.includes(currentUser.role) ||
                      dashboard.Permissions.Users.includes(currentUser.id)) &&
                    currentUser.isAllowedTo(
                      getReportingPagePermissionScope(dashboard!.ReportPageCode)
                    )
                )
                  .map(page => {
                    const pageStore = reportingPages?.get(page.ReportPageCode);

                    if (!pageStore) {
                      return null;
                    }

                    return {
                      icon: getIconForDiaryType('reporting'),
                      name: intl.formatMessage(
                        {
                          id: `commandPalette.goToReport`,
                        },
                        {
                          report: intl.formatMessage({ id: pageStore?.title }),
                        }
                      ),
                      command: () => {
                        history.push(
                          `/reporting/${page.ReportPageCode}?${searchParams}`
                        );
                      },
                    };
                  })
                  .filter(Boolean) as Command[]
            )
        : [],
    [
      currentUser,
      history,
      intl,
      moduleConfig,
      reportingPages,
      searchParams,
      reportingPagesCount,
    ]
  );
}

export function useCommands(): Command[] {
  const headerLinks = useHeaderLinks();
  const modulesLinks = useModulesLinks();
  const userLinks = useUserSectionLinks();
  const adminLinks = useAdminLinks();
  const profileLinks = useProfileLinks();
  const evidenceUserLinks = useEvidenceUserLinks();
  const debugLinks = useDebugLinks();
  const languages = useLanguages();
  const yollandaLinks = useYollandaLinks();
  const reportingLinks = useReportingLinks();

  return useMemo(
    () =>
      [
        ...headerLinks,
        ...modulesLinks,
        ...userLinks,
        ...adminLinks,
        ...profileLinks,
        ...evidenceUserLinks,
        ...debugLinks,
        ...languages,
        ...yollandaLinks,
        ...reportingLinks,
      ].map(command => ({
        ...command,
        plainText: normalizeString(command.name),
      })),
    [
      headerLinks,
      modulesLinks,
      userLinks,
      adminLinks,
      profileLinks,
      evidenceUserLinks,
      debugLinks,
      languages,
      yollandaLinks,
      reportingLinks,
    ]
  );
}
