export interface HrZone {
  ZoneId: number;
  LowerLimit: number;
  UpperLimit: number;
  Name: string | null;
}

export interface ZoneData {
  ZoneId: number;
  TimeInZone: number;
  Percentage: number | null;
  Name: string | null;
}

export type PositionData = [number, number];

export interface Workout {
  AltitudeDistanceStep: number;
  AltitudeEvolution: number[] | null;
  AltitudeMax: number | null;
  AltitudeMin: number | null;
  DistanceEvolution: number[] | null;
  EndTime: string;
  HeartRateAverage: number | null;
  HeartRateEvolution: number[] | null;
  HeartRateMax: number | null;
  HeartRateMin: number | null;
  HeartRateZones: HrZone[] | null;
  Label: string;
  PositionEvolution: PositionData[] | null;
  PowerEvolution: number[] | null;
  SpeedEvolution: number[] | null;
  StartTime: string;
  TemperatureEvolution: number[] | null;
  TimeEvolution: number[] | null;
  TimeStep: number;
  TotalAscent: number | null;
  TotalDistance: number | null;
  TotalTime: number;
  TotalZoneTimes: ZoneData[];
  ZoneTimesEvolution: number[] | null;
}

export const WORKOUT_URL_PARAM = 'workout';
