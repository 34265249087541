import { useCallback } from 'react';
import { Location } from 'history';

import { NavItem } from '../nav-item';
import { useDefaultCommonSearchParams } from '../hooks';
import moment from 'moment';
import { ROUTE_DATE_FORMAT } from '../../diary/utils';
import { LinkCallback } from '../../routes/types';
import { appendDefaultSearchParams } from '../utils';
import { observer } from 'mobx-react-lite';

export interface OkrsLinkProps {
  className?: string;
  onClick?(): void;
}

export function useOkrsLink(): LinkCallback {
  const defaultParams = useDefaultCommonSearchParams({
    week: moment().format(ROUTE_DATE_FORMAT),
  });

  return useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultParams
      );
      return `/okr?${searchParams}`;
    },
    [defaultParams]
  );
}

export const OkrsLink = observer(function OkrsLink(
  props: OkrsLinkProps
): JSX.Element {
  const { className, onClick } = props;
  const link = useOkrsLink();

  return (
    <NavItem
      to={link}
      moduleKey="okrs"
      icon="TargetArrow"
      className={className}
      onClick={onClick}
    />
  );
});
