import { VideoReportData } from './types';
import { ContentBox, Text, TextSize } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Video } from '../video-player/video';
import { VideoReportStore } from './mobx/video-report-store';
import { ReportContentBox } from './report-content-box';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { AsyncStatus } from '../api/mobx/request-store';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';

export interface VideoReportProps {
  readonly report: VideoReportStore;
  readonly data?: VideoReportData;
  readonly noReportWrapper?: boolean;
}

const VideoReportWrapper = styled(ContentBox)`
  @media print {
    height: 100%;
  }
`;

export const VideoReport = observer(function VideoReport(
  props: VideoReportProps
): JSX.Element {
  const { report, data: passedData, noReportWrapper } = props;
  const dataStore = useReportingDataStore();

  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as VideoReportData | undefined);
  const video = data?.Data;
  const status = dataStore?.status;

  return (
    <ReportContentBox
      customContentBoxComponent={
        noReportWrapper ? Fragment : VideoReportWrapper
      }
    >
      {status === AsyncStatus.resolved && !video && (
        <Text size={TextSize.s14}>
          <FormattedMessage id={report.noDataMessage ?? 'reporting.noData'} />
        </Text>
      )}
      {video && <Video src={video} fullWidth />}
    </ReportContentBox>
  );
});
