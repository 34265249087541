import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useIsLoggedIn } from '../auth/hooks';
import { useLocation } from './hooks';
import { memo } from 'react';
import { observer } from 'mobx-react-lite';

function Redirector() {
  const location = useLocation();

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
}
export const PrivateRoute = memo(
  observer(function PrivateRoute(props: RouteProps): JSX.Element {
    const { component, ...routeProps } = props;
    const Component: React.ComponentType<any> = component!;
    const isLoggedIn = useIsLoggedIn();

    return (
      <Route {...routeProps} exact={routeProps.exact ?? true}>
        {isLoggedIn ? <Component /> : <Redirector />}
      </Route>
    );
  })
);
