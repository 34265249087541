import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { UserId } from '../types';
import { UserGroupId } from '../../groups/types';

export function getUsersInGroups(): AxiosPromise<
  { UserId: UserId; UserGroupIds: UserGroupId[] }[]
> {
  return axios.get('api/UserGroupUsers');
}
