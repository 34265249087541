import {
  DropdownOption,
  DropdownProvider,
} from '../../components-2/dropdown-provider/dropdown-provider';
import { usePlannerStore } from '../planner-store-context';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { UserId } from '../../users/types';
import { IconSize } from '@yarmill/components';
import { Team } from '@yarmill/icons-2';
import { useRootStore } from '../../app/root-store-context';
import { generateUrl } from '../../utils/generate-url';
import { ATHLETE_SEARCH_PARAM } from '../../routes/types';
import {
  HeaderPageTitle,
  HeaderPageTitleTeamIcon,
} from '../../components-2/header';
import { RoleAvatar } from '../../components-2/role-avatar';
import { Text } from '../../components-2/text';
import { useIntl } from 'react-intl';
import { AthletesInGroupDropdownWrapper } from '../../#-components/planner/athletes-in-group-dropdown';

export const AthleteSelector = observer(
  function AthleteSelector(): JSX.Element {
    const rootStore = useRootStore();
    const plannerStore = usePlannerStore();
    const intl = useIntl();
    const options: DropdownOption<UserId | null>[] = useMemo(
      () =>
        plannerStore.group?.athletes.map(user => ({
          label: user.displayName,
          icon: <RoleAvatar size={IconSize.s16} user={user} />,
          value: user.id,
        })) ?? [],
      [plannerStore]
    );

    const handleSelect = useCallback(
      (userId: UserId | null) => {
        const location = rootStore.historyService.history.location;
        const searchParams = new URLSearchParams(location.search);
        const search = Object.fromEntries(searchParams.entries());
        const url = generateUrl({
          ...search,
          [ATHLETE_SEARCH_PARAM]: userId,
        });
        rootStore.historyService.history.push(url);
      },
      [rootStore]
    );

    const additionalOption: DropdownOption<null> = useMemo(
      () => ({
        icon: <Team />,
        label: intl.formatMessage({ id: 'sidebar.athletes.allAthletes' }),
        value: null,
        isSelected: !plannerStore.athlete,
      }),
      [intl, plannerStore.athlete]
    );

    return (
      <HeaderPageTitle appearance="task13" absolutelyCentered as="div">
        <DropdownProvider
          options={options}
          handleSelect={handleSelect as any}
          selectedValue={plannerStore.athlete?.id}
          label={plannerStore.group?.name}
          showRadio
          additionalOption={additionalOption}
          placement="bottom"
        >
          <AthletesInGroupDropdownWrapper>
            {plannerStore.athlete ? (
              <>
                <RoleAvatar user={plannerStore.athlete} size={IconSize.s24} />
                &nbsp;
                <Text appearance="task13" inheritColor>
                  {plannerStore.athlete.displayName}
                </Text>
              </>
            ) : (
              <>
                <HeaderPageTitleTeamIcon size={IconSize.s24}>
                  <Team />
                </HeaderPageTitleTeamIcon>
                &nbsp;
                <Text appearance="task13" inheritColor>
                  {plannerStore.group?.name}
                </Text>
              </>
            )}
          </AthletesInGroupDropdownWrapper>
        </DropdownProvider>
      </HeaderPageTitle>
    );
  }
);
