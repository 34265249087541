import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { PopoverItem } from '../../components/popover/popover-item';
import { TrainingDayExtraAttributeProps } from '../training-day-extra-attribute';
import { RichtextAttributeStore } from '../../attributes/mobx/richtext-attribute-store';
import { InputArrayAttributeStore } from '../../attributes/mobx/input-array-attribute-store';
import { when } from 'mobx';

export function Basic(props: TrainingDayExtraAttributeProps): JSX.Element {
  const { attribute, hideTooltip } = props;

  const onClick = useCallback((): void => {
    attribute.show();
    if (attribute instanceof RichtextAttributeStore) {
      when(
        () => attribute.editor,
        () => {
          const editor = attribute.editor;
          if ('chain' in editor) {
            editor.chain().focus().run();
          } else {
            editor.current?.focus();
          }
        }
      );
    } else if (attribute instanceof InputArrayAttributeStore) {
      setTimeout(() => {
        attribute.inputs[0]?.getInputRef()?.current?.focus();
      }, 0);
    }
    hideTooltip();
  }, [hideTooltip, attribute]);

  return (
    <PopoverItem onClick={onClick} dataTest={attribute.name}>
      <FormattedMessage id={attribute.name} />
    </PopoverItem>
  );
}
