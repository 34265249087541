import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar } from '../profile/avatar';
import {
  Button,
  ButtonAppearance,
  Checkbox,
  CheckboxList,
  CheckboxListAvatarWrapper,
  CheckboxListLabel,
  CheckboxListRow,
  ContentBox,
  ContentBoxHead,
  Text,
  TextSize,
} from '@yarmill/components';
import { useRootStore } from '../app/root-store-context';
import { useEffect, useState } from 'react';

interface AthletesInGroupSelectorProps {
  readonly groupId: number;
  onChange(athletes: number[]): void;
}

export function AthletesInGroupSelector({
  groupId,
  onChange,
}: AthletesInGroupSelectorProps) {
  const rootStore = useRootStore();
  const group = rootStore.groupsStore.getGroupById(groupId);
  const athletes = group?.athletes ?? [];
  const [selectedAthletes, setSelectedAthletes] = useState<number[]>(
    athletes.map(a => a.id)
  );

  useEffect(() => {
    onChange(group?.athletes?.map(a => a.id) ?? []);
  }, [group, onChange]);

  const selectAll = (): void => {
    const athleteIds = athletes.map(a => a.id);
    setSelectedAthletes(athleteIds);
    onChange(athleteIds);
  };

  const unselectAll = (): void => {
    setSelectedAthletes([]);
    onChange([]);
  };

  const onSelectAthlete = (e: React.FormEvent<HTMLElement>): void => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      const { value } = target;
      const groupId = Number(value);
      const idx = selectedAthletes.indexOf(groupId);
      const newAthletes = [...selectedAthletes];
      if (idx === -1) {
        newAthletes.push(groupId);
      } else {
        newAthletes.splice(idx, 1);
      }

      setSelectedAthletes(newAthletes);
      onChange(newAthletes);
    }
  };

  return (
    <ContentBox data-cy="athletes-in-group">
      <ContentBoxHead>
        <Text size={TextSize.s16} medium>
          <FormattedMessage id="plan.copy.selectAthletes" />
        </Text>
        <Button
          type="button"
          appearance={ButtonAppearance.ActiveLink}
          onClick={
            athletes.length === selectedAthletes.length
              ? unselectAll
              : selectAll
          }
          data-cy="select-all-button"
        >
          {group?.athletes.length === selectedAthletes.length ? (
            <FormattedMessage id="plan.copy.selectAthletes.unselectAll" />
          ) : (
            <FormattedMessage id="plan.copy.selectAthletes.selectAll" />
          )}
        </Button>
      </ContentBoxHead>
      <CheckboxList>
        {group?.athletes.map(athlete => (
          <CheckboxListRow key={athlete.id}>
            <CheckboxListLabel htmlFor={`athlete-${athlete.id}`}>
              <CheckboxListAvatarWrapper>
                <Avatar id={athlete.avatar} alt={athlete.displayName} />
              </CheckboxListAvatarWrapper>
              <Text size={TextSize.s14}>{athlete.displayName}</Text>
            </CheckboxListLabel>
            <Checkbox
              value={String(athlete.id)}
              checked={selectedAthletes.indexOf(athlete.id) !== -1}
              onChange={onSelectAthlete}
              id={`athlete-${athlete.id}`}
              name={`athlete-${athlete.id}`}
              data-cy="user-checkbox"
              noError
            />
          </CheckboxListRow>
        ))}
      </CheckboxList>
    </ContentBox>
  );
}
