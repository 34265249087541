import { Announcement, AnnouncementType } from '../types';
import { action, makeObservable, observable } from 'mobx';
import { incrementNumberOfViews, shouldShowAnnouncement } from '../utils';
import { RootStore } from '../../app/mobx/root-store';

export class AnnouncementStore {
  private readonly _definition: Announcement;
  private readonly _rootStore: RootStore;

  @observable
  private _isVisible: boolean;
  constructor(rootStore: RootStore, definition: Announcement) {
    this._definition = definition;
    this._rootStore = rootStore;
    this._isVisible = shouldShowAnnouncement(
      rootStore.currentUserStore,
      definition
    );
    makeObservable(this);
  }

  public get code(): string {
    return this._definition.code;
  }

  public get type(): AnnouncementType {
    return this._definition.type;
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  @action
  public readonly close = (): void => {
    this._isVisible = false;
    incrementNumberOfViews(
      this.code,
      this._rootStore.currentUserStore,
      this._rootStore.settingsService
    );
  };
}
