import { TrainingEvent } from '../#-components/planner/training-event';
import { usePlannerStore } from './planner-store-context';
import { useIntl } from 'react-intl';
import { useCallback, useEffect } from 'react';
import moment from 'moment/moment';
import { observer } from 'mobx-react-lite';
import { useCurrentUserIsAllowedToWrite } from './hooks/use-current-user-is-allowed-to-write';

export const CalendarFormEvent = observer(
  function CalendarFormEvent(): JSX.Element | null {
    const plannerStore = usePlannerStore();
    const intl = useIntl();
    const currentUserIsAllowedToWrite = useCurrentUserIsAllowedToWrite();
    const calendarFormEvent = plannerStore.calendarFormEvent;
    const scrollIntoView = Boolean(
      plannerStore.scrollToEvent && plannerStore.scrollToEvent === 'form-event'
    );

    useEffect(() => {
      if (scrollIntoView) {
        plannerStore.scrollToEvent = null;
      }
    }, [scrollIntoView, plannerStore]);

    const onStartUpdate = useCallback(
      (date: string) => {
        if (moment(date) <= moment(plannerStore.calendarFormEvent?.endDate)) {
          plannerStore.calendarFormEvent?.setStartDate(date);
        }
      },
      [plannerStore]
    );

    const onEndUpdate = useCallback(
      (date: string) => {
        if (moment(date) >= moment(plannerStore.calendarFormEvent?.startDate)) {
          plannerStore.calendarFormEvent?.setEndDate(date);
        }
      },
      [plannerStore]
    );

    if (
      !calendarFormEvent?.startDate ||
      !calendarFormEvent?.endDate ||
      calendarFormEvent.start.isAfter(
        plannerStore.currentSeason?.end.clone().endOf('month')
      ) ||
      calendarFormEvent.end.isBefore(
        plannerStore.currentSeason?.start.clone().startOf('month')
      )
    ) {
      return null;
    }

    return (
      <TrainingEvent
        start={calendarFormEvent.startDate}
        end={calendarFormEvent.endDate}
        name={
          calendarFormEvent.title ??
          intl.formatMessage(
            { id: 'planner.newEvent' },
            {
              eventType: calendarFormEvent.eventTypeId,
            }
          )
        }
        color={plannerStore.getEventTypeColor(calendarFormEvent.eventTypeId)}
        onStartUpdate={onStartUpdate}
        onEndUpdate={onEndUpdate}
        showEditHandles={
          currentUserIsAllowedToWrite && calendarFormEvent.isEditable
        }
        showEditMode
        conflictingEvents={calendarFormEvent.layout.conflictingEvents}
        conflictOffset={calendarFormEvent.layout.offset}
        scrollIntoView={scrollIntoView}
      />
    );
  }
);
