import {
  PageMainContent,
  PageScrollContainer,
  TermsWrapper,
  Text,
  TextSize,
} from '@yarmill/components';
import { FormattedHTMLMessage } from '../intl/formatted-html-message';
import { observer } from 'mobx-react-lite';

export const TermsAndConditions = observer(
  function TermsAndConditions(): JSX.Element {
    return (
      <PageMainContent>
        <PageScrollContainer>
          <TermsWrapper>
            <Text size={TextSize.s14}>
              <FormattedHTMLMessage id="app.termsAndConditions" />
            </Text>
          </TermsWrapper>
        </PageScrollContainer>
      </PageMainContent>
    );
  }
);
