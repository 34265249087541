import { LayerOptions, LayerRenderer, LayerType } from '../types';
import { IObservableArray, observable, makeObservable, action } from 'mobx';
import { Layer } from './layer';

export class LayerManagerService {
  @observable
  private _layers: IObservableArray<Layer> = observable.array();

  constructor() {
    makeObservable(this);
  }

  public get layers(): Layer[] {
    return this._layers;
  }

  @action
  private readonly removeLayer = (layer: Layer): void => {
    this._layers.remove(layer);
  };

  @action
  public openLayer(
    type: LayerType,
    render: LayerRenderer,
    options: LayerOptions
  ): Layer {
    const layer = new Layer(type, render, this.removeLayer, options);
    this._layers.push(layer);

    return layer;
  }
}
