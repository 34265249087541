import {
  ContentWrapper,
  MainPageContentContainer,
} from '../components-2/page-layout';
import { Header } from './header';
import { Button, ButtonAppearance } from '../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { Chat } from '@yarmill/icons-2';
import { Tippy } from '../components/tippy/tippy';
import { useIntl } from 'react-intl';
import { FakeButton } from '../components-2/okrs/objectives-list';
import { ObjectiveDetailFooter } from './objective-detail-footer';

export function EmptyPage(): JSX.Element {
  const intl = useIntl();

  return (
    <MainPageContentContainer>
      <Header
        left={<FakeButton />}
        inverted
        right={
          <Tippy
            tooltipContent="okrs.activityLog.showActivityLog"
            noWrapper
            touch={false}
          >
            <Button
              as="button"
              disabled
              $appearance={ButtonAppearance.Secondary}
              $appearanceStyle="neutral"
              $iconOnly
              aria-label={intl.formatMessage({
                id: 'okrs.activityLog.showActivityLog',
              })}
            >
              <Icon size={IconSize.s24}>
                <Chat />
              </Icon>
            </Button>
          </Tippy>
        }
      />
      <ContentWrapper />
      <ObjectiveDetailFooter />
    </MainPageContentContainer>
  );
}
