import { useCallback, useEffect } from 'react';
import { FullScreenLayerCloseButtonWrapper } from '@yarmill/components';

import { useWorkoutDetailStore } from './hooks';
import { useHistory } from '../routes/hooks';
import { getDiaryRouteLink } from '../diary/utils';
import { useDiaryStore } from '../diary/diary-store-context';
import { observer } from 'mobx-react-lite';
import { WorkoutDetail } from './workout-detail';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';
import { CloseButton } from '../components/close-button/close-button';

export const Workout = observer(function Workout(): JSX.Element | null {
  const workoutDetailStore = useWorkoutDetailStore();
  const history = useHistory();
  const diaryStore = useDiaryStore();
  const workoutId = workoutDetailStore.id;
  const workoutDetailLayer = useLayer('full-screen');

  const onClose = useCallback(() => {
    const { diaryType, athleteId, groupId, week } = diaryStore;

    if (!groupId) {
      return;
    }

    const link = getDiaryRouteLink({
      viewType: 'week',
      diaryType,
      athleteId,
      groupId,
      week,
    });

    if (workoutDetailLayer.isOpened) {
      workoutDetailLayer.layer.close();
    }

    history.push(link);
    workoutDetailStore.clear();
  }, [diaryStore, workoutDetailLayer, history, workoutDetailStore]);

  useEffect(() => {
    if (workoutId && !workoutDetailLayer.isOpened) {
      workoutDetailLayer.open({ onClose });
    }
  }, [workoutId, workoutDetailLayer, onClose]);

  return (
    <LayerPortal
      layerHandle={workoutDetailLayer}
      getContent={layer => (
        <>
          <WorkoutDetail />
          <FullScreenLayerCloseButtonWrapper>
            <CloseButton onClick={layer.close} />
          </FullScreenLayerCloseButtonWrapper>
        </>
      )}
    />
  );
});
