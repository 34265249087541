import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { CopyGoalsRequestParams } from './copy-goals-from-user';

export function copyGoalsFromGroup(
  params: Partial<CopyGoalsRequestParams>
): AxiosPromise<boolean> {
  const paramsWithDefaults: CopyGoalsRequestParams = {
    TrainingDayState: 1,
    CopyType: 2,
    DestinationUsersIds: [],
    DestinationGroupsIds: [],
    DestinationWeekStartDate: params.SourceWeekStartDate,
    ...params,
  } as CopyGoalsRequestParams;

  return axios.post('api/Goals/Group/Copy', paramsWithDefaults);
}
