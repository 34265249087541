import { observer } from 'mobx-react-lite';
import { ObjectiveStore } from '../mobx/objective-store';
import {
  ObjectiveValidationButtonWrapper,
  ObjectiveValidationWrapper,
} from '../../components-2/okrs/detail/objective-validation';
import { ObjectiveValidationRating } from './objective-validation-rating';
import { Button } from '../../components-2/button';
import { FormattedMessage } from 'react-intl';
import { ObjectiveValidationChecks } from './objective-validation-checks';
import { useConfig } from '../../app/hooks';

interface ObjectiveValidationProps {
  readonly objective: ObjectiveStore;
}

export const ObjectiveValidation = observer(function ObjectiveValidation({
  objective,
}: ObjectiveValidationProps) {
  const instructionsUrl = useConfig('okrInstructionsUrl');

  return (
    <ObjectiveValidationWrapper>
      <ObjectiveValidationRating objective={objective} />
      {instructionsUrl && (
        <ObjectiveValidationButtonWrapper>
          <Button
            as="a"
            href={instructionsUrl}
            target="_blank"
            rel="noopener noreferrer"
            stretch
          >
            <FormattedMessage id="okrs.detail.validation.tutorialLink" />
          </Button>
        </ObjectiveValidationButtonWrapper>
      )}
      <ObjectiveValidationChecks objective={objective} />
    </ObjectiveValidationWrapper>
  );
});
