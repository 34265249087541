import { observer } from 'mobx-react-lite';
import { useConfig } from '../app/hooks';
import {
  PrimarySidebarHeaderLogo,
  PrimarySidebarHeaderLogoImage,
  PrimarySidebarHeaderLogoInstanceName,
} from '../components-2/primary-sidebar';
import { apiTarget } from '../api/api-mapping';
import defaultLogo from '../app/logo.png';
import { useRootStore } from '../app/root-store-context';
import { WhiteSpace } from '@yarmill/components';

export const Logo = observer(function Logo(): JSX.Element {
  const logo = useConfig('logo');
  const smallLogo = useConfig('logoSmall');
  const rootStore = useRootStore();
  const logoUrl = smallLogo
    ? `${apiTarget}/${smallLogo}`
    : logo
      ? `${apiTarget}/${logo}`
      : defaultLogo;
  const instanceName = rootStore.configStore.appName;

  return (
    <PrimarySidebarHeaderLogo>
      <PrimarySidebarHeaderLogoImage src={logoUrl} />
      <PrimarySidebarHeaderLogoInstanceName
        ellipsis
        whiteSpace={WhiteSpace.noWrap}
      >
        {instanceName}
      </PrimarySidebarHeaderLogoInstanceName>
    </PrimarySidebarHeaderLogo>
  );
});
