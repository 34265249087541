import ContentLoader from 'react-content-loader';
import { Breakpoints, BreakpointWrapper } from '@yarmill/components';

export function SidebarLoader() {
  return (
    <>
      <BreakpointWrapper max={Breakpoints.tablet}>
        <ContentLoader
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox="0 0 500 42"
          width="100%"
          height="42px"
        >
          <rect x="0" y="0" rx="8" ry="8" width="45%" height="100%" />
          <rect x="55%" y="0" rx="8" ry="8" width="45%" height="100%" />
        </ContentLoader>
      </BreakpointWrapper>
      <BreakpointWrapper min={Breakpoints.tablet}>
        <ContentLoader
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox="0 0 188 364"
        >
          <rect x="0" y="0" rx="8" ry="8" width="194" height="22" />
          <rect x="40" y="25" rx="8" ry="8" width="114" height="16" />

          <circle cx="22" cy="162" r="18" />
          <circle cx="22" cy="210" r="18" />
          <circle cx="22" cy="258" r="18" />
          <circle cx="22" cy="306" r="18" />
          <circle cx="22" cy="354" r="18" />

          <rect x="54" y="152" rx="8" ry="8" width="134" height="20" />
          <rect x="54" y="200" rx="8" ry="8" width="134" height="20" />
          <rect x="54" y="248" rx="8" ry="8" width="134" height="20" />
          <rect x="54" y="296" rx="8" ry="8" width="134" height="20" />
          <rect x="54" y="344" rx="8" ry="8" width="134" height="20" />
        </ContentLoader>
      </BreakpointWrapper>
    </>
  );
}
