import { ObjectiveStore } from './mobx/objective-store';
import {
  ObjectiveFooter,
  ObjectiveListItemContent,
  ObjectiveListItemEvaluationIcon,
  ObjectiveListItemEvaluations,
  ObjectiveListItemLayout,
  ObjectiveListItemPriorityWrapper,
  ObjectiveListItemTitle,
  ObjectiveListItemTitleWrapper,
  ObjectiveListKeyResultLayout,
} from '../components-2/okrs/objectives-list';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { PriorityIcon } from '../components-2/priority';
import { Status } from '../components-2/status';
import { OkrCategory } from './okr-category';
import { MouseEvent, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OkrCategory as iOkrCategory } from './types';
import { Text } from '../components-2/text';
import { IconSize } from '@yarmill/components';
import { Check } from '@yarmill/icons-2';

interface ObjectiveListItemProps {
  readonly objective?: ObjectiveStore;
  readonly isActive?: boolean;
  readonly isNewObjectivePlaceholder?: boolean;
}

export const ObjectiveListItem = observer(function ObjectiveListItem({
  objective,
  isActive,
  isNewObjectivePlaceholder,
}: ObjectiveListItemProps): JSX.Element {
  const { url } = useRouteMatch();
  const history = useHistory();
  const itemRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  function edit(e: MouseEvent) {
    e.preventDefault();
    const { location } = history;
    history.push({ ...location, pathname: `${url}/${objective?.id ?? 'add'}` });
  }

  useEffect(() => {
    if (isActive) {
      itemRef.current?.focus();
    }
  }, [isActive]);

  return (
    <ObjectiveListItemLayout
      onMouseDown={isNewObjectivePlaceholder ? undefined : edit}
      $isActive={isActive}
      $isNewObjectivePlaceholder={isNewObjectivePlaceholder}
      tabIndex={0}
      role="button"
      ref={itemRef}
    >
      <ObjectiveListItemContent>
        <ObjectiveListItemTitleWrapper>
          <ObjectiveListItemTitle
            ellipsis
            as="div"
            appearance="nav16strong"
            priority={objective?.priority}
            lineClamp={2}
          >
            {objective?.title ||
              intl.formatMessage({ id: 'okrs.objectiveList.newObjective' })}
            {objective && objective.priority !== 'low' && (
              <ObjectiveListItemPriorityWrapper>
                <PriorityIcon priority={objective.priority ?? 'low'} />
              </ObjectiveListItemPriorityWrapper>
            )}
          </ObjectiveListItemTitle>
          <ObjectiveListItemEvaluations>
            {objective?.evaluations
              ?.filter(evaluation => evaluation?.evaluationText?.trim())
              ?.map((evaluation, idx, filteredEvaluations) => (
                <ObjectiveListItemEvaluationIcon
                  size={IconSize.s14}
                  key={evaluation.createdBy}
                  $isActive={isActive}
                  as="div"
                  $idx={filteredEvaluations.length - idx}
                >
                  <Check />
                </ObjectiveListItemEvaluationIcon>
              ))}
          </ObjectiveListItemEvaluations>
        </ObjectiveListItemTitleWrapper>
        <Text appearance="task13" ellipsis whiteSpace="noWrap" inheritColor>
          <ObjectiveListKeyResultLayout>
            <FormattedMessage
              id="okrs.objectiveList.keyResultsCount"
              values={{ count: objective?.keyResults.length ?? 0 }}
            />
          </ObjectiveListKeyResultLayout>
        </Text>
        <ObjectiveFooter>
          <Status status={objective?.status ?? 'not-started'} />
          <OkrCategory
            category={objective?.category || ('noCategory' as iOkrCategory)}
            showOnlySubcategory
            limitWidth
          />
        </ObjectiveFooter>
      </ObjectiveListItemContent>
    </ObjectiveListItemLayout>
  );
});
