import { UserStore } from '../users/mobx/user-store';
import { useAvatar } from '../profile/hooks';
import { useCallback } from 'react';
import { Location } from 'history';
import { generateUrl } from '../utils/generate-url';
import { ATHLETE_SEARCH_PARAM } from '../routes/types';
import { ListItem } from './list-item';
import { SidebarAvatar, useTheme } from '@yarmill/components';
import { observer } from 'mobx-react-lite';
import { NavigationItem } from '../sidebar/navigation-item';

interface AthleteLinkProps {
  onClick: (name: string) => void;
  athlete: UserStore;
  isActive: boolean;
}

function generateRootPath(location: Location): string | undefined {
  if (location.pathname.includes('okr')) {
    return `${window.location.origin}/okr`;
  }
  return undefined;
}

export const AthleteLink = observer(function AthleteLink(
  props: AthleteLinkProps
) {
  const { isActive, athlete, onClick } = props;
  const name = athlete.displayName;
  const src = useAvatar(athlete.avatar);
  const theme = useTheme();

  const athleteLink = useCallback(
    (location: Location) => {
      const searchParams = new URLSearchParams(location.search);
      const search = Object.fromEntries(searchParams.entries());
      const path = generateRootPath(location);
      return generateUrl(
        {
          ...search,
          [ATHLETE_SEARCH_PARAM]: athlete.id,
        },
        path
      );
    },
    [athlete]
  );

  return theme.name === 'main' ? (
    <NavigationItem
      icon={<SidebarAvatar src={src} alt={name} />}
      text={name}
      to={athleteLink}
      isAthlete
      isCoach={!athlete.isAthlete}
      isActive={isActive}
    />
  ) : (
    <ListItem
      onClick={onClick}
      isActive={isActive}
      link={athleteLink}
      name={name}
      icon={<SidebarAvatar src={src} alt={name} />}
    />
  );
});
