import {
  PropsWithChildren,
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useRootStore } from '../app/root-store-context';
import { OkrsStore } from './mobx/okrs-store';

export const OkrsStoreContext = createContext<OkrsStore | undefined>(undefined);

export function useOkrsStore(): OkrsStore {
  const diaryStore = useContext(OkrsStoreContext);
  if (!diaryStore) {
    throw new Error('Okrs Store not available');
  }

  return diaryStore;
}

export function InternalOkrsStoreContextProvider(
  props: PropsWithChildren
): JSX.Element {
  const rootStore = useRootStore();
  const okrsStore = useRef(new OkrsStore(rootStore)).current;

  useEffect(() => () => okrsStore.disposeReactions(), [okrsStore]);

  return (
    <OkrsStoreContext.Provider value={okrsStore}>
      {props.children}
    </OkrsStoreContext.Provider>
  );
}

export const OkrsStoreContextProvider = memo(InternalOkrsStoreContextProvider);
