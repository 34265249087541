import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { UserInGroupValidity } from '../types';
import { User } from '../../users/types';

export interface GetHistoricalAthletesRequestParams {
  userGroupId: number;
}

export interface GetHistoricalAthletesResponse {
  Users: User[];
  ValidityDates: UserInGroupValidity[];
}

export function getHistoricalAthletesForGroup(
  params: GetHistoricalAthletesRequestParams
): AxiosPromise<GetHistoricalAthletesResponse> {
  return axios.get('api/UserGroupUsers/Historical', { params });
}
