import { isValidEmail } from '../../utils/is-valid-email';
import { FormUserInvitation } from '../types';
import { checkIfEmailExists } from '../api/check-if-email-exists';

export async function validateEmail(
  value: string,
  currentInvitation: FormUserInvitation,
  invitations: FormUserInvitation[]
): Promise<string | undefined> {
  const isEmailValid = isValidEmail(value);

  if (!value) {
    return 'evidence.attribute.required';
  }

  if (!isEmailValid || !value) {
    return 'settings.users.incorrectEmail';
  } else if (invitations.length > 1 && value) {
    const isDuplicate = Boolean(
      invitations.find(
        invitation =>
          invitation.Email === value && currentInvitation.uid !== invitation.uid
      )
    );
    if (isDuplicate) {
      return 'error.account.invite.duplicateEmail';
    }
  }

  const { data } = await checkIfEmailExists(value);
  if (data) {
    return 'settings.users.emailExists';
  }
}
