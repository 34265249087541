import { ServerError } from '../app/app-types';
import { useCallback, useMemo, useState } from 'react';

export enum AsyncState {
  idle,
  pending,
  resolved,
  rejected,
}

export type SetAsyncState<E = ServerError> = (
  newState: AsyncState,
  errors?: E
) => void;

export function useAsyncState<E = ServerError>(): [
  AsyncState,
  SetAsyncState<E>,
  E | undefined,
] {
  const [state, setState] = useState<AsyncState>(AsyncState.idle);
  const [errors, setErrors] = useState<E | undefined>();

  const setAsyncState: SetAsyncState<E> = useCallback(
    (newState: AsyncState, errors?: E) => {
      setState(newState);
      if (errors) {
        setErrors(errors);
      }
    },
    []
  );

  return useMemo(
    () => [state, setAsyncState, errors],
    [errors, setAsyncState, state]
  );
}
