import { useCgmStore } from './hooks';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';
import { FullScreenLayerCloseButtonWrapper } from '@yarmill/components';

import { getCgmLink } from './utils';
import { useHistory } from '../routes/hooks';
import { CgmDetail } from './cgm-detail';
import { CloseButton } from '../components/close-button/close-button';

export const Cgm = observer(function Cgm(): JSX.Element {
  const cgmStore = useCgmStore();
  const cgmId = cgmStore.cgmId;
  const layer = useLayer('full-screen');
  const history = useHistory();

  useEffect(() => {
    if (cgmId && !layer.isOpened) {
      layer.open();
    }
  }, [cgmId, layer]);

  const closeDetail = useCallback(() => {
    if (layer.isOpened) {
      layer.layer.close();
      cgmStore.clear();
      history.push(getCgmLink(null));
    }
  }, [layer, history, cgmStore]);

  return (
    <LayerPortal
      layerHandle={layer}
      getContent={() => (
        <>
          <CgmDetail />
          <FullScreenLayerCloseButtonWrapper>
            <CloseButton onClick={closeDetail} />
          </FullScreenLayerCloseButtonWrapper>
        </>
      )}
    />
  );
});
