import { observer } from 'mobx-react-lite';
import { AddButtonWrapper } from './components/add-button';
import { Button, ButtonAppearance } from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { LayerPortal } from '../layer-manager/layer-portal';
import { AddFilesLayer } from './add-files-layer';
import { useCurrentUserStore } from '../users/hooks';
import { useCallback } from 'react';
import { useLayer } from '../layer-manager/hooks';
import { Tippy } from '../components/tippy/tippy';

export const AddFilesButton = observer(function AddFilesButton() {
  const currentUser = useCurrentUserStore();
  const addFilesLayer = useLayer('full-screen');
  const isDisabled = currentUser.writableAthletes.length === 0;

  const showAddFilesForm = useCallback((): void => {
    if (!addFilesLayer.isOpened) {
      addFilesLayer.open();
    }
  }, [addFilesLayer]);

  if (!currentUser.isAllowedTo('filesOverview.addFile')) {
    return null;
  }

  return (
    <>
      <Tippy
        tooltipContent="filesOverview.addFiles.disabled.tooltip"
        translateValue
        isEnabled={isDisabled}
      >
        <AddButtonWrapper>
          <Button
            onClick={isDisabled ? undefined : showAddFilesForm}
            disabled={isDisabled}
            appearance={ButtonAppearance.Primary}
            noShadow
            data-cy="add-files"
          >
            + &nbsp;
            <FormattedMessage id="filesOverview.addFiles" />
          </Button>
        </AddButtonWrapper>
      </Tippy>

      <LayerPortal
        layerHandle={addFilesLayer}
        getContent={layer => <AddFilesLayer close={layer.close} />}
      />
    </>
  );
});
