import moment from 'moment';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export function getYears(startDate: Date, endDate: Date): number[] {
  const start = moment(startDate);
  const end = moment(endDate);

  const currentYear = start.clone();
  const years = [];

  while (currentYear.year() <= end.year()) {
    years.push(currentYear.get('year'));
    currentYear.add(1, 'year');
  }

  return years;
}

export function groupByWeeks(
  days: (number | { dayLabel: string; date: Date })[]
): (number | { dayLabel: string; date: Date })[][] {
  const weeks = days.reduce(
    (weeks: (number | { dayLabel: string; date: Date })[][], day) => {
      if (weeks.length === 0) {
        return [[day]];
      } else if (typeof day === 'number') {
        weeks[weeks.length - 1].push(day);
        return weeks;
      } else if (day.date.getDay() === 1) {
        return [...weeks, [day]];
      } else {
        weeks[weeks.length - 1].push(day);
        return weeks;
      }
    },
    []
  );

  return weeks;
}
