import moment from 'moment';
import * as React from 'react';

import { DATE_FORMAT } from '../helpers';

export const MonthIndexContext = React.createContext<string[]>([]);

export type MonthIndexContextProviderProps = React.PropsWithChildren<{
  startDate: moment.Moment;
  endDate: moment.Moment;
}>;

export function MonthIndexContextProvider(
  props: MonthIndexContextProviderProps
): JSX.Element {
  const { children, startDate, endDate } = props;
  const monthIndexes = React.useMemo(() => {
    const current = startDate.clone();
    const columns: string[] = [''];

    while (current.diff(endDate) <= 0) {
      columns.push(current.startOf('month').format(DATE_FORMAT));
      current.add(1, 'months');
    }

    return columns;
  }, [startDate, endDate]);

  return (
    <MonthIndexContext.Provider value={monthIndexes}>
      {children}
    </MonthIndexContext.Provider>
  );
}

export function useMonthIndexes(): string[] {
  return React.useContext(MonthIndexContext);
}
