import { useRootStore } from '../app/root-store-context';
import { ModulesStore } from './mobx/modules-store';

export function useModulesStore(): ModulesStore {
  return useRootStore().modulesStore;
}

type ModuleKey = keyof ModulesStore;
export function useModule<P extends ModuleKey>(moduleKey: P): ModulesStore[P] {
  return useModulesStore()[moduleKey];
}
