/* eslint-disable react/style-prop-object */
import { observer } from 'mobx-react-lite';
import { ObjectiveStore } from '../mobx/objective-store';
import { ActivityLogLayout } from '../../components-2/okrs/activity-log';
import { ActivityLogItem } from '../../components-2/okrs/activity-log-item';
import { RoleAvatar } from '../../components-2/role-avatar';
import { useUsersStore } from '../../users/hooks';
import { Avatar } from '../avatar';
import {
  FormattedMessage,
  FormattedRelativeTime,
  IntlShape,
  useIntl,
} from 'react-intl';
import moment from 'moment';
import { translateCategory } from '../okr-category';
import { IconSize } from '@yarmill/components';
import { useEffect, useState } from 'react';
import { Tippy } from '../../components/tippy/tippy';
import { ObjectiveActivityLogTab } from '../types';

interface ActivityLogProps {
  readonly objective: ObjectiveStore;
  readonly activeTab: ObjectiveActivityLogTab;
}

export function FormattedItemTime({
  eventDate,
}: {
  eventDate: string;
}): JSX.Element {
  const intl = useIntl();
  const today = moment.utc();
  const date = moment.utc(eventDate);
  const dateDiff = date.diff(today, 'minutes');
  const [showNow, setShowNow] = useState(dateDiff > -1);

  useEffect(() => {
    if (showNow) {
      setTimeout(() => setShowNow(false), 60 * 1000);
    }
  }, [showNow]);

  return (
    <Tippy
      tooltipContent={intl.formatDate(date.toDate(), {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })}
      translateValue={false}
    >
      <>
        {showNow ? (
          <FormattedMessage id="okrs.activityLog.now" />
        ) : (
          <FormattedRelativeTime
            value={dateDiff}
            numeric="auto"
            unit="minute"
            updateIntervalInSeconds={60}
            style="narrow"
          />
        )}
      </>
    </Tippy>
  );
}

function translateValueByMessageType(
  type: string,
  value: string,
  intl: IntlShape
): string {
  if (type.includes('priority')) {
    return intl.formatMessage({ id: `okrs.priority.${value}` });
  }
  if (type.includes('status')) {
    return intl.formatMessage({ id: `okrs.status.${value}` });
  }
  if (type.includes('category')) {
    return translateCategory(value, intl).join(' > ');
  }
  if (type.includes('date')) {
    return intl.formatDate(value, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
  }
  return value;
}
export const ActivityLog = observer(function ActivityLog({
  objective,
  activeTab,
}: ActivityLogProps): JSX.Element {
  const usersStore = useUsersStore();
  const intl = useIntl();

  return (
    <ActivityLogLayout>
      {objective.activities.map((activity, idx) => {
        const user = usersStore.getUserById(activity.createdBy);
        const isComment = activity.activityType === 'comment';

        if (
          (activeTab === 'comments' && !isComment) ||
          (activeTab === 'activities' && isComment)
        ) {
          return null;
        }

        return (
          <ActivityLogItem
            key={`${objective.uid}-${idx}`}
            avatar={
              user ? (
                <RoleAvatar
                  user={user}
                  size={isComment ? IconSize.s24 : IconSize.s16}
                />
              ) : (
                <Avatar />
              )
            }
            label={
              user?.displayName ??
              intl.formatMessage({ id: 'okrs.activityLog.unknownUser' })
            }
            appearance={
              isComment
                ? user && objective.supervisors.find(s => s.id === user.id)
                  ? 'sand'
                  : 'neutral'
                : 'neutralDark'
            }
            description={intl.formatMessage(
              {
                id: activity.label ?? 'okrs.activityLog.emptyLabel',
              },
              {
                ...activity.labelParams,
                valueBefore: activity.labelParams?.valueBefore
                  ? translateValueByMessageType(
                      activity.activityType ?? '',
                      activity.labelParams?.valueBefore ?? '',
                      intl
                    )
                  : '',
                valueAfter: activity.labelParams?.valueAfter
                  ? translateValueByMessageType(
                      activity.activityType ?? '',
                      activity.labelParams?.valueAfter ?? '',
                      intl
                    )
                  : '',
                lastName: user?.internalUser.LastName,
                firstName: user?.internalUser.FirstName,
              }
            )}
            date={<FormattedItemTime eventDate={activity.eventDate ?? ''} />}
          />
        );
      })}
    </ActivityLogLayout>
  );
});
