import { AsyncStatus } from '../../api/mobx/request-store';
import { EvidenceObject } from '../types';
import { RootStore } from '../../app/mobx/root-store';
import { getEvidenceDefinition } from '../api/get-evidence-definition';
import { computed, makeObservable, observable, runInAction } from 'mobx';

export class EvidenceDefinitionStore {
  private readonly rootStore: RootStore;
  private readonly definitionUrl: string;
  private readonly moduleKey: string;

  @observable
  private _definition: EvidenceObject[] | undefined = undefined;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  constructor(rootStore: RootStore, definitionUrl: string, moduleKey: string) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.definitionUrl = definitionUrl;
    this.moduleKey = moduleKey;
  }

  public async loadDefinition(): Promise<void> {
    this._status = AsyncStatus.pending;
    const transaction = this.rootStore.navbarStore.createTransaction(
      'loadingData',
      'evidence'
    );
    const request = this.rootStore.requestsStore.createRequest(() =>
      getEvidenceDefinition(this.definitionUrl, this.moduleKey)
    );

    const response = await request.getResponse();
    if (response) {
      runInAction(() => {
        this._definition = response;
        transaction.finished();
        this._status = AsyncStatus.resolved;
      });
    } else {
      transaction.error();
    }
  }

  public get definition(): EvidenceObject[] | undefined {
    return this._definition;
  }

  @computed
  public get status(): AsyncStatus {
    return this._status;
  }
}
