import { RootStore } from '../../app/mobx/root-store';
import { User } from '../../users/types';
import { subscribeToCalendar } from '../api/subscribe-to-calendar';
import { getUserName } from '../../users/utils';
import { RequestStore } from '../../api/mobx/request-store';
import { GroupStore } from '../../groups/mobx/group-store';

export class GoogleCalendarService {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public subscribeToUserCalendar(user: User): RequestStore<void> {
    const request = this.rootStore.requestsStore.createRequest(cancelToken =>
      subscribeToCalendar(
        {
          userId: user.UserId,
          userGroupId: undefined,
          calendarName: getUserName(user),
        },
        cancelToken
      )
    );

    return request;
  }

  public subscribeToGroupCalendar(group: GroupStore): RequestStore<void> {
    const request = this.rootStore.requestsStore.createRequest(cancelToken =>
      subscribeToCalendar(
        {
          userId: undefined,
          userGroupId: group.id,
          calendarName: group.name,
        },
        cancelToken
      )
    );

    return request;
  }
}
