import { useConfig } from '../app/hooks';
import { useIntl } from 'react-intl';
import { Locale } from './types';
import { IntlStore } from './mobx/intl-store';
import { useRootStore } from '../app/root-store-context';

export function useIntlStore(): IntlStore {
  return useRootStore().intlStore;
}

export function useLocale(): Locale {
  const defaultLanguage = useConfig('defaultLanguage');
  const intlStore = useIntlStore();

  return intlStore.locale || defaultLanguage;
}

export function useOptionalTranslation(code: string): string {
  const intl = useIntl();
  const messages = intl.messages;
  const message = code && messages[code];

  return message ? intl.formatMessage({ id: code }) : code;
}
