import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { UserId } from '../types';

export function updateEmail(
  userId: UserId,
  email: string
): AxiosPromise<boolean> {
  return axios.put('api/Account/UpdateEmail', { userId, email });
}
