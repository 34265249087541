import { AsyncToastMessage } from '@yarmill/components';
import { useEffect } from 'react';
import { toast, Id as ToastId, ToastOptions } from 'react-toastify';
import { FileUploadStore } from './mobx/file-upload-store';
import { observer } from 'mobx-react-lite';
import { AsyncStatus } from '../api/mobx/request-store';
import { useIntl } from 'react-intl';

export interface FileUploadProps {
  store: FileUploadStore;
}

function getTitle(store: FileUploadStore): string {
  if (store.isCanceled) {
    return 'fileupload.canceled';
  }
  switch (store.status) {
    case AsyncStatus.resolved:
      return 'fileupload.success';
    case AsyncStatus.rejected:
      return 'fileupload.error';
    default:
      return 'fileupload.uploading';
  }
}

function InternalFileUpload(props: FileUploadProps): JSX.Element {
  const { store } = props;
  const intl = useIntl();

  useEffect(() => {
    return () => {
      if (store.status !== AsyncStatus.resolved) {
        store.cancelUpload();
      }
    };
  }, [store]);

  const title = getTitle(store);
  const progress = AsyncStatus.pending ? store.progress : 0;

  return (
    <AsyncToastMessage
      data-cy="file-upload"
      title={intl.formatMessage({ id: title })}
      description={store.name}
      status={store.status}
      progress={progress}
    />
  );
}

const FileUpload = observer(InternalFileUpload);

const showOptions: ToastOptions = {
  autoClose: false,
  closeOnClick: true,
  position: 'bottom-right',
  className: 'ytd-toast',
  hideProgressBar: true,
  closeButton: false,
  icon: false,
};

const successFileUploadOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast success',
};

const errorFileUploadOptions: ToastOptions = {
  ...showOptions,
  autoClose: 5000,
  className: 'ytd-toast error',
};

export const toastShowFileUpload = (store: FileUploadStore) =>
  toast(<FileUpload store={store} />, showOptions);

export const toastSuccessFileUpload = (toastId: ToastId) =>
  toast.update(toastId, {
    ...successFileUploadOptions,
  });

export const toastErrorFileUpload = (toastId: ToastId) =>
  toast.update(toastId, {
    ...errorFileUploadOptions,
  });
