import { FileUploadService } from './mobx/file-upload-service';
import { useRootStore } from '../app/root-store-context';
import { FileDownloadService } from './mobx/file-download-service';

export function useFileUploadService(): FileUploadService {
  const rootStore = useRootStore();

  return rootStore.fileUploadService;
}

export function useFileDownloadService(): FileDownloadService {
  const rootStore = useRootStore();

  return rootStore.fileDownloadService;
}
