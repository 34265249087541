import { memo } from 'react';
import {
  FilesOverviewStoreContextProvider,
  useFilesOverviewStore,
} from './files-overview-store-context';
import { observer } from 'mobx-react-lite';
import { Sidebar } from '../athletes-sidebar/sidebar';
import { Page } from '../page/page';
import { useCurrentUserStore } from '../users/hooks';
import { PageContent } from './page-content';
import { PageMainContent } from '@yarmill/components';
import { useRootStore } from '../app/root-store-context';
import { Redirect } from 'react-router-dom';

function InternalFilesOverviewRouteRoot(): JSX.Element {
  const currentUser = useCurrentUserStore();
  const rootStore = useRootStore();
  const filesOverviewStore = useFilesOverviewStore();

  if (
    rootStore.isReady &&
    !currentUser.isAthlete &&
    !filesOverviewStore.groupId &&
    !filesOverviewStore.athleteId
  ) {
    return <Redirect to="/noGroup" />;
  }

  return (
    <Page>
      {currentUser.role !== 'athlete' ? <Sidebar /> : undefined}
      <PageMainContent>
        <PageContent />
      </PageMainContent>
    </Page>
  );
}

const ObserverFilesOverviewRouteRoot = observer(InternalFilesOverviewRouteRoot);

export const FilesOverviewRouteRoot = memo(() => (
  <FilesOverviewStoreContextProvider>
    <ObserverFilesOverviewRouteRoot />
  </FilesOverviewStoreContextProvider>
));
