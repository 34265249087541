import { CsvTableHeader } from '../types';
import { PRINT_WIDTH } from '../../reporting/utils';

function getPercentValue(value: string): number {
  const parsedValue = Number(value.replace('%', '')) / 100;
  return Number.isNaN(parsedValue) ? 0 : parsedValue;
}

function getPxValue(value: string | number | null): number {
  if (value == null) {
    return 0;
  }
  if (typeof value === 'number') {
    return value;
  }
  const parsedValue = Number(value.replace('px', ''));
  return Number.isNaN(parsedValue) ? 0 : parsedValue;
}
export function calculateColumnPrintWidth(
  columnConfig: CsvTableHeader,
  sectionRatio: number
): number {
  if (typeof window !== 'undefined') {
    return 0;
  }
  const width = columnConfig.Width;
  const isProportion = typeof width === 'string' && width.includes('%');
  const printWidth = isProportion
    ? PRINT_WIDTH * sectionRatio * getPercentValue(width)
    : getPxValue(width);

  return printWidth;
}
