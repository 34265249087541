import { RootStore } from '../../app/mobx/root-store';
import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
  when,
  makeObservable,
} from 'mobx';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import {
  DiaryDataUniqueId,
  DiaryType,
  GetAttributeDataForRangeResponse,
  GoalsAttributes,
  SeasonDataResponse,
  ViewType,
  WeekDataResponse,
} from '../types';
import { AsyncStatus, RequestStore } from '../../api/mobx/request-store';
import { getWeekDataForAthlete } from '../api/get-week-data-for-athlete';
import { getWeekDataForGroup } from '../api/get-week-data-for-group';
import {
  DATE_FORMAT,
  getWeekEndString,
  getWeekStartString,
  isAthleteRequired,
} from '../utils';
import {
  ActivityListStore,
  EmptyActivityListStore,
} from '../../activities/mobx/activity-list-store';
import { getSeasonDataForAthlete } from '../api/get-season-data-for-athlete';
import { getSeasonDataForGroup } from '../api/get-season-data-for-group';
import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  WEEK_SEARCH_PARAM,
} from '../../routes/types';
import { isModuleEnabled } from '../../modules/utils';
import { SeasonStore } from '../../seasons/mobx/season-store';
import moment from 'moment';
import { TrainingWeekStore } from '../../training-day/mobx/training-week-store';
import {
  getAttributeValuesForRange,
  GetAttributeValuesForRangeRequestParams,
} from '../api/get-attribute-values-for-range';
import { GoalsResponse } from '../../goals/types';
import {
  GoalsStore,
  NUMBER_OF_GOALS_WEEKS,
} from '../../goals/mobx/goals-store';
import { CancelTokenSource } from 'axios';
import { FocusedDayService } from './focused-day-service';
import { MaskCode } from '../../activities/types';
import { SeasonGoalsStore } from '../../goals/mobx/season-goals-store';
import { CopyMode } from '../../copy/types';
import {
  getAllowedSeasonCopyModes,
  getAllowedWeekCopyModes,
} from '../../copy/utils';
import { PermissionScope } from '../../permissions/types';
export class DiaryStore {
  private readonly rootStore: RootStore;
  private readonly focusedDayService: FocusedDayService;

  @observable
  public activityListStore: ActivityListStore | null = null;

  @observable
  public trainingWeekStore: TrainingWeekStore | null = null;

  @observable
  public goalsStore: GoalsStore | null = null;

  @observable
  public seasonGoalsStore: SeasonGoalsStore | null = null;

  @observable
  public athleteId: UserId | null = null;

  @observable
  public groupId: UserGroupId | null = null;

  @observable
  public week: string = '';

  @observable
  public diaryType: DiaryType = 'plan';

  @observable
  public viewType: ViewType = 'week';

  @observable
  private _currentSeason: SeasonStore | undefined = undefined;

  @observable
  private _lastWritableDay: moment.Moment | null = null;

  @observable
  private _applyBackfillScope: boolean = true;

  @observable
  private _maskCode?: MaskCode;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;

  @observable
  private dataRequest: RequestStore<
    WeekDataResponse | SeasonDataResponse | GoalsResponse
  > | null = null;

  @observable
  private placeholdersRequest: RequestStore<GetAttributeDataForRangeResponse> | null =
    null;

  @observable
  private goalsPlaceholdersRequest: RequestStore<GoalsResponse> | null = null;

  private reactions: IReactionDisposer[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.focusedDayService = rootStore.focusedDayService;
    this.registerReactions();

    when(
      () => rootStore.status === AsyncStatus.resolved,
      () => {
        this.updateMaskCode();
        this.activityListStore = this.createActivityListStore();
        this.trainingWeekStore = this.createTrainingWeekStore(
          this.createDataId({})
        );
        this.goalsStore = this.createGoalsStore(this.createDataId({}));
        this._currentSeason = this.rootStore.seasonsStore.getSeasonByWeek(
          this.week
        );
        this.loadDiaryData();
      }
    );
  }

  public disposeReactions(): void {
    this.reactions.forEach(dispose => dispose());
    this.activityListStore?.disposeReactions();
    this.trainingWeekStore?.disposeReactions();
    this.goalsStore?.disposeReactions();
  }

  @computed
  public get isValidRoute(): boolean {
    if (this.rootStore.status === AsyncStatus.pending) {
      return true;
    }

    if (!isModuleEnabled(this.diaryType, this.rootStore.modulesStore)) {
      return false;
    }

    if (
      this.viewType === 'week' &&
      !this.rootStore.configStore.weekViewEnabled
    ) {
      return false;
    }

    return true;
  }

  @computed
  public get isValidAthleteId(): boolean {
    const requiredAthlete = isAthleteRequired(this.diaryType);
    return !requiredAthlete || (requiredAthlete && this.athleteId !== null);
  }

  @computed
  public get isCurrentUserAllowedToCopy(): boolean {
    const diaryType = this.diaryType;
    const viewType = this.viewType;
    const currentUserStore = this.rootStore.currentUserStore;

    if (diaryType === 'reality') {
      return false;
    }

    const permissionScope: PermissionScope =
      `${diaryType}.${viewType}.copy` as PermissionScope;

    return currentUserStore.isAllowedTo(permissionScope);
  }

  @computed
  public get isCurrentUserAllowedToExport(): boolean {
    const diaryType = this.diaryType;

    if (this.viewType !== 'season') {
      return false;
    }

    const permissionScope: PermissionScope = `${diaryType}.${this.viewType}.export`;

    return this.rootStore.currentUserStore.isAllowedTo(permissionScope);
  }

  @computed
  public get status(): AsyncStatus {
    return this._status;
  }

  @computed
  public get currentSeason(): SeasonStore | undefined {
    return this._currentSeason;
  }

  @computed
  public get enabledViewTypes(): ViewType[] {
    const diaryType = this.diaryType;
    const currentUser = this.rootStore.currentUserStore;
    const weekViewEnabled = this.rootStore.configStore.weekViewEnabled;
    const goalsViewEnabled = this.rootStore.configStore.goalsViewEnabled;
    const seasonGoalsViewEnabled =
      this.rootStore.configStore.seasonGoalsViewEnabled;
    const isSeasonEditable = this.rootStore.configStore.seasonEditable;

    const viewTypes: ViewType[] = [];

    if (weekViewEnabled && currentUser.isAllowedTo(`${diaryType}.week`)) {
      viewTypes.push('week');
    }

    if (goalsViewEnabled && currentUser.isAllowedTo(`${diaryType}.goals`)) {
      viewTypes.push('goals');
    }

    if (
      seasonGoalsViewEnabled &&
      (diaryType === 'plan' || diaryType === 'reality') &&
      currentUser.isAllowedTo(`${diaryType}.seasonGoals`)
    ) {
      viewTypes.push('seasonGoals');
    }

    if (
      (diaryType === 'plan' &&
        currentUser.isAllowedTo(`${diaryType}.season`)) ||
      (diaryType === 'reality' &&
        isSeasonEditable &&
        currentUser.isAllowedTo(`${diaryType}.season`))
    ) {
      viewTypes.push('season');
    }

    return viewTypes;
  }

  @computed
  public get goalsAttributes(): GoalsAttributes | undefined {
    const attributes = this.rootStore.configStore.goalsAttributes;
    if (this.diaryType === 'plan') {
      return attributes?.plan;
    } else if (this.diaryType === 'reality') {
      return attributes?.reality;
    }

    return undefined;
  }

  public get lastWritableDay(): moment.Moment | null {
    return this._lastWritableDay;
  }

  public get applyBackfillScope(): boolean {
    return this._applyBackfillScope;
  }

  @action
  public readonly setMaskCode = (maskCode: MaskCode): void => {
    this._maskCode = maskCode;
    this.loadDiaryData();
  };

  public hasFilledDay(date: string): boolean {
    return Boolean(
      this.trainingWeekStore?.hasFilledDay(date) ||
        this.activityListStore?.hasFilledDay(date)
    );
  }

  @computed
  public get currentMaskCode(): MaskCode | undefined {
    return this._maskCode;
  }

  @computed
  public get copyModes(): CopyMode[] {
    let modes: CopyMode[] = [];
    if (this.viewType === 'week' || this.viewType === 'goals') {
      modes = getAllowedWeekCopyModes(
        this.rootStore.currentUserStore,
        this.groupId,
        this.athleteId
      );
    } else if (this.viewType === 'season' || this.viewType === 'seasonGoals') {
      modes = getAllowedSeasonCopyModes(
        this.rootStore.currentUserStore,
        this.groupId,
        this.athleteId,
        this.viewType
      );
    }
    return modes;
  }

  private readonly initDiaryBackfill = (): void => {
    const diaryBackfill = this.rootStore.configStore.diaryBackfill;
    const diaryBackfillScope = this.rootStore.configStore.diaryBackfillScope;

    if (
      (diaryBackfillScope.length === 0 ||
        diaryBackfillScope.includes(this.rootStore.currentUserStore.role)) &&
      diaryBackfill > -1
    ) {
      const now = moment().startOf('day');
      const validTo = now.clone().subtract(diaryBackfill, 'days');
      this._lastWritableDay = validTo;
      this._applyBackfillScope = true;
    } else {
      this._applyBackfillScope = false;
    }
  };

  private readonly updateMaskCode = (): void => {
    const athleteId = this.athleteId;
    const groupId = this.groupId;

    const athlete = athleteId
      ? this.rootStore.usersStore.getUserById(athleteId)
      : null;
    const group = groupId
      ? this.rootStore.groupsStore.getGroupById(groupId)
      : null;

    this._maskCode = athlete?.activityMask || group?.activityMask;
  };

  private registerReactions(): void {
    const observerAthleteId = reaction(
      () =>
        this.rootStore.historyService.searchParams.get(ATHLETE_SEARCH_PARAM),
      id => {
        this.athleteId = id !== undefined ? Number(id) : null;
      },
      {
        fireImmediately: true,
      }
    );
    const observeGroupId = reaction(
      () => this.rootStore.historyService.searchParams.get(GROUP_SEARCH_PARAM),
      id => {
        this.groupId = id !== undefined ? Number(id) : null;
      },
      {
        fireImmediately: true,
      }
    );
    const observeWeek = reaction(
      () => this.rootStore.historyService.searchParams.get(WEEK_SEARCH_PARAM),
      week => {
        this.week = week || '';
        this._currentSeason = this.rootStore.seasonsStore.getSeasonByWeek(
          this.week
        );
        this.focusedDayService.setFocusedDay(
          this.focusedDayService.focusedDay === -1 ? -2 : -1
        );
      },
      {
        fireImmediately: true,
      }
    );
    const observePathname = reaction(
      () => this.rootStore.historyService.pathname,
      pathname => {
        this.parseDiaryTypeAndViewType(pathname);
      },
      {
        fireImmediately: true,
      }
    );
    const viewType = reaction(
      () => this.viewType,
      viewType => {
        this.activityListStore = this.createActivityListStore();
        this.trainingWeekStore = this.createTrainingWeekStore(
          this.createDataId({ viewType })
        );
        this.goalsStore = this.createGoalsStore(
          this.createDataId({ viewType })
        );
      }
    );

    const diaryType = reaction(
      () => this.diaryType,
      diaryType => {
        if (diaryType === 'plan' || diaryType === 'reality') {
          this.trainingWeekStore = this.createTrainingWeekStore(
            this.createDataId({ diaryType })
          );
          this.goalsStore = this.createGoalsStore(
            this.createDataId({ diaryType })
          );
        }

        this.activityListStore = this.createActivityListStore();
      }
    );

    const maskCode = reaction(
      () => ({
        athleteId: this.athleteId,
        groupId: this.groupId,
        diaryType: this.diaryType,
        viewType: this.viewType,
      }),
      () => this.updateMaskCode()
    );

    const activityListStore = reaction(
      () => this.currentMaskCode,
      () => {
        this.activityListStore = this.createActivityListStore();
      }
    );

    const dataLoader = reaction(
      () => ({
        athleteId: this.athleteId,
        groupId: this.groupId,
        week: this.week,
        diaryType: this.diaryType,
        viewType: this.viewType,
      }),
      () => this.loadDiaryData()
    );

    const backfill = autorun(this.initDiaryBackfill);

    this.reactions.push(
      observerAthleteId,
      observeGroupId,
      observeWeek,
      observePathname,
      viewType,
      diaryType,
      backfill,
      dataLoader,
      activityListStore,
      maskCode
    );
  }

  @action
  private parseDiaryTypeAndViewType(pathname: string): void {
    if (pathname.includes('plan')) {
      this.diaryType = 'plan';
    } else if (pathname.includes('reality')) {
      this.diaryType = 'reality';
    }

    if (pathname.includes('week')) {
      this.viewType = 'week';
    } else if (pathname.includes('seasonGoals')) {
      this.viewType = 'seasonGoals';
    } else if (pathname.includes('season')) {
      this.viewType = 'season';
    } else if (pathname.includes('goals')) {
      this.viewType = 'goals';
    }
  }

  private async loadDiaryData(): Promise<void> {
    if (!this.week) {
      return;
    }
    const transaction = this.rootStore.navbarStore.createTransaction(
      'loadingData',
      'diary'
    );
    if (this.viewType === 'week') {
      await this.loadWeekData();
    } else if (this.viewType === 'season') {
      await this.loadSeasonData();
    } else if (this.viewType === 'goals') {
      await this.loadGoalsData();
    } else if (this.viewType === 'seasonGoals') {
      this.seasonGoalsStore = this.createSeasonGoalsStore(
        this.createDataId({})
      );
    }
    transaction.finished();
  }

  public async reloadDiaryData(): Promise<void> {
    const promise = this.loadDiaryData();

    await promise;
  }

  private async loadWeekData(): Promise<void> {
    if (this.dataRequest) {
      this.dataRequest.cancel();
      this.rootStore.requestsStore.removeRequest(this.dataRequest);
    }
    if (this.placeholdersRequest) {
      this.placeholdersRequest.cancel();
      this.rootStore.requestsStore.removeRequest(this.placeholdersRequest);
      this.placeholdersRequest = null;
    }

    this.activityListStore?.clear();

    const week = this.week;
    const weekStart = getWeekStartString(week);
    const weekEnd = getWeekEndString(week);
    const athleteId = this.athleteId;
    const groupId = this.groupId;
    const diaryType = this.diaryType;
    const viewType = this.viewType;

    if ((diaryType === 'reality' && !athleteId) || (!athleteId && !groupId)) {
      // It is not possible to load reality for group
      return;
    }

    this._status = AsyncStatus.pending;

    const promise = athleteId
      ? (cancelToken: CancelTokenSource) =>
          getWeekDataForAthlete(
            diaryType,
            {
              userId: athleteId,
              startDate: weekStart,
              endDate: weekEnd,
              referenceTime: this.referenceTime,
            },
            cancelToken
          )
      : (cancelToken: CancelTokenSource) =>
          getWeekDataForGroup(
            diaryType,
            {
              userGroupId: groupId as number,
              startDate: weekStart,
              endDate: weekEnd,
              referenceTime: this.referenceTime,
            },
            cancelToken
          );

    const goalsAttributes = this.rootStore.configStore.goalsAttributes?.reality;
    if (diaryType === 'plan' && goalsAttributes) {
      this.placeholdersRequest = this.rootStore.requestsStore.createRequest(
        cancelToken =>
          getAttributeValuesForRange({
            startDate: weekStart,
            endDate: weekEnd,
            userId: athleteId ?? undefined,
            userGroupId: !athleteId && groupId ? groupId : undefined,
            attributeIds: [goalsAttributes.day, goalsAttributes.week],
            referenceTime: this.referenceTime,
          })
      );
    }

    const request = this.rootStore.requestsStore.createRequest(promise);
    this.dataRequest = request;
    const trainingWeekStore = this.createTrainingWeekStore({
      week,
      athleteId,
      groupId,
      diaryType,
      viewType,
    });
    if (this.diaryType === 'reality') {
      trainingWeekStore?.loadAdditionalData();
    }
    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        this.activityListStore?.setWeekActivitiesData(response.DayActivities);
        this.trainingWeekStore = trainingWeekStore;

        this.trainingWeekStore?.setWeekData(response.DayAttributes);
        this.trainingWeekStore?.setWeekEvents(response.Events);
        this._status = AsyncStatus.resolved;
      });
    } else {
      if (request.status === AsyncStatus.rejected) {
        this._status = AsyncStatus.rejected;
      }
    }

    if (this.placeholdersRequest) {
      const placeholdersResponse = await this.placeholdersRequest.getResponse();

      if (placeholdersResponse) {
        runInAction(() => {
          trainingWeekStore?.setGoalsPlaceholders(
            placeholdersResponse.DayAttributes
          );
        });
      }
    }
  }

  private async loadSeasonData(): Promise<void> {
    if (this.dataRequest) {
      this.dataRequest.cancel();
      this.rootStore.requestsStore.removeRequest(this.dataRequest);
    }

    this.activityListStore?.clear();

    const season = this.rootStore.seasonsStore.getSeasonByDate(this.week);

    if (!season) {
      return;
    }

    const athleteId = this.athleteId;
    const groupId = this.groupId;
    const diaryType = this.diaryType;

    if ((diaryType === 'reality' && !athleteId) || (!athleteId && !groupId)) {
      // It is not possible to load reality for group
      return;
    }
    this._status = AsyncStatus.pending;

    const promise = athleteId
      ? (cancelToken: CancelTokenSource) =>
          getSeasonDataForAthlete(
            diaryType,
            {
              userId: athleteId,
              seasonId: season.id,
              referenceTime: this.referenceTime,
            },
            cancelToken
          )
      : (cancelToken: CancelTokenSource) =>
          getSeasonDataForGroup(
            diaryType,
            {
              userGroupId: groupId as number,
              seasonId: season.id,
              referenceTime: this.referenceTime,
            },
            cancelToken
          );

    const request = this.rootStore.requestsStore.createRequest(promise);
    this.dataRequest = request;
    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        this.activityListStore?.setSeasonActivitiesData(
          response,
          season.seasonSections
        );
        this._status = AsyncStatus.resolved;
      });
    } else {
      if (request.status === AsyncStatus.rejected) {
        this._status = AsyncStatus.rejected;
      }
    }
  }

  private async loadGoalsData(): Promise<void> {
    if (this.dataRequest) {
      this.dataRequest.cancel();
      this.rootStore.requestsStore.removeRequest(this.dataRequest);
    }
    if (this.goalsPlaceholdersRequest) {
      this.goalsPlaceholdersRequest.cancel();
      this.rootStore.requestsStore.removeRequest(this.goalsPlaceholdersRequest);
    }

    this._status = AsyncStatus.pending;

    const attributes = this.rootStore.configStore.goalsAttributes;
    const diaryType = this.diaryType;
    const planAttributes = attributes?.plan;
    const realityAttributes = attributes?.reality;
    const week = this.week;
    const viewType = this.viewType;
    const startDate = getWeekStartString(week);
    const weekEnd = getWeekEndString(week);
    const endDate = moment(weekEnd)
      .add(NUMBER_OF_GOALS_WEEKS - 1, 'weeks')
      .format(DATE_FORMAT);
    const athleteId = this.athleteId;
    const groupId = this.groupId;
    const promises = [];

    if (!athleteId && !groupId) {
      return;
    }

    const baseParams: GetAttributeValuesForRangeRequestParams = {
      startDate,
      endDate,
      userGroupId: athleteId ? undefined : groupId || undefined,
      userId: athleteId || undefined,
      attributeIds: [],
    };

    if (planAttributes && diaryType === 'plan') {
      const planIds = [
        planAttributes.cycle,
        planAttributes.week,
        planAttributes.day,
      ];
      promises.push((cancelToken: CancelTokenSource) =>
        getAttributeValuesForRange(
          { ...baseParams, attributeIds: planIds },
          cancelToken
        )
      );
    }

    if (
      realityAttributes &&
      (diaryType === 'reality' ||
        this.rootStore.configStore.showGoalPlaceholders)
    ) {
      const realityIds = [
        realityAttributes?.cycle,
        realityAttributes?.week,
        realityAttributes?.day,
      ];
      promises.push((cancelToken: CancelTokenSource) =>
        getAttributeValuesForRange(
          { ...baseParams, attributeIds: realityIds },
          cancelToken
        )
      );
    }

    const requests = promises.map(promise =>
      this.rootStore.requestsStore.createRequest(promise)
    );
    this.dataRequest = requests[0];

    if (requests[1]) {
      this.goalsPlaceholdersRequest = requests[1];
    }

    const [data, placeholders] = await Promise.all([
      requests[0].getResponse(),
      requests[1]?.getResponse(),
    ]);

    runInAction(() => {
      this.goalsStore = this.createGoalsStore({
        diaryType,
        viewType,
        week,
        groupId,
        athleteId,
      });
      if (data) {
        this.goalsStore?.setGoalsData(data.DayAttributes, data.Events);
        this._status = AsyncStatus.resolved;
      } else {
        if (requests[0].status === AsyncStatus.rejected) {
          this._status = AsyncStatus.rejected;
        }
      }

      if (placeholders) {
        this.goalsStore?.setPlaceholders(placeholders.DayAttributes);
      }
    });
  }

  private async loadSeasonGoalsData(
    start: moment.Moment,
    end: moment.Moment
  ): Promise<void> {
    if (this.dataRequest) {
      this.dataRequest.cancel();
      this.rootStore.requestsStore.removeRequest(this.dataRequest);
    }
    if (this.goalsPlaceholdersRequest) {
      this.goalsPlaceholdersRequest.cancel();
      this.rootStore.requestsStore.removeRequest(this.goalsPlaceholdersRequest);
    }

    const attributes = this.rootStore.configStore.goalsAttributes;
    const planAttributes = attributes?.plan;
    const realityAttributes = attributes?.reality;
    const promises = [];
    const userGroupId = this.groupId;
    const athleteId = this.athleteId;

    if (!athleteId && !userGroupId) {
      // It is not possible to load reality for group
      return;
    }
    this._status = AsyncStatus.pending;

    const baseParams: GetAttributeValuesForRangeRequestParams = {
      startDate: start.format(DATE_FORMAT),
      endDate: end.format(DATE_FORMAT),
      userGroupId: athleteId ? undefined : userGroupId || undefined,
      userId: athleteId || undefined,
      attributeIds: [],
    };

    if (planAttributes && this.diaryType === 'plan') {
      const planIds = [
        planAttributes.cycle,
        planAttributes.week,
        planAttributes.day,
      ];
      promises.push((cancelToken: CancelTokenSource) =>
        getAttributeValuesForRange(
          { ...baseParams, attributeIds: planIds },
          cancelToken
        )
      );
    }

    if (
      realityAttributes &&
      (this.diaryType === 'reality' ||
        this.rootStore.configStore.showGoalPlaceholders)
    ) {
      const realityIds = [
        realityAttributes?.cycle,
        realityAttributes?.week,
        realityAttributes?.day,
      ];
      promises.push((cancelToken: CancelTokenSource) =>
        getAttributeValuesForRange(
          { ...baseParams, attributeIds: realityIds },
          cancelToken
        )
      );
    }

    const requests = promises.map(promise =>
      this.rootStore.requestsStore.createRequest(promise)
    );
    this.dataRequest = requests[0];

    if (requests[1]) {
      this.goalsPlaceholdersRequest = requests[1];
    }

    const [data, placeholders] = await Promise.all([
      requests[0].getResponse(),
      requests[1]?.getResponse(),
    ]);

    runInAction(() => {
      if (data) {
        this.seasonGoalsStore?.setGoalsData(data.DayAttributes, data.Events);
        this._status = AsyncStatus.resolved;
      } else {
        if (requests[0].status === AsyncStatus.rejected) {
          this._status = AsyncStatus.rejected;
        }
      }

      if (placeholders) {
        this.seasonGoalsStore?.setPlaceholders(placeholders.DayAttributes);
      }
    });
  }

  private createActivityListStore(): ActivityListStore {
    this.activityListStore?.disposeReactions();

    switch (this.viewType) {
      case 'week':
        return new ActivityListStore(
          this.rootStore,
          this,
          this.currentMaskCode
        );
      case 'season': {
        const season = this.rootStore.seasonsStore.getSeasonByWeek(this.week);

        if (!season) {
          return new EmptyActivityListStore(this.rootStore, this);
        }

        return new ActivityListStore(
          this.rootStore,
          this,
          this.currentMaskCode,
          season
        );
      }
      case 'goals':
      case 'seasonGoals':
        return new EmptyActivityListStore(this.rootStore, this);
    }
  }

  private createTrainingWeekStore(
    dataId: DiaryDataUniqueId
  ): TrainingWeekStore | null {
    this.trainingWeekStore?.disposeReactions();

    if (this.viewType === 'week') {
      return new TrainingWeekStore(this.rootStore, this, dataId);
    }

    return null;
  }

  private createGoalsStore(dataId: DiaryDataUniqueId): GoalsStore | null {
    this.goalsStore?.disposeReactions();

    if (this.viewType === 'goals') {
      return new GoalsStore(this.rootStore, this, dataId);
    }

    return null;
  }

  private createSeasonGoalsStore(
    dataId: DiaryDataUniqueId
  ): SeasonGoalsStore | null {
    if (this.viewType === 'seasonGoals') {
      return new SeasonGoalsStore(
        this.rootStore,
        this,
        (start, end) => this.loadSeasonGoalsData(start, end),
        dataId
      );
    }

    return null;
  }

  private get referenceTime(): string | undefined {
    return typeof window !== 'undefined'
      ? window.localStorage.getItem('yarmill-reference-time') || undefined
      : undefined;
  }

  private createDataId(data: Partial<DiaryDataUniqueId>): DiaryDataUniqueId {
    return {
      week: this.week,
      athleteId: this.athleteId,
      groupId: this.groupId,
      diaryType: this.diaryType,
      viewType: this.viewType,
      ...data,
    };
  }
}
