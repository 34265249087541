import {
  mockOkrCategoryList,
  OkrCategory as iOkrCategory,
  OkrRootCategory,
} from '../types';
import { getAppearanceForCategory, OkrCategory } from '../okr-category';
import {
  DropdownOption,
  DropdownProvider,
} from '../../components-2/dropdown-provider/dropdown-provider';
import { IntlShape, useIntl } from 'react-intl';
import { useMemo } from 'react';
import { DropdownButtonWrapper } from '../../components-2/dropdown-provider/dropdown-button-wrapper';
import { Category } from '../../components-2/category';
import { Folder } from '@yarmill/icons-2';
import { CategoryDropdownIconWrapper } from '../../components-2/okrs/detail/okr-form';
import { Tippy } from '../../components/tippy/tippy';

function createCategoryOption(
  intl: IntlShape,
  category: iOkrCategory,
  isSubcategory = false
): DropdownOption<iOkrCategory> {
  return {
    label: intl.formatMessage({ id: `okrs.category.${category}` }),
    icon: isSubcategory ? (
      <CategoryDropdownIconWrapper>
        <Folder />
      </CategoryDropdownIconWrapper>
    ) : undefined,
    value: category,
    children: [],
  };
}
const createOkrCategoryOptions = (
  intl: IntlShape
): DropdownOption<iOkrCategory>[] =>
  mockOkrCategoryList
    .map(c => c.split('.'))
    .sort((a, b) => a.length - b.length)
    .reduce<DropdownOption<iOkrCategory>[]>((options, category) => {
      const rootCategory = category[0] as OkrRootCategory;
      if (category.length === 1) {
        options.push(createCategoryOption(intl, rootCategory));
      } else if (category.length === 2) {
        const parentCategoryIndex = options.findIndex(
          o => o.value === rootCategory
        );

        if (parentCategoryIndex !== -1) {
          options[parentCategoryIndex]?.children?.push(
            createCategoryOption(intl, category.join('.') as iOkrCategory, true)
          );
        }
      }

      return options;
    }, []);

interface CategoryDropdownProps {
  readonly selectedValue: iOkrCategory | null;
  readonly handleSelect: (value: iOkrCategory) => void;
  readonly disabled?: boolean;
}
export function CategoryDropdown({
  selectedValue,
  handleSelect,
  disabled,
}: CategoryDropdownProps): JSX.Element {
  const intl = useIntl();
  const categories = useMemo(() => createOkrCategoryOptions(intl), [intl]);

  return (
    <Tippy tooltipContent="okrs.category" noWrapper touch={false}>
      <DropdownProvider
        keyboardShortcut="C"
        options={categories}
        selectedValue={selectedValue ?? undefined}
        searchInputPlaceholder={intl.formatMessage({
          id: 'okrs.form.category.label',
        })}
        handleSelect={handleSelect as any}
        disabled={disabled}
      >
        {selectedValue ? (
          <DropdownButtonWrapper
            appearance={getAppearanceForCategory(selectedValue)}
            disabled={disabled}
          >
            <OkrCategory category={selectedValue} />
          </DropdownButtonWrapper>
        ) : (
          <DropdownButtonWrapper appearance="sand" disabled={disabled}>
            <Category
              category={intl.formatMessage({
                id: 'okrs.form.category.selectCategory',
              })}
              appearance="sand"
            />
          </DropdownButtonWrapper>
        )}
      </DropdownProvider>
    </Tippy>
  );
}
