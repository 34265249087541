import { Button, ButtonAppearance } from '../../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { Trash } from '@yarmill/icons-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOkrsStore } from '../okrs-store-context';
import { ObjectiveStore } from '../mobx/objective-store';
import { useContext } from 'react';
import { KeyResultsSelectionContext } from '../key-results-selection-context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';

interface RemoveKeyResultsProps {
  readonly objective: ObjectiveStore;
}
export const RemoveKeyResults = observer(function RemoveKeyResults({
  objective,
}: RemoveKeyResultsProps): JSX.Element {
  const intl = useIntl();
  const okrsStore = useOkrsStore();
  const selectionStore = useContext(KeyResultsSelectionContext);
  async function handleClick() {
    if (
      window.confirm(
        intl.formatMessage(
          {
            id: 'okrs.detail.removeKeyResults.confirm',
          },
          {
            count: selectionStore.selectedKeyResults.size,
          }
        )
      ) &&
      objective
    ) {
      await runInAction(async () => {
        selectionStore.selectedKeyResults.forEach(id =>
          objective.removeKeyResult(id)
        );
        selectionStore.clear();
        await okrsStore.saveObjective(objective);
      });
    }
  }

  return (
    <Button
      $appearance={ButtonAppearance.Secondary}
      $appearanceStyle="red"
      onClick={handleClick}
      $iconOnly
    >
      <Icon size={IconSize.s16}>
        <Trash />
      </Icon>
      <FormattedMessage
        id="okrs.detail.removeKeyResults"
        values={{ count: selectionStore.selectedKeyResults.size }}
      />
    </Button>
  );
});
