import { EvidenceAttachmentStore } from '../mobx/evidence-attachment-store';
import { useFileUpload } from '../../fileupload/hooks/use-file-upload';
import { useCallback } from 'react';
import { FileUploadStore } from '../../fileupload/mobx/file-upload-store';
import { Id } from 'react-toastify';
import {
  toastErrorFileUpload,
  toastSuccessFileUpload,
} from '../../fileupload/file-upload';

export function useEvidenceFileUpload(
  attribute: EvidenceAttachmentStore,
  saveOnUpload = true
) {
  const onSuccess = useCallback(
    async (fileUpload: FileUploadStore, toastId: Id) => {
      const file = fileUpload.uploadedFile;
      if (file) {
        attribute.addAttachment(fileUpload.uploadedFile);
        if (saveOnUpload) {
          await attribute.objectDataStore.syncValue();
        }
        toastSuccessFileUpload(toastId);
      } else {
        toastErrorFileUpload(toastId);
      }
      attribute.objectDataStore.pendingAttachments.remove(fileUpload);
    },
    [attribute, saveOnUpload]
  );

  const onStart = useCallback(
    (fileUpload: FileUploadStore) => {
      attribute.objectDataStore.pendingAttachments.push(fileUpload);
    },
    [attribute]
  );

  const onError = useCallback(
    (fileUpload: FileUploadStore) => {
      attribute.objectDataStore.pendingAttachments.remove(fileUpload);
    },
    [attribute]
  );

  return useFileUpload(onSuccess, onError, onStart);
}
