import { RootStore } from '../../app/mobx/root-store';
import { toast } from '../../components/toast-message';
import { handleConnectGoogleCalendarServiceSuccessRedirectAction } from '../../google-calendar/utils';

export function handleServicesRedirectActions(
  rootStore: RootStore,
  action: string
): void {
  switch (action) {
    case 'disconnectExternalServiceSuccess': {
      toast('toast.success.disconnectExternalService', 'success');
      break;
    }
    case 'disconnectExternalServiceError': {
      toast('toast.error.disconnectExternalService', 'error');
      break;
    }
    case 'connectExternalServiceSuccess': {
      toast('toast.success.connectExternalService', 'success');
      break;
    }
    case 'connectExternalServiceError': {
      toast('toast.error.connectExternalService', 'error');
      break;
    }
    case 'deactivatedAccount': {
      rootStore.authStore.showDeactivatedUserMessage();
      break;
    }
    case 'connectGoogleCalendarServiceSuccess': {
      handleConnectGoogleCalendarServiceSuccessRedirectAction(rootStore);
      break;
    }
  }
}
