import {
  Button,
  ButtonAppearance,
  ExternalIcon,
  Icon,
  IconSize,
} from '@yarmill/components';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { LayerPortal } from '../layer-manager/layer-portal';
import { SeasonStartDateDialog } from './season-start-date-dialog';
import { useLayer } from '../layer-manager/hooks';

const SettingsButton = styled(Button)`
  margin-left: 20px;
`;

export const SeasonStartSettingsButton = observer(
  function SeasonStartSettingsButton(): JSX.Element | null {
    const seasonStartDateLayer = useLayer('alert', {
      showShim: true,
      closeOnShimClick: true,
    });

    function openLayer() {
      if (!seasonStartDateLayer.isOpened) {
        seasonStartDateLayer.open();
      }
    }

    return (
      <>
        <SettingsButton
          type="button"
          appearance={ButtonAppearance.Secondary}
          square
          onClick={openLayer}
          noShadow
        >
          <Icon size={IconSize.s14}>
            <ExternalIcon name="Settings" />
          </Icon>
        </SettingsButton>
        <LayerPortal
          layerHandle={seasonStartDateLayer}
          getContent={layer => <SeasonStartDateDialog layer={layer} />}
        />
      </>
    );
  }
);
