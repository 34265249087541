import { ExternalIcon, ExternalIconName } from '@yarmill/components';

export function getYarmillIcon(
  name?: ExternalIconName | null
): JSX.Element | null {
  if (name) {
    return <ExternalIcon name={name} />;
  }

  return null;
}
