import { ContextLayerOptions, LayerComponentProps } from './types';
import { ContextLayer as ContextLayerComponent } from '@yarmill/components';
import { LayerContent } from './layer-content';

export function ContextLayer(props: LayerComponentProps): JSX.Element {
  const { layer, active, ...ownProps } = props;
  const options = layer.options as ContextLayerOptions;

  return (
    <ContextLayerComponent
      {
        ...ownProps /* Used to pass through the animation props */
      }
      active={Boolean(active)}
      top={options.position.top}
      left={options.position.left}
      verticalAlignment={options.position.verticalAlignment}
      horizontalAlignment={options.position.horizontalAlignment}
    >
      <LayerContent layer={layer} active={active} />
    </ContextLayerComponent>
  );
}
