import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { User } from '../types';

export interface UserConsents {
  NewsletterConsent: boolean;
  CommercialConsent: boolean;
}
export function changeEmailPreferences(
  userConsents: UserConsents
): AxiosPromise<User> {
  return axios.put('api/User/settings', userConsents);
}
