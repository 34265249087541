import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { TrainingDayAttributeState } from '../../training-day/types';

export interface UpdateAttributeRequestParams {
  attributeId: number;
  value: string | string[];
  date: string;
  userId: number | null;
  userGroupId: number | null;
  state: TrainingDayAttributeState;
}

export interface UpdateAttributeResponse {
  Message: string;
}

export function updateTrainingDayAttribute(
  params: UpdateAttributeRequestParams
): AxiosPromise<UpdateAttributeResponse> {
  return axios.post('api/TrainingDayAttribute', params);
}
