import { useCurrentUserAllowedGroups } from '../groups/hooks';
import { GroupStore } from '../groups/mobx/group-store';
import { observer } from 'mobx-react-lite';
import { SidebarList } from '@yarmill/components';
import { GroupLink } from './group-link';

export interface GroupsListProps {
  selectedGroup?: GroupStore | null;
  onSelect(): void;
}

export const GroupsList = observer(function GroupsList(
  props: GroupsListProps
): JSX.Element {
  const { selectedGroup, onSelect } = props;
  const groups = useCurrentUserAllowedGroups();

  function onGroupClick() {
    onSelect();
    if (window.innerWidth < 768) {
      window.scrollTo({ top: 0 });
    }
  }

  return (
    <SidebarList>
      {groups.map(group => (
        <GroupLink
          key={group.id}
          isActive={selectedGroup?.id === group.id}
          group={group}
          onClick={onGroupClick}
        />
      ))}
    </SidebarList>
  );
});
