import { useCallback } from 'react';
import { CancelTokenSource } from 'axios';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { useYollandaService } from './use-yollanda-service';
import { useConfig } from '../../app/hooks';
import { isTouchDevice } from '../../utils/is-touch-device';

export function useFetchAutoCompletion(
  athleteId: UserId | null,
  groupId: UserGroupId | null
) {
  const yollandaService = useYollandaService();
  const isCopilotAvailable = useConfig('yollandaCopilotEnabled');

  const fetchAutoCompletion = useCallback(
    async (query: string, cancelToken?: CancelTokenSource) =>
      await yollandaService.suggestTextCompletion(
        query,
        athleteId,
        groupId,
        cancelToken
      ),
    [yollandaService, athleteId, groupId]
  );

  return isCopilotAvailable && !isTouchDevice()
    ? fetchAutoCompletion
    : undefined;
}
