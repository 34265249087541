import { action, observable, makeObservable } from 'mobx';

export class ImageService {
  @observable
  private imageSrc: string | Promise<string> | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  public showPreview(src: string | Promise<string>): void {
    this.imageSrc = src;

    if (src instanceof Promise) {
      src.then(s => (this.imageSrc = s));
    }
  }

  @action
  public readonly closePreview = (): void => {
    this.imageSrc = null;
  };

  public get previewSrc(): string | Promise<string> | null {
    return this.imageSrc;
  }
}
