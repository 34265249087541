import { useCallback } from 'react';
import moment from 'moment';
import { Location } from 'history';

import { NavItem } from '../nav-item';
import { useDefaultCommonSearchParams } from '../hooks';
import { ROUTE_DATE_FORMAT } from '../../diary/utils';
import { LinkCallback } from '../../routes/types';
import { appendDefaultSearchParams } from '../utils';
import { useConfig } from '../../app/hooks';

export interface AttendanceLinkProps {
  className?: string;
  onClick?(): void;
}

export function useAttendanceLink(): LinkCallback {
  const phases = useConfig('attendancePhases') || [];
  const firstPhase = phases.slice().sort((a, b) => a.sortCode - b.sortCode)[0];

  const defaultParams = useDefaultCommonSearchParams({
    week: moment().format(ROUTE_DATE_FORMAT),
    phase:
      phases.length > 1 && firstPhase
        ? String(firstPhase?.activityItemId)
        : undefined,
  });

  return useCallback(
    (location: Location) => {
      const searchParams = appendDefaultSearchParams(
        location.search,
        defaultParams
      );

      return `/attendance/week?${searchParams}`;
    },
    [defaultParams]
  );
}

export function AttendanceLink(props: AttendanceLinkProps): JSX.Element {
  const { className, onClick } = props;
  const link = useAttendanceLink();

  return (
    <NavItem
      to={link}
      moduleKey="attendance"
      icon="UserCheck"
      className={className}
      onClick={onClick}
    />
  );
}
