import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';
import { EvidenceObject } from '../types';
import { devMode, instance } from '../../api/api-mapping';

function createDefinitionUrl(definitionUrl: string, moduleKey: string): string {
  return definitionUrl.replace('{moduleKey}', moduleKey);
}

export function getEvidenceDefinition(
  definitionUrl: string,
  moduleKey: string
): AxiosPromise<EvidenceObject[]> {
  return axios.get(createDefinitionUrl(definitionUrl, moduleKey), {
    params: {
      instanceCode: devMode ? instance : undefined,
    },
  });
}
