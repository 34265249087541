import {
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  CycleTrend,
  KPI,
  SeasonTrend,
  WeekTrend,
} from '../dashboards/general/types';
import { getWeekTrend } from '../dashboards/general/api/get-week-trend';
import { getCycleTrend } from '../dashboards/general/api/get-cycle-trend';
import { getKpi } from '../dashboards/general/api/get-kpi';
import { getSeasonTrend } from '../dashboards/general/api/get-season-trend';
import { BaseDashboardStore } from './base-dashboard-store';
import { AsyncStatus } from '../../api/mobx/request-store';
import { RootStore } from '../../app/mobx/root-store';
import { Dashboard } from '../types';

export class GeneralDashboardStore extends BaseDashboardStore {
  @observable
  private readonly _kpi: KPI[] = [];
  @observable
  private readonly _weekTrend: WeekTrend[] = [];
  @observable
  private readonly _cycleTrend: CycleTrend[] = [];
  @observable
  private readonly _seasonTrend: SeasonTrend[] = [];

  constructor(rootStore: RootStore, dashboard: Dashboard) {
    super(rootStore, dashboard);
    makeObservable(this);
  }

  public async loadData(): Promise<void> {
    const requestsStore = this._rootStore.requestsStore;
    const filterValues = Object.fromEntries(this._filterValues.entries());
    const requests = [
      requestsStore.createRequest(() => getKpi(filterValues)),
      requestsStore.createRequest(() => getWeekTrend(filterValues)),
      requestsStore.createRequest(() => getCycleTrend(filterValues)),
      requestsStore.createRequest(() => getSeasonTrend(filterValues)),
    ];

    const responses = await Promise.all(requests.map(r => r.getResponse()));

    runInAction(() => {
      const [kpi, weekTrend, cycleTrend, seasonTrend] = responses;
      (this._kpi as IObservableArray<KPI>).replace(kpi as KPI[]);
      (this._weekTrend as IObservableArray<WeekTrend>).replace(
        weekTrend as WeekTrend[]
      );
      (this._cycleTrend as IObservableArray<CycleTrend>).replace(
        cycleTrend as CycleTrend[]
      );
      (this._seasonTrend as IObservableArray<SeasonTrend>).replace(
        seasonTrend as SeasonTrend[]
      );
      this._status = AsyncStatus.resolved;
    });
  }

  public get kpi(): KPI[] {
    return this._kpi;
  }

  public get weekTrend(): WeekTrend[] {
    return this._weekTrend;
  }

  public get cycleTrend(): CycleTrend[] {
    return this._cycleTrend;
  }

  public get seasonTrend(): SeasonTrend[] {
    return this._seasonTrend;
  }
}
