import { useCallback, useMemo } from 'react';
import { AttachmentStore } from '../../attributes/mobx/attachment-store';
import { toast } from '../../components/toast-message';
import { useLayer } from '../../layer-manager/hooks';
import { LayerPortal } from '../../layer-manager/layer-portal';
import { RenameFileDialog } from '../rename-file-dialog';

export function useRenameFileHandler(
  file: AttachmentStore
): [() => void, JSX.Element] {
  const renameFileLayer = useLayer('alert', {
    showShim: true,
    closeOnShimClick: true,
  });

  const rename = useCallback(
    async (name: string) => {
      const result = await file.rename(name);

      if (result === true) {
        toast('toast.success.renameFile', 'success');
      } else {
        toast('toast.error.renameFile', 'error');
      }

      return result;
    },
    [file]
  );

  return useMemo(
    () => [
      () => {
        if (!renameFileLayer.isOpened) renameFileLayer.open();
      },
      <LayerPortal
        key="rename-file-layer"
        layerHandle={renameFileLayer}
        getContent={layer => (
          <RenameFileDialog
            close={layer.close}
            file={file.attachment}
            rename={rename}
          />
        )}
      />,
    ],
    [renameFileLayer, file.attachment, rename]
  );
}
