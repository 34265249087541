import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ButtonAppearance,
  Checkbox,
  CheckboxList,
  CheckboxListLabel,
  CheckboxListRow,
  ContentBox,
  ContentBoxHead,
  Text,
  TextSize,
} from '@yarmill/components';
import { useMemo, useState } from 'react';
import { useRootStore } from '../app/root-store-context';

export interface GroupsSelectorProps {
  excludedGroupId?: number;
  onChange(athletes: number[]): void;
}

export function GroupsSelector({
  excludedGroupId,
  onChange,
}: GroupsSelectorProps): JSX.Element {
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const rootStore = useRootStore();
  const groupsStore = rootStore.groupsStore;
  const currentUser = rootStore.currentUserStore;

  const sortedGroups = useMemo(
    () =>
      groupsStore.sortedGroups.filter(
        group =>
          excludedGroupId !== group.id &&
          currentUser.hasPermissionToGroup(group.id) &&
          currentUser.getPermissionToGroup(group.id) === 'write'
      ),
    [groupsStore, excludedGroupId, currentUser]
  );

  const selectAll = (): void => {
    const groups = sortedGroups.map(g => g.id);
    setSelectedGroups(groups);
    onChange(groups);
  };

  const unselectAll = (): void => {
    setSelectedGroups([]);
    onChange([]);
  };

  const onSelectAthlete = (e: React.FormEvent<HTMLElement>): void => {
    const { target } = e;
    if (target instanceof HTMLInputElement) {
      const { value } = target;
      const groupId = Number(value);
      const idx = selectedGroups.indexOf(groupId);
      const newGroups = [...selectedGroups];
      if (idx === -1) {
        newGroups.push(groupId);
      } else {
        newGroups.splice(idx, 1);
      }

      setSelectedGroups(newGroups);
      onChange(newGroups);
    }
  };

  return (
    <ContentBox data-cy="groups">
      <ContentBoxHead>
        <Text size={TextSize.s16} medium>
          <FormattedMessage id="plan.copy.selectGroups" />
        </Text>
        <Button
          type="button"
          appearance={ButtonAppearance.ActiveLink}
          onClick={
            sortedGroups.length === selectedGroups.length
              ? unselectAll
              : selectAll
          }
          data-cy="select-all-button"
        >
          {sortedGroups.length === selectedGroups.length ? (
            <FormattedMessage id="plan.copy.selectAthletes.unselectAll" />
          ) : (
            <FormattedMessage id="plan.copy.selectAthletes.selectAll" />
          )}
        </Button>
      </ContentBoxHead>

      <CheckboxList>
        {sortedGroups.map(group => (
          <CheckboxListRow key={group.id}>
            <CheckboxListLabel htmlFor={`group-${group.id}`}>
              <Text size={TextSize.s14}>{group.name}</Text>
            </CheckboxListLabel>
            <Checkbox
              value={String(group.id)}
              checked={selectedGroups.indexOf(group.id) !== -1}
              onChange={onSelectAthlete}
              id={`group-${group.id}`}
              name={`group-${group.id}`}
              data-cy="group-checkbox"
              noError
            />
          </CheckboxListRow>
        ))}
      </CheckboxList>
    </ContentBox>
  );
}
