import {
  MouseEventHandler,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDiaryStore } from '../diary/diary-store-context';
import { Units } from '../units/types';
import { AbstractActivityStore } from './mobx/abstract-activity-store';
import { formatValueByUnit } from './utils';

export function useFormattedValue(value: number | string, unit: Units): string {
  return useMemo(() => formatValueByUnit(value, unit), [value, unit]);
}

export function useSelectableActivityInputs(
  activityStore: AbstractActivityStore | undefined,
  columnIndex: number
): MouseEventHandler {
  const diaryStore = useDiaryStore();
  const activityListStore = diaryStore.activityListStore;
  const hasSelectedActivities =
    (activityListStore?.selectedActivityStores.length ?? 0) > 0;

  useEffect(() => {
    function unselectAll() {
      activityListStore?.selectedActivityStores.forEach(store =>
        store.unselect()
      );
    }
    function keyboardListener(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        unselectAll();
        activityListStore?.cancelSelectionRange();
      } else if (e.key === 'Backspace' || e.key === 'Delete') {
        activityListStore?.selectedActivityStores.forEach(store =>
          store.empty()
        );
        unselectAll();
      } else if (e.key === 'a' && activityListStore?.selectionRangeStart) {
        e.preventDefault();
        e.stopPropagation();
        unselectAll();
        activityListStore.selectAll();
      }
    }

    function mouseDownListener(e: MouseEvent) {
      if (!e.metaKey && !e.ctrlKey) {
        unselectAll();
        return;
      }

      if (
        !(e.target instanceof HTMLInputElement) &&
        e.target instanceof HTMLElement &&
        !e.target.className.includes('activity-title') &&
        !e.target.className.includes('activity-column')
      ) {
        unselectAll();
      }
    }

    if (
      hasSelectedActivities &&
      activityListStore &&
      !activityListStore?.hasSelectedItemsGlobalListener
    ) {
      document.addEventListener('keydown', keyboardListener);
      document.addEventListener('mousedown', mouseDownListener);
      activityListStore.hasSelectedItemsGlobalListener = true;
    }

    return () => {
      if (activityListStore?.hasSelectedItemsGlobalListener) {
        activityListStore.hasSelectedItemsGlobalListener = false;
      }
      document.removeEventListener('keydown', keyboardListener);
      document.removeEventListener('mousedown', mouseDownListener);
    };
  }, [hasSelectedActivities, activityListStore]);

  return useCallback(
    (e: ReactMouseEvent) => {
      if (
        (e.metaKey || e.ctrlKey || e.shiftKey) &&
        activityStore &&
        activityListStore
      ) {
        e.preventDefault();
        e.stopPropagation();
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }

        if (e.shiftKey && activityListStore.selectionRangeStart) {
          activityListStore.selectRange(
            activityStore.activityItemId,
            columnIndex
          );
        } else {
          if (
            activityListStore.selectionRangeStart &&
            activityListStore.selectedActivityStores.length === 0
          ) {
            const prevCell = activityListStore.getActivityStore(
              activityListStore.selectionRangeStart.activityItem,
              activityListStore.selectionRangeStart.dayIndex
            );
            prevCell?.select();
          }

          if (activityStore.isSelected) {
            activityListStore.cancelSelectionRange();
            activityStore.unselect();
          } else {
            activityStore.select();
            activityListStore.startSelectionRange(
              activityStore.activityItemId,
              columnIndex
            );
          }
        }
      } else {
        if (activityStore && activityListStore) {
          activityListStore.startSelectionRange(
            activityStore.activityItemId,
            columnIndex
          );
        }
      }
    },
    [activityStore, activityListStore, columnIndex]
  );
}
