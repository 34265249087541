import { Icon, IconSize, styled } from '@yarmill/components';
import { Trash, Location } from '@yarmill/icons-2';
import { Button, ButtonAppearance } from '../../components-2/button';
import { Text } from '../../components-2/text';

interface SelectedLocationProps {
  readonly title: string;
  readonly description?: string;
  readonly goToDetails: () => void;
  readonly remove: () => void;
  readonly disabled?: boolean;
}

const LocationLayout = styled.button`
  display: grid;
  width: 100%;
  border: 0;
  cursor: pointer;
  grid-template-columns: ${({ theme }) => theme.size.x2} 1fr ${({ theme }) =>
    theme.size.x3};
  column-gap: ${({ theme }) => theme.size.x1};
  justify-content: start;
  align-items: center;
  padding: ${({ theme }) => theme.size.x1};
  background-color: ${({ theme }) => theme.color.text};
  color: ${({ theme }) => theme.color.white};
  text-align: left;

  :disabled {
    cursor: default;
  }
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.color.blackHover};
  }
`;

const SelectedLocationLayout = styled.div`
  padding: ${({ theme }) => theme.size.x05};
  background: ${({ theme }) => theme.color.blackHover};
  border-radius: ${({ theme }) => theme.size.x075};
`;

const DescriptionWrapper = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
`;

const RemoveIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.red};
`;
export function SelectedLocation({
  title,
  description,
  goToDetails,
  remove,
  disabled,
}: SelectedLocationProps): JSX.Element {
  return (
    <SelectedLocationLayout>
      <LocationLayout type="button" onClick={goToDetails} disabled={disabled}>
        <Icon size={IconSize.s16}>
          <Location />
        </Icon>
        <Text appearance="text12strong" inheritColor>
          {title}
        </Text>
        {!disabled && (
          <Button
            $appearanceStyle="white"
            $appearance={ButtonAppearance.Tertiary}
            $iconOnly
            $square
            onClick={remove}
            small
            as="div"
            role="button"
          >
            <RemoveIcon size={IconSize.s16}>
              <Trash />
            </RemoveIcon>
          </Button>
        )}
        {description && (
          <DescriptionWrapper>
            <Text appearance="label10" inheritColor>
              {description}
            </Text>
          </DescriptionWrapper>
        )}
      </LocationLayout>
    </SelectedLocationLayout>
  );
}
