import { observer } from 'mobx-react-lite';
import { Report } from './report';
import { Section } from './section';
import { Filters } from './filters';
import { useEffect } from 'react';
import { ReportsContainer } from '@yarmill/components';
import { useReportingStore } from './hooks/use-reporting-store';

export interface ReportsProps {
  showFilters?: boolean;
}

export const Reports = observer(function Reports(
  props: ReportsProps
): JSX.Element {
  const { showFilters = true } = props;
  const reportingStore = useReportingStore();
  const reports = reportingStore.pageReports;
  const sections = reportingStore.pageSections;
  const currentPage = reportingStore.currentPage?.code;

  useEffect(() => {
    if (currentPage) {
      document
        .querySelector('.sticky-content')
        ?.scroll({ top: 0, behavior: 'auto' });
    }
  }, [currentPage]);

  return (
    <ReportsContainer>
      {showFilters && <Filters position="page" />}
      {!sections?.length &&
        reports.map(report => <Report report={report} key={report.code} />)}
      {sections
        ?.filter(section => !section.ParentSection)
        ?.map((section, idx) => (
          <Section
            key={`${reportingStore.currentPage?.code}-${idx}`}
            sectionDefinition={section}
            subSections={sections?.filter(
              subSection =>
                subSection.ParentSection &&
                subSection.ParentSection === section.Code
            )}
          />
        ))}
    </ReportsContainer>
  );
});
