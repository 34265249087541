import { NavItem } from '../nav-item';

import { LinkCallback } from '../../routes/types';

function profileLinkCallback(): string {
  return `/settings/personal`;
}

export function useProfileLink(): LinkCallback {
  return profileLinkCallback;
}

export function SettingsLink(): JSX.Element {
  const link = useProfileLink();

  return <NavItem to={link} moduleKey="settings" icon="Adjustments" />;
}
