import {
  ContentBox,
  ContentBoxHead,
  Switch,
  Text,
  TextSize,
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { useCurrentUserStore } from '../users/hooks';
import { useState } from 'react';
import { useSettingsService } from '../settings/hooks';
import styled from 'styled-components';
import { getBoolean } from '../utils/get-boolean';

const DescriptiveText = styled(Text)`
  color: #9a9a9a;
`;
export function PolarZones(): JSX.Element {
  const currentUser = useCurrentUserStore();
  const settingsService = useSettingsService();
  const [isEnabled, setIsEnabled] = useState<boolean>(
    getBoolean(currentUser.getSetting('heartRateZones.usePolarZones'))
  );
  async function onClick() {
    setIsEnabled(!isEnabled);
    await settingsService.saveSettings(
      'heartRateZones.usePolarZones',
      !isEnabled,
      { userId: currentUser.id }
    );
  }
  return (
    <ContentBox>
      <ContentBoxHead>
        <Text size={TextSize.s16} medium>
          <FormattedMessage id="settings.profile.heartRateZones.usePolarZones.header" />
        </Text>
        <Switch isChecked={isEnabled} id="zones" onClick={onClick} />
      </ContentBoxHead>
      <DescriptiveText size={TextSize.s14}>
        <FormattedMessage id="settings.profile.heartRateZones.usePolarZones.description" />
      </DescriptiveText>
    </ContentBox>
  );
}
