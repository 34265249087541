import { Dispatch, SetStateAction, useCallback } from 'react';
import { AttributesSelector } from '../attributes-selector';
import { ImporterHeader } from './importer-header';
import { BackToEventTypePanel } from '../buttons/back-to-event-type';
import { Button, ButtonAppearance } from '../../components-2/button';
import { FormattedMessage } from 'react-intl';
import { Header } from '../../okrs/header';
import { RightPanelContent } from '../../components-2/right-panel';

interface AttributeFiltersProps {
  readonly hideFilters: () => void;
  readonly selectedAttributes: number[];
  readonly setAttributeFilters: Dispatch<SetStateAction<number[]>>;
}
export function AttributeFilters({
  hideFilters,
  selectedAttributes,
  setAttributeFilters,
}: AttributeFiltersProps): JSX.Element {
  const onChange = useCallback(
    (attrs: (string | number)[]) => setAttributeFilters(attrs as number[]),
    [setAttributeFilters]
  );

  return (
    <>
      <ImporterHeader
        backButton={<BackToEventTypePanel onClick={hideFilters} />}
      />
      <RightPanelContent noPadding>
        <AttributesSelector
          selectedAttributes={selectedAttributes}
          onChange={onChange}
          showSelectAllButton
          onEsc={hideFilters}
        />
      </RightPanelContent>
      <Header
        position="footer"
        dark
        center={
          <Button
            $appearance={ButtonAppearance.Primary}
            $appearanceStyle="sand"
            type="submit"
            stretch
            onClick={hideFilters}
          >
            <FormattedMessage id="planner.importer.applyFilters" />
          </Button>
        }
      />
    </>
  );
}
