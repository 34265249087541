import { NavbarContext, Status } from '../types';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { NavbarTransaction } from './navbar-transaction';
import { RootStore } from '../../app/mobx/root-store';

export class NavbarStore {
  private readonly _rootStore: RootStore;

  private transactions: IObservableArray<NavbarTransaction> =
    observable.array<NavbarTransaction>([], { autoBind: true });

  @observable
  private _context: NavbarContext | null = null;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this._rootStore = rootStore;
    this.createHistoryListener();
  }

  @computed
  private get relevantTransactions() {
    const currentContext = this.context;
    return this.transactions.filter(
      t => !t.context || t.context === currentContext
    );
  }
  @computed
  public get status(): Status | null {
    const relevantTransaction = this.relevantTransactions;

    if (relevantTransaction.find(t => t.status === 'loadingData')) {
      return 'loadingData';
    } else if (relevantTransaction.find(t => t.status === 'loading')) {
      return 'loading';
    } else if (relevantTransaction.find(t => t.status === 'error')) {
      return 'error';
    } else if (relevantTransaction.find(t => t.status === 'success')) {
      return 'success';
    }

    return null;
  }

  @action
  public createTransaction(
    status: Status,
    context?: NavbarContext
  ): NavbarTransaction {
    const transaction = new NavbarTransaction(status, context);
    this.transactions.push(transaction);

    return transaction;
  }

  @action
  public clear() {
    const currentContext = this.context;
    this.transactions.replace(
      this.transactions.filter(t => !t.context || t.context !== currentContext)
    );
  }

  private createHistoryListener() {
    reaction(
      () => this._rootStore.historyService.pathname,
      pathname => {
        if (pathname.includes('plan') || pathname.includes('reality')) {
          this._context = 'diary';
        } else if (
          pathname.includes('analytics') ||
          pathname.includes('reporting')
        ) {
          this._context = 'reporting';
        } else if (pathname.includes('attendance')) {
          this._context = 'attendance';
        } else if (pathname.includes('evidence')) {
          this._context = 'evidence';
        } else if (pathname.includes('season-evaluation')) {
          this._context = 'season-evaluation';
        } else {
          this._context = null;
        }
      },
      {
        fireImmediately: true,
      }
    );
  }

  public get context() {
    return this._context;
  }
}
