import { TableReportData } from './types';
import { observer } from 'mobx-react-lite';
import { CsvTable } from '../csv-table/csv-table';
import {
  ChartWidthProvider,
  ContentBox,
  ReportTitleLayout,
  ResponsiveTableWrapper,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import styled from 'styled-components';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useWindowSize } from '../utils/use-window-size';
import { TableChartStore } from './mobx/table-chart-store';
import { ReportContentBox } from './report-content-box';
import { useReportingDataStore } from './hooks/use-reporting-data-store';
import { useShowReportTitle } from './hooks/use-show-report-title';
import { ReportTitle } from './report-title';
import { AsyncStatus } from '../api/mobx/request-store';
import { FormattedMessage } from 'react-intl';

const StyledContentBox = styled(ContentBox)`
  padding-top: 0;

  ${ReportTitleLayout} {
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  @media print {
    page-break-inside: avoid;
  }
`;

const NoDataMessage = styled(Text)`
  margin-top: 12px;
`;

export interface TableReportProps {
  readonly report: TableChartStore;
  readonly data?: TableReportData;
}

export const TableReport = observer(function TableReport(
  props: TableReportProps
): JSX.Element {
  const { report, data: passedData } = props;
  const ref = useRef<HTMLTableElement>(null);
  const [tableWidth, setTableWidth] = useState<number | null>(null);
  const [contentBoxWidth, setContentBoxWidth] = useState<number | null>(null);
  const windowSize = useWindowSize();
  const dataStore = useReportingDataStore();
  const data =
    passedData ??
    (dataStore?.getReportData(report.code) as TableReportData | undefined);
  const showReportTitle = useShowReportTitle(report);

  const hasData = Boolean(data?.Data);

  useEffect(() => {
    if (ref.current && windowSize.width && hasData) {
      const rect = ref.current.getBoundingClientRect();
      setTableWidth(rect.width);
    }
  }, [windowSize.width, hasData]);

  useEffect(() => {
    report.setConfigOverride(data?.ConfigOverride);
  }, [data?.ConfigOverride, report]);

  const Wrapper =
    contentBoxWidth === null ||
    tableWidth === null ||
    contentBoxWidth < tableWidth
      ? ResponsiveTableWrapper
      : Fragment;

  return (
    <ReportContentBox customContentBoxComponent={StyledContentBox}>
      <ChartWidthProvider setWidth={setContentBoxWidth} useStaticPosition />
      {showReportTitle && report.titlePosition === 'inside' && (
        <ReportTitle report={report} />
      )}

      <Wrapper>
        {dataStore?.status === AsyncStatus.resolved &&
        (!data?.Data || data?.Data.length === 0) &&
        report.noDataMessage ? (
          <NoDataMessage size={TextSize.s14} tag={TextTag.div}>
            <FormattedMessage id={report.noDataMessage ?? 'reporting.noData'} />
          </NoDataMessage>
        ) : (
          <CsvTable
            table={report.table}
            data={data?.Data}
            ref={ref}
            descriptiveData={data?.Metadata?.DescriptiveData}
          />
        )}
      </Wrapper>
    </ReportContentBox>
  );
});
