import styled from 'styled-components';

export const AddButtonWrapper = styled.div`
  margin-left: auto;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 12px;

  @media (min-width: 768px) {
    align-items: center;
  }
`;
