import { FormattedDate, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { times } from '../utils/times';
import styled from 'styled-components';
import { Text, TextSize } from '@yarmill/components';

const HeaderColumnCell = styled.div<{ capitalize?: boolean }>`
  padding-top: 8px;
  @media (max-width: ${1024 - 1}px) {
    display: none;
  }
  ${({ capitalize }) => capitalize && `text-transform: capitalize`};
`;

export function HeaderColumn(): JSX.Element {
  const monday = moment().startOf('isoWeek');
  return (
    <>
      <HeaderColumnCell />
      <HeaderColumnCell>
        <Text size={TextSize.s14}>
          <FormattedMessage id="diary.goals.cycle" />
        </Text>
      </HeaderColumnCell>
      <HeaderColumnCell>
        <Text size={TextSize.s14}>
          <FormattedMessage id="diary.goals.week" />
        </Text>
      </HeaderColumnCell>
      {times(7).map((_, idx) => (
        <HeaderColumnCell key={idx} capitalize>
          <Text size={TextSize.s14}>
            <FormattedDate
              value={monday.clone().add(idx, 'days').toDate()}
              weekday="long"
            />
          </Text>
        </HeaderColumnCell>
      ))}
    </>
  );
}
