import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { HeartRateZonesSetStore } from './heart-rate-zones-set-store';
import { RootStore } from '../../app/mobx/root-store';
import { getHeartRateZones } from '../api/get-heart-rate-zones';
import { HeartRateZoneStore } from './heart-rate-zone-store';
import { sortByDate, sortBySortCode } from '../../app/utils';
import moment from 'moment';
import { hasOverlap } from '../utils';
import { AvailableSport } from '../types';
import { getAvailableSports } from '../api/get-available-sports';
import { saveHeartRateZones } from '../api/save-heart-rate-zones';
import { GlobalIntl } from '../../intl/global-intl-provider';
import { AsyncStatus } from '../../api/mobx/request-store';

export class HeartRateZonesManagerStore {
  private readonly _rootStore: RootStore;

  @observable
  private _status: AsyncStatus = AsyncStatus.idle;
  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeObservable(this);
  }
  @observable
  private _heartRateZonesSets: IObservableArray<HeartRateZonesSetStore> =
    observable.array<HeartRateZonesSetStore>();

  @observable
  private _availableSports: IObservableArray<AvailableSport> =
    observable.array<AvailableSport>();

  public async loadHeartRateZones(): Promise<void> {
    this._status = AsyncStatus.pending;
    const userId = this._rootStore.currentUserStore.id;
    const request = this._rootStore.requestsStore.createRequest(() =>
      getHeartRateZones(userId)
    );

    const response = await request.getResponse();

    if (request.status === AsyncStatus.resolved) {
      const data = response || [];
      runInAction(() => {
        data.forEach(set => {
          const heartRateZonesSet = this.createHeartRateZonesSet();
          heartRateZonesSet
            .setId(set.id)
            .setSport(set.sport)
            .setValidFrom(set.validFrom)
            .setValidTo(set.validTo)
            .setCreated(set.created ?? null)
            .setLastUpdated(set.lastUpdated ?? null);
          if (set.isDefault) {
            heartRateZonesSet.setIsDefault();
          }
          set.zones.forEach(zone => {
            const zoneStore = new HeartRateZoneStore(zone);
            heartRateZonesSet.heartRateZones.push(zoneStore);
          });
        });

        if (data.length === 0 || !data.find(z => z.isDefault)) {
          const heartRateZonesSet = this.createHeartRateZonesSet();
          heartRateZonesSet
            .setIsDefault()
            .setValidFrom(null)
            .createDefaultZones();
        }

        this._status = AsyncStatus.resolved;
      });
    } else {
      this._status = AsyncStatus.rejected;
    }
  }

  public async save(): Promise<boolean> {
    this._status = AsyncStatus.pending;
    const userId = this._rootStore.currentUserStore.id;
    const sets = this._heartRateZonesSets.map(set => set.toJS());

    const request = this._rootStore.requestsStore.createRequest(() =>
      saveHeartRateZones(sets, userId)
    );

    await request.getResponse();

    return runInAction(() => {
      if (request.statusCode === 200) {
        this._status = AsyncStatus.resolved;
        return true;
      } else {
        this._status = AsyncStatus.rejected;
        return false;
      }
    });
  }

  public async loadAvailableSports(): Promise<void> {
    const request = this._rootStore.requestsStore.createRequest(() =>
      getAvailableSports()
    );

    const response = await request.getResponse();

    if (response) {
      runInAction(() => {
        response.dataSources
          .find(set => set.key === 'sports')
          ?.data.sort(sortBySortCode)
          .forEach(sport => this._availableSports.push(sport));
      });
    }
  }
  public get heartRateZonesSets() {
    return this._heartRateZonesSets;
  }

  public get availableSports() {
    return this._availableSports;
  }

  @computed
  public get sortedHeartRateZonesSets() {
    return this.heartRateZonesSets
      .slice()
      .sort((a, b) => sortByDate(a.validTo || moment(), b.validTo || moment()))
      .sort((a, b) => {
        const aSport = this.availableSports.find(s => s.value === a.sport);
        const bSport = this.availableSports.find(s => s.value === b.sport);
        const aLabel = GlobalIntl.formatMessage({
          id: aSport?.label || a.sport,
        });
        const bLabel = GlobalIntl.formatMessage({
          id: bSport?.label || b.sport,
        });
        return aLabel
          .toLocaleLowerCase('cs')
          .localeCompare(bLabel.toLocaleLowerCase('cs'));
      })
      .sort((a, b) => {
        if (a.isDefault) {
          return -1;
        } else if (b.isDefault) {
          return 1;
        }
        return 0;
      });
  }

  @action
  public createHeartRateZonesSet() {
    const set = new HeartRateZonesSetStore(this._rootStore);
    this._heartRateZonesSets.push(set);

    return set;
  }

  @action
  public removeHeartRateZonesSet(heartRateZonesSet: HeartRateZonesSetStore) {
    this._heartRateZonesSets.remove(heartRateZonesSet);
  }

  public get status() {
    return this._status;
  }

  public hasOverlap(heartRateZonesSet: HeartRateZonesSetStore): boolean {
    const otherZones = this.heartRateZonesSets.filter(
      set => set !== heartRateZonesSet && set.sport === heartRateZonesSet.sport
    );

    return otherZones.some(set => hasOverlap(heartRateZonesSet, set));
  }
}
