import { AxiosPromise } from 'axios';
import { axios } from '../../api/axios';

export interface AddUserToGroupRequestParams {
  groupId: number;
  userIds: number[];
}

export function addUserToGroup(
  params: AddUserToGroupRequestParams
): AxiosPromise<{ athletes: number[]; coaches: number[] }> {
  return axios.post('api/UserGroupUsers', params);
}
