import { Fragment } from 'react';
import { ButtonAppearance, Spinner } from '@yarmill/components';
import { FormState } from '../../app/app-types';
import { EvidenceAttributeType } from '../types';

export function getSubmitButtonIcon(state: FormState): JSX.Element | null {
  switch (state) {
    case 'submitting':
      return (
        <Fragment>
          <Spinner />
          &nbsp;
        </Fragment>
      );

    case 'error':
      return (
        <Fragment>
          <span>✖</span>
          &nbsp;
        </Fragment>
      );

    case 'success':
      return (
        <Fragment>
          <span>✓</span>
          &nbsp;
        </Fragment>
      );

    default:
      return null;
  }
}

export function getSubmitButtonAppearance(state: FormState): ButtonAppearance {
  switch (state) {
    case 'error':
      return ButtonAppearance.Error;

    case 'success':
      return ButtonAppearance.Success;

    default:
      return ButtonAppearance.Primary;
  }
}

export function getAligningForAttributeType(
  attributeType: EvidenceAttributeType
): 'left' | 'center' | 'right' | 'justify' | 'char' {
  switch (attributeType) {
    case 'date':
    case 'time':
    case 'number':
    case 'decimal-number':
      return 'right';
    default:
      return 'left';
  }
}
