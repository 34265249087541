import moment from 'moment';

import { ActivityData } from '../activities/types';
import { DashboardType, dashboardTypes } from '../analytics/types';
import {
  ATHLETE_SEARCH_PARAM,
  GROUP_SEARCH_PARAM,
  WEEK_SEARCH_PARAM,
} from '../routes/types';
import { SeasonStore } from '../seasons/mobx/season-store';
import { BaseTrainingDayData } from '../training-day/types';
import { CurrentUserStore } from '../users/mobx/current-user-store';
import { DiaryRouteParams, DiaryType, ViewType } from './types';

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const ROUTE_DATE_FORMAT = 'YYYY-MM-DD';

export declare type FocusedInput = 'startDate' | 'endDate' | null;

export function getDaysInWeek(
  day: string,
  format: string = DATE_FORMAT
): string[] {
  const weekStart = getWeekStart(day);
  const weekEnd = getWeekEnd(day);
  const currentDay = weekStart;
  const daysInWeek = [];

  while (currentDay <= weekEnd) {
    daysInWeek.push(currentDay.format(DATE_FORMAT));
    currentDay.add(1, 'days');
  }

  return daysInWeek;
}

export function getWeeksInMonth(
  month: string,
  format: string = DATE_FORMAT
): string[] {
  const monthStart = moment(month, format).startOf('month');
  const monthEnd = moment(month, format).endOf('month');

  const currentWeek = monthStart;
  const weeksInMonth = [];

  while (currentWeek <= monthEnd) {
    weeksInMonth.push(currentWeek.format(DATE_FORMAT));
    currentWeek.add(1, 'weeks');
  }

  return weeksInMonth;
}

export function getWeekStart(day: string | moment.Moment): moment.Moment {
  const date = moment(day, ROUTE_DATE_FORMAT);

  return date.startOf('isoWeek');
}

export function getWeekEnd(day: string | moment.Moment): moment.Moment {
  const date = moment(day, ROUTE_DATE_FORMAT);

  return date.endOf('isoWeek');
}

export function getWeekStartString(day: string): string {
  return getWeekStart(day).format(DATE_FORMAT);
}

export function getWeekEndString(day: string): string {
  return getWeekEnd(day).format(DATE_FORMAT);
}

export function getBaseId(
  item: Partial<ActivityData> | Partial<BaseTrainingDayData>
): string {
  return `${item.Date}`;
}

export const monthsMock = [
  '2000-04-01T00:00:00',
  '2000-05-01T00:00:00',
  '2000-06-01T00:00:00',
  '2000-07-01T00:00:00',
  '2000-08-01T00:00:00',
  '2000-09-01T00:00:00',
  '2000-10-01T00:00:00',
  '2000-11-01T00:00:00',
  '2000-12-01T00:00:00',
  '2000-01-01T00:00:00',
  '2000-02-01T00:00:00',
  '2000-03-01T00:00:00',
];

export const getFirstSeasonStart = (seasons: SeasonStore[]) =>
  moment
    .min(seasons.map((season: SeasonStore) => moment(season.startDate)))
    .format(DATE_FORMAT);

export const getLastSeasonEnd = (seasons: SeasonStore[]) =>
  moment
    .max(seasons.map((season: SeasonStore) => moment(season.endDate)))
    .format(DATE_FORMAT);

export function getMinBookDate(seasons: SeasonStore[]): Date {
  return getWeekStart(getFirstSeasonStart(seasons)).toDate();
}

export function getMaxBookDate(seasons: SeasonStore[]): Date {
  return getWeekEnd(getLastSeasonEnd(seasons)).toDate();
}

export function getDiaryRouteLink(
  routeParams: DiaryRouteParams,
  overrides: Partial<DiaryRouteParams> = {}
): string {
  const finalParams = { ...routeParams, ...overrides };

  const searchParams = new URLSearchParams();
  searchParams.set(WEEK_SEARCH_PARAM, finalParams.week);
  searchParams.set(GROUP_SEARCH_PARAM, String(finalParams.groupId));

  if (finalParams.athleteId) {
    searchParams.set(ATHLETE_SEARCH_PARAM, String(finalParams.athleteId));
  }

  return `/${finalParams.diaryType}/${
    finalParams.viewType
  }?${searchParams.toString()}`;
}

export function isAthleteRequired(diaryType: DiaryType): boolean {
  return diaryType === 'reality';
}

export function isValidDashboardType(x: string): x is DashboardType {
  return dashboardTypes.includes(x as DashboardType);
}

export function getTrainingDayElementId(dayIndex: number): string {
  return `training-day-container-${dayIndex}`;
}

export function getIndexForDate(date: string | moment.Moment): number {
  const momentDate =
    typeof date === 'string' ? moment(date, DATE_FORMAT) : date;
  return momentDate.isoWeekday() - 1; // we want monday to be 0
}

export function getDateForDayIndex(week: string, day: number): moment.Moment {
  return moment(week).isoWeekday(day + 1);
}

export function getDateForDayIndexString(week: string, day: number): string {
  return getDateForDayIndex(week, day).format(DATE_FORMAT);
}

export function currentUserHasPermissionToSelectMask(
  currentUser: CurrentUserStore,
  diaryType: DiaryType,
  viewType: ViewType
): boolean {
  if (viewType === 'season') {
    return currentUser.isAllowedTo(`${diaryType}.season`);
  } else {
    return currentUser.isAllowedTo(`${diaryType}.week.activities`);
  }
}
