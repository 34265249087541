import { FormUserInvitation } from '../types';
import { isValidEmail } from '../../utils/is-valid-email';

export function isValidInvitation(invitation: FormUserInvitation): boolean {
  try {
    return Boolean(
      invitation.FirstName.trim() &&
        invitation.LastName.trim() &&
        !invitation.emailAlreadyExist &&
        invitation.Email.trim() &&
        isValidEmail(invitation.Email)
    );
  } catch (e) {
    return false;
  }
}
