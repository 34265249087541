import moment from 'moment';
import { WrappedComponentProps } from 'react-intl';
import { DATE_FORMAT, getDaysInWeek } from '../../../diary/utils';
import { ChartHeader } from './chart-header';
import { ReBarChart } from './rebarchart';
import { KPI, WeekTrend as WeekTrendType } from './types';
import { getKPIValue, prepareChartData } from './utils';
import { observer } from 'mobx-react-lite';
import {
  AnalyticsReportTitleWrapper,
  ContentBox,
  ExternalIcon,
  Icon,
  IconSize,
  ReportContainer,
  Text,
  TextSize,
  TextTag,
} from '@yarmill/components';
import { FormattedHTMLMessage } from '../../../intl/formatted-html-message';

export interface WeekTrendProps extends WrappedComponentProps {
  data: WeekTrendType[];
  kpi: KPI[];
}

export const WeekTrend = observer(function WeekTrend(
  props: WeekTrendProps
): JSX.Element {
  const { data, kpi, intl } = props;
  const weekDays = getDaysInWeek(moment().format(DATE_FORMAT));
  const labels = weekDays.map(day => moment(day).format('dd'));
  const tooltipLabels = weekDays.map(day => moment(day).format('dddd'));
  const chartData = prepareChartData(labels, data, tooltipLabels);

  const xAxisLabel = intl.formatMessage({
    id: 'analytics.weekTrend.xLabel',
  });

  return (
    <ReportContainer sectionArea="week">
      <AnalyticsReportTitleWrapper>
        <Text size={TextSize.s16} tag={TextTag.div} medium>
          <FormattedHTMLMessage id="analytics.charts.thisWeek" />
        </Text>
        <Icon size={IconSize.s16}>
          <ExternalIcon name="ChartBar" />
        </Icon>
      </AnalyticsReportTitleWrapper>
      <ContentBox>
        <ChartHeader
          plan={getKPIValue(kpi, 'HZ Week Plan')}
          reality={getKPIValue(kpi, 'HZ Week Reality')}
          absDiff={getKPIValue(kpi, 'HZ Week AbsDiff')}
          relDiff={getKPIValue(kpi, 'HZ Week RelDiff')}
          intl={intl}
        />
        <ReBarChart data={chartData} xAxisLabel={xAxisLabel} stepSize={60} />
      </ContentBox>
    </ReportContainer>
  );
});
