import { useEffect } from 'react';
import { SingleSeasonEvaluationHeader } from './single-season-evaluation-header';
import { useSeasonEvaluationStore } from './season-evaluation-store-context';
import { SingleSeasonEvaluation } from './single-season-evaluation';
import { SeasonOverSeasonEvaluation } from './season-over-season-evaluation';
import { observer } from 'mobx-react-lite';
import { useCurrentUserStore } from '../users/hooks';

export const PageContent = observer(function PageContent(): JSX.Element {
  const store = useSeasonEvaluationStore();
  const currentUser = useCurrentUserStore();
  useEffect(() => () => store.disposeReactions(), [store]);

  return (
    <>
      <SingleSeasonEvaluationHeader />
      {store.viewType === 'single-season' &&
        currentUser.isAllowedTo('seasonEvaluation.singleSeasonEvaluation') && (
          <SingleSeasonEvaluation />
        )}
      {store.viewType === 'season-over-season' &&
        currentUser.isAllowedTo(
          'seasonEvaluation.seasonOverSeasonEvaluation'
        ) && <SeasonOverSeasonEvaluation />}
    </>
  );
});
