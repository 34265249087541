import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { PlannerEvent } from '../types';
import { coreApiTarget, devMode, instance } from '../../api/api-mapping';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';

export interface GetEventsRequestParams {
  userId: UserId | undefined;
  userGroupId: UserGroupId | undefined;
  startDate: string;
  endDate: string;
}

export function getEvents(
  params: GetEventsRequestParams,
  cancelToken?: CancelTokenSource
): AxiosPromise<PlannerEvent[]> {
  return axios.get(`${coreApiTarget}/api/planner`, {
    params: {
      ...params,
      instanceCode: devMode ? instance : undefined,
    },
    cancelToken: cancelToken?.token,
  });
}
