import { Icon, styled } from '@yarmill/components';
import { RightPanelSectionHeading } from '../../components-2/right-panel';

export const AttributeIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.sand};
`;

export const AttributesGroupHeading = styled(RightPanelSectionHeading)`
  margin-bottom: ${({ theme }) => theme.size.x1};
  margin-top: ${({ theme }) => theme.size.x2};
  padding: 0 ${({ theme }) => theme.size.x2};
`;

export const SelectAllButtonWrapper = styled.div`
  padding-top: ${({ theme }) => theme.size.x05};
`;
