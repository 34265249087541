import { AxiosPromise, CancelTokenSource } from 'axios';
import { axios } from '../../api/axios';
import { UserId } from '../../users/types';
import { UserGroupId } from '../../groups/types';
import { ApiExportType } from '../types';
import { Locale } from '../../intl/types';

export interface ExportSeasonEvaluationToXlsxRequestParams {
  seasonId: number;
  users: UserId[];
  groups: UserGroupId[];
  exportTypes: ApiExportType[];
  language: Locale;
}

export function exportSeasonEvaluationToXlsx(
  params: ExportSeasonEvaluationToXlsxRequestParams,
  cancelToken: CancelTokenSource
): AxiosPromise<BlobPart> {
  return axios.post(`api/rtcexport`, params, {
    responseType: 'blob',
    cancelToken: cancelToken.token,
  });
}
