import { FormattedMessage, useIntl } from 'react-intl';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { usePlannerStore } from '../planner-store-context';
import { Cal, Search, Tag as TagIcon } from '@yarmill/icons-2';
import { IntegratedRightPanelInput } from '../../components-2/integrated-right-panel-input';
import {
  AttributeTagsList,
  EventSetEventsForm,
  EventSetEventsListHeading,
} from '../../#-components/planner/importer';
import {
  SelectAllButton,
  SelectionList,
  SelectionListItem,
} from '../../components-2/selection-list';
import { ImportPanelStep, PlannerEventSet } from '../types';
import { Header } from '../../okrs/header';
import { Button, ButtonAppearance } from '../../components-2/button';
import { useFormik } from 'formik';
import {
  showGenericAsyncErrorToastMessage,
  showGenericAsyncSuccessToastMessage,
  showGenericAsyncToastMessage,
} from '../../components/toast-message/generic-async-toast-message';
import { AsyncStatus } from '../../api/mobx/request-store';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { normalizeString } from '../../command-palette/utils';
import {
  FilteredRightPanelScrollContainer,
  RightPanelContent,
} from '../../components-2/right-panel';
import { ImporterHeader } from './importer-header';
import { BackToEventTypePanel } from '../buttons/back-to-event-type';
import { ShowAttributeFiltersSelector } from '../buttons/show-attribute-filters-selector';
import { Tag } from '../../components-2/tag';
import {
  KeyboardListNavigationContext,
  useKeyboardListNavigation,
} from '../../utils/use-keyboard-list-navigation';
interface EventsSelectorProps {
  readonly eventSet: PlannerEventSet;
  readonly attributeFilters: number[];
  readonly setAttributeFilters: Dispatch<SetStateAction<number[]>>;
  readonly setCurrentStep: Dispatch<SetStateAction<ImportPanelStep>>;
  readonly close: () => void;
}
export const EventsSelector = observer(function EventsSelector({
  attributeFilters,
  setAttributeFilters,
  eventSet,
  setCurrentStep,
  close,
}: EventsSelectorProps): JSX.Element {
  const plannerStore = usePlannerStore();
  const intl = useIntl();
  const [filter, setFilter] = useState('');
  const formik = useFormik({
    initialValues: {
      selectedEvents: [] as number[],
    },
    onSubmit: async ({ selectedEvents }) => {
      close();
      let cancelCallback: () => void;
      const cancelRequest = () => {
        cancelCallback?.();
      };
      const toastId = showGenericAsyncToastMessage(
        'toast.progress.planner.import',
        cancelRequest
      );
      const request = plannerStore.importEvents(selectedEvents);
      if (!request) {
        showGenericAsyncErrorToastMessage(
          toastId,
          'toast.error.planner.import'
        );
      } else {
        cancelCallback = () => request.cancel();
        await request.getResponse();
        if (request.status === AsyncStatus.resolved) {
          showGenericAsyncSuccessToastMessage(
            toastId,
            'toast.success.planner.import'
          );
          plannerStore.reloadData();
        } else {
          showGenericAsyncErrorToastMessage(
            toastId,
            'toast.error.planner.import'
          );
        }
      }
    },
  });
  const { selectedEvents } = formik.values;
  const onEsc = useCallback(() => {}, []);
  const keyboardNavigationContext = useKeyboardListNavigation(onEsc);

  const filterEvents = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target instanceof HTMLInputElement) {
      setFilter(e.target.value);
    }
  }, []);

  const filteredEvents = useMemo(
    () =>
      eventSet.events.filter(
        evt =>
          normalizeString(evt.title ?? '')
            .toLocaleLowerCase()
            .includes(normalizeString(filter).toLocaleLowerCase()) &&
          (attributeFilters.length === 0 ||
            evt.attributes?.some(attr => attributeFilters.includes(attr)))
      ),
    [eventSet.events, filter, attributeFilters]
  );
  const eventsInSet: SelectionListItem<number>[] = useMemo(
    () =>
      filteredEvents.map(evt => ({
        icon: <Cal />,
        color: plannerStore.getEventTypeColor(evt.eventTypeId),
        value: evt.id ?? -1,
        title: evt.title ?? 'Missing title',
        description: evt.startDate
          ? intl.formatDate(moment(evt.startDate).toDate(), {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })
          : undefined,
        isSelected: selectedEvents.includes(evt.id ?? -1),
      })) ?? [],
    [filteredEvents, intl, plannerStore, selectedEvents]
  );

  const selectEvent = useCallback(
    (item: SelectionListItem<number>) => {
      const idx = selectedEvents?.indexOf(item.value) ?? -1;
      if (idx !== -1) {
        const updatedValue = [...(selectedEvents ?? [])];
        updatedValue.splice(idx, 1);
        formik.setFieldValue('selectedEvents', updatedValue);
      } else {
        formik.setFieldValue('selectedEvents', [
          ...(selectedEvents ?? []),
          item.value,
        ]);
      }
    },

    [formik, selectedEvents]
  );

  const selectAll = useCallback(
    () =>
      formik.setFieldValue(
        'selectedEvents',
        eventsInSet.map(e => e.value)
      ),
    [formik, eventsInSet]
  );
  const unSelectAll = useCallback(
    () => formik.setFieldValue('selectedEvents', []),
    [formik]
  );

  const removeAttributeFilter = useCallback(
    (attr: number) => setAttributeFilters(prev => prev.filter(a => a !== attr)),
    [setAttributeFilters]
  );

  return (
    <>
      <ImporterHeader
        backButton={
          <BackToEventTypePanel onClick={() => setCurrentStep('eventSets')} />
        }
        filtersButton={
          <ShowAttributeFiltersSelector
            onClick={() => setCurrentStep('eventFilters')}
          />
        }
      />
      <EventSetEventsForm onSubmit={formik.handleSubmit}>
        <KeyboardListNavigationContext.Provider
          value={keyboardNavigationContext}
        >
          <RightPanelContent
            noPadding
            {...keyboardNavigationContext.getListProps()}
          >
            <IntegratedRightPanelInput
              placeholder={intl.formatMessage({
                id: 'planner.importer.searchEventSets',
              })}
              onChange={filterEvents}
              name="search-events"
              icon={<Search />}
              value={filter}
              autoFocus
            />
            <FilteredRightPanelScrollContainer>
              {attributeFilters.length !== 0 && (
                <>
                  <EventSetEventsListHeading
                    as="h3"
                    appearance="button10"
                    upperCase
                  >
                    <FormattedMessage id="planner.importer.eventSets.eventsInSet.filters" />
                  </EventSetEventsListHeading>
                  <AttributeTagsList>
                    {attributeFilters.map(attr => (
                      <Tag
                        icon={<TagIcon />}
                        appearance="sand"
                        value={attr}
                        label={intl.formatMessage({
                          id:
                            plannerStore.attributeValues.find(
                              a => a.valueId === attr
                            )?.value ?? 'unknown-attribute',
                        })}
                        key={attr}
                        onRemoveClick={removeAttributeFilter}
                        small
                      />
                    ))}
                  </AttributeTagsList>
                </>
              )}

              <EventSetEventsListHeading
                as="h3"
                appearance="button10"
                upperCase
              >
                <FormattedMessage id="planner.importer.eventSets.eventsInSet" />
              </EventSetEventsListHeading>
              <SelectAllButton
                isAllSelected={eventsInSet.length === selectedEvents.length}
                selectAll={selectAll}
                unSelectAll={unSelectAll}
                selectAllLabel={intl.formatMessage({
                  id: 'planner.eventTypeFilter.selectAll',
                })}
                unSelectAllLabel={intl.formatMessage({
                  id: 'planner.eventTypeFilter.unSelectAll',
                })}
              />
              <SelectionList
                items={eventsInSet}
                inputType="checkbox"
                onClick={selectEvent}
              />
            </FilteredRightPanelScrollContainer>
          </RightPanelContent>
        </KeyboardListNavigationContext.Provider>
        <Header
          position="footer"
          dark
          center={
            <Button
              $appearance={ButtonAppearance.Primary}
              $appearanceStyle="tangerine"
              type="submit"
              stretch
              disabled={selectedEvents.length === 0}
            >
              <FormattedMessage id="planner.importer.submit" />
            </Button>
          }
        />
      </EventSetEventsForm>
    </>
  );
});
