import { useActiveHeaderLink } from './hooks';
import { HeaderLink } from './types';
import {
  ExternalIcon,
  Icon,
  IconSize,
  SelectedNavigationButton,
} from '@yarmill/components';
import { FormattedMessage } from 'react-intl';
import { isEvidenceLink, isMainNavLink, isReportingLink } from './utils';

interface ActiveNavigationItemProps {
  readonly isOpened: boolean;
  readonly items: HeaderLink[];
  readonly onClick: () => void;
}
export function ActiveNavigationItem({
  items,
  isOpened,
  onClick,
}: ActiveNavigationItemProps): JSX.Element {
  const activeLink = useActiveHeaderLink(items);

  const text = isMainNavLink(activeLink)
    ? `header.navigation.${activeLink.module}`
    : isReportingLink(activeLink)
      ? activeLink.title
      : isEvidenceLink(activeLink)
        ? `header.navigation.evidence/${activeLink.moduleKey}`
        : 'header.navigation.settings';

  return (
    <SelectedNavigationButton onClick={onClick} type="button">
      <FormattedMessage id={text} />
      <Icon size={IconSize.s20}>
        {!isOpened ? (
          <ExternalIcon name="ChevronDown" />
        ) : (
          <ExternalIcon name="ChevronUp" />
        )}
      </Icon>
    </SelectedNavigationButton>
  );
}
