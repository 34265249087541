import { Locale } from './types';
import * as moment from 'moment/moment';

export function getDefaultAvailableLanguages(
  defaultLanguage: Locale
): Locale[] {
  return defaultLanguage === 'en' ? ['en', 'cs'] : [defaultLanguage, 'en'];
}

export function setMomentLocale(locale: string): void {
  moment.locale(locale);
}
