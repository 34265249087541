import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tippy } from '../../components/tippy/tippy';
import { PopoverItem } from '../../components/popover/popover-item';
import { TrainingDayStore } from '../mobx/training-day-store';
import { useRootStore } from '../../app/root-store-context';
import { observer } from 'mobx-react-lite';
import { useFocusedDayService } from '../../diary/hooks';
import { useLayer } from '../../layer-manager/hooks';
import { ConflictResolution } from '../../copy/types';
import { LayerPortal } from '../../layer-manager/layer-portal';
import { ConflictLayerContentWrapper } from '../../copy/conflict-layer-content';
import { CopyConflictErrorResponse } from '../../copy/copy-conflict-error-response';
import { useState } from 'react';

export interface CopyPlanToRealityProps {
  day: TrainingDayStore;
  hideTooltip?(e: React.MouseEvent): void;
}

function InternalCopyPlanToReality(props: CopyPlanToRealityProps): JSX.Element {
  const { day, hideTooltip } = props;
  const rootStore = useRootStore();
  const focusedDayService = useFocusedDayService();
  const copyService = rootStore.copyService;
  const validDate = copyService.isAllowedToCopyPlanToReality(day);
  const conflictLayer = useLayer('alert', { showShim: true });
  const [conflictResponse, setConflictResponse] = useState<
    CopyConflictErrorResponse | undefined
  >();
  async function submitCopy(resolution: ConflictResolution) {
    const result = await copyService.copyPlanToReality(day, resolution);
    if (
      result instanceof CopyConflictErrorResponse &&
      !conflictLayer.isOpened
    ) {
      setConflictResponse(result);
      conflictLayer.open();
    } else if (result) {
      await day.diaryStore.reloadDiaryData();
      focusedDayService.setFocusedDay(day.index);
    }
  }

  const onClick = async (e: React.MouseEvent): Promise<void> => {
    submitCopy('FailOnConflict');

    hideTooltip?.(e);
  };

  return (
    <>
      <PopoverItem
        onClick={validDate ? onClick : undefined}
        appearance={validDate ? 'default' : 'disabled'}
        dataTest="trainingDay.actions.importPlan"
      >
        <Tippy
          isEnabled={!validDate}
          placement="top"
          tooltipContent="trainingDay.actions.importPlan.due"
        >
          <span>
            <FormattedMessage id="trainingDay.actions.importPlan" />
          </span>
        </Tippy>
      </PopoverItem>
      <LayerPortal
        layerHandle={conflictLayer}
        getContent={layer => (
          <ConflictLayerContentWrapper
            layer={layer}
            submitCopy={submitCopy}
            buttonsConfig={conflictResponse?.resolutions ?? []}
          >
            <FormattedMessage id="trainingDay.actions.importPlan.conflict.description" />
          </ConflictLayerContentWrapper>
        )}
      />
    </>
  );
}

export const CopyPlanToReality = observer(InternalCopyPlanToReality);
