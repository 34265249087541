import {
  Breakpoints,
  FixedContentBox,
  ResponsiveTableWrapper,
  StyledTd,
  StyledTh,
  StyledTr,
  Table,
  TableFilterOption,
  Text,
  TextSize,
} from '@yarmill/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledThWithBackground } from '../evidence/table/evidence-table';
import { useFilesOverviewStore } from './files-overview-store-context';
import { FileRow } from './file-row';
import { observer } from 'mobx-react-lite';
import { Filters } from '../evidence/table/filters';
import { useMemo } from 'react';
import styled from 'styled-components';
import { AsyncStatus } from '../api/mobx/request-store';

const StyledButtonsColumns = styled(StyledThWithBackground)`
  min-width: 36px;
  @media (min-width: ${Breakpoints.tablet}px) {
    min-width: 80px;
  }

  &:after {
    content: ' ';
    position: absolute;
    height: calc(100% + 2px);
    width: 6px;
    top: 0;
    right: 0;
    background-color: #ffffff;
    transform: translateX(100%) translateY(-1px);
  }
`;

const StyledCellContent = styled.div`
  display: inline-flex;
  align-items: center;
`;
const STICKY = { top: '0' };
const TableHead = observer(function TableHead(): JSX.Element {
  const store = useFilesOverviewStore();
  const intl = useIntl();
  const filtersStore = store.filtersStore;
  const sortConfig = filtersStore.sortConfig;
  const filesTags = store.tags;

  const tags: TableFilterOption[] = useMemo(
    () =>
      filesTags.map(tag => ({
        label: tag.TagValue,
        value: tag.TagValue,
      })),
    [filesTags]
  );

  const modules: TableFilterOption[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'filesOverview.tags.p' }),
        value: 'P',
      },
      {
        label: intl.formatMessage({ id: 'filesOverview.tags.r' }),
        value: 'R',
      },
    ],
    [intl]
  );

  const fileTypes: TableFilterOption[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'filesOverview.fileType.video' }),
        value: 'video',
      },
      {
        label: intl.formatMessage({ id: 'filesOverview.fileType.picture' }),
        value: 'picture',
      },
      {
        label: intl.formatMessage({ id: 'filesOverview.fileType.general' }),
        value: 'general',
      },
    ],
    [intl]
  );

  return (
    <>
      <StyledTh sticky={STICKY}>
        <StyledCellContent>
          <Text size={TextSize.s12} bold>
            <FormattedMessage id="filesOverview.fileType" />
          </Text>
          <Filters
            attributeId="FileType"
            onChange={filtersStore.handleFilterChange}
            filter={filtersStore.getFilter('FileType')}
            sort={sortConfig?.sort === 'FileType' ? sortConfig.order : null}
            options={fileTypes}
            filterType="options"
          />
        </StyledCellContent>
      </StyledTh>
      <StyledTh align="left" sticky={STICKY}>
        <StyledCellContent>
          <Text size={TextSize.s12} bold>
            <FormattedMessage id="filesOverview.fileName" />
          </Text>
          <Filters
            attributeId="FileName"
            onChange={filtersStore.handleFilterChange}
            filter={filtersStore.getFilter('FileName')}
            sort={sortConfig?.sort === 'FileName' ? sortConfig.order : null}
            filterType="text"
          />
        </StyledCellContent>
      </StyledTh>
      <StyledTh align="left" sticky={STICKY}>
        <StyledCellContent>
          <Text size={TextSize.s12} bold>
            <FormattedMessage id="filesOverview.tags" />
          </Text>
          <Filters
            attributeId="Tags"
            onChange={filtersStore.handleFilterChange}
            filter={filtersStore.getFilter('Tags')}
            sort={sortConfig?.sort === 'Tags' ? sortConfig.order : null}
            options={tags}
            filterType="options"
          />
        </StyledCellContent>
      </StyledTh>
      <StyledTh align="center" sticky={STICKY}>
        <StyledCellContent>
          <Text size={TextSize.s12} bold>
            <FormattedMessage id="filesOverview.module" />
          </Text>
          <Filters
            attributeId="Type"
            onChange={filtersStore.handleFilterChange}
            filter={filtersStore.getFilter('Type')}
            sort={sortConfig?.sort === 'Type' ? sortConfig.order : null}
            options={modules}
            filterType="options"
          />
        </StyledCellContent>
      </StyledTh>
      <StyledTh align="center" sticky={STICKY}>
        <StyledCellContent>
          <Text size={TextSize.s12} bold>
            <FormattedMessage id="filesOverview.referenceDate" />
          </Text>
          <Filters
            attributeId="ReferenceDate"
            onChange={filtersStore.handleFilterChange}
            filter={filtersStore.getFilter('ReferenceDate')}
            sort={
              sortConfig?.sort === 'ReferenceDate' ? sortConfig.order : null
            }
            hideFilters
          />
        </StyledCellContent>
      </StyledTh>
      <StyledButtonsColumns sticky={{ right: 0, top: 0 }} />
    </>
  );
});

const TableContent = observer(function TableContent(): JSX.Element {
  const store = useFilesOverviewStore();

  return (
    <>
      {store.files.map(file => (
        <FileRow file={file} key={file.id} />
      ))}
      {store.status === AsyncStatus.resolved && store.files.length === 0 && (
        <StyledTr>
          <StyledTd colSpan={5}>
            <Text size={TextSize.s12}>
              <FormattedMessage id="evidence.table.noData" />
            </Text>
          </StyledTd>
        </StyledTr>
      )}
    </>
  );
});

function Colgroup(): JSX.Element {
  return (
    <>
      <col width="60px" />
      <col />
      <col />
      <col width="120px" />
      <col width="120px" />
      <col width="120px" />
    </>
  );
}

function InternalFilesTable(): JSX.Element {
  return (
    <FixedContentBox>
      <ResponsiveTableWrapper>
        <Table head={<TableHead />} colgroup={<Colgroup />}>
          <TableContent />
        </Table>
      </ResponsiveTableWrapper>
    </FixedContentBox>
  );
}

export const FilesTable = observer(InternalFilesTable);
