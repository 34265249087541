import moment from 'moment';
import { useMemo } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import styled from 'styled-components';

import { StyledTd, StyledTr, Table, Text, TextSize } from '@yarmill/components';

import { DATE_FORMAT } from '../diary/utils';
import { ChartLoader } from './chart-loader';
import { Workout } from './types';
import {
  formatAscentWithUnit,
  formatDistanceWithUnit,
  formatSecondsWithUnit,
} from './utils';

const StyledAdditionalInfo = styled(Text)`
  color: #d4d6d9;
`;

export interface SummaryProps {
  detail: Workout | null;
}

export function Summary(props: SummaryProps): JSX.Element {
  const { detail } = props;

  const startTime = useMemo(
    () => moment(detail?.StartTime, DATE_FORMAT).toDate(),
    [detail]
  );

  const endTime = useMemo(
    () => moment(detail?.EndTime, DATE_FORMAT).toDate(),
    [detail]
  );

  return (
    <Table>
      <StyledTr>
        <StyledTd align="left" noBorder>
          <Text size={TextSize.s14}>
            <FormattedMessage id="workout.summary.totalTime" />
          </Text>
          <br />
          <StyledAdditionalInfo size={TextSize.s14}>
            <FormattedMessage id="workout.summary.startEnd" />
          </StyledAdditionalInfo>
        </StyledTd>
        <StyledTd align="right" noBorder minWidth={detail ? undefined : '60px'}>
          {detail ? (
            <>
              <Text size={TextSize.s14} monoSpace>
                {formatSecondsWithUnit(detail.TotalTime)}
              </Text>
              <br />
              <StyledAdditionalInfo monoSpace size={TextSize.s14}>
                <FormattedTime value={startTime} />
                &nbsp;-&nbsp;
                <FormattedTime value={endTime} />
              </StyledAdditionalInfo>
            </>
          ) : (
            <ChartLoader width={60} height={18} />
          )}
        </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd align="left">
          <Text size={TextSize.s14}>
            <FormattedMessage id="workout.summary.totalDistance" />
          </Text>
          <br />
          <StyledAdditionalInfo size={TextSize.s14}>
            <FormattedMessage id="workout.summary.ascent" />
          </StyledAdditionalInfo>
        </StyledTd>
        <StyledTd align="right" minWidth={detail ? undefined : '50px'}>
          {detail ? (
            <>
              <Text size={TextSize.s14} monoSpace>
                {formatDistanceWithUnit(detail.TotalDistance || 0)}
              </Text>
              <br />
              <StyledAdditionalInfo monoSpace size={TextSize.s14}>
                {formatAscentWithUnit(detail.TotalAscent || 0)}
              </StyledAdditionalInfo>
            </>
          ) : (
            <ChartLoader width={50} height={18} />
          )}
        </StyledTd>
      </StyledTr>
      <StyledTr>
        <StyledTd align="left">
          <Text size={TextSize.s14}>
            <FormattedMessage id="workout.summary.averageHr" />
          </Text>
          <br />
          <StyledAdditionalInfo size={TextSize.s14}>
            <FormattedMessage id="workout.summary.minMaxHr" />
          </StyledAdditionalInfo>
        </StyledTd>
        <StyledTd align="right" minWidth={detail ? undefined : '70px'}>
          {detail ? (
            <>
              <Text size={TextSize.s14} monoSpace>
                <FormattedMessage
                  id="attributes.workout.hr"
                  values={{ hr: detail.HeartRateAverage }}
                />
              </Text>
              <br />
              <StyledAdditionalInfo monoSpace size={TextSize.s14}>
                <FormattedMessage
                  id="attributes.workout.hrRange"
                  values={{
                    min: detail.HeartRateMin || '?',
                    max: detail.HeartRateMax || '?',
                  }}
                />
              </StyledAdditionalInfo>
            </>
          ) : (
            <ChartLoader width={70} height={18} />
          )}
        </StyledTd>
      </StyledTr>
    </Table>
  );
}
