import { RootStore } from '../../app/mobx/root-store';
import { searchPlaces } from '../api/search-places';

export class GoogleMapsService {
  private readonly _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
  }
  public async searchPlaces(textQuery: string) {
    const request = this._rootStore.requestsStore.createRequest(() =>
      searchPlaces({ textQuery })
    );

    const response = await request.getResponse();

    if (request.statusCode === 200 && response) {
      return response.places;
    }
  }
}
